<template>
  <div class="shopping-button-wrapper">
    <CustomButton class="booking-button" @click="handle_book_click">
      <div v-if="width > 480">Boka här</div>
      <div v-else><shoppingBag /></div>
    </CustomButton>
    <CustomButton v-if="bookingStore.shoppingCart.length > 0 && width > 480">
      <div class="shopping-button" @click="state.showSummary = true">
        <CustomIcon></CustomIcon>
      </div>
      <div class="shopping-cart-number">
        {{ bookingStore.itemsInShoppingCart }}
      </div>
    </CustomButton>
  </div>
  <CustomModal
    :show="state.showSummary"
    @onClose="state.showSummary = false"
    enterFrom="right"
    :fullHeight="true"
  >
    <template v-slot:header> Kundvagn </template>
    <template v-slot:body>
      <div class="body-wrapper">
        <div>
          <TicketSummary
            v-for="(ticket, index) in bookingStore.shoppingCart"
            :key="`summary-${ticket.id}-${index}`"
            :ticketId="ticket.id"
            :ageGroup="ticket.age"
            :comfort="ticket.comfort"
            :price="String(ticket.price)"
            :title="ticket.title"
          >
          </TicketSummary>
        </div>
        <div class="footer">
          <div>Totalt</div>
          <div class="price">
            {{ bookingStore.totalPrice(bookingStore.shoppingCart) }} Kr
          </div>
          <CustomButton @click="onNavButtonClick">Gå till kassa</CustomButton>
        </div>
      </div>
    </template>
  </CustomModal>
</template>

<script lang="ts" setup>
import { router } from "@/router/router";
import CustomButton from "../generics/CustomButton.vue";
import CustomIcon from "../generics/CustomIcon.vue";
import CustomModal from "../generics/CustomModal.vue";
import TicketSummary from "./TicketSummary.vue";
import shoppingBag from "@/assets/SVG/shoppingBag.vue";
import { useBookingStore } from "@/store/bookingStore";
import { reactive, defineProps } from "vue";
import { useTrackingStore } from "@/store/trackingStore";
import { useWindowSize } from "vue-window-size";

const bookingStore = useBookingStore();
const trackingStore = useTrackingStore();

const { width, height } = useWindowSize();

const state = reactive({
  showSummary: false,
});

function handle_book_click() {
  trackingStore.trackEvent("view_item_list");
  router.push({ name: "cardbuy" });
}

function onNavButtonClick() {
  state.showSummary = false;
  router.push({ name: "cardbuy" });
}
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";

.shopping-button {
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
}

.shopping-cart-number {
  position: absolute;
  right: 0.6rem;
  top: 0.6rem;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $secondary-color;
  font-weight: bold;
}

.shopping-button-wrapper {
  display: flex;
  column-gap: 0.5rem;
}

.booking-button {
  height: 2.5rem;
  text-wrap: nowrap;
}

.body-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.footer {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  .price {
    font-size: 1.5rem;
    text-align: end;
    border-bottom: 1px solid lightgray;
  }
}
</style>
