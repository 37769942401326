<template>
  <div class="customer_input">
    <h2 class="title">
      {{ bookingStore.get_ticket_by_id(shopping_cart_ticket.id).title }}
    </h2>
    <div class="divider"></div>
    <div class="ticket-info">
      <div class="icon-and-text">
        <PersonIcon />
        <CustomSelect
          :id="String(index_in_shoppingcart)"
          label="Åldersgrupp"
          :options="[
            { value: 'CHD', name: 'Barn' },
            { value: 'YTH', name: 'Ungdom' },
            { value: 'ADT', name: 'Vuxen' },
            { value: 'SEN', name: 'Senior' },
          ]"
          :destyle="true"
          :initialySelected="
            [
              { value: 'CHD', name: 'Barn' },
              { value: 'YTH', name: 'Ungdom' },
              { value: 'ADT', name: 'Vuxen' },
              { value: 'SEN', name: 'Senior' },
            ].find((select_obj) => select_obj.value == shopping_cart_ticket.age)
              ?.name
          "
          @selected="handleAgeSelected"
        ></CustomSelect>
      </div>
      <div class="icon-and-text">
        <SeatIcon />
        <CustomSelect
          :id="String(index_in_shoppingcart)"
          label="Klass"
          :options="[
            { value: 'first', name: 'Första klass' },
            { value: 'second', name: 'Andra klass' },
          ]"
          :destyle="true"
          :initialySelected="shopping_cart_ticket.comfort"
          @selected="handleComfortSelected"
        ></CustomSelect>
      </div>
    </div>
    <div class="selections"></div>
    <div class="input-fields">
      <div class="input-fields-wrapper">
        <CustomInputField
          :id="String(index_in_shoppingcart)"
          class="input-field"
          label="Förnamn"
          :init-value="shopping_cart_ticket.firstName"
          @onInput="handleFirstNameInput"
        ></CustomInputField>
        <CustomInputField
          :id="String(index_in_shoppingcart)"
          class="input-field"
          label="Efternamn"
          :init-value="shopping_cart_ticket.lastName"
          @onInput="handleLastNameInput"
        ></CustomInputField>
      </div>
      <div class="input-fields-wrapper">
        <CustomSelect
          :id="String(index_in_shoppingcart)"
          class="input-field"
          label="Födelseland"
          :initialy-selected="shopping_cart_ticket.country"
          :options="[
            { value: 'SE', name: 'Sverige' },
            { value: 'NO', name: 'Norge' },
            { value: 'DK', name: 'Danmark' },
          ]"
          @selected="handleCountryInput"
        ></CustomSelect>
        <CustomInputField
          :id="String(index_in_shoppingcart)"
          class="input-field"
          label="Födelsedatum"
          :init-value="shopping_cart_ticket.dateOfBirth"
          @onInput="handleBirthdayInput"
          input-type="date"
        ></CustomInputField>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive, defineProps, PropType, Prop } from "vue";
import CustomInputField from "../generics/CustomInputField.vue";
import CustomSelect from "../generics/CustomSelect.vue";
import PersonIcon from "../../assets/SVG/personIcon.vue";
import SeatIcon from "../../assets/SVG/seatIcon.vue";
import { useBookingStore } from "@/store/bookingStore";
import ticket from "@/types/ticket";

const props = defineProps({
  index_in_shoppingcart: {
    type: Number,
    required: true,
  },
  shopping_cart_ticket: {
    type: Object as PropType<ticket>,
    required: true,
  },
});

const bookingStore = useBookingStore();

const state = reactive({
  firstName: props.shopping_cart_ticket.firstName,
  lastName: props.shopping_cart_ticket.lastName,
  country: props.shopping_cart_ticket.country,
  birthday: props.shopping_cart_ticket.dateOfBirth,
});

function handleFirstNameInput(input: string) {
  state.firstName = input;
  bookingStore.update_ticket_in_shoppingcard(props.index_in_shoppingcart, {
    firstName: state.firstName,
  });
}

function handleLastNameInput(input: any) {
  state.lastName = input;
  bookingStore.update_ticket_in_shoppingcard(props.index_in_shoppingcart, {
    lastName: state.lastName,
  });
}

function handleBirthdayInput(input: any) {
  state.birthday = input;
  bookingStore.update_ticket_in_shoppingcard(props.index_in_shoppingcart, {
    dateOfBirth: state.birthday,
  });
  isValidAge();
}

function handleCountryInput(input: any) {
  state.country = input;
  bookingStore.update_ticket_in_shoppingcard(props.index_in_shoppingcart, {
    country: input.value,
  });
}

function handleAgeSelected(input: any) {
  bookingStore.update_ticket_in_shoppingcard(props.index_in_shoppingcart, {
    age: input.value,
  });
}

function handleComfortSelected(input: any) {
  bookingStore.update_ticket_in_shoppingcard(props.index_in_shoppingcart, {
    comfort: input.value,
  });
}

function isValidAge() {
  /*   let diff_ms = Date.now() - Date.parse(state.birthday);
  let age_dateTime = new Date(diff_ms);
  const age = Math.abs(age_dateTime.getUTCFullYear() - 1970);
  return age; */
  console.log(
    bookingStore.is_age_valid_for_ticket(
      props.index_in_shoppingcart,
      state.birthday
    )
  );
}
</script>

<style scoped lang="scss">
@import "../../styles/variables.scss";
.customer_input {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.input-fields {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.input-fields-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 1rem;
}

.input-field {
  width: 100%;
}

.divider {
  border-bottom: 2px solid grey;
}

.ticket-info {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  .icon-and-text {
    display: flex;
    column-gap: 0.5rem;
    align-content: center;
    align-items: center;
  }
}

@media (max-width: $screen-size-mobile) {
  .input-fields-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    justify-content: space-between;
    column-gap: 1rem;
  }

  .ticket-info {
    flex-direction: column;
    row-gap: 1rem;
    .icon-and-text {
      width: 100%;
      div {
        width: 100%;
      }
    }
  }
}
</style>
