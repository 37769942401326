<template>
  <div
    :class="[
      destyle ? 'destyle-class' : 'select-frame',
      state.selected != '' ? 'option-selected' : '',
      state.open ? 'select-frame-focused' : '',
    ]"
    @click="handleSelectClick"
  >
    <label class="label" :for="label">{{ label }}</label>
    <input
      :name="label"
      :id="label"
      class="select"
      v-model="state.selected"
      disabled
    />
    <ArrowIcon class="icon"></ArrowIcon>
    <CustomCard class="options" :remove-top-border="true" v-if="state.open">
      <div
        class="option"
        v-for="(option, index) in options"
        :key="`${label}-${option}-${index}`"
        @click.stop="handleOptionSelected(option.name)"
      >
        {{ option.name }}
      </div>
    </CustomCard>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, PropType, reactive, defineEmits } from "vue";
import CustomCard from "./CustomCard.vue";
import ArrowIcon from "@/assets/SVG/ArrowIcon.vue";

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  options: {
    type: Array as PropType<{ value: string; name: string }[]>,
    default: () => [],
  },
  destyle: {
    type: Boolean,
    default: false,
  },
  initialySelected: {
    type: String,
    default: "",
  },
});

const state = reactive({
  selected: props.initialySelected,
  open: false,
});

const emit = defineEmits(["selected"]);

function handleSelectClick() {
  state.open = true;
}

function handleOptionSelected(name: string) {
  state.selected = name;
  state.open = false;
  emit(
    "selected",
    props.options.find((el) => el.name == name)
  );
}
</script>

<style scoped lang="scss">
@import "./../../styles/variables.scss";
.select-frame {
  position: relative;
  background-color: whitesmoke;
  border: none;
  padding: 0.5rem;
  padding-top: 1.5rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 2px solid lightgray;
  .select {
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 1rem;
    padding-left: 0.25rem;
    color: black;
    pointer-events: none;
  }
  .label {
    position: absolute;
    font-size: 1rem;
    top: 1rem;
    left: 1rem;
    color: grey;
    transition: all 0.1s ease-in-out;
  }

  .icon {
    position: absolute;
    height: 1rem;
    width: 1rem;
    right: 0.5rem;
    top: calc(50% - 0.5rem);
  }
}

.select-frame:hover {
  background-color: whitesmoke;
}

.select-frame-focused {
  border-bottom: 2px solid $primary-color;
  .icon {
    transform: rotate(180deg);
  }
}

.destyle-class {
  position: relative;
  border: none;
  /*  padding: 0.5rem;
  padding-top: 1.5rem; */
  .select {
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 1rem;
    padding-left: 0.25rem;
    color: black;
    pointer-events: none;
    cursor: pointer;
  }
  .label {
    display: none;
  }

  .icon {
    position: absolute;
    height: 1rem;
    width: 1rem;
    right: 0.5rem;
    top: calc(50% - 0.5rem);
  }
}

.destyle-class:hover {
  /* background-color: whitesmoke; */
}

.select-frame-focused {
  border-bottom: 2px solid $primary-color;
  .icon {
    transform: rotate(180deg);
  }
}

.option-selected {
  .label {
    position: absolute;
    font-size: 0.75rem;
    top: 0.5rem;
    left: 0.75rem;
  }
}

.options {
  position: absolute;
  top: calc(100% + 2px);
  background-color: white;
  left: 0;
  width: 100%;
  row-gap: 0.5rem;
  display: flex;
  flex-direction: column;
  z-index: 1;

  .option {
    padding: 0.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    &:hover {
      background-color: whitesmoke;
    }
  }
}

/* REMOVE ARROW */
select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}

/* For IE10 */
select::-ms-expand {
  display: none;
}
</style>
