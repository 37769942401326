<template>
	<svg version="1.1" id="Lager_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
		y="0px" width="100%" height="100%" viewBox="0 0 1193.37 346.582"
		enable-background="new 0 0 1193.37 346.582" xml:space="preserve">
		<g>
			<defs>
				<path id="SVGID_1_" d="M40.217,174.984c0,54.876,31.112,102.483,76.66,126.148c0,0,181.577-39.785,187.825-51.205
			c11.644-21.284,19.653-48.935,19.653-74.943c0-78.462-63.607-142.068-142.069-142.068
			C103.823,32.916,40.217,96.522,40.217,174.984" />
			</defs>
			<clipPath id="SVGID_2_">
				<use xlink:href="#SVGID_1_" overflow="visible" />
			</clipPath>
			<path clip-path="url(#SVGID_2_)" fill="#A0CF69" d="M28.481-70.765c0.193,0.193,0.516,0.321,0.709,0.515
		c0.129,0.129,0.516,0.58,0.322,0.515c-0.581-0.257-1.675-1.998-1.998-0.129c-0.129,0.967,3.029,0.859,3.222,0.924
		c0.773,0.064,1.417,0.644,2.255,0.709c0.387,0.064,0.773-0.258,1.224-0.258c1.225,0.064,3.157,0.644,4.252,1.095
		c0.387,0.129,1.934,2.126,1.74,2.448c-0.516,0.839-0.707-2.577-2.126-2.19c-0.262,0.062-0.405,0.151-0.451,0.279
		c-0.047-0.134-0.099-0.279-0.193-0.472c-0.064-0.129-0.193,0.386-0.322,0.322c-0.193-0.129,0-0.516-0.193-0.58
		c-0.452-0.065-0.903,0.322-1.418,0.322c-0.516-0.064-0.773-0.837-1.289-0.902c-0.386-0.064-0.773,0.065-1.095,0.258
		c-0.065,0.065,0,0.386-0.129,0.322c-0.387-0.194-0.644-0.644-1.03-0.838c-0.452-0.193-0.967-0.257-1.483-0.322
		c-0.258,0-0.515,0.194-0.773,0.129c-0.838-0.129-1.545-0.708-2.448-0.773c-0.194-0.064-0.451,0-0.58,0.129
		c-1.547,2.708,1.74,1.804,1.869,2.384c0.128,0.451-0.581,0.902-0.323,1.288c0.129,0.258,0.645-0.193,0.967-0.129
		c0.451,0.194,0.966,0.451,1.288,0.902c0.194,0.258-0.579-0.257-0.901-0.322c-0.968-0.193-1.805-0.064-1.805-0.064
		c-0.193-0.516,0.322-1.16,0-1.611c-0.322-0.386-0.966-0.193-1.481-0.322c-0.323-0.129-0.58-0.387-0.903-0.387
		c-0.58,0-0.322,1.418,0.194,1.676c0.386,0.193,0.967-0.645,1.224-0.258c0.451,0.644-1.353,1.933-1.611,2.062
		c-0.322,0.193-1.418,0.322-1.03,0.515c0.386,0.258,0.966,0.193,1.417,0.322c0.258,0.065,0.515,0.451,0.838,0.451
		c0.257,0,0.45-0.451,0.708-0.451c0.516-0.064,2.061,0.709,2.384,1.095c0.127,0.159,0.261,0.656,0.365,1.117
		c-0.469-0.838-1.311-1.654-1.912-1.825c-0.708-0.193-1.416,0.387-2.125,0.257c-0.387-0.064-1.869-1.224-2.32-0.837
		c-2.45,2.578,1.288,0.515,1.095,1.611c-0.129,0.515-1.03,0-1.482,0.193c-0.193,0.064,0.065,0.644-0.128,0.58
		c-0.258-0.065-0.409-0.451-0.602-0.387c-0.193,0.065,0.064,0.387-0.064,0.516c-0.065,0.128-0.323,0.128-0.323,0.257
		c-0.064,0.194,0.323,0.451,0.129,0.516c-0.515,0.258-1.288-0.065-1.675,0.386c-0.216,0.304,0.254,0.653,0.752,0.838
		c-1.598-0.179-2.623,1.034-3.2,1.739c-0.064,0.065-1.482,0.903-1.353,1.096c0.644,1.031,5.412-0.838,5.541-0.645
		c0.257,0.387-0.966,0.516-0.902,0.967c0,0.387,0.644,0.322,0.966,0.387c0.322,0.064,0.666-0.065,0.988,0.064
		c0.065,0.064-0.257,0.129-0.257,0.258c0.064,0.064,0.193,0.129,0.257,0.064c0.086-0.06,0.169-0.134,0.258-0.193
		c-0.038,0.108-0.028,0.217,0,0.322c0.451,1.483,2.255,0.128,1.868,1.095c-0.709,1.741-1.868,0.064-2.448,1.547
		c-0.258,0.644,0.301,2.061-0.408,2.448c-0.258,0.193-0.258-2.255-0.193-2.255c0.128-0.838,1.761-1.417,0.988-2.384
		c-0.258-0.322-0.731-0.386-1.117-0.515c-1.741-0.58-0.579,2.447-0.579,2.899c0,0.129-0.323-0.129-0.387-0.258
		c-0.129-0.386,0-0.902-0.194-1.288c-0.644-1.032-1.803,0.45-2.19,0.515c-1.935,0.129-0.063-2.126-1.869-2.255
		c-0.838-0.064-0.065,1.868,0.967,2.448c0.515,0.258,1.159,0.58,1.739,0.709c0.451,0.129,1.547-0.323,1.289,0.065
		c-0.258,0.451-1.354-0.13-1.482,0.321c-0.194,0.516,0.837,0.516,1.16,0.903c0.064,0.129,0.129,0.45,0,0.515
		c-0.194,0-0.194-0.386-0.323-0.451c-0.322-0.193-0.773-0.129-1.031-0.386c-0.193-0.194,0-0.58-0.193-0.774
		c-0.193-0.258-0.644-0.128-0.902-0.257c-0.386-0.258-0.902-1.16-1.417-1.224c-0.129-0.065-0.065,0.579-0.194,0.45
		c-0.193-0.322,0-0.708-0.129-1.03c-0.064-0.129-0.385-0.194-0.45-0.065c-0.064,0.387,0.451,0.773,0.258,1.095
		c-0.259,0.451-0.967,0.323-1.354,0.645c-0.064,0.129,2.062,0.837,2.062,0.837c-0.516,0-1.095,0-1.61-0.193
		c-0.323-0.129-0.516-0.902-0.838-0.709c-0.516,0.194-0.258,1.031-0.58,1.482c-0.194,0.194-0.773-0.193-0.837,0.065
		c-0.645,1.804,3.415,0.386,2.835,1.997c-0.323,1.031-2.578-1.418-3.093-0.902c-0.129,0.064,0.193,0.193,0.129,0.258
		c-0.065,0.129-0.323,0.064-0.387,0.193c-0.258,0.258,0.966,1.482,1.224,1.739c0.193,0.194,0.451,0.194,0.644,0.387
		c0.129,0.129,0.258,0.451,0.129,0.387c-0.258,0-0.322-0.516-0.516-0.451c-0.451,0.258-0.514,0.966-0.965,1.224
		c-0.258,0.129-2.513-0.645-2.191,0.902c0.129,0.515,1.095,0.321,1.288,0.838c0.129,0.257-0.644-0.129-0.902-0.065
		c-0.258,0-0.579,0.064-0.772,0.193c-0.129,0.065-0.065,0.258-0.129,0.322c-0.033,0.032-0.135,0.057-0.215,0.065
		c1.169-0.014,2.052-0.242,3.114,0.258c0.129,0,0.258,0.322,0.129,0.386c-1.612,0.838-4.961-0.259-5.606,1.997
		c-0.064,0.387-0.322,0.773-0.193,1.16c0.065,0.193,0.58-0.322,0.645-0.129c0.322,1.032-0.774,0.645-0.709,0.773
		c0.322,0.645,1.546,1.611,1.481,1.74C8.164-35.5,7.133-38.271,6.36-37.305c-0.322,0.387,1.16,1.481,1.289,1.481
		c0.387-0.064,0.708-0.515,1.159-0.644c0.129-0.064,0.387-0.064,0.387,0.065c-0.064,2.191-1.159,1.096-2.126,0.515
		c-0.451-0.258-1.546,0.516-1.417,0.58c0.322,0.193,1.288-0.258,1.159,0.065c-0.193,0.386-0.966,0-1.288,0.193
		c-0.193,0.129-0.322,0.58-0.129,0.58c0.709,0.128,1.417-0.516,2.191-0.516c0.322,0.065,1.095,0.516,0.773,0.58
		c-0.259,0.129-3.802-0.645-3.157,1.16c0.193,0.451,0.772,0.579,1.095,0.966c0.128,0.065,0.257,0.387,0.128,0.387
		c-0.451-0.194-0.836-0.451-1.159-0.773c-0.258-0.323-0.386-0.773-0.644-1.16c-0.065,0,0,0.193-0.065,0.193
		c-0.129,0.065-0.386-0.193-0.386-0.064c-0.238,0.768-0.185,1.238,0.021,1.524c-0.301-0.164-0.513-0.185-0.537,0.086
		c0,1.032,2.514,2.405,2.19,3.695c-0.257,0.838-1.223,0.515-1.739,0.837c-0.129,0.065-0.258,0.258-0.129,0.322
		c0.451,0.194,1.289-0.065,1.418,0.387c0.129,0.451-0.773,0.709-1.16,0.58c-0.516-0.065-0.708-0.709-1.095-1.096
		c-0.13-0.128-0.451-0.644-0.515-0.386c-0.129,0.58,2.19,2.126,1.803,2.19c-0.773,0.194-1.352-0.644-2.126-0.708
		c-0.128-0.065-0.58-0.129-0.515,0.064c0.322,0.709,1.03,1.159,1.481,1.804c0.129,0.258,0.387,0.902,0.516,0.644
		c0.193-0.451-0.58-1.288-0.129-1.482c1.612-0.709,1.096,1.547,1.16,1.611c0.322,0.258,1.03-0.129,1.159,0.258
		c0.129,0.451-0.385,0.837-0.708,1.224c-0.064,0.065-0.258,0-0.258,0.065c-0.064,0.129,0.065,0.322,0.194,0.257
		c1.16-0.709,0.321-1.676,2.255-0.386c0.193,0.064,0,0.45,0.129,0.579c0.193,0.065,0.064-0.386,0.193-0.45
		c0.129-0.065,0,0.322,0.129,0.322s0.322-0.451,0.258-0.322c0,0.386-0.58,0.901-0.323,1.224c0.258,0.258,0.257-0.967,0.581-0.902
		c0.515,0.064,0.579,0.902,1.03,1.16c0.193,0.128,0.58-0.065,0.709-0.323c0.129-0.386-0.516-1.03-0.194-1.288
		c0.452-0.258,1.224-0.001,1.353,0.515c0.193,0.452-0.644,0.773-0.837,1.224c-0.064,0.129,0.193,0.451,0.065,0.451
		c-2.451,0.387-0.128-0.902-2.32-0.451c-0.193,0-0.322,0.387-0.193,0.516c0.644,0.322,1.932,1.031,2.641,0.58
		c0.387-0.258,0.708-1.418,0.837-1.031c0.226,0.451-0.589,0.85-0.665,1.288c-0.941,0.179-0.417,0.559-2.234-0.451
		c-0.387-0.193-2.062-0.322-1.997-0.064c0,0.064,0.902,1.482,1.353,1.546c0.065,0,3.802-0.903,2.062,0.58
		c-0.193,0.129-0.322-0.322-0.516-0.386c-0.386-0.065-0.837-0.065-1.223-0.065c-0.065,0.065,0.064,0.258,0,0.194
		c-0.387-0.065-0.774-0.452-1.161-0.387c-0.193,0,0,0.386,0.129,0.579c0.129,0.129,0.774,0.258,0.58,0.258
		C7.069-17.375,5.264-18.6,6.038-17.89c0.386,0.386,1.16,0.257,1.418,0.773c0.193,0.515-2.321,1.353-1.096,1.224
		c0.645-0.065,1.16-0.322,1.74-0.515c0.194,0,0.257-0.452,0.45-0.387c0.324,0.129,1.096,0.966,0.323,1.353
		c-0.58,0.258-1.546-0.065-1.804,0.515c-0.58,1.032,1.868,0.193,2.577,0.129c0.322-0.065,1.096-0.129,0.967,0.129
		c-0.451,0.709-1.997,0.387-2.577,0.644c-0.129,0.065,0.387,0.193,0.258,0.322c-0.258,0.258-0.58,0.516-1.032,0.58
		c-0.644,0.065-1.481,0.065-1.61,0.129c-0.064,0.065-1.16,0.386-0.58,0.837c0.258,0.323,0.901,0,1.224,0.258
		c0.193,0.258,0.257,0.644,0.193,0.967c-0.065,0.322-0.902,0.773-0.516,0.902c0.451,0.193,0.903-0.516,1.418-0.58
		c0.194,0,0.194,0.322,0.387,0.387C8.294-9.9,8.036-10.61,8.294-10.675c0.129-0.064,0.128,0.323,0.256,0.258
		c0.259-0.129,0.258-0.644,0.516-0.708c0.258-0.13,0.838-0.065,0.774,0.193c-0.194,0.58-1.482,0.45-1.546,1.095
		c0,0.129,0.256,0.064,0.385,0.193c0.387,0.387,0.387,1.16,0.903,1.418C9.646-8.162,9.84-8.419,9.904-8.354
		c0.064,0.258-0.001,2.448,0.967,2.126c1.998-0.644,0.192-1.095,0.901-2.384c-0.102-0.044-0.211-0.092-0.322-0.128
		c-0.258-0.129-1.03-0.194-0.773-0.194c0.58-0.129,1.74,0.451,1.675-0.129c0-0.322-0.709-1.675-0.773-1.675
		c-0.194-0.129-0.644,0.451-0.579,0.258c0.129-0.258,0.45-0.386,0.45-0.644c-0.065-0.516-1.031-1.418-0.515-1.418
		c0.065,0,0.065,0.194,0.129,0.129c0.065-0.065-0.001-0.451,0.064-0.322c0.581,1.289,1.159,2.705,2.32,3.737
		c0,0,1.804,1.611,1.997,0.193c0-0.258-0.645,0.129-0.902-0.064c-0.323-0.323-0.838-0.838-0.645-1.353
		c0.129-0.387,0.387,0.837,0.774,1.03c0.257,0.194,0.58,0.194,0.902,0.129c0.451,0-1.225-2.577-1.354-2.964
		c-0.064-0.129,1.161,1.869,1.354,2.127c0.129,0.129,0.451,0.451,0.451,0.322c0.065-1.225-0.902-1.739-1.289-2.706
		c-0.064-0.129,0.258-0.322,0.387-0.258c0,0.065,0.902,1.868,0.902,1.868c0.516,0.129,1.03-0.257,1.482-0.322
		c0.128,0,0.322,0.194,0.322,0.065c-0.322-0.838-1.03-1.417-1.482-2.126c-0.194-0.323-0.902-1.096-0.515-1.031
		c0.644,0.129,1.739,2.77,2.512,3.092c1.612,0.645-2.579-3.285-0.257-3.221c3.609,0.129-0.839,1.353,1.352,2.061
		c0.451,0.129,0.838-0.386,1.225-0.644c0.193-0.128,0.709-0.257,0.644-0.58c-0.005-0.021-0.01-0.042-0.021-0.064
		c0.095,0.113,0.185,0.205,0.279,0.193c0.902-0.129,1.761-0.257,1.632-0.451c-0.258-0.387-0.859-0.515-1.117-0.902
		c-0.07-0.174-0.023-0.366,0.021-0.558c0.433,0.871,0.848,1.009,2.191,0.687c0.387-0.064,1.095-0.257,0.966-0.644
		c-0.064-0.452-0.836-0.451-1.288-0.709c-0.064-0.064-0.257-0.129-0.193-0.193c0.322-0.065,3.093-0.838,2.964-1.353
		c-0.193-0.451-0.645-0.773-0.774-1.224c-0.064-0.258,0.258-0.516,0.258-0.773c0-0.129-0.386-1.676-0.064-1.289
		c0.129,0.129,1.03,4.124,1.352,3.028c0.129-0.516-0.58-0.966-0.515-1.546c0.064-0.581,0.709-1.16,0.515-1.74
		c-0.129-0.451-0.579-0.708-0.643-1.159c-0.065-0.194,0.192-0.58,0.321-0.452c0.387,0.258,0.387,0.838,0.58,1.225
		c0.065,0.128,0.065,0.322,0.193,0.386c0.967,1.032,1.804-0.194,2.9,0.773c0.193,0.129,0,0.516,0.129,0.709
		c0.258,0.516,1.481,0.194,1.61-0.387c0.129-0.451-0.708-0.772-0.772-1.224c-0.065-0.257,0.579-2.319,1.417-1.482
		c0.902,0.967-0.838,4.254,0.773,2.255c1.354-1.675,0.387-1.159,0.193-1.868c-0.322-0.967,0.516,0.129,0.516-0.129
		c0-0.258-0.193-0.45-0.129-0.708c0-0.259,0.064-0.516,0.257-0.58c0.323-0.129,0.838-0.323,0.967,0
		c0.237,0.332,0.169,0.738,0.086,1.159c-0.09-0.318-0.194-0.6-0.151-0.386c0.078,0.18,0.096,0.41,0.108,0.644
		c-0.011,0.066-0.035,0.128-0.043,0.194c0,0.128-0.194,0.257-0.194,0.45c0,0.322,0.065,0.709,0.258,1.031
		c0.065,0.129,0.129-0.257,0.194-0.451c0-0.168-0.027-0.352-0.043-0.537c0.043,0.036,0.107,0.07,0.172,0.086
		c0.451,0.065,0.386-1.031,0.837-1.16c0.645-0.193,0.451,1.934,1.095,1.676c1.354-0.451-1.933-5.671,0.258-4.059
		c0.258,0.193-0.193,0.579-0.193,0.837l0.515,1.546c0.065,0.129,0.967,1.289,1.16,1.16c0.516-0.387,1.031-0.966,1.031-1.61
		c0-0.323-0.58-0.645-0.323-0.774c0.387-0.257,0.967,0.129,1.483,0c0.129,0,0.322-0.257,0.193-0.386
		c-1.225-1.418-1.74-0.772-1.418-3.028c0-0.194-0.322-0.387-0.193-0.451c1.031-0.129,0.838,0.579,1.418,1.03
		c0.515,0.452,1.481-0.129,1.675,0.709c0.064,0.194-0.258,0.387-0.194,0.58c0.065,0.129,0.258-0.065,0.387,0
		c0.064,0,0,0.129,0,0.193c0.064,0.065,0.193,0.129,0.258,0.065c0.193-0.451,0.193-1.031,0.387-1.482
		c0.257-0.451,0.772-0.773,1.03-1.224c0.064-0.065-0.194-0.065-0.194-0.194c0.065-0.515-0.064-1.03,0.065-1.546
		c0.064-0.193,0.387,0.194,0.451,0c0.065-0.193-0.258-0.408-0.258-0.666c0-0.193,0.129-0.451,0.194-0.58
		c0.451-0.773,0.129,0.645,0.515,0.258c0.387-0.258,0.194-1.16,0.645-1.224c0.387-0.064,0.515,0.644,0.58,1.031
		c0.064,0.193-0.387,0.58-0.193,0.58c0.386,0,0.643-0.709,1.03-0.58c1.741,0.516-2.257,2.406,1.224,2.019
		c0.645-0.065,2.191-0.666,2.32-1.568c0.064-0.387-0.387-0.709-0.516-1.095c-0.064-0.194-0.129-0.645,0.065-0.58
		c0.451,0.193,0.515,0.902,0.966,0.966c0.452,0.065,0.773-0.515,1.16-0.709c0.128-0.064,0.322-0.193,0.322-0.064
		c0,0.58-0.516,2.062-0.065,1.74c0.387-0.322,0.58-0.773,0.903-1.16c0.129-0.129,0.322-0.387,0.322-0.258
		c0.258,0.709-1.483,3.179,0.773,2.857c0.387,0-0.001-1.095,0.451-1.16c0.386-0.064,0.451,0.644,0.644,1.031
		c0.065,0.193,0,0.644,0.258,0.644c3.61-0.322-1.29-4.339,1.61-3.049c0.839,0.322,0.58,1.696,0.709,1.631
		c0.451-0.322,0.709-0.858,1.16-1.116c0.194-0.194,0.58-0.064,0.773-0.258c0.129-0.129-0.322-0.515-0.129-0.58
		c0.322,0,0.838,0.838,0.902,0.515c0.129-0.515-0.451-0.965-0.644-1.417c-0.065-0.064,0-0.128,0.064-0.128
		c0.387-0.065,0.989-0.258,1.311,0c0.387,0.322,0.258,0.965,0.322,1.481c0.065,0.323,0.579,0.451,0.644,0.773
		c0,0.194-0.386,0-0.579,0.065c-0.452,0.064-0.924,0.064-1.311,0.193c-0.645,0.194-0.644,0.086-1.288,0.215
		c-0.709,0.065-0.065,0.451-0.322,0.644c-0.258,0.193-0.774,0-0.838,0.258c0,0.258,0.58-0.129,0.709,0.064
		c0.515,0.774-0.194,2.062,0.258,2.191c0.902,0.129,0.836-0.902,1.545-1.16c0.194-0.129,0.602-0.773,0.538-0.515
		c-0.258,0.451-0.602,0.901-0.924,1.353c-0.065,0.064-0.194,0.064-0.258,0.128c-0.065,0.194,1.826,0.323,2.148,0.323
		c0.258-0.065,0.451-0.773,0.515-0.709c0.387,0.774,0.064,1.997,0.322,1.997c0.645,0.065,1.676-1.418,1.805-0.773
		c0.387-0.064,0.901,0.065,1.288-0.193c0.258-0.129,0.193-2.062,0.322-1.804c0.774,1.934,0,1.223,0.323,1.997
		c0.064,0.194-0.065,0.773,0.193,0.709c0.387-0.129,0.321-0.709,0.644-0.966c0.129-0.129,0.515-0.258,0.58-0.065
		c0.064,0.387-0.581,0.837-0.387,1.16c0.388,0.515,2.835-0.387,2.899-0.451c0,0,0.129-0.902,0.194-0.967
		c0.257-0.129,0.902-0.709,0.773-0.45c-0.129,0.386-0.387,0.708-0.516,1.03c-0.064,0.129-0.129,0.387-0.064,0.387
		c0.58-0.065,1.16-0.258,1.611-0.58c0.129-0.129,0.064-0.58,0.258-0.516c0.193,0-0.13,0.645,0,0.58
		c0.451-0.257,0.708-0.708,0.966-1.095c0.064-0.129-0.065-0.322,0.064-0.322c0.129-0.064,0.194,0.322,0.323,0.258
		c0.129-0.065-0.194-0.387-0.065-0.451c0.387-0.129,0.902-0.129,1.289-0.065c0.194,0.065,0.257,0.387,0.386,0.258
		c0.258-0.258,0.129-0.773,0.387-1.16c1.548-2.256,0.321-0.064,1.932-0.451c0.452-0.064,0.645-0.601,1.032-0.859
		c0.257-0.129,0.644-0.064,0.837-0.258c0.129-0.128-0.193-0.257-0.257-0.386c0-0.065,0.064-0.258,0.129-0.258
		c0.451,0.129,0.772,0.516,1.223,0.58c0.065,0,1.483-0.516,1.483-0.516c0.386,0.194,0.45,0.967,0.901,0.838
		c0.517-0.064,0.58-0.837,0.902-1.224c0.323-0.322,0.774,0.58,0.709-0.193c0-0.395-0.103-0.787-0.258-1.181
		c0.197,0.252,0.392,0.492,0.645,0.407c0.29-0.12,0.438-0.283,0.494-0.493c0.005,0.041,0.008,0.067,0.022,0.107
		c0.129,0.516,1.03,0.644,0.965,1.095c0,0.451-0.965,0.193-1.094,0.644c-0.129,0.258,0.579,0.129,0.772,0.387
		c0.129,0.129,1.288,3.115,2.255,2.212c0.903-0.902,0.065-1.052-0.064-2.019c-0.065-0.451,0.838,0.537,0.902,0.988
		c0.064,1.161-1.16,0.644,0,1.289c1.16,0.58,1.224-1.998,2.384-1.739c0.386,0.128,0.064,0.836,0,1.288
		c0,0.193-0.323,0.257-0.323,0.451c0.065,0.774,0.967,0.902,1.547,0.515c0.194-0.128,0-0.773,0.323-0.837
		c0.58,0,0.772,0.902,1.352,1.095c0.258,0.129,0.451-0.451,0.709-0.386c0.194,0.128,0.194,0.515,0.387,0.644
		c0.193,0.128,0.064-0.516,0.193-0.773c0.129-0.258,0.128,0.708,0.322,0.966c0.129,0.064,1.74,0.193,2.062,0.258
		c0.258,0.064,0.45,0.451,0.708,0.515c0.71,0.065,1.439-0.58,2.084-0.386c0.193,0-0.258,0.257-0.193,0.386
		c0.257,0.194,0.579,0.258,0.901,0.322c1.097,0.452-0.193-0.515,0.838-0.579c0.322-0.065,0.516,0.451,0.838,0.451
		c0.258,0.064,0.514-0.065,0.772-0.194c0.065-0.064-0.064-0.322,0.065-0.257c0.193,0,0.193,0.451,0.386,0.386
		c0.452-0.193,0.774-0.644,1.032-1.031c0.064-0.193-0.258-0.58-0.065-0.58c0.451,0.065,0.837,0.451,1.224,0.58
		c0.193,0.064,0.387,0.258,0.515,0.129c0.194-0.387-0.257-1.16,0.194-1.353c0.515-0.258,1.096,1.482,1.482,1.031
		c0.323-0.452,0.064-1.224,0.515-1.546c1.226-0.839,0.773,1.224,1.547,1.095c1.418-0.322-0.387-2.062,0.193-1.74
		c0.258,0.194,0.773,2.127,1.482,1.353c0.322-0.322,0.644-0.773,0.709-1.224c0.253,0.354,0.548,0.665,1.223,0.387
		c0.259-0.129-0.129-1.031,0.129-0.902c0.387,0.193,0.065,1.353,0.516,1.224c0.515-0.064,0.129-1.224,0.644-1.417
		c0.387-0.194,0.773,0.579,1.225,0.644c0.258,0.064,0.515-0.645,0.643-0.451c0.065,0.129-0.193,0.386-0.064,0.451
		c0.451,0.451,1.611-0.344,1.547-0.924c-0.065-0.709-1.417-0.129-1.998-0.451c-0.903-0.451-1.996-1.095-2.577-1.932
		c-0.451-0.71-1.417-2.126-1.675-2.642c-0.065-0.193,0.193-0.58-0.065-0.645c-0.386-0.129-1.03,0.645-1.159,0.258
		c-0.193-0.387,1.095-0.515,0.902-0.902c-0.129-0.387-3.157,0.129-3.543-0.064c-1.161-0.581-0.515-1.805-2.256-1.031
		c-0.451,0.194-0.966,1.611-1.675,1.095c-0.451-0.322-0.773-0.708-1.031-1.159c-0.129-0.259,0.064-0.58-0.065-0.774
		c-0.258-0.258-0.837-0.257-1.094-0.515c-0.323-0.387,1.03-0.387,1.417-0.129c0.322,0.194,0.257,0.773,0.58,0.902
		c1.353,0.774,0.965-0.064,1.932-0.258c0.387-0.064,0.838-0.064,1.16,0.065c0.387,0.129,0.451,1.353,1.224,0.773
		c0.323-0.258,0.773-0.773,0.58-1.16c-0.258-0.516-4.058-1.546-4.51-1.933c-0.773-0.515,0.709-0.708,0.452-1.03
		c-0.323-0.452-2.642-0.452-2.578-0.709c0.452-1.483,2.19,0.773,3.158,0.257c0.128-0.064-0.129-0.322,0-0.386
		c0.451-0.258,1.932,0.13,2.126-1.16c0.064-0.516-1.032-1.095-0.645-1.417c0.387-0.387,0.901,0.515,1.289,0.837
		c0.129,0.129,0.128,0.387,0.257,0.516c0.517,0.322,0.193-1.224,0.451-1.804c0-0.13,0.258,0.128,0.322,0.257
		c0,0-0.386,1.74-0.386,1.804c0.838,1.354,5.154,1.805,6.379,0.644c1.74-1.74-0.966-2.641-1.804-3.35
		c-0.065-0.064-0.065-0.129-0.065-0.193c-0.193-0.258-0.257-0.58-0.515-0.644s-0.516,0.193-0.774,0.193
		c-0.064-0.065,0.065-0.193,0.065-0.322c0-0.451,0.129-0.966-0.129-1.289c-0.451-0.451-3.415,0.259-2.255-0.837
		c0.709-0.709,3.157,1.869,3.351,0.515c0.064-0.387-0.258-0.708-0.452-1.031c-0.709-1.805-0.386-1.031,0.129-0.709
		c0.065,0.065,0.129-0.192,0.065-0.321l-1.16-1.096c-0.194-0.902-0.194-3.416,0.709-1.61c0.193,0.451,0.064,1.03,0.128,1.481
		c0.065,0.355,0.107,0.51,0.129,0.559c0.046-0.055,0.092-0.616,0.516-0.108c0.193,0.258,0.258,2.835,0.258,2.9
		c0,0.257-0.581,1.61,0,1.868c1.353,0.516,1.738-0.581,2.577,1.74c0.064,0.322-0.065,2.254,0.129,2.448
		c0.387,0.258,1.03-0.129,1.288,0.258c0.193,0.322-0.387,0.644-0.451,1.03c-0.065,0.323,0.193,1.482-0.065,1.998
		c-0.966,1.934-1.611,3.285-0.515,5.412c0.645,1.354,1.997,0.967,2.577-0.064c0.388-0.774,1.547-2.707,1.353-1.869
		c-0.193,0.58-1.804,2.577-1.546,3.222c0.323,0.773,1.289,1.482,2.126,0.837c2.708-1.999-0.516-1.03,0.516-1.997
		c0.129-0.129,0.321,0.322,0.515,0.257c0.258,0-0.516-0.321-0.45-0.515c0.128-0.193,0.643,0,0.579-0.193
		c0-0.258-0.644-0.194-0.644-0.516c0.065-0.451,1.61-0.064,1.546-0.644c-0.064-0.387-1.031-0.258-0.967-0.644
		c0.065-0.387,1.225,0.065,1.16-0.387c-0.028-0.127-0.157-0.227-0.322-0.322c0.214,0.025,0.391,0.016,0.451-0.064
		c0.258-0.387-0.258-1.031,0.065-1.418c0.193-0.258,0.772,0.322,1.03,0.065c0.387-0.323,0.258-0.902,0.387-1.418
		c0-0.064,0.193-0.258,0.064-0.258c-0.515-0.129-1.095-0.257-1.546,0c-0.451,0.194-0.515,0.773-0.902,1.096
		c-0.065,0.064-0.258,0.128-0.258,0.064c0.129-1.354,0.966-2.126,2.191-2.577c0.193-0.065,0.451-0.065,0.709-0.129
		c0.104,0,0.405-0.145,0.451-0.193c-0.323-0.064-3.222,0.645-2.126-0.387c0.322-0.322,1.288-0.193,1.159-0.644
		c-0.193-0.516-1.159-0.064-1.546-0.386c-0.258-0.129,0.516-0.129,0.773-0.194c0.387-0.129,0.837-0.064,1.031-0.386
		c0.322-0.452-0.322-1.096-0.708-1.547c-0.065-0.064-0.451-0.064-0.387-0.193c0.129-0.129,0.45-0.129,0.451-0.258
		c-0.065-0.451-0.322-0.901-0.644-1.16c-0.194-0.193-0.709,0.13-0.709-0.128c0-0.387,0.773-0.451,0.709-0.838
		c0-0.451-0.58-0.709-0.774-1.16c-0.193-0.322-0.064-0.773-0.258-1.159c-0.451-0.709-1.932-0.387-2.448,0.064
		c-0.258,0.258-0.322,0.644-0.58,0.837c-0.129,0.065-0.516,0.194-0.451,0.065c0.064-0.387,0.387-0.58,0.644-0.773
		c0.323-0.322,0.903-0.386,1.161-0.838c0.064-0.193-0.774-0.386-0.516-0.451c1.418-0.386,2.448,1.612,3.093-0.837
		c0-0.194-0.065-0.387-0.129-0.516c-0.645-2.127-0.45-1.546-2.126-1.482c-0.258,0-0.451-0.322-0.709-0.257
		c-0.451,0.064-0.902,0.966-1.289,0.644c-0.451-0.258-0.258-1.095,0-1.482c0.258-0.258,0.838,0.451,1.095,0.194
		c0.645-0.774-1.61-1.225-1.803-1.676c-0.065-0.193,1.546,0.451,1.675,0.58c0.322,0.258,0.257,0.773,0.451,1.16
		c0.58,0.773,1.03-0.193,1.739-0.129c0.193,0,2.384,1.676,2.255-0.451c0-0.058-1.632-2.057-2.169-2.534
		c0.041-0.071,0.067-0.142,0.107-0.237c0,0-1.223-2.383-1.094-2.899c0.064-0.193,0.45-0.129,0.579,0
		c0.386,0.387-0.065,1.095,0.129,1.611c0.064,0.193,0.257,0.322,0.451,0.451c0.064,0.065,0.258-0.064,0.322,0.065
		c0.129,0.257,0,0.644,0.129,0.901c0.645,1.548,2.448-0.451,2.77-0.451c0.516,0.065,1.031,0.838,1.417,0.516
		c0.129-0.065-0.257-3.093-0.257-3.157c-0.065-0.387,0.064-0.709,0.193-1.031c0-0.129,0.193-0.322,0.258-0.194
		c0.064,0.646-0.258,4.317,0.451,4.446c0.387,0.065,1.095-2.448,2.19-1.546c0.838,0.709,0.709,2.834,1.483,3.286
		c0.451,0.322,1.352-0.258,1.546,0.193c0.129,0.452-0.966,0.064-1.482,0.064c-0.129-0.064-0.257-0.128-0.322-0.193
		c0-0.064-1.095-3.479-1.74-2.513c-0.645,1.096,0.515,4.832,1.418,5.219c0.387,0.194,0.901-0.064,1.352-0.064
		c0.323,0,0.774,0.065,1.096-0.064c0.064-0.065-0.064-0.838,0.387-0.58c0.322,0.193,1.546,1.61,2.061,1.353
		c1.547-0.774,0.516-1.095,1.032-1.997c0.128-0.259,1.545-0.129,1.223-0.967c-0.129-0.323-0.708-0.258-0.966-0.515
		c-0.064-0.065-1.16-2.449-1.289-2.385c-0.967,0.388-1.094,1.417-1.417,2.256c-0.064,0.322-0.515,0.966-0.515,0.708
		c-0.065-0.451,1.288-2.19,1.159-2.77c-0.129-0.387-0.773-0.644-0.773-1.031c0-1.031,1.546-0.193,1.74-0.838
		c0.129-0.386-0.838-0.708-0.645-1.031c0.258-0.258,0.645,0.58,0.967,0.58c0.387,0,0.773-0.193,0.902-0.515
		c0.258-0.451-0.452-1.482,0.065-1.611c0.515-0.129,0.063,1.095,0.385,1.546c0.258,0.387,2.599,0.902,2.535,1.289
		c0,0.58-0.516,3.158,1.289,2.384c0.644-0.258,1.417-0.579,1.674-1.224c0.194-0.452,0.129-1.869,0-1.869
		c-0.386-0.064-0.836,0.451-1.223,0.323c-0.387-0.194-0.258-0.773-0.516-1.031c-0.064-0.129-1.932-0.774-1.868-0.838
		c0.451-0.193,1.03,0.193,1.353-0.065c0.322-0.193,0-0.708,0.193-1.03c0.258-0.58,1.482-0.967,1.16-1.482
		c-1.097-1.612-2.319,0.58-2.641-0.258c-0.129-0.322,0.064-0.644,0-0.966c-0.065-0.452-0.709-0.967-0.387-1.353
		c0.258-0.323,0.451,0.708,0.58,1.159c0.064,0.258-0.322,0.838-0.065,0.903c0.516,0.193,1.031-0.387,1.546-0.452
		c0.451-0.064,1.288,0.774,1.482,0.58c0.968-0.967-1.031-3.414-1.546-3.995c-0.031-0.031-0.053-0.089-0.086-0.128
		c0.35,0.406,0.827,0.867,1.246,0.902c0.387,0.064-0.645-2.019-0.709-2.405H46.328v0.064c0.049,0.145,0.286,0.378,0.344,0.451
		c-0.004-0.001-0.014,0.004-0.022,0c-0.258-0.194-0.451-0.322-0.58-0.515h-0.58c0.129,0.257,0.193,0.515,0.193,0.708
		c0.065,0.322-0.257-0.322-0.579-0.708h-2.191c0.06,0.12,0.142,0.237,0.258,0.343c-0.066-0.02-0.129-0.022-0.194,0.043
		c-1.224,1.032,0.838,2.47,0.838,2.535c-0.193,1.417-1.288-1.031-1.352-1.482c-0.065-0.387,0.257-0.795,0.257-1.182v-0.257h-2.384
		c0.322,0.193,0.644,0.321,0.838,0.579c0.193,0.322-0.387-0.322-0.838-0.579H39.95c-0.193,0.386-0.902,1.889-0.838,2.018
		c0.032,0.089,0.078,0.149,0.15,0.215c-0.292-0.093-0.404-0.149-0.15-0.73c0.258-0.581,0.387-1.052,0.451-1.503h-3.479
		c-0.129,0.193-0.451,0.386-1.032,0.708c-0.966,0.451-1.545,0.001-1.997-0.708h-1.288c0.451,0.58,0.966,1.18,0.902,1.631
		c0,0.13-0.258,0.065-0.323,0c-0.257-0.451-0.256-1.051-0.514-1.374c-0.194-0.193-0.451-0.193-0.645-0.257H28.481z M144.969-70.765
		c1.612,1.031,0.128,3.242,1.288,4.209c0.258,0.322,0.967,0,1.161,0.322c0.451,0.58-1.548,2.255,0.773,1.611
		c1.289-0.323,1.288-2.706,1.546-3.608c0.064-0.194,0.258-0.387,0.451-0.451c0.129,0,0.322,0.322,0.322,0.193
		c0.065-0.129-0.257-0.258-0.257-0.451c0-0.451,0.322-0.923,0.129-1.375c0,0-0.194-0.193-0.516-0.45H144.969z M220.287-70.658
		c0.387,0.064,0.709,0.064,1.031,0.193c0.322,0.129,0.773,0.451,0.645,0.773c-0.065,0.065,0.128,0.323,0.064,0.258
		c-0.968-0.645-1.159-0.838-2.385-0.902c-0.451,0-0.579,1.096-0.837,0.773c-0.129-0.258,0.516-0.709-0.129-0.838
		c-0.386-0.064-1.095,0.129-1.031,0.516c0.065,0.451,0.709,2.577,1.289,2.964c0.323,0.193,0.773,0.128,1.095,0.322
		c0.193,0.129,0.193,1.031,0.709,0.902c0.451-0.129,0.709-0.773,1.225-0.773c0.311,0,0.262,0.596,0.493,0.751
		c-0.692,0.201-1.532,0.437-1.331,0.988c1.353,3.803,0.514-0.581,1.674,1.353c0.258,0.323-0.064,0.967,0.258,1.096
		c0.387,0.193,0.903-0.129,1.225-0.387c1.096-0.903-0.129-0.966-0.322-1.482c-0.194-0.58,0.644-1.03,0.58-1.61
		c0-0.323-0.451-0.387-0.516-0.645c0-0.193,0.451,0.065,0.516-0.064c0.193-0.516,0.129-1.16,0.129-1.675
		c0-0.839-0.646-1.224,0.129-2.127c0.193-0.258,0.772,0.13,1.03-0.129v-0.257H220.287z M233.71-70.658
		c-0.065,0.516,0,0.966,0.129,1.095c0.451,0.194,0.644-0.515,0.902-1.095H233.71z M310.681-70.207v0.193
		c-0.193,0.967-2.127,1.288-1.352,2.771c0.258,0.515,0.579,0.966,1.03,1.224c0.193,0.129,0.451-0.258,0.645-0.129
		c0.128,0-0.065,0.258,0,0.387c0.015,0.016,0.048,0.007,0.086,0c-0.284,0.105-0.58,0.117-0.795,0.386
		c-0.194,0.129-0.709,1.868-0.129,2.062c0.709,0.193,1.481-1.096,2.126-0.709c0.129,0.065,0.064,0.322,0,0.515
		c0,0.452-0.387,0.967-0.194,1.418c0.195,0.387,1.032,0.193,1.16,0.58c0.129,0.387-0.58,0.708-0.515,1.16
		c0,0.386,0.387,0.644,0.644,0.901c0.065,0.194,0.58,0.323,0.452,0.387c-0.452,0.258-1.16,0-1.612,0.322
		c-0.838,0.645,0.838,2.835,1.16,3.286c0.709,0.645,3.286,3.03,3.802,0.516c0.065-0.451-1.096-0.967-0.709-1.289
		c0.387-0.322,0.708,0.709,1.159,0.902c0.516,0.258,1.354,0.387,1.354-0.451c-0.065-0.451-0.451-0.902-0.387-1.353
		c0-0.322,0.193,0.644,0.451,0.902c0.258,0.322,0.579,0.515,0.966,0.709c0.129,0.064,0.387,0.193,0.451,0
		c0-0.194,0-0.451-0.064-0.709c0.064,0.129,0.129,0.257,0.193,0.451c0,0.193-0.258,0.515-0.064,0.58
		c0.515,0.064,1.095,0,1.547-0.322c0.064-0.065,0.064-0.129,0-0.194c0.257,0.194,1.094-0.258,0.837,0.065
		c-0.323,0.58-1.804,0.837-1.482,1.353c0.322,0.451,1.095,0.129,1.417,0.515c0.322,0.387-0.193,1.096,0.129,1.418
		c0.322,0.193,0.516-0.58,0.838-0.709c0.322-0.065,0.58,0.258,0.902,0.258c0.194-0.065,0.515-0.193,0.515-0.451
		c-0.065-0.065-0.321-0.193-0.193-0.193c0.452-0.065,2.834,0.193,3.092-0.129c0.323-0.516-1.159-1.418-0.643-1.74
		c0.773-0.516,2.318-0.644,3.22-0.966c-0.322-0.129-0.643-0.129-1.03-0.194c-0.064-0.064-0.258,0.129-0.258,0.065
		c0.065-1.612,4.833-1.094,2.706-2.835c-0.322-0.258-1.61,0.193-1.997,0.193c-1.225,0.065-2.383-0.387-3.608-0.644
		c-1.354-0.258-3.543-1.482-4.703-2.384c-0.258-0.193-0.387-0.58-0.709-0.709c-0.258-0.064-0.516,0.451-0.774,0.322
		c-0.838-0.58-0.45-1.739-0.901-2.448c-0.322-0.387-0.773-0.515-1.16-0.773c-0.129-0.129-0.194-0.516-0.451-0.516
		c-0.323,0-0.644,0.709-0.773,0.452c-0.193-0.452,0.386-0.838,0.45-1.289c0.065-0.516,0.066-1.031-0.128-1.418
		c-0.129-0.257-0.451-0.386-0.708-0.386c-0.387,0.064-0.774,0.773-0.967,0.451c-0.58-0.902,2.449-0.773,1.095-1.804h-0.966
		c-0.194,0.258-0.387,0.58-0.838,0.322c-0.194-0.064-0.322-0.193-0.386-0.322H310.681z M367.401-70.207
		c0.129,0.322,0.257,0.515,0.321,0.644c0.194,0.387,0.258,0.773,0.516,1.031c0.129,0.129,0.516-0.129,0.516,0.064
		c-0.065,0.452-0.258,0.967-0.645,1.096c-0.322,0.129-0.579-0.452-0.901-0.452c-0.452,0-0.774,0.387-1.16,0.581
		c-0.129,0-0.323,0.064-0.387,0c-0.065-0.129,0.387-0.129,0.258-0.129c-0.258-0.129-0.58-0.129-0.838-0.065
		c-0.774,0-1.867,0.645-2.642,0.194c-0.129-0.065,0.001-0.452-0.193-0.452c-0.387-0.064-1.031,0.838-1.288,0.967
		c-0.58,0.258-1.224,0.064-1.804,0.386c-0.387,0.194-0.709,0.516-1.096,0.645c-0.87-0.048-0.012-0.613,0.151-0.752
		c-0.029,0.008-0.069,0.009-0.151,0.043c-0.451,0.258-0.837,0.645-1.288,0.838c-0.323,0.129-0.773,0.258-1.16,0.258
		c-0.129,0-0.322-0.129-0.451-0.129c-1.805,0.064-3.672,0.643-5.283,1.417c-1.676,0.774-2.77,2.384-4.188,3.286
		c0,0-2.083,0.451-2.083,0.837c-0.001,0.581,1.03,1.031,0.837,1.611c-0.193,0.645-1.418,1.16-0.451,1.675
		c0.903,0.516,6.078,1.546,5.885,2.32c-0.129,0.644,0.451,1.417,0.129,2.191c-0.258,0.451-1.289,1.159-1.16,1.546
		c0.387,1.354,2.771,0.256,1.804,2.512c-0.193,0.323-0.644,0.129-0.967,0.323c-1.16,0.515-1.094,0.451-2.061-0.838
		c-0.838-1.16,1.612-1.933-1.675-1.804c-0.193,0-0.451,0.258-0.645,0.193c-0.193-0.064-0.128-0.644-0.322-0.515
		c-0.193,0.194-0.924,1.804-0.795,1.933c0.065,0.113,0.19,0.199,0.323,0.258c-0.121-0.055-0.237-0.072-0.323,0
		c-0.386,0.257,0.194,0.966,0.065,1.482c-0.065,0.322-0.579,0.45-0.644,0.772c-0.001,0.581,0.837,1.289,0.45,1.74
		c-0.322,0.322-0.643,0.58-1.03,0.773c-0.322,0.065-0.644-0.129-0.902,0c-0.58,0.387,0.516,0.58-1.159,1.031
		c-0.195,0-0.516-0.065-0.581,0.064c-0.193,0.388,0.065,0.838-0.064,1.225c-0.387,1.031-1.225,1.159-0.773,2.577
		c0,0.194,1.289,1.696,1.031,1.761c-0.451,0.193-1.031-0.516-1.483-0.258c-0.386,0.193,0.194,1.031-0.193,1.289
		c-0.258,0.129-0.321-0.967-0.644-0.773c-0.323,0.129,0.258,0.708,0.065,1.03c-0.13,0.258-0.645,0.129-0.902,0.323
		c-0.323,0.258-0.387,0.837-0.774,0.837c-2.578,0.065,0.967-1.481,0.516-2.319c-0.322-0.645-1.417-0.73-1.933-1.181
		c-0.258-0.387-0.387-0.838-0.515-1.289c0-0.193,0.322-0.58,0.128-0.644c-0.451-0.065-0.902,0.709-1.224,0.451
		c-0.129-0.129,1.418-1.676,1.418-1.676c0.129-0.257,0.322-0.515,0.258-0.773c-0.065-0.128-0.323,0-0.387,0.129
		c-1.16,1.032-3.157,5.24-2.771,6.787c0,0.129,0.258,0,0.323-0.064c0.064-0.129-0.065-0.258-0.065-0.323
		c0.065-0.193,0.129-0.45,0.258-0.58c0.129-0.064-0.193,0.645,0,0.709c0.387,0.194,0.901,0.129,1.288,0.322
		c0.129,0.065,0.129,0.322,0.065,0.451c-0.065,0.516,0.193,1.482-0.323,1.547c-0.58,0.064-0.708-2.514-1.675-1.804
		c-0.386,0.322-0.257,1.03-0.515,1.481c-0.065,0.194-0.387,0.129-0.451,0.323c-0.065,0.193,0.258,0.257,0.193,0.386
		c0,0.129-0.258,0.129-0.322,0.258c-0.194,0.387-0.193,0.837-0.387,1.224c-0.193,0.258-0.772,0.129-0.837,0.386
		c-0.258,1.226-0.322,2.577-0.387,3.802c0,0.129-0.128,0.451-0.193,0.322c-0.064-0.258,0.193-0.966,0.065-0.773
		c-0.387,0.645-0.387,2.319-0.903,2.899c-0.322,0.323-0.708,0.644-1.095,0.773c-0.322,0.129-1.095-0.322-0.902-0.064
		c0.193,0.258,0.774,0.064,1.031,0.322c0.258,0.129,0.193,0.58,0.452,0.773c0.322,0.258,1.739-0.193,1.352,0.065
		c-0.451,0.322-1.095-0.065-1.546,0.193c-0.193,0.064,0,0.451-0.129,0.58c-0.193,0.258-0.773,0.064-0.838,0.386
		c-0.064,0.323,0.194,0.645,0.129,0.967c-0.064,0.193-0.515-0.451-0.644-0.258c-0.58,1.096,0.451,0.708,0.967,1.611
		c0.257,0.451,0.128,1.739,0.451,2.255c0.129,0.193,1.481,2.126,1.223,2.448c-0.193,0.194-0.515-0.193-0.709-0.064
		c-0.773,0.58-1.352,1.352-1.803,2.126c-0.129,0.322,0.064,1.224-0.194,0.967c-0.258-0.323,0.258-0.967-0.064-1.289
		c-0.451-0.387-0.773,1.031-1.288,1.289c-0.838,0.451-2.191-1.547-2.771-1.805c-0.709-0.258-2.126,0.323-2.77-0.193
		c-1.225-1.031,0-1.868,0-2.513c0.064-0.258-0.129-0.45-0.323-0.644c0-0.064-0.193,0-0.193-0.064
		c-0.193-0.58,1.482-1.611,1.868-2.062c0.258-0.387,1.418-1.095,1.418-1.095c-0.773-1.741-2.769-1.869-4.124-2.449
		c-0.322-0.129-0.643-0.45-0.837-0.708c-0.064-0.065,0.129-0.322,0-0.322c-0.774-0.129-1.61,0.128-2.383,0.45
		c-0.646,0.259-2.32,2.062-3.029,2.062c-0.773,0-1.675-0.644-2.512-0.064c-0.71,0.515,1.868,1.031,1.932,1.031
		c3.481,0.644,2.191,2.19,3.157,2.512c0.194,0.129,0.387-0.837,0.452-0.579c0.128,0.386-0.323,0.837-0.258,1.288
		c0.129,0.516,0.966,1.675,1.223,1.933c0,0-0.257,0.193,0,1.353c0.065,0.451,0.323,2.513,0.903,2.706
		c0.515,0.129,0.966-0.322,1.417-0.322c0.322,0.064,1.674,0.966,2.126,1.288c0.129,0.194,0.193,0.451,0.257,0.709
		c0,0.129-0.193,0.386-0.128,0.516c0.193,0.322,0.515,0.515,0.837,0.708c0.129,0,0.258-0.257,0.258-0.193
		c0.451,1.612-0.322,2.898,0.709,4.381c1.289,1.741,1.804,2.191,1.675,2.191c-0.967,0.129-1.288-1.675-2.319-1.804
		c-0.258,0-0.451,0.128-0.58,0.257c0.064-0.129,0.064-0.321-0.129-0.386c-0.387-0.193-0.902-0.064-1.353-0.193
		c-1.999-0.774-0.643-0.13-2.641,0.966c-0.065,0,0.644-0.644-0.258-0.708c-0.451,0-0.967-0.065-1.354,0.064
		c-0.258,0.129-0.129,3.35-0.193,3.672c-0.451,1.354-1.482,1.89-2.062,2.985c-0.322,0.516,0,1.224-0.193,1.804
		c-0.451,1.548-1.934,2.512-0.644,4.124c0.903,1.096,4.639,2.319,4.961,3.479c0,0.129-0.257,0.258-0.193,0.387
		c0.258,0.515,1.546,1.095,1.03,1.417c-1.16,0.774-2.126-0.387-2.706,0.065c-0.322,0.257-0.128,0.966-0.514,1.159
		c-0.517,0.258-3.738-0.966-4.447-0.257l-0.064,0.064c-0.065-0.193-0.129-0.387-0.258-0.387c-0.516-0.064-0.966,0.451-1.481,0.516
		c-0.194,0-4.725-2.449-4.789-0.773c0,0.58,1.031,1.224,0.644,1.61c-0.323,0.323-1.031,0.387-1.225,0.838
		c-0.128,0.387,4.081,3.286,4.467,3.35c0.58,0.065,1.16-0.193,1.547-0.515c0.129-0.064,0-0.193,0-0.322
		c0.065-0.194,0-0.516,0.194-0.516c0.193-0.064,0.127,0.387,0.322,0.451c0.58,0.323,0.579-0.129,1.03,0.323
		c0.838,0.902,1.611,2.254,0.773,3.414c-0.128,0.194-0.515-0.064-0.644,0.129c-0.193,0.258,0.193,0.773-0.064,0.966
		c-0.129,0.065-4.768,0.323-4.897,0.194c-4.771-2.708,3.911,0.516-1.503-1.547c-2.837-1.031-2.126,0.194-2.514,0.258
		c-0.193,0.065-2.962-0.773-2.962-0.837c-0.323-0.322-0.387-0.902-0.323-1.353c0-0.322,0.709-0.516,0.516-0.709
		c-0.259-0.194-0.709,0.644-0.902,0.387c-0.194-0.258,0.515-0.645,0.386-0.902c-0.129-0.516-1.224-0.258-1.224-0.774
		c-0.065-0.193,0.193-0.322,0.193-0.515c0-0.064-1.868-1.869-2.126-2.062c-0.193-0.129-0.773,0.129-0.709-0.064
		c0.709-1.805,1.74-0.063,0.902-2.32c0-0.064-1.095-1.417-0.966-1.417c0.386-0.194,0.902,0.515,1.224,0.258
		c0.258-0.194-0.58-0.323-0.773-0.58c-0.58-0.774-0.451-0.515-0.903-1.225c-0.386-0.515-4.316-1.482-4.638-1.417
		c-0.323,0.064-0.644,0.709-0.903,0.516c-0.644-0.516,1.032-1.225,0.967-1.418c-0.129-0.387-0.643-0.386-0.902-0.58
		c-1.934-1.16-0.836-0.257-2.899-0.644c-0.516-0.064-0.773-0.837-1.289-1.031c-0.129-0.064-0.322,0.258-0.386,0.129
		c-0.129-0.129,0.193-0.322,0.064-0.451c-0.064-0.129-0.451,0.129-0.387,0c0.065-0.193,0.645-0.193,0.516-0.322
		c-0.645-1.225-1.481-0.58-2.448-0.515c-0.129,0-0.387-0.323-0.258-0.387c0.387-0.129,3.479-0.645,3.608-0.516
		c0.194,0.322-0.322,0.709-0.193,0.967c0.322,0.58,2.642,1.289,3.093,1.289c0.451,0.064,0.966,0.064,1.352-0.194
		c0.258-0.129,0.065-0.838,0.387-0.902c0.322,0-0.129,0.709,0.129,0.902c0.258,0.194,0.644-0.064,0.967,0.065
		c0.258,0.064,0.385,0.579,0.643,0.515c0.387-0.129,0.387-0.773,0.774-0.902c0.773-0.322,2.255,0.708,3.028,0.773
		c0.58,0.064,1.159-0.257,1.739-0.322c1.289-0.129,2.513-0.709,3.802-0.773c1.16,0,1.932,0.386,3.157,0.193
		c2.836-0.516,6.013-3.628,6.593-6.593c0.129-0.903,0-1.353,0.129-2.32c0.193-1.031,0.065-3.865-0.773-4.51
		c-0.258-0.193-0.708,0.194-0.966,0c-0.323-0.322-0.258-1.417-0.967-1.61c-0.065,0-0.129,0.193-0.193,0.128
		c-0.516-0.386-0.386-1.803-1.031-1.868c-0.581-0.064-1.181,0.838-1.633,0.451c-0.322-0.258-0.514-0.709-0.901-0.967
		c-0.259-0.128-0.644,0.065-0.902-0.064c-0.387-0.193-0.709-0.58-1.16-0.837c-0.194-0.129-0.644-0.516-0.644-0.323
		c0,0.387,0.837,0.58,0.708,0.967c-0.322,0.838-4.123-1.096-4.832-1.031c-0.064,0,0.129,0.193,0.064,0.258
		c-0.015,0.016-0.043,0.018-0.085,0.021c0.39,0.034,0.953-0.015,0.794,0.301c-0.128,0.387-0.837,0.193-1.224,0.064
		c-0.258-0.064-0.257-0.515-0.516-0.644c-0.322-0.193-0.708-0.065-1.03-0.193c-0.451-0.194-0.773-0.645-1.225-0.838
		c-2.062-0.838-4.96-1.353-6.958-1.804c-1.74-0.387-2.447,0.322-3.93,0.838c-0.967,0.322-1.352-0.774-2.32,0.129
		c-0.322,0.322-0.45,0.966-0.643,1.417c0,0.194,0.064,0.322,0,0.451c-0.258,0.451-0.774,0.773-0.903,1.288
		c-0.128,0.258,0.387,0.967,0.129,0.838c-1.224-0.838,0.516-1.417,0.452-1.997c0-0.387-0.709-0.386-0.774-0.773
		c-0.58-2.257,0.645,0,0.322-1.418c-0.129-0.58-2.512,0.129-3.479,0.838c0.129-0.387,0.258-0.773,0-0.967
		c-0.387-0.257-0.902,0.645-1.224,0.387c-0.387-0.323-0.58-1.095-0.258-1.546c0.58-0.645,1.997,1.224,2.578,0.579
		c0.386-0.322,0.515-1.159,0.193-1.546c-0.451-0.387-1.418,0.645-1.805,0.193c-0.258-0.193-1.997-1.03-2.061-0.773
		c-0.194,0.516-0.258,1.031-0.129,1.547c0.064,0.322,0.773,0.579,0.58,0.837c-0.065,0.065-1.997,0.065-2.9,0.129h-0.193
		c-0.516,0.065-1.03,0.193-1.546,0.065c-0.145,0-0.129-0.296-0.129-0.387c-0.129,0.387-0.193,0.773-0.129,1.224
		c0,0.193,0.516,0.387,0.387,0.451c-0.516,0.193-1.182-0.129-1.633,0.065c-0.064,0.064-0.129,0.128-0.193,0.257
		c0.193-0.645,0.323-1.675-0.129-1.804c-0.387-0.064-0.644,1.16-0.837,0.838c-0.193-0.387,0.838-0.838,0.58-1.16
		c-0.323-0.451-1.031-0.065-1.547-0.129c-0.064,0-0.129-0.064-0.193-0.128c-0.322-0.065-0.579-0.129-0.901-0.129
		c-0.185,0-0.531,0.145-0.645,0.193c1.16-1.29,2.92-0.322,4.338-1.031c0.516-0.258,0.193-1.159,0.58-1.675
		c0.193-0.323,0.645-0.387,0.967-0.58c0.129-0.129,0.45-0.129,0.386-0.258c-0.258-0.387-0.773-0.579-1.095-0.837
		c-0.129-0.065-0.194-0.194-0.258-0.258c-0.193-0.065-0.451-0.065-0.646-0.193c-0.064-0.065,0.065-0.323-0.064-0.323
		c-0.387,0.065-0.729,0.387-1.116,0.516c-0.129,0.064-0.516,0.129-0.451,0c0.322-0.838,1.869-0.902-0.258-1.546
		c-4.126-1.29,2.192,1.417-1.997,1.224c-0.065,0-0.837-2.836-1.998-1.353c-0.258,0.386-0.194,2.577-0.129,2.835
		c0,0,0.967,0.321,0.58,0.709c-0.129,0.064-0.387-0.129-0.516-0.065c0-0.064-0.063-0.129-0.129-0.193
		c-0.386-0.258-1.03,0.902-1.288,0.515c-0.258-0.451,0.516-0.902,0.709-1.353c0.065-0.322,0.258-0.966-0.064-0.902
		c-0.451,0.129-0.645,0.837-1.031,1.16c-0.065,0.129-0.516,0.451-0.452,0.322c0.194-0.387,2.321-3.157,1.547-3.672
		c-1.031-0.709-1.417-0.258-1.933-0.065c-0.323,0.193-0.773-0.773-0.837-0.451c-0.129,0.451,0.451,0.966,0.193,1.417
		c-0.193,0.323-1.225-0.128-1.16,0.258c0,0.516,0.773,0.644,1.225,0.838c0.128,0.064,0.58-0.065,0.45,0.064
		c-0.323,0.387-0.966,0.451-1.159,0.902c-0.258,0.387-0.065,0.966-0.065,1.417c0,0.323,0.838,0.516,0.58,0.774
		c-0.387,0.322-1.031,0.644-1.482,0.322c-0.387-0.193,0.129-1.031-0.129-1.353c-0.193-0.258-0.773,0.451-0.837,0.193
		c-0.064-0.451,0.579-0.644,0.708-1.031c0-0.129-0.45-1.804-0.45-1.804c-0.71,0.387-1.74,4.639-2.127,5.734
		c-0.194,0.581,0.515,0.129,0.387,0.516c-0.129,0.322-0.323,0.58-0.387,0.966c-0.065,0.258,0.129,0.644-0.065,0.838
		c-1.095,1.096-0.965-0.773-0.965-0.902c0.064-0.387,0.709-0.966,0.322-1.095c-1.097-0.323,0.838-0.58,0-1.16
		c-0.194-0.129-0.58,0.193-0.709,0c0-0.046,0.041-0.061,0.107-0.064c-0.024-0.009-0.043-0.033-0.043-0.065
		c-0.064-0.193,1.675-3.35,1.546-3.543c-0.064-0.194-3.607-0.516-3.801,0c-0.129,0.515,0.709,1.03,0.452,1.482
		c-0.129,0.322-1.032-0.581-1.096-0.194c0,0.194,1.997,0.902,1.739,1.482c-0.129,0.193-2.061-0.001-1.61,1.095
		c0.129,0.323,1.16,0.322,0.838,0.515c-0.194,0.194-1.032-0.386-1.612,0.129c1.483-3.094-2.383-0.322-2.061,0.838
		c0.129,0.387,0.709,0.58,1.031,0.902c0.193-0.322,0.386-0.579,0.515-0.837v0.129c-0.258,1.224-0.194,1.61,0.064,1.997
		c0.258,0.451-0.514,0.321-0.514,0.58c0,0.193,0.321,0.257,0.514,0.322c0.194,0.064,0.58-0.194,0.58,0
		c0.065,0.322-0.129,0.773-0.451,0.837c-1.74,0.129-0.643-1.739-1.481-1.997c-0.323-0.129-0.774-0.065-1.161-0.065
		c-0.128-0.064-0.385-0.193-0.514-0.064c-0.129,0.064,0.064,0.451-0.065,0.451c-0.452-0.129-0.709-0.708-1.16-0.773
		c-0.515-0.129-2.384,0.321-2.126,1.353c0.516,1.869,2.384,0.258,2.706,0.258c0.129-0.065,0.323,1.288,0.194,2.19
		c0,0.129-0.258,0.129-0.258,0.194c0.129,0.322,1.031,0.837,0.709,0.773c-0.323-0.129-0.516-0.515-0.774-0.709
		c-0.322-0.258-0.709-0.322-1.031-0.58c-0.129-0.129-0.064-0.387-0.193-0.515c-0.129-0.129-0.322-0.323-0.451-0.194
		c-0.387,0.452,0.129,1.418-0.386,1.74c-1.935,1.29-0.257-2.966-1.934,0.773c-0.386,0.903,0.58,1.417,1.032,1.804
		c0.128,0.129,0.644,0.258,0.451,0.387c-0.387,0.193-0.967-0.065-1.354,0.193c-0.387,0.258,0.195,2.513-0.966,2.126
		c-0.451-0.129,0.386-0.902,0.515-1.353c0-0.129,0.259-3.737,0-4.123c-0.967-1.161-1.61,1.61-1.481,1.933
		c0.128,0.386,1.031,0.321,1.031,0.708c-0.065,0.387-0.903-0.129-1.096,0.194c-0.258,0.322,0.065,0.837,0,1.224
		c-0.064,0.193-0.258,0.515-0.387,0.386c0-0.129,0.58-3.156,0.129-3.415c-0.515-0.386-1.61,0.258-1.868,0.774
		c-1.418,3.03,2.384,2.64,1.868,4.188c-0.129,0.258-0.322-0.58-0.515-0.774c-0.387-0.322-0.837-0.257-1.16-0.579
		c-0.322-0.323-0.257-1.16-0.708-1.16c-0.452-0.064-0.903,0.515-1.032,0.966c-0.129,0.452,1.869,1.16,1.805,1.16
		c-0.451,0.193-1.031,0.451-1.482,0.193c-0.452-0.257-0.58-2.062-0.902-1.546c-0.129,0.322-0.258,0.644-0.258,1.031
		c-0.064-1.031-0.193-2.255-0.838-2.062c-0.386,0.129-0.193,0.967-0.58,1.224c-0.386,0.194-0.901-0.386-1.223-0.193
		c-0.258,0.193,0.708,0.064,0.966,0.322c0.644,0.838-0.837,0.451-1.74,0.902c-0.193,0.129,0.709-0.193,0.709,0.065
		c0.065,0.451-0.644,0.773-0.515,1.159c0.064,0.258,0.902-0.322,0.773-0.064c-0.129,0.515-0.967,0.579-1.096,1.095
		c0,0.323,0.58,0.322,0.903,0.516c0.386-0.58,0.514-1.418,1.159-1.676c0.387-0.257,0.838,0.129,1.289,0.129
		c0.064-0.064,0.129-0.321,0.129-0.773c0.129,0.387,0.451,0.773,0.322,1.16c-0.129,0.516-1.418,0.064-1.482,0.58
		c-0.129,0.58,0.064,1.288,0.515,1.61c0.065,0.065,0.387,0.065,0.323,0.129c-0.323,0.258-1.096,0.129-1.096,0.516
		c0,0.129,0.064,0.129,0.129,0.193h-0.129c-0.322,0-0.644,0.322-0.644,0.58c0.129,0.838,2.126,0.322,1.482,0.709
		c-0.451,0.258-1.03,0.408-1.546,0.343c-0.258,0-0.387-0.536-0.645-0.536c-0.193,0-0.386,0.15-0.644,0.279
		c0-0.323-0.194-0.924-0.774-1.697c-1.095-1.483-0.772,1.632-0.965,1.954c-0.065,0.129-0.194-0.322-0.323-0.257
		c-0.193,0.064-0.386,0.257-0.515,0.515c0.322-1.418,0.559-4.339-0.795-2.792c-1.869,1.999,1.633,1.632,0.279,2.921
		c-0.322,0.322-0.987,0.193-1.31,0.516c-0.128,0.257,0.194,0.579,0.129,0.837c-0.064,0.451-0.451,0.902-0.644,1.353v0.258
		c0.193-0.258,0.387-0.516,0.644-0.774c0.194-0.193,0.451-0.386,0.644-0.579c0.193-0.194,0.064-0.709,0.322-0.774
		c0.322-0.064,0.731-0.129,1.053,0.065c0.258,0.193,0,1.16,0.258,0.967c0.386-0.258,0.064-1.096,0.386-1.418
		c0.194-0.193,0.58-0.258,0.902-0.322c-0.129,0.258-0.194,0.579-0.065,0.837c0.194,0.387,0.838,0.322,1.225,0.258
		c0.258,0,0.258-0.386,0.451-0.451c0.129-0.065,0.322,0.129,0.322,0.258c0.065,0.129-0.322,0.257-0.193,0.322
		c0.645,0.129,2.255-2.579,2.642,0.129c0.064,0.322-0.516,0.322-0.903,0.387c-0.257,0-0.451-0.387-0.773-0.387
		c-1.16,0,1.482,2.577,0.258,1.997c-1.741-0.838,0.582-2.191-2.191-1.739c-0.515,0.064-1.352-0.001-1.417,0.515
		c-0.042,0.146,0.022,0.222,0.151,0.279c-0.115-0.028-0.2-0.005-0.28,0.043c-0.967,0.451,2.255,3.286,1.482,4.188
		c-0.296,0.355-1.479-1.294-1.675-1.675c0.129,1.418-1.567,0.772-2.599,1.804c-0.064,0.064,0.194-0.258,0.065-0.322
		c-0.322-0.258-1.418,0-1.16,0.386c0.129,0.194,0.902-0.257,0.709-0.064c-0.258,0.258-0.903-0.001-1.032,0.387
		c-0.193,0.451-0.128,1.159,0.323,1.353c0.386,0.193,1.481-1.16,1.675-1.547c0.064-0.129-0.194-0.386-0.065-0.386
		c0.13,0,2.148,0.644,1.697,1.417c-0.257,0.322-2.083-0.58-1.889,0c0,0.064,0.257-0.064,0.192,0
		c-0.064,0.322-0.644-0.065-0.386,0.58c0.451,1.225,0.794,0.773,1.761,0.451c0.193-0.065,0.709-0.387,0.58-0.194
		c-0.258,0.387-1.031,0.451-1.096,0.903c-0.128,0.515,0.516,0.901,0.645,1.353c0.193,0.515-0.516-0.902-0.902-1.289
		c-0.258-0.258-0.601-0.386-0.923-0.386c-0.451,0.064-0.838,0.773-1.096,0.515c-0.322-0.258,0.967-0.644,0.645-0.902
		c-0.967-0.709-1.096,1.223-1.483,2.062c-0.193,0.322-0.966,0.708-0.708,0.902c0.645,0.386,1.417-0.129,2.062-0.387
		c0.257-0.129,0.386-0.58,0.58-0.515c0.193,0.064-0.387,0.451-0.194,0.579l0.194,0.194l-0.129,0.129
		c-0.322,0.193,0.129,1.095-0.258,1.095c-0.387,0.064,0.258-0.966-0.064-1.095c-0.516-0.258-2.062,0.644-2.062,0.708
		c0.065,0.452,0.966,0.644,0.773,1.096c-0.129,0.451-0.837,0.708-1.353,0.644c-0.322-0.065-0.064-0.644-0.258-0.837
		c-0.064-0.129-0.193,0.192-0.193,0.257c0,0.129-0.322,1.482-0.387,0.58c0-0.129,0.322-0.387,0.193-0.387
		c-1.611-0.515-0.257,0.451-0.515,0.58c-0.258,0.129-0.966-0.064-0.837,0.194c0.258,0.451,2.19,1.094,1.288,1.482
		c-1.611,0.644-2.577-0.389-3.028,1.997c-0.129,0.515-0.323,1.546,0.258,1.675c0.451,0.064,0.837-0.129,1.289-0.065
		c0.193,0,0.579,0,0.45,0.065c-0.387,0.322-3.866,0.773-1.611,1.095c0.709,0.065,1.16-1.095,1.804-0.966
		c0.129,0.064,0,0.451,0.129,0.451c0.064,0,0.129-0.065,0.193-0.065v0.065c0.065,0.258,0.903,0.644,0.58,0.644
		c-0.386,0-0.58-0.516-0.966-0.644c-0.903-0.194-0.966,0.644-1.546,0.902c-0.322,0.129-2.449-0.194-1.417,0.644
		c0.322,0.258,0.901-0.258,1.223-0.065c0.323,0.194,0.258,0.773,0.387,1.096c0.065,0.258,0.451,0.773,0.258,0.709
		c-0.516-0.129-0.773-0.967-1.289-0.902c-0.387,0-0.966,1.481-0.901,1.868c0.064,0.258,0.256,0.58,0.45,0.838
		c0.049,0.048,0.311,0.031,0.386,0.043c-0.003,0.003,0.009,0.017,0,0.021c-0.451,0.258-1.288,0-1.481,0.515
		c-0.129,0.258-0.129,0.387-0.129,0.581c-0.516,0.064-0.516,1.868-0.451,2.19c0.064,0.193,0.322-0.193,0.451-0.258
		c0.322-0.322,0.58-0.708,0.902-1.031c-0.128-0.257-0.257-0.386-0.322-0.515c0.451,0,1.03-0.129,1.095,0.064
		c0.644,1.096-1.675,1.095-1.804,1.933c0,0.258,0.451-0.129,0.709-0.193c0.322,0,0.644,0.064,0.966,0
		c0.258-0.129,0.773-0.774,0.773-0.451c-0.322,1.869-1.61,1.288-2.577,2.191c-0.129,0.129,0.129,0.45,0,0.579
		c-0.322,0.129-2.319,0-2.512,0.451c-0.065,0.129,0.386,0,0.386,0.129v0.065c0.194-0.065,0.323-0.065,0.387,0.064
		c0.193,0.258-2.578,1.933-1.611,1.482c0.322-0.129,0.58-0.322,0.838-0.515c-0.065,0.128-0.194,0.386-0.258,0.773
		c-0.58,2.449,1.74,0.063,1.418,0.966c-0.194,0.387-1.032,0.322-1.225,0.709c-0.129,0.258,0.902,0.064,0.774,0.258
		c-0.258,0.322-0.774,0.451-1.161,0.322c-0.644-0.258-0.837-1.74-1.352-1.418c-0.516,0.323-1.483,0.838-1.16,1.289
		c0.193,0.322,1.031,0.644,0.709,0.708c-0.451,0.129-0.838-0.644-1.289-0.515c-0.258,0.065,0.644,0.387,0.58,0.644
		c-0.129,0.387-0.709,0.323-0.903,0.58c-0.193,0.258,0.001,0.645-0.128,0.838c-0.129,0.258-0.58-0.193-0.773,0
		c-0.064,0.193-0.129,0.387-0.129,0.58c0,0.129,0.387,0.129,0.258,0.129c-0.193,0.129-0.58-0.194-0.709,0
		c-0.258,0.387-0.193,0.858-0.258,1.31c0,0.129-0.064,0.257,0.065,0.257c0.257,0.065,1.031-0.322,0.837-0.129
		c-0.257,0.388-0.773,0.387-1.16,0.58c-0.709,0.452-2.127,1.289-1.159,1.676c0.645,0.258,1.353-0.773,1.804-0.967
		c0.064-0.065-0.258,0.258-0.129,0.258c0.032,0.016,0.084,0.014,0.129,0c-0.37,0.205-0.81,0.342-0.967,0.709
		c-0.129,0.258,0.322,0.45,0.451,0.708c0.129,0.258,0,0.774,0.322,0.838c0.516,0.129,2.126-1.288,2.449-1.546
		c0.322-0.258,0.709-0.387,1.031-0.58c0.064-0.065,0.258-0.129,0.193-0.129c-0.451-0.194-1.224,0.065-1.482-0.387
		c-0.193-0.322,0.58-0.515,0.838-0.709c0.515-0.451,2.835-3.609,2.641-1.03c-0.064,0.515-1.224,0.386-1.482,0.837
		c-0.322,0.451,1.225,0.451,1.289,0.967c0.129,0.644-2.513,0.772-2.706,1.61c-0.064,0.258,0.709-0.258,0.773,0
		c0.065,0.516,0,1.224-0.451,1.547c-0.064,0-2.706-0.645-2.706-0.129c-0.064,0.515,0.903,0.902,0.709,1.417
		c-0.193,0.323-0.644-0.644-1.031-0.708c-0.258-0.065-0.58,0.515-0.645,0.258c-0.064-0.388,0.516-0.581,0.516-0.967
		c0-0.323-0.451-0.451-0.58-0.709c-0.193-0.322,0.001-1.095-0.387-0.967c-0.515,0.065-0.514,0.838-0.901,1.225
		c-0.322,0.387-1.031,0.515-1.031,0.966c-0.065,0.387,0.837-0.515,1.031-0.257c0.258,0.257-0.645,0.257-0.967,0.386
		c-0.193,0.129-0.387,0.644-0.516,0.451c-0.322-0.387,0.13-1.224-0.257-1.418c-0.452-0.257-1.997,0.773-2.062,1.354
		c-0.387,2.771,1.418-1.226,1.676,0.902c0.258,1.74-2.32,0.386-2.385,0.386c-0.543-0.108-0.057,0.424,0,0.516
		c-0.193-0.065-0.643-0.451-0.515-0.258c0.129,0.451,0.257,1.031,0.708,1.288c0.228,0.114,0.46-0.195,0.731-0.321
		c-0.089,0.064-0.183,0.16-0.15,0.193c0.128,0.128,0.709-0.129,0.58,0c-0.258,0.193-0.967-0.065-0.967,0.257
		c0.064,0.516,1.289,0.709,1.096,1.16c-0.258,0.451-1.095-0.128-1.418-0.451c-0.258-0.193,0.128-0.58,0-0.838
		c-0.129-0.322-0.58-0.321-0.773-0.579c-0.129-0.193,0.065-0.645-0.129-0.58c-0.387,0.064-0.902,0.451-0.838,0.838
		c0.065,0.064,0.129,0.193,0.194,0.257c-0.387-0.064-0.708-0.064-1.096,0c-0.258,0-0.45,0.451-0.708,0.387
		c-0.387-0.129-0.387-0.838-0.774-0.902c-1.74-0.193-1.932,0.257-1.481,0.773c0.322,0.451,1.159,0.386,1.224,0.902
		c0.129,0.774-3.288,1.224,0.58,1.16c0.451,0,0.901-0.129,1.417-0.322c-0.709,0.257-1.611,0.966-1.159,1.353
		c0.322,0.257,1.094-0.387,1.288,0c0.193,0.322-0.579,0.644-0.966,0.579c0-0.064-0.967-1.482-1.418-1.224
		c-0.258,0.129,0.129,0.967-0.129,0.838c-0.322-0.129,0.064-0.773-0.193-0.967c-0.903-0.709-1.675,0.645-1.869,0.58
		c-0.128,0-0.064-0.322-0.193-0.386c-0.258-0.129-0.58-0.129-0.838-0.065c-0.193,0-0.45,0.193-0.386,0.258
		c0.258,0.193,1.998,0.773,2.32,1.224c0.119,0.15,0.135,0.355,0.172,0.559c-0.129-0.387-0.366-0.623-0.752-0.559
		c-2.256,0.387-0.838,1.224-0.58,2.513c0,0.129-0.065,0.386-0.258,0.386c-0.129,0,0.193-0.257,0.193-0.386
		c-0.129-0.387-0.451-0.644-0.644-1.031c-0.129-0.258,0.001-0.773-0.257-0.837c-0.581-0.129-1.547,0.321-1.483,0.901
		c0,0.452,0.129,0.902,0.452,1.289c0.193,0.258,1.16,0.129,1.031,0.451c-0.195,0.387-0.838,0-1.225-0.193
		c-0.193-0.129-0.064-0.58-0.258-0.58c-0.386,0-0.193,0.838-0.58,0.967c-0.064,0,0.065-0.194,0.065-0.258
		c0-0.451,0.451-1.031,0.129-1.289c-0.258-0.258-0.129,0.966-0.516,0.966c-0.516,0-0.772-1.03-1.288-0.966
		c-0.387,0.065,0.451,1.095,0.064,1.16c-0.386,0.064-0.386-0.709-0.58-1.031c-0.129-0.193-0.773-0.451-0.773-0.193
		c0,0.644,1.289,0.901,1.16,1.482c-0.129,0.451-1.16,0.45-1.096,0.901c0.065,0.387,0.774-0.193,1.161-0.193
		c0.241,0,0.442,0.118,0.666,0.172c0.074,0.018,0.155,0.021,0.236,0.021c0.902,0.129,2.898,1.096,3.221,1.031
		c1.87-0.129-1.483-0.773,0.773-0.644c0.258,0,0.773,0.129,0.58,0.322c-1.16,1.096-2.706-0.258-3.028,0.129
		c-0.129,0.129,0.644,0.58,0.386,0.58c-0.322-0.065-0.451-0.709-0.773-0.645c-0.038,0.01-0.084,0.041-0.107,0.086
		c-0.134,0.259-0.05,0.972-0.215,0.752c-0.145-0.193,0.142-0.504,0.215-0.752c0.024-0.082,0.027-0.15-0.022-0.214
		c-0.261-0.391-0.755-0.586-1.266-0.666c-0.249-0.039-0.5-0.043-0.731-0.043c-0.967,0.064-2.514,1.739-1.481,2.577
		c0.322,0.322,0.901,0.065,1.288,0.258c0.322,0.193-1.289,0.128-1.16,0.515c0.387,0.903,3.416,0.129,2.641,0.709
		c-0.773,0.516-1.867-0.258-2.513-0.322c-0.386,0-1.998,0.708-0.643,1.224c0.322,0.129,0.579-0.322,0.901-0.322
		c0.516-0.065,1.547-0.387,1.483,0.064c0,0.516-0.967-0.064-1.483,0.065c-0.451,0.128-1.03,0.322-1.352,0.773
		c-0.129,0.193,0.837,1.289,0.966,1.289c0.709,0.193,1.545,0.064,2.255-0.258c0.129-0.065-0.001-0.451,0.194-0.387
		c0.515,0,2.512,0.902,2.577,0.838c0.129-0.129,0.321-0.451,0.515-0.902l0.064,0.064c0.451,0.258,0.967,0.387,1.418,0.645
		c0.064,0.064,0,0.322,0.129,0.257c0.644-0.064,1.417-0.257,1.417-0.322c0.129-0.322-0.388-3.286,1.224-2.641
		c0.645,0.257-1.16,0.965-1.16,1.675c0,0.258,0.065,0.644,0.323,0.708c0.386,0.129,1.224-0.709,1.289-0.257
		c0.451,2.256-2.835,0.128-2.9,1.03c-0.064,0.839,1.612,1.869,0.387,1.998c-1.999,0.129-0.064-3.157-1.934-2.642
		c-1.482,0.387-0.643,1.547-1.094,1.353c-0.452-0.193-0.516-0.837-0.967-1.03c-1.418-0.71-2.706,0.837-2.771,0.772
		c-0.58,0-1.094-0.966-1.546-0.644c-0.322,0.194-0.773,1.675-0.193,1.997c0.451,0.258,1.288-0.386,1.804-0.451
		c0.129-0.064,0.258,0.194,0.193,0.258c-0.387,0.322-1.159,0.322-1.289,0.774c-0.064,0.128,0,0.257,0,0.321
		c-0.387-0.322-0.772-0.708-1.224-0.708c-0.322,0.064,0.838,1.224,0.903,1.353c0.129,0.258-0.001,0.902,0.321,0.902
		c0.194-0.065,0.451-0.322,0.774-0.644c0.515-0.58,1.095-1.225,1.224-0.516c-0.515,0.258-1.418,0.386-1.418,0.966
		c0,0.258,0.838,1.225,1.289,0.774c-0.064,0.322-0.193,0.579-0.451,0.644c-1.16,0.387-2.126-0.129-2.061,0.387
		c0.128,0.515,0.321,1.03,0.515,1.481c0.774,1.418,1.224-1.417,1.868-1.61c0.58-0.129-0.451,1.03-0.709,1.546
		c-0.257,0.645,0.065,0.451,0.452,1.16c0.064,0.193,0,0.902,0.193,0.709c0.387-0.388,0.258-1.031,0.515-1.547
		c0.194-0.258,0.386-0.58,0.708-0.773c0.129-0.129,0.323,0.193,0.452,0.193c-0.129,0.194-0.258,0.387-0.194,0.516
		c0,0.322,0.903,0.257,0.838,0.644c-0.064,0.322-0.773,0.129-1.096,0.322c-0.128,0.129-0.256,0.322-0.385,0.58
		c0,0.129,0.193,0.322,0.064,0.386c-0.259,0.194-0.58-0.064-0.838,0.129c-0.193,0.065-0.064,0.387-0.193,0.516
		c-0.064,0.129-0.451,0.064-0.387,0.129c1.998,1.805,0.708,0.064,2.771,0.193c0.193,0-2.448,1.16-2.448,1.224
		c-0.065,0.258,0.837,0.258,0.58,0.322c-0.129,0.065-0.323-0.129-0.452,0c-0.128,0.065,0.065,0.451-0.064,0.387
		c-0.322-0.065-0.516-0.774-0.838-0.58c-0.451,0.194-0.321,1.031-0.709,1.353c-0.258,0.194-1.03-0.387-1.03-0.064
		c0,0.193,0.193,0.322,0.387,0.451c0.257,0.128,0.514,0.257,0.643,0.451c0.194,0.322-0.129,1.181,0.322,1.181
		c0.838,0.064,0.516-1.954,1.354-2.212c0.515-0.129,1.288-0.001,1.481,0.515c0.129,0.451-0.966,0.58-1.03,1.095
		c0,0.581,0.128,0.602,0.387,0.28c0.386,0.386,2.19-0.452,1.932,0.129c-0.193,0.451-0.966,0.257-1.353,0.515
		c-0.322,0.129-0.644,0.322-0.773,0.644c-0.064,0.194,0.644,0.065,0.515,0.194c-0.258,0.322-1.224,0.192-1.159,0.644
		c0.129,0.322,0.193,0.644,0.322,0.966c-0.129-0.129-0.258-0.257-0.387-0.129c-0.322,0.129-0.386,0.967-0.644,0.773
		c-0.516-0.193-0.129-1.288-0.645-1.417c-0.451-0.129,0.065,0.837,0,1.225c-0.129,0.451-0.579,0.708-0.579,1.095
		c-0.064,0.451,0.708,2.126,1.159,2.384c0.258,0.128,0.645,0,0.838,0.193c0.387,0.258,0.837,1.417,1.611,1.739
		c0.386,0.194,0.773,0.451,1.16,0.387c0.322-0.065,0.258-0.644,0.515-0.709c0.258-0.064,0.708,0.193,0.644,0.451
		c-0.193,0.452-2.319,1.16-1.224,1.675c0.516,0.194,1.031,0.387,1.546,0.323c0.387,0,0.774-0.838,0.967-0.516
		c0.129,0.387-0.967,0.644-0.709,0.967c0.258,0.322,0.774-0.387,1.225-0.387c0.902,0,0.708,0.58,1.352,0.193
		c0.194-0.064,0.258-0.322,0.451-0.386c0.258-0.129,0.709,0.193,0.967,0c0.322-0.258,0.128-1.095,0.579-1.224
		s0.516,0.966,0.903,0.837c0.515-0.064,0.451-0.902,0.838-1.224c0.128-0.129,0.322,0.258,0.451,0.129
		c0.064-0.065,1.739-2.062,2.061-2.513c0.065-0.129-0.193-0.258-0.129-0.387c0.323-1.353,0.837,2.064,1.224-0.579
		c0.064-0.258-0.773-0.322-0.579-0.58c0.322-0.323,1.159,0,1.481-0.387c0.194-0.193-0.515-0.451-0.451-0.708
		c0.193-0.645,1.933-0.773,1.868-0.967c-0.193-0.451-1.03-0.708-0.837-1.159c0.194-0.323,0.773,0.386,1.095,0.579
		c0.258,0.193-0.064,0.838,0.258,0.838c2.708,0,0.643-0.708,1.74-1.417c0.194-0.129-0.129,0.708,0.129,0.708
		c0.516,0-0.065-1.095,0.386-1.933c0.129-0.129,0.258,0.258,0.387,0.258c0.128,0,0.128-0.193,0.128-0.258
		c0.387-0.902,0.001-0.214-0.515-1.567c-0.129-0.323,0.258-0.902,0.129-1.16c-0.194-0.452-0.837-0.773-0.773-1.289
		c0.065-0.322,0.708,0.258,0.902,0.58c0.129,0.322-0.065,0.773,0,1.16c0,0.064,0.064,0.128,0.129,0.128
		c1.612-1.095-1.161-3.092,0.451-3.092c0.451,0-0.129,0.837-0.065,1.224c0.129,0.645,0.323,1.224,0.451,1.868
		c0,0.065,0.065,0.129,0.065,0.258c-0.129-0.129-0.258,0.859,0.129,1.439c0,0,0.386-0.386,0.386,0c0,0.258-0.386,0.644-0.193,0.838
		c2.127,1.998,1.417-1.161,2.771,0.966c0.193,0.258,0.58,0.773,0.322,0.967c-0.065,0.064-0.065,0.064-0.129,0.064
		c-0.387,0.065-1.031-0.515-1.289-0.193c0,0,0.773,2.899,0.838,3.672c0,0.451-0.645,1.611-0.258,1.998
		c2.449,2.449,1.804-1.548,2.964-0.065c0.838,1.032-0.967,3.285-0.838,4.252c0,0.387-0.064,0.838,0.129,1.096
		c0.193,0.193,0.644-0.065,0.773,0.128c0.452,0.71,0.065,2.255,0.387,2.835c0.193,0.323,0.45,0.709,0.773,0.967
		c0.128,0.065,0.386,0,0.451,0.129c0.064,0.065-0.194,0.128-0.194,0.257c0,0.065,0.774,1.096,0.903,1.418
		c0.129,0.258-0.065,0.579,0.064,0.838c0.258,0.322,0.773,0.322,1.031,0.644c0.387,0.451,0.257,1.353,0.773,1.546
		c0.258,0.065,0.644-0.129,0.773,0.129c0.258,0.451,0.451,1.095,0.129,1.546c-0.193,0.387-1.096-0.129-1.289,0.322
		c-0.258,0.516,1.547,1.289,1.031,1.482c-0.58,0.258-1.481-1.031-1.739-0.45c-0.129,0.128,0.192,0.772,0.708,1.61
		c-0.939-0.188-2.118-0.429-2.835,0.387v0.021c0.179-0.115,0.389-0.176,0.602-0.215c-0.214,0.047-0.424,0.129-0.602,0.258v-0.043
		c-0.125,0.185-0.259,0.308-0.321,0.559c-0.129,0.322,0.901-0.001,0.966,0.386c0.193,0.903,0.581,3.222-0.58,2.255
		c-0.258-0.193,0.58-0.515,0.644-0.838c0.322-1.998-1.223-1.546-2.19-1.546c-0.323,0-0.645-0.128-0.967-0.257
		c-0.145,0-0.535-0.133-0.644-0.151c0.006,0.005-0.012,0.013,0,0.022c0.387,0.258,1.095,0.128,1.224,0.579
		c0.58,1.999-2.513,1.289-3.093,1.611c-0.193,0.065,0.194,0.387,0.322,0.516c1.032,0.902,1.612,0.385,1.354,2.255
		c-0.064,0.258-0.516,0.193-0.58,0.451c-0.065,0.193,1.096-0.065,0.709,0.966c0,0.065,0,0.258,0.129,0.258
		c0.258,0.064,0.579-0.193,0.901-0.129c0.322,0.065,0.58,0.387,0.967,0.387c0.129,0,0.193-0.387,0.322-0.258
		c0.323,0.387,0.709,0.902,0.58,1.353c-0.064,0.193-0.386,0.064-0.644-0.064c0.515,0.257,0.966,0.644,1.546,0.644
		c1.611-0.065,0.064-1.031,0-1.74c0-0.516,1.129-0.419,1.74-0.709c0.193-0.064,0.321-0.193,0.321-0.322
		c0.17-0.38-0.141-0.579-0.514-0.773c-0.053-0.029-0.098-0.058-0.151-0.086c-0.147-0.072-0.301-0.156-0.43-0.236
		c-0.312-0.195-0.5-0.425-0.128-0.838c0.322-0.322,0.836-0.386,1.159-0.709c0.064,0,0.064,0,0.064-0.064
		c1.419-1.289,0.129-1.159,0.065-2.513c0-0.322,0.322-0.579,0.386-0.837c0.903,1.418,2.062,3.028,2.062,3.028
		c-0.129,0.323-0.644,0.515-0.644,0.902c-0.064,0.387,0.323,0.709,0.323,1.095c0,0-1.161,0.194-0.709,0.452
		c0.257,0.128,0.643-0.065,0.902-0.065c0.451,0.065,0.901,0.387,1.417,0.387c1.611,0,1.181-1.096,2.728-0.967
		c0.193,0,1.223,0.645,1.545,0.322c0.387-0.386,0.709-0.837,0.774-1.353c0.064-1.676-2.063-0.901-0.387-3.35
		c0.193-0.322,0.258-0.773,0.645-0.902c0.451-0.129,1.03,0.258,1.352-0.065c0.194-0.322-0.514-0.708-0.386-1.095
		c0.065-0.258,0.451-0.064,0.708-0.064c0.323,0,0.581,0.129,0.903,0.064c0.129,0,0.708-0.128,1.16-0.387
		c0.193-0.064-0.194,0.903,0.386,0.452c0.322-0.323,0.386-0.644,1.031-0.709c0.064,0-0.129,0.257-0.064,0.322
		c0,0,1.095-0.129,1.095,0.064c0.065,0.129-0.129,0.258-0.129,0.387c0.065,0.129,0.258,0.193,0.323,0.129
		c1.095-1.031,1.159-3.156,1.352-4.51c0.322-2.192,0,1.483,0.709-0.644c0.129-0.194-0.322-0.452-0.193-0.645
		c0.064-0.193,0.58,0.001,0.58-0.193c0.193-0.838-0.903-1.868-0.387-2.384c0.193-0.193,0.516,0.064,0.645-0.065
		c0.064-0.128-0.838-1.545-0.709-1.932c0.064-0.516,0.322-1.031,0.58-1.482c0.064-0.065,0.385-0.065,0.321-0.193
		c-0.258-0.323-0.901-0.322-0.901-0.709c-0.065-0.516,0.773-0.773,0.773-1.224c0-0.516-1.031-0.837-1.289-1.16
		c-0.129-0.129-0.387-0.386-0.193-0.386c0.451-0.194,1.223,0.386,1.546,0c0.452-0.581-0.966-2.32-0.902-2.642
		c0.065-0.258,0.708,0.193,0.838-0.064c0.193-0.323-0.322-1.482-0.58-1.676c-0.387-0.258-1.676-0.064-1.354-0.451
		c0.323-0.387,0.967,0.322,1.483,0.322c0.709,0,0.128-0.386,0.387-0.644c0.064-0.064,0.385,0.129,0.385,0
		c-0.451-1.031-1.288-1.289-2.319-1.031c-0.193,0,0,0.387-0.129,0.387c-0.193,0.064-0.193-0.323-0.322-0.387
		c-0.065,0-0.128,0.193-0.193,0.193c-0.194,0-0.386,0-0.451-0.129c-0.258-0.58,3.028-0.128,3.157-0.128
		c0.064,0,0.128-0.129,0.193-0.193c0.097-0.049,0.271-0.007,0.322,0c0-0.145-0.296-0.201-0.387-0.237
		c0.004-0.004-0.007-0.017,0-0.021c0.129-0.129,1.096,0.387,0.838-0.387c0-0.193-0.709-0.322-0.515-0.387
		c0.451-0.064,1.031,0.13,1.418-0.128c1.16-0.838,0.966-3.673,0.966-3.673c1.032-0.129,0.128,2.062,0.773,2.32
		c0.193,0.129-0.322-0.58-0.129-0.709c0.322-0.129,0.516,1.096,0.645,0.837c0.193-0.451,0-1.03,0.257-1.481
		c0.258-0.452,0.773-0.709,1.224-0.967c0.129-0.129,0.516,0.043,0.451-0.086c0-0.451-0.387-0.837-0.58-1.224
		c-0.064-0.064-0.258-0.258-0.193-0.258c0.516-0.193,1.289,0.259,1.611-0.193c0.193-0.322-0.451-0.966-0.838-0.902
		c-0.515,0.065-0.579,0.902-1.03,1.095c-0.322,0.194-0.774-0.193-1.096,0c-0.258,0.194,0,0.903-0.322,1.032
		c-0.451,0.193-0.901-0.129-1.417-0.258c-0.258-0.065-0.516-0.451-0.838-0.323c-0.129,0.065,0.387,0.387,0.258,0.516
		c-0.193,0.193-0.709,0.322-0.838,0.129c-0.129-0.322,0.58-0.709,0.387-0.902c-0.323-0.323-0.902,0.129-1.288,0
		c-0.388-0.194-0.516-0.902-0.903-0.902c-0.322-0.065,0.451,0.708,0.194,0.837c-0.58,0.194-1.224-0.644-1.869-0.579
		c-0.387,0.129-1.031,0.644-1.546,0.322c-0.129-0.129-0.452-0.322-0.323-0.387c0.452-0.258,1.032-0.258,1.547-0.322
		c0.194,0,0.386,0.258,0.45,0.129c0.194-0.322-0.129-0.967,0.194-1.031c0.386-0.064,0.451,0.773,0.838,0.773
		c0.58,0,0.837-0.709,1.353-0.837c0.387-0.129,2.254,2.127,3.028,0.644c0.193-0.323-0.773-0.258-0.966-0.58
		c-0.258-0.387-0.065-0.966-0.323-1.417c-0.064-0.129-0.644,0.128-0.58-0.065c0.194-0.322,0.645-0.644,0.967-0.516
		c0.451,0.194,0.515,0.773,0.773,1.16c0.064,0.129,0,0.258,0,0.322c0.193,0.258,0.58,0.194,0.515,0.645
		c0,0.451-0.451,1.417,0.065,1.417c0.515,0,0.966-0.386,1.418-0.451c0.257-0.064,0.579,0.258,0.708,0.065
		c0-0.065-0.386-0.645-0.322-0.709c1.805-0.838,1.224-1.417,1.804-2.062c0.258-0.193,0.322-0.579,0.645-0.644
		c0.064,0,0.643,0.709,0.708,0.064c0.193-1.74-0.515-1.03-1.482-2.126c-0.258-0.193-0.064-0.773-0.387-0.902
		c-0.257-0.129-0.386,0.58-0.58,0.515c-0.386-0.257-0.45-0.837-0.708-1.224c-0.193-0.258-0.966-0.515-0.644-0.644
		c0.387-0.129,0.966,0.774,1.223,0.451c1.742-1.934-1.545-0.644-1.61-0.644c-0.129-0.065,0.193-0.322,0.065-0.451
		c-0.065-0.129-0.323,0.129-0.387,0.064c-0.387-0.258-0.774-0.515-0.967-0.902c-0.129-0.257,0.13-0.708-0.129-0.837
		c-0.644-0.387-1.996,0-2.705,0c-0.194,0-0.064-0.451-0.258-0.516c-0.129-0.064-0.322,0.129-0.322,0
		c-0.064-0.386,0.322-0.773,0.258-1.159c-0.065-0.387-0.516-0.58-0.645-0.903c-0.064-0.129-0.064-0.321,0-0.45
		c0.065-0.065,0.387-0.065,0.323-0.129c0-0.129-0.258,0.129-0.323,0c-0.129-0.258-0.064-0.58-0.129-0.838
		c-0.129-0.258-0.129-0.58-0.129-0.838c0-0.129,0.323-0.064,0.258-0.193c-0.064-0.258-0.58-0.322-0.515-0.644
		c0-0.193,0.644,0.193,0.644-0.064c0.194-1.419-2.32-2.77-0.838-3.287c0.516-0.193,1.096,0.903,1.483,0.516
		c0.386-0.387-0.323-1.031-0.58-1.482c-0.065-0.064-0.258,0.129-0.258,0.065c-0.129-0.387-0.129-0.773-0.065-1.16
		c0.065-0.322,0.323-0.58,0.387-0.902c0.065-0.516-0.064-1.031,0-1.546c0-0.065,0.193-0.065,0.193,0
		c0.065,0.064,0.387,0,0.323-0.065c-0.775-0.902-2.514-1.223-1.483-1.997c0.387-0.258,0.967-0.193,1.418-0.258
		c0.194,0,0.515,0.258,0.579,0.064c0.258-0.322,0-0.837,0.194-1.159c0.129-0.129,0.064,0.515,0.193,0.515
		c0.516,0.129,0.193-1.095,0-1.546c-0.129-0.194-0.258-0.386-0.322-0.58c0.258-0.064,0.967-0.58,0.773-0.193
		c-0.064,0.193-0.515,0.064-0.58,0.258c0,0.193,0.387,0.257,0.58,0.193c0.194-0.065,0.323-0.322,0.451-0.451
		c0.323-0.451,0.902-0.773,1.095-1.289c0.13-0.451-1.03,0.065-1.417-0.257c-0.258-0.194,0.709,0,0.967-0.258
		c0.193-0.194,0.064-0.644,0.193-0.902c0.065-0.065,0.322,0.064,0.322,0c-0.065-0.194-0.515-0.258-0.386-0.387
		c0.193-0.193,0.579,0.193,0.708,0.065c0.129-0.194-0.322-0.387-0.258-0.581c0.065-0.128,0.322-0.128,0.451-0.063
		c-0.236-0.118-0.481-0.194-0.322-0.194c0.451-0.064,0.902,0.58,1.289,0.386c0.451-0.257,0.516-0.901,0.58-1.353
		c0.065-0.386-0.322-0.901,0-1.159c0.258-0.194,0.708,0.773,0.901,0.451c0.194-0.451-0.773-0.966-0.514-1.353
		c0.258-0.322,0.837,0.387,1.223,0.258c0.323-0.129,0.258-0.644,0.452-0.903c0.193-0.386,0.965-0.257,1.159-0.515
		c0.193-0.129-0.065-0.515,0.064-0.709h0.065c0-0.064,0-0.15,0.064-0.15c0.194-0.129,0.129,0.795,0.322,0.666
		c0.452-0.194-0.193-1.246,0.258-1.31c0.129-0.065,0.129,0.386,0.258,0.386c0.773-0.128,0.902-3.285,1.289-4.059
		c0.258-0.58,1.61-2.254,1.546-2.319c-0.516-1.161-2.642-1.288-2.577-2.835c0.064-1.289,1.997-1.804,1.61-2.964
		c-0.193-0.515-0.579-0.966-1.03-1.288c-0.129-0.065-0.516,0.129-0.516-0.065c0.258-0.967,1.74-0.644,2.255-0.966
		c0.129-0.065-0.129-0.322-0.064-0.387c0.129-0.064,0.193,0.322,0.258,0.258c0.386-0.322-0.323-0.773-0.387-0.838
		c-0.129,0-0.387,0-0.322-0.064c0.386-0.194,1.289,0.065,1.16-0.322c-0.129-0.71-1.869-0.451-1.74-1.16
		c0.065-0.193,0.257,0.322,0.451,0.386c0.386,0.194,0.838,0.258,1.289,0.387c-0.065-0.064-0.129-0.129-0.129-0.193
		c-0.129-0.387,0.258-0.773,0.322-1.224c0.065-0.129,0-0.258,0-0.387c0-0.193-0.258-0.58-0.129-0.58
		c0.967,0.194,1.417,1.74,2.771,0.902c0.322-0.193-0.194-0.966,0.129-1.159c0.451-0.258,1.03,0.193,1.545,0.128
		c0.129,0,0.194-0.322,0.323-0.386c0.322-0.065,0.515,0.58,0.773,0.451c0.258-0.129,0.45-0.451,0.644-0.709
		c0.259-0.451,0.58-0.838,1.482-0.322c0.451,0.258,0.194,1.159,0.645,1.418c0.386,0.257,0.901-0.258,1.352-0.129
		c0.194,0,0.194,0.386,0.323,0.515c0.386,0.193,0.386-0.58,0.773,0.064c0.709,1.483,0.193,2.77,0.451,3.415
		c0.193,0.58,1.933-0.71,1.159,1.16c-0.193,0.451-0.837,0.322-1.223,0.58c-0.387,0.257-0.838,0.451-1.096,0.838
		c-0.129,0.128,0.064,0.45-0.064,0.579c-0.129,0.129-0.452-0.193-0.516-0.064c-0.193,0.451-0.258,1.03-0.258,1.546
		c0,0.322,0.193,0.644,0.065,0.966c-0.065,0.581-0.837,0.838-0.966,1.353c-0.129,0.387,0.193,0.838,0.064,1.16
		c-0.322,0.71-1.031,0.58-1.096,1.546c-0.064,0.194,0.323,0.387,0.323,0.645c-0.194,1.225-1.933,1.03-1.997,2.126
		c-0.065,0.451-0.387,1.611-0.452,1.675c-0.257,0-0.58-0.644-0.837-0.451c-0.58,0.516,0.322,1.825-0.258,2.212
		c-0.194,0.129-0.58-0.709-0.516-0.451c0,0.323,0.451,0.451,0.58,0.773c0.58,2.128-0.192,0.709-1.094,1.74
		c-0.194,0.258,0.193,0.902-0.065,0.902c-0.387-0.065-0.193-0.838-0.516-0.902c-0.515-0.129-1.16,0.064-1.418,0.516
		c-1.031,1.74,1.161,0.514,1.032,1.545c-0.129,0.646-1.804,1.74-1.868,2.449c-0.323,4.255,0.063-0.13,0.708,1.482
		c0.064,0.065-0.581,1.224-0.064,1.546c0.065,0.065,0.192-0.258,0.257-0.193c0.129,0.194-0.065,0.644,0.193,0.709
		c0.129,0.064-0.129-0.58,0.065-0.58c0.193-0.065,0.258,0.193,0.322,0.386c0.387,1.032-0.064,2.706,0.129,3.093
		c0.129,0.258,0.516,0.129,0.709,0.322c0.258,0.323,0.387,0.709,0.58,1.031c0.064,0.129-0.064,0.322,0,0.451
		c0.129,0.193,0.386,0.193,0.515,0.322c0.064,0.065-0.257,0-0.257,0.065c-0.065,0.128,0.063,0.257,0.129,0.386
		c0.322,0.387,1.03,0.58,0.965,1.031c-0.064,0.387-1.094,0.064-1.159,0.451c-0.129,0.516,0.644,0.838,0.773,1.289
		c0.064,0.193-0.258,0.386-0.194,0.644c0.065,0.129,0.452,0.129,0.452,0.322c-0.065,0.838-0.709-0.065-0.452,0.644
		c0.065,0.258,0.387,0.451,0.323,0.645c-0.065,0.257-0.708-0.065-0.773,0.129c-0.064,1.675,0.644,1.868,1.159,2.577
		c0.129,0.129-0.515,0.258-0.386,0.451c0.064,0.322,0.193,0.579,0.386,0.837c1.418,1.87,0.322-0.064,1.675-0.322
		c0.258,0-0.258,0.708,0,0.902c0.388,0.194,0.838-0.129,1.289-0.129c0.387,0.065,0.58,0.709,0.903,0.709
		c0.322,0,0.45-0.387,0.708-0.387c0.129-0.064,0,0.194,0.064,0.258c0.193,0.064,0.322,0,0.516-0.064
		c0.049-0.049,0.077-0.265,0.107-0.323c0.004,0.005,0.018-0.008,0.022,0c0.644,1.419-0.387,1.676-0.129,1.74
		c0.129,0,1.61-1.095,1.803-1.224c0.065-0.065,0.194-0.387,0.258-0.451c-0.322,0.967-1.16,2.061,0.387,2.641
		c0.258,0.129,0.773-0.451,0.902-0.193c0.129,0.387-0.902,0.967-0.515,1.096c0.386,0.193,0.322-0.903,0.709-0.967
		c0.322-0.065,0.064,0.644-0.065,0.902c-0.193,0.323-0.58,0.451-0.902,0.709c-0.094,0.141-0.37,0.425-0.451,0.515
		c1.999-0.773,1.223-2.061,2.255-2.706c0.323-0.193-0.516,0.838-0.193,1.031c0.451,0.258,1.052-0.064,1.503-0.322
		c0.322-0.129,0.451-0.644,0.709-0.838c0.387-0.322,2.126-0.515,2.319-0.773c0.194-0.193-0.451-0.515-0.257-0.709
		c0.322-0.257,0.837-0.064,1.288-0.128c0.129,0,0.386,0.064,0.451-0.065c0.129-0.193-0.129-0.451-0.065-0.579
		c0.004-0.009,0.019,0.003,0.022,0c0.033,0.075,0.075,0.337,0.172,0.386c0.064,0.064,1.74-0.967,1.869-1.16
		c0.064-0.064-0.259-1.353,0.386-1.224c0.129,0,2.513,2.192,1.933-0.193c-0.129-0.452-1.289-0.516-1.095-1.031
		c0.128-0.451,0.965-0.194,1.418-0.065c0.257,0.065,0.256,0.774,0.514,0.645c0.645-0.451-0.322-2.642,0.451-2.191
		c0.645,0.452-1.096,2.063,0.903,1.417c3.093-1.031,0.45-0.385,1.094-1.353c0.065-0.064,0.129,0.129,0.258,0.129
		s0.322,0,0.387-0.129c0.064-0.128-0.193-0.579,0-0.579c0.064,0,1.096,0.902,1.418,0.773c0.451-0.129,0.772-0.515,1.03-0.838
		c0.065,0,0.065-0.064,0.065-0.128c0-0.129-0.065-0.258,0.064-0.323c0.451-0.258,1.031-0.193,1.547-0.451
		c0.064,0,1.224-1.933,1.288-1.804c0.58,0.774,0,2.126,0.58,2.9c0.193,0.258,0.645-0.194,0.902-0.065
		c0.387,0.129,0.644,0.451,0.902,0.773c0.129,0.129,0.193,0.387,0.322,0.451c0.838,0.387,1.546-0.515,2.126-0.709
		c1.548-0.644,1.095,0.516,2.062,0.967c0.387,0.194,1.031-0.194,1.353,0.065c1.805,1.482-1.932,0.837-3.093,0.902
		c-3.48,0.193-0.643,1.932-2.19,2.254c-0.193,0.065-1.675-0.644-1.739,0.194c-0.065,0.451,0.386,1.031,0.064,1.288
		c-0.258,0.258-0.644-0.257-0.967-0.451c-0.129-0.064-0.322-0.45-0.515-0.322c-0.774,0.774,0.709,1.804,0.58,2.771
		c-0.065,0.258-0.194,0.515-0.645,0.773c-0.58,0.387-6.635,0.451-7.667,0.322c-0.193,0-0.322-0.322-0.58-0.258
		c-1.224,0.258,1.548,0.967-0.514,0.709c-0.324-0.065-0.387-0.773-0.709-0.709c-0.258,0.065,0.193,0.58,0.064,0.838
		c-0.193,0.386-0.58,0.773-0.967,0.967c-0.644,0.322-1.352,0.45-2.061,0.321c-0.258-0.064-0.451-0.579-0.645-0.45
		c-0.322,0.193,0.451,0.901,0.129,0.966c-0.644,0.193-2.577,0.257-2.964,1.224c-0.129,0.322,0.58,0.644,0.387,0.902
		c-0.322,0.516-2.255,0.386-2.513,0.967c-0.902,2.449,0.774,0.901,1.16,1.932c0.194,0.58-0.773,1.288-0.515,1.869
		c0.258,0.709,1.288,1.438,1.417,1.696c0.386,0.581-0.258,1.224,0.58,1.676c0.322,0.128,0.709-0.194,1.031-0.065
		c0.322,0.065,0.515,0.709,0.837,0.516c0.451-0.258,0.45-2.707,1.805-1.095c0.129,0.193-0.323,0.386-0.323,0.644
		c0,0.515,0.258,1.03,0.258,1.546c0,1.289-0.515,0.579-0.387,1.74c0.065,0.129,0.065,0.321,0.065,0.515
		c0.258,1.354,1.933,3.221,1.546,4.703c-0.194,0.903-1.739,2.77-2.706,2.899c-0.387,0.065-0.644-0.321-0.966-0.386
		c-0.646-0.193-0.773,0.258-1.16-0.387c-0.451-0.644-0.45-1.545-1.031-2.061c-0.581-0.581-1.954-0.837-2.599-1.418
		c-0.387-0.322,0.001-1.159-0.386-1.353c-0.581-0.257-1.031,0.644-1.547,0.967c-0.644,0.515-1.546,0.644-2.126,1.288
		c-1.547,1.612-0.193,3.479-0.773,5.09c-0.193,0.645-1.03,0.902-1.224,1.547c-0.129,0.386,0.194,0.772,0.194,1.224
		c0,0.451-0.194,0.902-0.065,1.288c0,0.065,0.065,0.129,0.129,0.129c-0.645,0.774,0.386,2.706,0.773,3.608
		c0.064,0.129,0.064,0.193,0.128,0.258c0.452,1.676-0.322,0.643,0.323,2.255c0.064,0.129,0.129,0.258,0.258,0.386
		c-0.065,0.065-0.065,0.129-0.065,0.129c-0.193,0.516,0.258,1.031,0.322,1.547c0.065,0.644-0.064,1.352-0.322,2.126
		c-0.064,0.193-0.193,0.451-0.258,0.644c0.129-0.193,0.258-0.451,0.323-0.644c0.193-0.387,0.257-0.773,0.322-1.289
		c0-0.258,0.064-1.159,0.064-1.868c0.322,0.451,0.58,0.966,0.645,1.481c0.064,0.388-0.194,0.773-0.129,1.161
		c0.064,0.128,0.129-0.323,0.258-0.323c0.257-0.064,0.257,0.451,0.321,0.644v0.258c0.064,0.194,0.064,0.322,0,0.451
		c-0.064,0.129-0.386-0.064-0.386,0.065c-0.064,0.709,0.837,1.352,0.644,2.061c-0.194,0.838-3.608,0.774-3.479,0.387l0.194-0.387
		c-0.709,0.774-1.482,0.644-2.513,0.902c-0.129,0-0.322-0.064-0.322,0.065c-0.065,0.516,0.193,1.03,0.193,1.546
		c0,0.903-0.451,1.89-1.225,2.663c-1.16,1.218-2.966,1.91-4.445,1.332c-1.289-0.516-1.031-1.74-1.418-2.513
		c-0.129-0.193-0.58-0.709-0.322-0.644c0.193,0,0.387,0,0.515,0.064c-0.322-0.193-0.708-0.386-1.094-0.451
		c-2.322-0.387-4.897,1.675-6.637,2.706c-0.58,0.323-1.288,0.193-1.739,0.58c-1.096,0.967-1.353,2.534-1.676,2.598
		c-2.385,0.581-4.638,1.611-6.894,2.514c-0.58,0.257-0.965,0.966-1.545,1.095c-0.323,0.129-0.645-0.193-0.967-0.065
		c-0.58,0.323,0.773,1.74,0.193,1.869c-0.193,0.064-0.451,0-0.838-0.129c-0.12-0.045-0.259-0.079-0.387-0.129l-0.021-0.021
		c-0.407-0.16-0.8-0.365-0.944-0.752c-0.194-0.516-0.387-1.031-0.451-1.547c0-0.193,0.451-0.515,0.257-0.579
		c-0.515-0.129-1.052,0.193-1.504,0.193c-0.58,0.065-0.772-0.837-1.159-1.16c-0.193-0.258-0.644-0.128-0.838-0.386
		c-0.257-0.387,0-1.289-0.451-1.289c-0.129,0-0.45,0.065-0.772,0.129c-0.774-0.258-1.612-0.58-1.741-0.451
		c-0.386,0.451-0.45,1.16-0.836,1.611c-0.323,0.387-0.838,0.579-1.225,1.031c-0.064,0.064,0,0.386,0.064,0.58
		c-0.386-0.903-1.288-0.709-2.126-0.258c-0.58,0.322-0.773,1.932-1.095,2.126c-1.418,0.967-0.708-0.902-1.353-0.966
		c-0.515,0-0.902,0.644-1.418,0.579c-2.449-0.258,2.063-1.996,1.16-3.672c-0.451-0.774-1.739,0.709-1.803,0.709
		c-0.323,0.129-1.805-1.16-2.192-1.095c-0.451,0.064-0.45,1.159-0.901,0.966c-0.387-0.129,0.322-0.838,0.258-1.224
		c-0.065-0.581-1.289,0.58-1.612,0.129c-0.257-0.323,0.645-0.516,0.774-0.838c0.129-0.323,0.193-0.709,0-1.031
		c-0.065-0.129-0.387,0.129-0.451,0c-0.129-0.065,0.129-0.215,0.257-0.279c0,0,0.129,0.064,0.129,0c0-0.194-0.064-0.451-0.257-0.58
		c-0.323-0.193-0.773-0.193-1.224-0.258c-0.193-0.064-0.451,0-0.645-0.129c-0.064-0.129,0.129-0.451,0-0.451
		c-0.257,0.065-0.386,0.387-0.644,0.58c-0.129,0.065-0.129,0-0.065-0.193c0.194-0.258,0.58-0.838,0.903-0.966
		c0.258-0.065,0.772,0.708,0.708,0.45c-0.065-0.515-0.45-0.966-0.902-1.288c-0.193-0.129-0.709,0.258-0.709,0
		c0.129-0.709,1.289-0.966,1.289-1.675c0-0.193-0.322-0.258-0.386-0.451c-0.129-0.323-0.194-0.709-0.129-1.095
		c0-0.194,0.451-0.258,0.387-0.452c-0.129-0.257-0.903,0-0.774-0.257c0-0.063,0.67-0.181,1.181-0.365
		c-0.01,0.009-0.022,0.02-0.022,0.043c0.129,0.515,0.774,0.773,0.967,1.224c0,0.064,0.451,1.997,0.645,1.997
		c0.515,0-1.097-1.418,0.515-0.58c1.032,0.58-0.773,0.451-0.258,0.838c0.194,0.193,0.515-0.129,0.709-0.129
		c0.516,0.129,0.966,0.515,1.482,0.58c2.385,0.258,1.16-2.706,0.773-3.415c-0.451-0.902,0.967,1.161-0.193-1.224
		c-0.193,0.129-0.258,0.58-0.516,0.515c-0.451-0.064-0.644-0.644-1.031-0.838c-0.709-0.257-1.224,0.838-1.933,1.032
		c-0.193,0-0.257-0.451-0.515-0.451h-0.129v-0.194c-0.258-0.515-1.288-0.257-1.482-0.708c-0.128-0.452,0.837,0.257,1.289,0.257
		c0.903,0,1.418-0.579,1.74-1.417c0.193-0.387,0.128-3.737,0.708-3.544c0.516,0.193,0.515,1.031,0.967,1.289
		c0.515,0.257,2.061-2.319,1.932-2.835c-0.322-1.483-1.481-0.387-2.255-0.58c-0.322-0.064-0.386-0.644-0.644-0.709
		c0-0.064,0.064-0.128,0.064-0.257c0.065-0.387-1.288-0.645-0.901-0.709c0.258-0.065,0.708,0.258,0.837,0
		c0.129-0.322-0.386-0.644-0.386-0.966c-0.064-0.452,0.258-0.902,0.129-1.289c0,0-1.547-0.387-1.869-0.58
		c-0.258,0-0.579-0.129-0.837,0c-0.323,0.193-0.322,0.773-0.58,1.031c-0.129,0.129,0.193-0.451,0.064-0.58
		c-0.193-0.193-0.967-0.258-1.224,0c-1.225,0.902-0.258,0.45-0.323,1.933c0,0.258-0.515,0.451-0.386,0.709
		c0.064,0.193,0.451-0.323,0.58-0.129c0.193,0.258-0.194,0.644-0.065,0.966c0,0.064,0.065,0.064,0.194,0.064
		c-0.451,0.129-0.966,0.387-1.224,0.065c-0.258-0.322,1.289-2.448-0.064-2.062c-0.129,0-2.9,3.352-3.48,1.997
		c-0.193-0.322,0.065-0.966-0.322-1.095c-0.645-0.258-0.387,2.513-0.322,2.642c-0.065,0-0.194,0.064-0.258,0.129
		c-0.323,0.258,0.064,0.773,0,1.159c0,0.387-0.129,0.838-0.194,1.289c0,0.193-0.129,0.709,0.065,0.645
		c0.322-0.065,0.258-0.645,0.58-0.774c0.193-0.129,0.258,0.258,0.322,0.387c0.129,0.258,1.419,2.835-0.58,1.868
		c-0.064-0.064-0.064-0.064-0.129-0.128c0,0.837-0.644,1.61-0.45,2.319c0.322,1.16,1.996,0.515,2.577,1.095
		c0.258,0.258,0.064,0.838,0.128,1.224c0,0.451,0.387,0.967,0.129,1.289c0.258,0.258-0.257,0.644-0.257,0.966
		c-0.064,0.387-0.064,0.709-0.064,1.031c0,0.452,0.063,0.966,0.257,1.417c0.128,0.259,1.611,1.891,1.611,2.019
		c-0.193,1.289-1.288,0.644-1.933,0.838c0.049,0.06,0.431,0.278,0.322,0.387c-0.065,0.128-0.515,0-0.451,0.128
		c0.194,0.709,1.611,0,1.804,0.194c0.58,0.709-0.322,2.512-0.387,3.092c-0.129,0.903,1.353,0.58,1.547,0.58
		c1.225,0.194,1.095,1.675,1.739,2.255c0.387,0.387,1.353,0.258,1.353,0.709c0,2.192-1.739-1.095-2.063-1.611
		c-0.257-0.387-0.257-1.031-0.708-1.224c-0.581-0.258-1.289,0.387-1.933,0.258c-0.258,0-0.58,0-0.774-0.129
		c-0.193-0.064-0.192-0.451-0.321-0.451c-1.483,0-0.451,4.446-0.967,4.639c-1.611,0.645-1.868-2.319-2.255-3.028
		c-0.064-0.065-0.257-0.129-0.322-0.129c-1.837-0.049-2.607-0.055-2.792-0.043c0.296,0.068,3.747,0.298,0.473,0.429
		c-0.322,0-0.709-0.386-1.031-0.193c-0.516,0.258-1.933,2.255-1.417,2.9c0.064,0.064,0.129,0.128,0.257,0.128
		c0.129,0,0.322-0.065,0.451,0.065c0.581,0,1.16-0.13,0.902,0.128c-0.193,0.259-0.387,0.387-0.58,0.451
		c-1.289,0.645-1.417-1.739-2.513-1.997c-0.967-0.193-6.077,0.902-6.657,1.804c-0.322,0.387-0.451,0.773-0.709,1.095l-0.645,0.494
		l-0.58,0.344c-0.451,0.129-0.965-0.064-1.417-0.064c-0.193,0-0.386-0.129-0.451-0.065c-0.838,1.032-0.579,3.092-0.966,4.188
		c-0.387,0.967-1.16,2.061-1.869,2.835c-0.258,0.323-0.45,0.709-0.772,0.902c-0.194,0.129-0.645-0.129-0.709,0.129
		c-0.129,0.387,0.451,0.902,0.258,1.289c-0.645,1.031-2.643,0.515-2.191,0.773c0.516,0.322,0.902,0.773,1.482,1.03
		c0.257,0.065,0.515-0.321,0.709-0.257c0.257,0.064,0.709,0.451,0.515,0.644c-0.323,0.193-1.16-0.516-1.16-0.129
		c0.065,0.58,1.739,0.515,1.417,0.966c-0.967,1.29-1.868-1.095-2.255-1.159c-1.224-0.322-2.384-0.129-2.19,0.322
		c0,0.064,1.997,1.418,2.384,1.353c0.128,0,0.064-0.258,0.193-0.387c0.258-0.193,0.516,0.258,0.773,0.323
		c0.258,0.129,0.644-0.129,0.903,0.129c0.129,0.128,0.257,0.322,0.321,0.515c0.129,0.258,0.193,0.515,0,0.644
		c-0.129,0.065-0.515-0.193-0.901-0.515c-0.323-0.323-0.709-0.58-0.838-0.644c-0.323-0.065-0.387,0.515-0.709,0.515
		c-1.096,0-0.386-0.644-1.675-0.838c-0.322-0.064-0.645-0.064-1.031,0.065c-1.483,0.451-3.285,1.868-4.446,2.191l-0.064,0.064
		c-0.709,0.129-1.482,0.193-2.255,0.257c-0.58,0.065-2.706,0.193-2.964,0.967c-0.129,0.322-0.644,3.415-0.644,3.866
		c0,0.193,0.322,0.193,0.322,0.322s-0.258-0.129-0.387,0s-0.193,0.451-0.129,0.644c0.065,0.387,0.774,0.58,0.58,0.967
		c-0.193,0.386-0.901,0.322-1.288,0.515c-0.451,0.258-0.773,0.644-1.224,0.838c-1.16,0.644-5.476,0.772-6.121,1.804
		c-0.903,1.547-0.258,2.018,0.451,2.147c-0.323,0-0.579,0-0.837,0.065c-1.097,0.322-0.773,1.16-2.256,0.966
		c-0.322-0.064-0.515-0.515-0.837-0.709c-0.902-0.451-2.126-0.644-3.093-1.03c-2.062-0.774,1.742,0.838-0.902,0.45
		c-0.064,0-0.795-2.083-0.602-2.276c0.129-0.129,0.344-0.064,0.409-0.193c0.129-0.258,0.257-0.644,0-0.709
		c-0.452-0.258-0.988,0-1.503,0c-0.129,0-0.323,0.129-0.452,0.065c-0.515-0.129-0.965-0.387-1.481-0.645
		c-0.049-0.048-0.133-0.265-0.172-0.322c-0.001,0.004-0.021-0.008-0.021,0c0.064,0.451,0.322,0.902,0.322,1.353
		c0,0.129-0.322,0.193-0.322,0.387c-0.065,0.386,0,0.901,0.129,1.288c0.129,0.194,0.514,0.215,0.579,0.408
		c0.193,0.323,0.064,0.838,0.193,1.16c0.065,0.129,0.451-0.064,0.451,0.064c0,0.516-0.386,1.031-0.515,1.482
		c-0.065,0.258,0.064,0.323,0.193,0.323c-0.193,0.451-0.322,0.901-0.451,1.353c-0.194,0.709,1.289,1.223,1.16,1.932
		c-0.129,0.387-0.901-0.193-1.288-0.193c-0.388-0.064-0.709,0.129-1.096,0.064c-0.387-0.064-0.451-1.417-0.773-0.966
		c-0.323,0.451-0.065,1.289-0.516,1.482c-1.547,0.645-1.03-1.611-1.61-1.869c-0.323-0.129-0.58,0.387-0.903,0.58
		c-0.451,0.194-0.837,0.258-1.223,0.516c-0.129,0-0.129,0.322-0.194,0.257c-0.257-0.515-1.288-3.286-1.61-3.028
		c-0.194,0.129-0.193,0.58-0.387,0.451c-0.322-0.193-0.064-0.837-0.386-1.03c-0.323-0.194-0.774,0.193-1.161,0.193
		c-0.193,0-0.451-0.387-0.58-0.258c-0.386,0.258-0.128,1.095-0.514,1.224c-0.451,0.129-0.774-0.708-1.225-0.708
		c-0.258,0.064-0.064,0.837-0.322,0.837c-0.387-0.129-0.193-0.902-0.58-1.031c-0.451-0.129-0.902,0.387-1.353,0.451
		c-1.547,0.452,0.065-0.386-0.644-0.386c-0.58,0-2.706,0.837-2.771,0.837c-1.353,3.481,2.706,0.515,2.513,1.289
		c-0.065,0.386-1.095,0.257-0.966,0.644c0.193,0.451,0.966,0.322,1.417,0.58c0.015,0.01,0.016,0.016,0.022,0.021
		c-0.094-0.018-0.473-0.15-0.666-0.15c-0.387-0.064-0.773,0.064-1.095-0.064l-0.065-0.065c0.387,0.645,0.966,1.546,0.966,1.675
		c0,0.129-0.321-0.129-0.45-0.129c-0.645,0-1.289-0.064-1.934,0.065c-0.128,0-0.322,0.193-0.257,0.258
		c0.516,0.322,1.353,0.128,1.546,0.902c0.129,0.451-0.257,1.159,0.129,1.417c0.387,0.322,1.031-0.064,1.482-0.258
		c0.129-0.064-0.129-0.386,0-0.45c1.612-0.129,0.58,0.644,1.418,1.288c0.193,0.193,0.514-0.064,0.772,0
		c0.388,0.193,0.903,1.031,1.289,1.289c0.516,0.322,0.322-0.58,1.032-0.516c1.353,0.129,0.708,0.966,0.579,1.804v0.064
		c0-0.064,0.064-0.129,0.129-0.193c0.451-0.193,0.966,0.193,1.482,0.193c0.193-0.064,0.258-0.258,0.451-0.258
		c0.065,0.064,0,0.258,0.065,0.322c0.047,0.049,0.28-0.04,0.364-0.064c-0.391,0.253-1.137,0.456-1.009,0.967
		c0.065,0.321,2.771-0.388,2.706,0.45c-0.064,1.418-2.515,2.063,0.129,2.32h0.064c-0.064,0.193-0.064,0.45,0.065,0.901
		c0,0.064,1.546,0.45,1.288,1.03c-0.129,0.259-1.417,0.902-1.159,1.548c0.193,0.45,0.514,0.9,0.772,1.287
		c0.065,0.065,0.258,0,0.323,0.065c0.257,0.322,0.322,1.739,0.386,1.932c1.096,2.128,0.45-0.258,1.611,0.838
		c0.193,0.13,0,0.58,0.193,0.709c0.193,0.13,0.451-0.129,0.645-0.063c0.257,0.193,0.322,0.708,0.644,0.838
		c0.129,0.064,0-0.323,0.129-0.387c0.193-0.065,0.516-0.065,0.516,0.063c0,0.451-0.387,0.902-0.387,1.354
		c0,0.386,0.451,0.709,0.387,1.095c-0.065,0.516-0.645,0.773-0.774,1.289c-0.129,0.515,1.289,1.16,0.838,1.418
		c-0.387,0.193-0.838,0.064-1.225-0.065c0.387,0.259,0.709,0.579,0.967,0.901c0.387,0.452,0.837,0.903,1.095,1.418
		c0.516,0.967-0.193,2.512,0.322,3.352c0.258,0.321,0.709,0.449,0.838,0.837c0.065,0.258-0.129,0.709-0.451,0.772
		c-0.193,0.065,0.064-0.515,0-0.709c-0.129-0.386-0.516-0.708-0.645-1.094c-0.644-2.064,0.581-0.257-0.322-2.192
		c-0.258-0.644-1.159-2.061-1.417-1.932c-0.709,0.515-1.417,4.316-1.675,5.347c-0.129,0.646-1.095,1.697-0.451,1.891
		c0.322,0.129,0.386-0.794,0.708-0.73c0.388,0.065,0.838,0.602,0.645,0.923c-0.129,0.323-0.708-0.258-0.966-0.129
		c-0.322,0.129-0.451,0.58-0.58,0.903c-0.709,1.933-1.482,8.118-3.351,9.02c-0.258,0.129-0.515,0.194-0.902,0.259
		c-1.225,0.321-3.027,0.387-3.865-0.064c-0.323-0.194-0.516-0.645-0.903-0.838c-0.257-0.194-0.772,0.193-1.03-0.065
		c-0.774-0.901,0.451-1.095-1.418-0.838c-0.258,0.065-0.516,0.129-0.644,0.323c-0.129,0.129,0.193,0.58,0,0.58
		c-0.516,0-1.933-1.354-2.449-1.483c0.065-0.063,0.129-0.191,0.129-0.257c-0.386-0.773-1.739-0.644-2.255-0.709
		c-0.257,0-0.515-0.063-0.773,0c-0.129,0.065-0.128,0.323-0.257,0.387c-2.128,0.646-6.313-1.417-7.925-2.513
		c-0.194-0.128-0.58,0.323-0.709,0.129c-0.129-0.063,0.258-0.257,0.129-0.386c-0.387-0.387-1.16,0-1.547-0.323
		c-0.386-0.322-0.45-1.095-0.901-1.224c-0.516-0.129-0.967,0.515-1.482,0.515c-0.387,0-0.58-0.386-0.966-0.515
		c-0.387-0.065-1.225,0.129-1.676,0.063c-1.805-0.258-0.858-0.32-2.663-0.771c-0.322-0.065-0.45,0.643-0.773,0.579
		c-1.612-0.388-1.481-3.437-3.221-3.437c-0.516,0-0.838,0.923-1.354,0.859c-0.322-0.065,0.322-0.859,0.065-1.052
		c-0.58-0.388-1.224,0.449-1.804,0.708c-1.547,0.645-3.801,2.084-5.477,2.213c-0.515,0.063-0.901-0.129-1.61,0
		c-0.064,0-0.322,0.129-0.258,0.258c0.194,0.129,0.773-0.066,0.708,0.128c-0.064,0.323-1.868-0.324-1.481,1.804
		c0.515,3.095,3.093-0.517,2.77,2.643c-0.064,0.387-0.644,0.449-0.838,0.772c-0.193,0.258-0.064,0.579-0.193,0.837
		c-0.064,0.065-0.708-0.772-0.644-0.129c0.065,0.258,0.257,0.58,0.515,0.709c0.322,0.129,1.225-0.386,1.16,0
		c-0.129,0.452-0.966,0.258-1.353,0.517c-0.258,0.258-0.129,0.837,0.193,0.771c0.387-0.063,0.774-0.45,1.16-0.514
		c0.194-0.065-0.064,0.258-0.193,0.322c-0.451,0.387-1.417,0.191-1.546,0.772c-0.064,0.128,0.193,0.386,0.064,0.451
		c-0.128,0.129-0.515-0.323-0.451-0.129c0,0.515,0.709,0.966,0.58,1.481c-0.129,0.646-1.74,0.967-1.353,1.546
		c0.258,0.322,1.031,0.193,1.16,0.58c0.129,0.322-0.709-0.063-0.902,0.194c-0.387,0.58-0.323,3.349-0.194,4.058
		c0,0.193,0.323,0.259,0.323,0.517c-0.065,1.095-0.516,1.739-0.967,2.641c-0.194,0.387-1.031,1.226-0.58,1.161
		c0.452-0.129,0.451-1.096,0.902-1.225c0.322-0.065,0.516,0.58,0.387,0.838c-0.323,0.451-1.224,0.257-1.546,0.771
		c-0.452,0.775-0.709,1.676-1.225,2.448c-0.193,0.324-0.58,0.516-0.515,0.838c0,0.323,0.58,0.452,0.709,0.774
		c-0.067-0.056-0.359-0.362-0.516-0.258c-0.322,0.192-0.451,0.708-0.709,1.096c-0.645,1.031-1.353,2.317-2.126,3.22
		c-0.193,0.258-0.579,0.323-0.837,0.646c-0.064,0.063,0.322,0.258,0.192,0.321c-0.515,0.129-1.159-0.321-1.481,0
		c-0.322,0.322,0.258,0.837,0.129,1.225c-0.193,0.645-2.126,3.35-1.933,3.995c0.129,0.45,0.451,0.837,0.708,1.288
		c0.646,1.097,0.387,1.633,0.387,1.633c0.516,0.129,1.096,0.063,1.611,0c0.258,0,0.386-0.322,0.644-0.258
		c0.258,0,0.387,0.387,0.709,0.45c0.193,0.065,0.193-0.838,0.322-0.644c0.194,0.322-0.129,0.772,0,1.031
		c0.194,0.258,1.096,0.386,0.838,0.515c-1.741,0.838-0.708-1.353-2.126-1.16c-0.388,0.065,0.45,0.58,0.579,0.903
		c0.065,0.386,0,0.837-0.064,1.288c-0.129,0.838-1.224,1.609-1.224,2.255c0,0.258,0.387,0.451,0.387,0.709
		c0.064,0.451-0.645,1.288-0.194,1.417c0.129,0,0.581-0.194,0.452-0.065c-0.129,0.194-0.516,0.065-0.645,0.259
		c-0.322,0.645-0.129,1.417-0.451,2.063c-0.258,0.515-1.804,2.64-1.675,3.349c0.065,0.259,0.515-0.258,0.773-0.258
		c0.386-0.063,0.773,0.065,1.16,0c0.193,0,0.386-0.192,0.58-0.129c1.031,0.258,4.381,2.45,4.832,2.385
		c0.193,0,2.19-0.967,2.319-1.03h0.838c0.838,0,1.997,0.192,2.255,0.321c0.322,0.194,0.515,0.709,0.901,0.646
		c0.129,0,0.194-0.065,0.258-0.194v0.129l1.74,1.675c0.258,0.322,0.515,0.645,0.708,1.031c0.194,0.387,0,1.16,0.452,1.225
		c0.515,0.129,0.837-0.968,1.353-0.839c0.58,0.13-1.934,1.289-1.934,1.289c-0.064,1.869,0.903,0.579,1.096,1.676
		c0.065,0.129-0.258,0.258-0.193,0.387c0.064,0.129,0.451-0.065,0.451,0.129c0.129,0.58-0.838-0.387-0.838-0.323
		c0.129,0.646,0.774,2.771,1.225,3.223c0.193,0.192,0.579,0,0.773,0.192c0.322,0.258,0.386,0.708,0.644,1.032
		c0.193,0.193,0.58,0.256,0.838,0.45c0.193,0.129,0.193,0.58,0.386,0.515c0.516-0.193,0.837-0.708,1.224-1.03
		c0.258-0.192,0.644,0.194,0.709,0.128c0.58-0.838,0.45-1.352,1.61-1.933c1.419-0.709,2.728,0.452,3.759,0.065
		c0.709-0.322,1.095-1.288,1.868-1.482c0.709-0.128,4.51,0.451,5.348,0.773c0.451,0.129,0.709,0.516,1.16,0.644
		c0.838,0.129,1.675-0.322,2.448-0.128c0.58,0.064,1.933,1.16,2.513,0.966c0.451-0.129,0.645-0.644,1.031-0.772
		c1.934-0.517,2.19,1.61,2.963,1.159c2.064-1.03,2.062-3.543,4.06-4.831c1.16-0.774,2.769-1.419,4.123-1.031
		c0.322,0.063,0.644,0.387,0.967,0.387c0.515,0.064,1.223,0.128,1.417-0.323c0.129-0.451-0.902-0.643-0.902-1.16
		c0.065-2.062,1.224-0.578,1.739-1.867c0.194-0.388-0.193-0.967,0.065-1.354c0.193-0.322,0.709-0.063,0.967-0.322
		c0.193-0.258,0-0.772,0.257-1.096c0.129-0.128,0.451,0.129,0.644,0c0.258-0.193,0.129-0.643,0.322-0.837
		c0.451-0.323,1.032-0.386,1.547-0.515c0.129,0,0.322,0.192,0.451,0.063c0.129-0.063,0.128-0.386,0.257-0.515
		c0.452-0.323,1.547-0.451,1.998-0.838c0.58-0.388-1.804-1.739-1.998-1.997c-0.58-0.838-1.288-3.931-0.772-4.897
		c1.16-2.385,3.542-4.209,5.347-6.078c0.645-0.644,0.923-1.738,1.697-2.318c0.129-0.065,2.126-0.517,2.126-0.709
		c0.064-0.451-0.902-0.387-1.095-0.837c-0.129-0.581,0.772-0.902,1.224-1.289c1.741-1.418,5.669-0.966,7.86-1.611
		c0.58-0.128,1.16-1.095,1.547-1.353c0.515-0.451,1.61-0.708,2.319-0.967c0.774-0.322,3.543-1.418,3.865-2.127
		c0.645-1.095-0.386-2.576-0.129-2.835c0.323-0.321,1.161-0.191,1.29-0.708c0.128-0.386-0.129-0.709-0.452-1.03
		c-0.386-0.322-0.773-0.645-0.902-1.031c-0.451-1.289,0.387-0.966,0.064-2.126c-0.064-0.194-0.514-0.129-0.514-0.323
		c0.064-0.708,0.901-1.094,1.03-1.739c0-0.192-0.322-0.451-0.193-0.58c0.773-1.096,2.061-0.837,2.706-1.158
		c0.709-0.388,1.739-1.676,2.448-2.063c0.645-0.386,1.289,0.452,1.805,0.709c0.193,0.129,2.448,0.387,2.512,0.646
		c0.065,0.258-0.838,1.61,1.224,0.772c0.146-0.063,0.135-0.141,0.043-0.236c0.094,0.062,0.207,0.1,0.344,0.108
		c0.451,0.064,0.967-0.129,1.289-0.452c0.193-0.257-0.516-0.966-0.129-1.031c0.451-0.063,1.159,0.322,1.159,0.839
		c-0.064,0.516-1.224,0.321-1.288,0.838c-0.064,0.387,0.708,0.258,1.095,0.258c0.258,0,0.515-0.451,0.644-0.258
		c0.258,0.321-0.064,0.966,0.258,1.288c0.322,0.321,0.837,0.258,1.288,0.387c0.258,0,0.451-0.065,0.645,0
		c0.902,0.451,0.451,0.772,0.451,0.902c0.064,0.129,0.258,0.129,0.451,0.129c0.193,0.063,0.451,0.063,0.58-0.065
		c0.129-0.128-0.774-0.515,0.065-0.451c1.289,0.065,0.836,0.967,1.417,0.774c0.515-0.195,0.901-0.774,1.481-0.903
		c0.452-0.063,1.117,0.258,1.439-0.063c0.258-0.193-0.322-0.58-0.257-0.839c0.129-0.321,0.644-0.387,0.838-0.644
		c0.193-0.194,0.064-0.515,0.257-0.58c0.258-0.128,0.579,0.194,0.837,0.065c0.323-0.258,0.258-0.774,0.516-0.968
		c0.322-0.192,0.773,0.065,1.031-0.129c0.258-0.192-0.064-0.644,0.129-0.838c0.258-0.258,0.708-0.32,1.095-0.385
		c0.064,0,0,0.256,0.064,0.321c0.129,0-0.064-0.257,0-0.321c0.129-0.13,1.225-0.516,1.289-0.516
		c0.967-0.129,2.448-0.065,3.286-0.902c0.516-0.452,0.837-1.482,1.288-1.933c0.194-0.193,0.709-0.129,0.903-0.388
		c0.193-0.192,0-0.58,0.129-0.838c0.129-0.257,1.803-1.223,2.061-1.223c0.645,0,1.997,0.643,2.32,1.03
		c0.064,0.129,0.257,0.192,0.386,0.129c0.129-0.065-0.064-0.388,0.065-0.388c0.515,0,2.126,1.677,2.319,1.806
		c0.451,0.321,0.837,0.708,1.353,0.837c0.193,0.063-0.194-0.516,0-0.516c0.322,0,0.515,0.387,0.838,0.516
		c0.064,0.063,0.128-0.129,0.193-0.129c0.193,0.063,0.451,0.129,0.58,0.321c1.611,1.484,0.644,3.222,1.997,5.154
		c0.709,1.097,0.902,1.546,0.709,2.835c0,0.387-0.516,0.967-0.193,1.096c0.515,0.193,1.159-0.192,1.546,0.13
		c0.322,0.192-0.258,0.708-0.065,0.966c0.258,0.388,0.902,0.192,1.225,0.451c0.258,0.192,0.258,0.579,0.451,0.838
		c0.129,0.129,0.386,0,0.515,0.129c0.193,0.192,0.966,1.029,0.58,1.546c-0.129,0.129-0.645,0.322-0.452,0.386
		c1.291,0.517,2.062-0.257,3.287,0.774c0.709,0.58,0.709,1.675,1.418,2.255c0.128,0.063,0.385-0.129,0.514-0.065
		c0.451,0.258,0.903,0.517,1.289,0.838c0.903,0.709,0,0.516,0.774,1.676c0.386,0.45,1.094,0.516,1.546,0.901l0.966,1.354
		c0.387,0.258,1.568,0.129,2.019,0.515c0.387,0.387,0.321,1.225,0.902,1.354c0.516,0.129,0.966-0.646,1.546-0.773
		c0.645-0.065,1.159,0.644,1.739,0.773c0.581,0.129-0.773-1.097,0.967-0.451c0.645,0.258,1.997,2.317,2.255,3.093
		c0.065,0.193-0.129,0.514,0.065,0.579c2.514,0.322,0.256-1.418,2.577,0.258c0.644,0.451-0.774,1.482-0.58,1.546
		c1.805,0.646,2.061-2.321,3.608-0.192c0.258,0.387,0.645,0.857,0.709,1.374c0.065,0.516-0.709,0.967-0.451,1.418
		c0.258,0.45,1.03,0.257,1.417,0.45c0.387,0.194,0.709,0.516,0.967,0.773c0.064,0.129-0.065,0.322,0,0.322
		c1.933-0.129,2.511-1.355,3.479,1.096c0.774,1.933-0.452,0.9,0.708,2.641c0.129,0.129,0.387,0.129,0.451,0.259
		c0.323,0.387,0.58,0.837,0.774,1.353c0.386,0.837,0.129,1.932,0.644,2.706c0.516,0.709,1.805,0.901,0.708,2.189
		c-0.451,0.646-1.997,0-2.255,1.16c-0.064,0.388,0.581,0.579,0.645,0.967c0.064,0.321-0.193,0.579-0.258,0.901
		c-0.064,0.323-0.064,0.709-0.258,0.967c-0.322,0.322-0.965,0.258-1.223,0.645c-0.387,0.71,0.321,2.384,0.966,2.577
		c3.223,0.902,2.126-2.19,3.672-3.543c0.645-0.517,1.288-0.259,1.482-1.226c0.258-1.16-0.838-2.125,0-3.092
		c1.161-1.354,3.737-0.836,3.802-2.191c0.128-2.319-0.709-0.642-0.774-2.447c0-0.258,0.387-0.516,0.258-0.708
		c-0.129-0.26-0.516-0.129-0.773-0.258c-0.709-0.322-1.224-1.097-1.998-1.354c-0.709-0.194-0.901,0.515-1.545-0.258
		c-1.096-1.418,0.193-1.354,0.451-2.577c0.064-0.258-0.258-0.452-0.258-0.645c0-0.13,1.61-3.371,1.739-3.564
		c1.225-1.289,1.933,0.192,3.222,0.644c0.967,0.322,2.834-0.451,3.415,0.321c1.031,1.355,0.191,2.535,2.19,3.309
		c0.387,0.192,0.837,0.387,1.095,0.192c0.258-0.129,0.064-1.417,0.193-1.739c0.194-0.579,0.774-0.321,0.516-1.159
		c-0.645-2.257-2.706-2.082-3.479-3.18c-0.194-0.192,0.064-0.643-0.194-0.837c-0.644-0.515-2.769-0.838-3.543-1.16
		c-0.967-0.321-1.288-1.029-2.126-1.48c-2.386-1.227-5.604-1.095-7.925-2.643c-2.45-1.677,2.385-2.317,0.773-4.124
		c-0.58-0.644-1.546,0.065-1.997,0.13c-2.063,0.321-5.347,0.902-7.087-0.901c-0.129-0.129,0.064-0.516-0.129-0.646
		c-0.322-0.321-0.902-0.387-1.289-0.644c-1.16-0.903-2.641-2.125-3.351-3.48c-0.709-1.288-1.931-6.378-2.834-7.15
		c-0.839-0.709-1.095-0.386-1.998-0.903c-0.902-0.515-5.432-3.735-5.819-4.38c-0.194-0.387-1.031-3.866-0.902-4.188
		c0.451-1.098,1.997-0.387,1.61-2.191c-0.064-0.452-0.708-0.645-1.095-0.966c-0.129,0-0.258,0.129-0.322,0.063
		c-0.064-0.129,0-0.258,0.129-0.321c0.258-0.193,0-0.709-0.129-0.968c-0.129-0.129-0.193,0.322-0.322,0.259
		c-0.258-0.13,0-0.858-0.323-0.858c-0.032,0-0.047,0.018-0.064,0.043c0.124-0.181,0.267-0.392,0.322-0.559
		c0.129-0.259-0.129-0.646,0.129-0.709c0.258-0.193,0.58,0.129,0.903,0.063c1.031-0.193,1.867-1.223,2.769-1.61
		c0.388-0.129,0.859,0.065,1.117-0.192s-0.064-0.967,0.258-1.16c0.58-0.258,1.804-0.129,1.804,0.515
		c0.605-0.816,0.64-1.23,1.804-0.129c0.258,0.323,0.258,0.58,0.129,0.903c-0.129,0.322-0.515,0.643-0.838,0.965
		c-0.064,0-0.064,0.065-0.064,0.065c-0.193,0-0.58-0.193-0.58,0c0.064,0.516,0.516,0.901,0.709,1.353
		c0,0.065-0.129,0.065-0.129,0.129c0.064,0.517-0.064,1.052,0.193,1.439c0,0,0.065,0,0.065,0.063
		c-0.258,0.388,0.772,0.838,1.095,1.289c0.257,0.388,0.451,2.063,0.773,1.675c0.322-0.387,0.516-0.9,0.773-1.353
		c0.065-0.194,0.129-0.386,0.129-0.579c0-0.065-0.193,0-0.193-0.129c0-0.065,0-0.323,0.064-0.258
		c0.258,0.063,0.064,1.547,0.774,0.387c0.129-0.323,0.128-0.645,0.128-1.032c0-0.063-0.128-0.129-0.128-0.258
		c0-0.063,0.063,0.194,0.128,0.129c0.516-0.257,0.064-2.018,0.644-2.018c1.87-0.129,1.224,0.6,2.384,1.566
		c0.258,0.194,0.645,0,0.838,0.194c1.16,1.353-0.194,3.027,1.096,4.446c0.386,0.451,1.481,1.417,1.932,1.738
		c0.516,0.259,2.126,0.517,1.611,0.709c-0.903,0.259-2.577-1.611-2.32,0.838c0.065,0.772,3.029,2.447,3.48,3.027
		c0.129,0.065-0.516-0.192-0.387-0.129c0.387,0.258,2.062,0.774,2.126,0.967c0.129,0.451-0.258,1.159,0.065,1.546
		c0.193,0.13,2.19-0.578,2.254-0.643c1.289-0.259,0.386,0.256,1.998,0.836c0.516,0.129,1.095-0.063,1.611,0.129
		c0.709,0.258,1.159,1.417,1.932,1.804c0.903,0.389,1.482,0.646,1.741,0.902c-0.001,0.065,0.063,0,0.063,0
		c0.13,0,0.258-0.128,0.322,0c0.254,0.445-0.113,0.462,0.43,0.709c-0.017,0.02-0.031,0.041-0.043,0.065
		c-0.064,0.192,0.387,0.258,0.58,0.258c0.075,0,0.161-0.021,0.237-0.043c0.097,0.042,0.168,0.051,0.279,0.106
		c0.129,0.065,0.258,0.451,0.129,0.451c-1.032-0.063-1.804-1.03-2.835-1.16c-0.451-0.063-0.967,0.065-1.418,0
		c-0.129,0-0.258-0.321-0.322-0.258c-0.129,0.065-0.129,0.388,0,0.452c0.387,0.128,0.837-0.064,1.289,0
		c0.386,0.064,0.837,0.193,1.289,0.451c0.129,0.063,0.063,0.322,0.192,0.386c2.192,1.161,1.738-0.711,5.154,1.997
		c0.453,0.323,1.032,0.646,1.612,0.774c0.322,0.063,0.579,0.063,0.902,0.063c-0.129,0.128-0.258,0.257-0.193,0.451
		c0.902,1.997,1.094,0.128,1.997,0.58c0.193,0.129,0.064,0.386,0.193,0.58c0.516,0.644,1.181,0.515,1.632,1.223
		c0.129,0.194,0,0.58,0.193,0.774c0.387,0.386,0.902,0.58,1.418,0.709s1.159-0.323,1.546,0.063c0.322,0.322-0.129,0.967-0.064,1.417
		c0.064,0.194,0.515,0.259,0.386,0.388c-0.257,0.258-0.837,0-1.031,0.258c-0.322,0.451-0.258,1.03-0.258,1.609
		c0,0.775,1.096,0.388,0.967,1.16c-0.06,0.605-0.405,2.865-0.129,2.878c0.019,0.001,0.04-0.021,0.065-0.043
		c0.193-0.192,0-0.709,0.258-0.772c0.193,0,0.322,0.515,0.193,0.709c-0.109,0.108-0.328,0.094-0.516,0.106
		c-0.143,0.012-0.266,0.038-0.322,0.151c-0.193,0.193,0,0.515,0,0.837c-0.064,0.258,0,0.58-0.064,0.837
		c0,0.194-0.323,0.258-0.258,0.452c0.193,0.709,1.482,1.545,1.418,1.674c-0.194,0.389-1.096-0.129-1.289,0.323
		c-0.452,1.031,3.672,2.146,4.124,2.727c0.257,0.259,0.192,0.774,0.514,1.031c0.129,0.194,0.516,0,0.645,0.194
		c0.129,0.322-0.065,0.643-0.065,1.03v0.387c0.065,0.129,0.065,0.192,0.129,0.258c0.645,1.546,1.611,0.837,1.804,1.031
		c0.194,0.192-0.193,0.837,0.064,0.966c0.775,0.645,2.578,1.48,3.287,2.384c0.128,0.194,0,0.516,0.193,0.708
		c0.193,0.129,0.322,0.194,0.451,0.258c-0.967,2.387,0.193,0.646,0.838,1.097c0.322,0.192,0.192,0.771,0.515,1.029
		c0.064,0.129,0.257-0.129,0.386-0.063c0.387,0.258,0.709,0.644,0.967,1.031c0.451,0.709-0.258,1.287,0.451,1.609
		c0.065,0,0,0.323,0.129,0.258c0.387-0.192,0.837-0.514,1.095-0.9c0.129-0.129-0.129-0.646,0.064-0.58
		c0.387,0.192,0.581,1.288,1.289,1.094c1.225-0.322,1.869-1.094,2.707-1.417c0.451-0.193,2.512,0.646,2.963,0.194
		c0.258-0.194-0.193-0.709-0.064-0.967c0.838-1.482,0.063,1.159,1.224,1.225c0.386,0,0.193-1.032,0.515-0.967
		c0.516,0.063,0.645,0.838,1.096,1.096c0.838,0.386,1.224-0.516,1.739,0.128c0.322,0.387,0.387-0.386,0.516-0.386
		s0,0.322,0.129,0.386c0.258,0.129,0.772-0.193,0.708,0c-0.774,2.127-3.029-0.064-2.384,1.417c0.129,0.323,0.838,0,0.967,0.258
		c0.064,0.323-0.451,0.646-0.709,0.58c-0.645-0.063-1.031-0.838-1.675-0.966c-1.096-0.323-2.319-0.194-3.351-0.581
		c-0.709-0.257-1.352-1.223-2.255-0.643c-1.289,0.838-0.321,0.9-1.031,1.609c-0.515,0.517-1.159-0.129-1.803-0.129
		c-0.065,0,0.128,0.258,0.064,0.258c-0.129,0-0.258-0.387-0.258-0.258c-0.322,1.226,0.194,1.805-0.838,2.514
		c-0.064,0.129-0.321,0-0.385,0.063c-0.904,1.225,0.836,0.709,1.223,1.096c0.322,0.322,0.065,0.902,0.322,1.225
		c0.129,0.192,0.323-0.258,0.516-0.194c0.838,0.258,2.513,1.546,2.448,2.578c-0.129,1.483-0.902,0.386-0.773,1.353
		c0.129,0.709,0.451,1.482,1.031,1.417c0.065,0,0.258,0,0.258,0.13c0.064,0.45-0.452,2.577,1.417,1.739
		c1.096-0.515-1.677-3.479,1.418-2.77c0.387,0.063,0.515,0.643,0.708,0.966c0.322,0.451,0.516,0.902,0.902,1.289
		c0.065,0.063,0.258,0,0.322,0.063c0.516,0.709-0.516,2.319,1.095,2.514c0.195,0.063,0.194-0.387,0.129-0.58
		c-0.129-0.774-0.258-2.126-0.192-2.255c1.611-2.257,2.06,0.32,3.156,1.159c0.193,0.129,0.451-0.193,0.58-0.064
		c0.193,0.128,0.129,0.515,0.322,0.644c0.258,0.065,0.709,0.065,0.838-0.129c0.129-0.515-1.353-1.352-1.418-1.48
		c-0.064-0.129,0.194-0.323,0.194-0.451c-0.065-0.129-0.323-0.194-0.323-0.387c0-0.194,0.774,0.192,0.516-0.388
		c-0.258-0.579-1.03-1.996-1.288-2.447c-0.129-0.193-0.516,0-0.645-0.193c-0.064-0.194,0.129-0.451,0.065-0.708
		c-0.258-0.453-2.385-2.062-1.869-2.771c0.323-0.388,1.031,0,1.547,0c0.064,0,0.257,0,0.322,0.063c0.129,0.194,0,0.515,0.193,0.58
		c0.258,0.064,0.966,0.128,1.868,0.064c0.58,0,1.997-0.129,1.611-0.579c-0.645-0.775-1.868-0.388-2.577-1.097
		c-0.194-0.192,0-0.58-0.129-0.838c-0.065-0.129-0.386-0.129-0.386-0.321c0-0.193,0.579-0.321,0.386-0.387
		c-0.452-0.192-1.224,0-1.417-0.451c-0.194-0.321,1.997-0.967,2.77-1.224c0.451-0.129-0.001-1.096,0.838-0.837
		c0.645,0.193,1.353,1.481,1.804,1.803c0.129,0.065,0.193-0.257,0.322-0.192c0.064,0,0.837,0.516,0.901,0.644
		c0.065,0.129-0.064,0.322,0.065,0.451c0.193,0.064,0.451,0.194,0.58,0c0.709-1.096-0.129-1.354-0.451-1.934
		c-0.129-0.258,0.064-0.643-0.065-0.966c-0.064-0.258-0.45-0.451-0.45-0.772c0-0.451,1.096-0.129,0.386-0.903
		c-0.903-1.031-1.287-0.58-2.577-0.644c-1.032-0.065-0.773-1.096-1.031-1.225c-0.451-0.321-1.095,0.58-1.611,0.258
		c-0.387-0.192,0.066-1.03-0.322-1.224c-0.387-0.194-0.837,0.515-1.288,0.386c-0.387-0.063-0.323-0.708-0.709-0.838
		c-0.451-0.192-0.966-0.063-1.482-0.063c-0.129,0.063-0.258,0.191-0.386,0.128c-0.194-0.129-0.194-0.516-0.452-0.516
		c-0.257,0-0.386,0.321-0.58,0.388c-0.193,0.063-0.772-0.129-0.579-0.193c1.225-0.517,2.125-0.581,3.028-1.354
		c1.096-0.968-0.322-0.967-0.773-1.288c-0.193-0.129-0.129-0.451-0.322-0.517c-0.129-0.063-0.065,0.258-0.194,0.323
		c-0.451,0.064,0.258-0.903,0.387-1.354c0-0.193-0.193-0.58,0-0.58c0.194-0.065,2.706,1.225,2.835,1.03
		c0.516-0.772-2.77-2.384-3.222-2.835l-0.837-1.695c-0.709-0.773-2.191-1.354-2.384-2.577l0.451-1.935
		c-0.064-0.321-0.709-0.386-0.645-0.708c0.065-0.45,0.581-0.709,0.903-1.03c0.387-0.322,1.095-1.031,1.095-0.773
		c0,1.29-1.354,1.094,0,2.126c0.709,0.516,2.512,0.128,3.028,0.773c0,0,0.451,2.061,0.644,2.126c0.516,0.129,2.191,0.192,2.32,0
		c0.322-0.58-3.995-1.029-2.513-2.643c0.193-0.258,0.58,0.065,0.902,0.065c0.258,0.063,0.515-0.065,0.773,0
		c1.032,0.516,1.095,1.676,2.127,2.062c0.451,0.194,0.515-1.095,0.129-1.481c-0.516-0.517-2.192-0.257-1.998-1.03
		c0.193-1.226,2.255-0.967,2.963-0.581c0,0,2.192,2.708,1.676,0.838c-0.258-0.967-1.739-1.224-2.448-1.418
		c-0.258-0.063-0.258-0.644-0.516-0.58c-0.193,0,0.065,0.258,0.065,0.388c-0.065,0.129-0.129,0.192-0.193,0.192
		c-0.258,0.065-0.516,0.129-0.645,0c-0.516-0.515-0.45-1.48-1.031-1.803c-0.194-0.129-0.966-0.065-0.515-0.646
		c1.16-1.481,1.287,0.065,2.513-0.45c1.418-0.646,0.901-1.739,1.61-2.19c1.805-1.096,0.837,1.16,2.642,0.193
		c0.386-0.259,0.709-0.58,1.031-0.968c1.353-1.676,1.631-0.321,3.693-0.192c0.645,0.063,2.706-0.838,3.222-0.45
		c1.031,0.902-1.031,0.965,0.064,1.545c0.129,0.129,0.387,0.194,0.709,0.323c0.967,0.322,4.768-2.321,4.51-1.032
		c-0.129,0.903-3.286,2.191-3.415,3.222c0,0.194,0.387,0.259,0.451,0.451c0.065,0.194,0.065,0.388,0,0.58
		c-0.064,0.387-0.515,1.482-0.193,1.225c1.225-1.031,1.481-2.705,2.513-4.06c0.902-1.29,2.577-1.545,3.415-2.963
		c0.258-0.452,0.515-0.967,0.709-1.483c0.064-0.387-0.194-0.837,0.064-1.158c1.096-1.418,1.545-0.13,2.835-0.58
		c0.193-0.065,0.065-0.451,0.258-0.646c0.387-0.322,0.772-0.579,1.288-0.708c1.482-0.516,2.19,1.097,1.868-0.515
		c-0.129-0.323,0.258,0.9,0.58,0.837c0.516-0.065,2.126-0.386,2.32-0.902c0.193-0.386-0.065-0.838,0.064-1.289
		c0-0.258,0.516-0.708,0.193-0.837c-1.353-0.452-4.444,0.065-6.121-0.966c-2.513-1.547-1.481-2.384-1.868-3.416
		c-0.064-0.193-0.258-0.386-0.451-0.644c-0.322-0.259-1.739-1.096-1.804-1.159c0-0.129,0.194-0.323,0.129-0.451
		c-0.709-1.741-3.544,0.581-2.384-0.967c0.258-0.388,0.837-0.516,1.095-0.902c0.129-0.128-0.257-0.322-0.192-0.515
		c0.064-0.258,0.385-0.322,0.514-0.58c0-0.065-0.257-0.194-0.193-0.194c0.322-0.192,0.902,0,1.096-0.322
		c0.193-0.321-0.194-0.837-0.258-1.224c-0.258-1.031-0.193-1.674-0.322-2.706c0-0.129-0.387-0.063-0.387-0.192
		c0.129-0.193,0.58-0.193,0.644-0.451c0.709-1.418-0.838-1.354,1.547-1.997c0.323-0.065,0.644,0.387,0.902,0.258
		c0.451-0.258,0.322-1.761,0.064-3.372c-0.322-2.063-0.901-4.317-0.966-4.768c-0.001-0.064,1.546-2.577,1.16-2.899
		c-0.033-0.033-0.076-0.043-0.108-0.043c0.071-0.047,0.125-0.104,0.172-0.151c0.113-0.111,0.263-0.418,0.215-0.45
		c0.364,0.024,0.371-0.844,0.687-1.096c0.516-0.386,2.449-0.451,2.513-1.289c0-0.258-0.064-2.447-0.193-2.706
		c-0.065-0.129-0.194,0.129-0.323,0.065c-0.129-0.065-0.257-0.194-0.257-0.387c0-0.065,0.193,0,0.257-0.065
		c0.129-0.386,0.645-1.868-0.129-2.126c-0.128-0.063,0.065,0.258-0.064,0.258c-0.064,0.065-0.064-0.192-0.129-0.258
		c-0.387-0.128-0.129,1.032-0.58,0.194c-0.387-0.58-0.322-1.482-0.387-2.126c0-0.258-0.45-0.902-0.192-0.774
		c0.58,0.194,0.45,1.676,1.03,1.482c1.934-0.773,0.645-0.965,0.645-1.805c0-0.129,0.129,0.259,0.258,0.194
		c0.193-0.065-0.323-0.58-0.129-0.709c0.322-0.129,0.708,0.129,1.03,0c0.258-0.063,0.451-0.322,0.58-0.58
		c0.516-1.16,1.096-2.576,1.482-3.866c0.065-0.258-0.451-0.515-0.322-0.772c0.065-0.193,0.451-0.258,0.58-0.451
		c0.451-0.838-0.838-0.708-0.322-1.417c1.418-1.805,3.35-1.031,3.35-1.611c0.064-0.516-0.645-1.224-0.193-1.546
		c0.322-0.258,0.128,1.031,0.579,1.288c0.194,0.194,0.451-0.322,0.774-0.386c0.322-0.065,0.709,0,1.095-0.065
		c0.838-0.129-0.193-1.546-0.193-1.546c-0.064-0.774,0.58-0.644,0.193-1.289c0.194,0,0.386-0.064,0.386,0.065
		c0.065,3.739,2.191,2.513,4.189,1.159c0.257-0.193-0.58,0.452-0.838,0.709c-0.323,0.387,0.129,1.096-0.451,1.16
		c-1.096,0.193-2.319,0.257-3.414,0.386c-0.065,0-1.676,0-1.483,0.194c0.387,0.322,0.774,0.773,1.289,0.902
		c0.194,0.064-0.129-0.516,0.065-0.58c0.386-0.193,0.837,0.064,1.288,0c0.129,0,0.644-0.322,0.709,0.064
		c0.129,0.645-1.483,1.096-1.032,1.547c0.194,0.258,2.449-0.258,2.771-0.194c0.193,0.065,0,0.517,0.193,0.581
		c0.451,0.193,1.032,0.257,1.547,0.064c0.516-0.129,0.773-0.773,1.224-0.968c0.451-0.258,1.031-0.128,1.418-0.321
		c0.129-0.065-0.129-0.386,0-0.451c0.129-0.129,0.386,0.194,0.514,0.129c0.323-0.129,0.129-1.031,0.452-0.902
		c0.58,0.129,1.095,1.353,1.546,0.967c0.323-0.323,0.257-0.902,0.644-1.16c1.097-0.645-0.064,1.095,0.645,1.739
		c0.257,0.194,0.923-0.58,0.859-0.258c0,0.194-2.019,2.256-2.405,2.449c-0.194,0.065-0.387-0.258-0.58-0.129
		c-0.065,0.129,0.322,0.258,0.257,0.386c0,0-1.353,1.226-1.418,1.226c-0.128,0.129-0.256,0.321-0.321,0.515
		c-0.001,0.129,0.193,0.194,0.193,0.258c-0.065,0.064-2.384,1.675-1.546,1.997c1.611,0.646,1.288-0.451,2.641-0.451
		c0.451,0.065,1.868,1.225,2.32,1.031c0.58-0.193,1.051-0.902,1.825-0.129c1.741,1.805,0.258,1.546,0.58,2.577
		c0.322,0.902,0.773,1.224,0.709,1.289c-0.258,0.258-1.16,0.258-1.031,0.644c0.193,0.452,1.031,0.194,1.482,0.387
		c0.451,0.129,0.45,0.579,1.03,0.579c3.224-0.386,2.576-4.122,4.64-5.54c0.387-0.322,0.772-0.58,1.288-0.644
		c0.258,0,0.58,0.321,0.773,0.193c0.452-0.452,0.065-1.354,0.516-1.74c0.451-0.322,0.708-0.193,0.837-0.773
		c0.064-0.064-0.129,0-0.193-0.064c-0.323-0.322,0.193-0.967,0.386-1.03c0.323-0.194,0.774-0.194,1.16-0.129
		c0.387,0.063,0.644,0.58,1.031,0.515c0.452-0.063,0.58-0.644,0.967-0.837c0.322-0.129,0.709,0,1.031-0.129
		c1.289-0.452,0.258-1.675,0.387-2.706c0.128-0.516,0.901-1.031,0.579-1.417c0,0-2.642,0.515-2.706,0.579
		c-0.387,0.258-0.064,1.031-0.451,1.224c-0.258,0.129-0.58,0-0.838-0.129c-0.193-0.063-0.128-0.45-0.322-0.45
		c-0.451,0-0.064,0.966-0.322,1.353c-0.322,0.322-0.644,0.708-1.095,0.837c-1.096,0.322-4.703-3.157-5.283-3.93
		c-0.194-0.193-1.096-1.804-0.967-1.933c0.387-0.258,0.773,0.709,1.224,0.644c1.483-0.257,1.096-3.22,1.676-4.123
		c0.193-0.258,0.579-0.322,0.709-0.58c0.322-0.451,0.321-1.031,0.643-1.417c0.967-1.29,1.482-1.16,2.32-0.774
		c0.064,0.065,0.064-0.129,0.128-0.193c0.581-0.645-0.128-1.89,1.418-1.954c0.516-0.065,0.837,0.966,1.224,0.708
		c0.451-0.257-0.129-1.03,0.064-1.546c0-0.128,1.483-1.804,1.547-1.804c0.258-0.129,0.579,0.516,0.708,0.323
		c0.129-0.194,0.194-1.933,0.58-2.256c0.516-0.387,2.126-1.031,2.771-1.16c0.193,0,0.258,0.645,0.387,0.452
		c0.193-0.258,0.129-0.709,0.322-0.967c0-0.064,0.064-0.064,0.064-0.129c0.58-0.644,3.222-1.094,3.415-2.062
		c0.129-0.709-0.774-0.579,0.451-1.095c0.322-0.193,0.709-0.258,1.031-0.193c1.031,0.322,1.482,1.03,0.838,1.997
		c-0.194,0.258-0.58,0.322-0.903,0.387c-0.064,0-0.064-0.258-0.193-0.258c-0.387,0.194-1.224,2.319-2.19,2.835
		c-0.065,0-1.87,0.257-0.516,1.289c0.387,0.258,1.16-0.323,1.289,0.064c0.258,0.58-1.353,0.515-1.611,0.387
		c-0.129-0.065-0.194-0.387-0.323-0.323c-0.58,0.259-0.514,1.095-0.965,1.482c-0.258,0.194-0.58,0.322-0.903,0.387
		c-0.257,0-0.644-0.387-0.773-0.129c-0.129,0.193,0.322,0.387,0.516,0.645c1.16,1.482,0.965,0.536,2.77,0.923
		c0.451,0.064,0.709,0.515,1.096,0.708c0.58,0.259,1.288,0,1.803,0.323c0.194,0.129,0.323,0.386,0.258,0.515
		c-0.515,0.838-1.675-0.193-2.126,0c-0.515,0.194-0.064,2.255-0.064,2.32c0,0.193,0.129,0.773,0.064,0.58
		c-0.773-1.419-0.322-2.578-1.289-1.418c-0.387,0.387,0.516,1.675,0.258,2.32c-0.193,0.451-0.58,0.772-0.645,1.159
		c0,0.194,0.387,0.194,0.323,0.322c-0.129,0.387-1.032-0.451-0.644,0.129c0.258,0.516,1.611,1.095,1.675,1.482
		c0.064,0.194-0.193,0.451-0.129,0.644c0,0.129,0.387,0.194,0.258,0.194c-0.451,0.064-0.967-0.194-1.418,0
		c-0.258,0.129-0.257,0.579-0.515,0.837c-0.064,0-0.064-0.258-0.193-0.258c-0.387,0.065-0.774,0.258-1.096,0.451
		c-0.129,0.065-0.129,0.323-0.258,0.387c-0.128,0.065,0.194-0.258,0.065-0.322c-0.258-0.129-0.644-0.065-0.966-0.065
		c-0.323,0-0.773-0.322-0.967-0.064c-0.258,0.322-0.193,0.966,0.129,1.16c0.322,0.193,0.644-0.773,0.966-0.515
		c1.032,0.902-2.771,1.288-1.739,1.868c1.224,0.773,2.576-0.258,3.995,0.258c0.967,0.386,1.416,1.933,2.513,1.868
		c0.064,0,1.868-0.387,1.932-0.451c0.193-0.258-0.709-0.644-0.451-0.772c0.322-0.13,2.77,1.675,3.351,1.867
		c0.837,0.259,1.61-0.515,2.383-0.579c1.16-0.064,4.08,1.159,5.176,1.61c1.16,0.516,2.254,1.417,3.48,1.74l0.064-0.065l1.288,0.646
		c0.065,0.129,0.129,0.192,0.258,0.257c0.193,0.065,0.322-0.064,0.516-0.064c0.515-0.064,0.965-0.192,1.417-0.063
		c0.58,0.193,0.773,1.417,1.289,1.159c0.064-0.064-0.065-0.322,0.064-0.322c0.322-0.129,0.644,0,0.966,0
		c0.193,0,0.322,0.129,0.516,0.065c1.353-0.388,1.352-0.968,2.899-0.259c0.516,0.259,0.709,1.096,1.225,1.159
		c0.257,0,0.385-0.257,0.579-0.257c0.516-0.065,1.031-0.129,1.482,0.063c0.838,0.388,1.03,1.676,1.546,2.256
		c0.967,1.29,2.642,2.126,3.093,3.673c0.451,1.224,0,0.9-0.258,2.061c-0.129,0.388-0.193,0.709-0.258,1.097
		c-0.387,1.547-0.837,2.77-2.19,4.059c-0.194,0.193-0.516,0.129-0.645,0.322c-0.838,0.967-1.803,3.478-3.092,4.059
		c-0.323,0.129-0.773-0.257-1.095-0.193c-0.258,0.065-0.323,0.516-0.58,0.579c-0.258,0.065-0.515-0.129-0.774-0.129
		c-0.258,0.065-0.45,0.388-0.772,0.323c-0.065,0-1.288-0.645-1.676-0.323c-0.322,0.258-0.321,0.709-0.579,0.903
		c-0.258,0.129-0.58-0.129-0.838,0c-0.258,0.193-0.258,0.515-0.516,0.709c-0.386,0.322-0.966,0.386-1.352,0.709
		c-0.645,0.58-0.709,1.051-1.418,1.567c-0.838,0.645-2.92,0.967-3.887,0.967c-2.321,0,0.258-0.258-1.096-0.774
		c-0.773-0.257-0.965,1.16-1.223,1.288c-0.452,0.194-1.032-0.063-1.547-0.128c-0.065-0.064-0.065-0.257-0.193-0.257
		c-0.516,0.063-0.966,0.514-1.546,0.45c-0.194,0-0.129-0.516-0.323-0.645c-0.322-0.192-0.773-0.386-1.224-0.386
		c-2.514,0.063-1.287,2.577-3.801,1.481c-0.773-0.388-1.159-2.385-1.997-2.256c-0.323,0-0.322,0.774-0.645,0.646
		c-0.257-0.065,0.258-0.838-0.064-0.838c-0.645,0.063-1.674,2.449-3.931,1.61c-2.385-0.902-1.03-2.127-1.159-2.256
		c-0.322-0.386-0.902-0.129-1.353-0.129c-0.194,0-0.387-0.129-0.452,0c-0.193,0.323-0.063,0.838-0.321,1.161
		c-0.71,1.289-7.409,2.19-9.085,2.899c-0.773,0.258-1.417,1.288-1.997,1.804c-0.516,0.515-1.417,0.709-1.997,1.095
		c-1.612,1.289-2.728,3.286-4.21,4.897c-0.387,0.451-1.03,1.159-1.481,1.48c-0.065,0.065-0.387,0-0.387,0.129
		c-0.258,1.29,0.709,1.288-0.516,2.191c-0.129,0.13-0.451,0-0.644,0.063c-1.547,0.581-0.321,0.774-2.32,0.839
		c-0.709,0-2.512-0.517-3.157-0.259c-0.193,0.065-0.128,0.388-0.322,0.517c-0.709,0.644-1.996,0.901-2.899,0.967
		c-1.096,0.128-3.608,0.193-3.672,0.322c-0.258,0.58-0.387,1.223-0.516,1.868c-0.193,0.838,1.418,0.838,1.611,0.901
		c0.129,0,0.322-0.001,0.386,0.128c0.064,0.194-0.258,0.451-0.129,0.517c0.193,0.063,0.322-0.322,0.516-0.322
		c0.129,0.063,0.064,0.386,0.193,0.386c1.225,0.129,2.319-1.094,3.609-0.965c1.095,0.064-1.933,1.288-2.191,1.352
		c-0.193,0-0.193-0.45-0.322-0.387c-0.129,0.065-3.866,2.449-4.124,2.706c-0.903,1.226,3.287-0.71,2.062,1.096
		c-0.259,0.322-2.835,0.517-3.737,0.646c-1.74,0.257-3.222,1.352-3.286,1.288c-0.258-0.323,0.644-0.966,0.322-1.225
		c-0.387-0.322-1.03,0-1.546,0.065c-0.064,0.064,0,0.192-0.064,0.192c-0.032,0-0.073-0.016-0.108-0.043
		c0.26,0.241,0.677,0.439,0.623,0.815c-0.194,0.646-0.966,1.032-1.611,1.226c-1.353,0.516-1.675-0.902-1.997-0.968
		c-0.838-0.129-1.224,1.031-1.868,1.16c-0.258,0.065-1.289-0.001-1.676,0.451c-0.387,0.452-0.45,1.16-0.837,1.611
		c-0.194,0.192-0.515,0.063-0.644,0.258c-0.129,0.257,0.064,0.514,0.064,0.708c-0.064,0.323-0.064,0.709-0.258,0.966
		c-0.386,0.387-1.095,0.065-1.095,0.774c0.064,1.03,0.58,1.546,0.58,2.318c0.064,0.258-0.774,1.74,0.193,1.868
		c0.129,0,4.123-1.932,4.574-2.061c0.129-0.065,0.387-0.193,0.322-0.065c-0.193,0.709-0.772,2.063-1.223,2.707
		c-0.065,0.128-0.645-0.064-0.258,0.193c0.387,0.321,1.868,0.32,2.062,1.288c0,0.322-0.645,0.795-0.387,1.116
		c0.193,0.13,2.19-0.579,1.868,0.259c-0.322,0.967-2.255,1.738-1.868,2.318c0.387,0.58,1.481,1.483,1.61,1.354
		c0.258-0.195,0.58-0.322,0.838-0.388c0.258-0.063,0.838-0.258,0.838,0c-0.065,0.13-1.997,1.354-2.255,1.354
		c-0.322,0.063-0.516-0.709-0.773-0.516c-0.323,0.258,0.451,1.225,0.129,1.096c-0.129,0-1.224-2.449-1.611-2.643
		c-0.194-0.129-0.644-0.192-0.773,0.065c-0.194,0.386,0.129,0.901,0.193,1.352c0,0.193-0.193,0.388-0.064,0.451
		c0.322,0.129,1.03-0.192,0.965,0.065c-0.128,0.515-0.708,0.772-1.159,1.031c-0.129,0-0.258-0.388-0.322-0.259
		c-0.064,0.13,0.258,0.259,0.193,0.388c0,0.192-0.515,0.387-0.322,0.516c0.387,0.257,0.901-0.001,1.353,0.128
		c0.129,0,1.16,0.837,1.289,0.708c0.386-0.322,0.451-0.965,0.902-1.223c0.516-0.258,0.45,1.159,0.966,1.352
		c0.258,0.065,0.451-0.451,0.709-0.451c0.322,0,2.061,0.517,2.061,0.517c0.065,0.129,0.452,1.675,0.194,1.997
		c-0.387,0.387-1.933,0.709-1.417,0.901c0.515,0.129,1.094-0.063,1.546,0.194c0.515,0.386-0.387,1.868,0.257,1.932
		c0.581,0.065,0.837-0.9,1.354-0.9c0.516,0.063,0.708,0.771,1.159,1.029c0.129,0.065,0.387-0.258,0.387-0.129
		c0.967,3.159-4.576-0.13-2.126,2.577c0.322,0.323,0.837-0.321,1.224-0.321c0.644,0,0.386,0.709,1.095,0.321
		c0.129-0.063-0.129-0.257-0.064-0.257c0.451-0.258,0.966,0,1.417-0.194c0.515-0.258,1.74-0.837,2.062-0.966
		c0.516-0.192,1.224-0.838,1.546-0.386c1.225,1.546-1.868,0.965-1.868,1.481c0,0.387,0.645,0.772,0.387,1.03
		c-0.388,0.388-1.032,0-1.547,0.129c-1.031,0.259-1.933,1.096-2.771,1.676c-0.322,0.258,0.838-0.13,1.225-0.193
		c0.322,0,0.644,0.257,0.837,0.129c0.258-0.194-0.065-0.708,0.193-0.902c0.387-0.258,0.903-0.129,1.354-0.192
		c1.353-0.388,1.159-1.031,1.223-0.903c0.838,1.87-0.063,1.289-0.643,1.869c-0.129,0.129,0.386,0.322,0.514,0.193
		c0.129-0.193,1.999-2.319,1.999-2.385c0.193-0.321-0.001-0.837,0.257-0.966c1.676-0.773,0.902,0.902,1.353,1.288
		c0.387,0.258,1.03,0.129,1.481,0.323c0.129,0,0.129,0.322,0.194,0.258c0.644-0.581-0.58-1.417,0.322-1.547
		c1.806-0.323,1.676,2.577,1.805,2.706c1.353,0.968,2.382,0.451,3.8,0.58c0.194,0,0.258,0.58,0.58,0.515
		c0.581-0.063,2.728-2.126,2.857-2.189c0.322-0.388,1.288-0.517,1.675-0.322c0.129,0.063,0,0.644,0.129,0.515
		c0.322-0.322,0.515-0.838,0.515-1.289c0-0.257-0.451-0.257-0.451-0.451c-0.064-0.387,0.258-0.771,0.387-1.158
		c0.258-0.773-1.031-1.805-0.322-2.771c0.257-0.322,3.35-0.902,3.866-0.966c0.193,0,5.798,0.58,5.927,0.644
		c2.514,0.709,2.06,2.577,5.412,2.835c1.676,0.13,0.257-0.32,1.611-1.096c0.322-0.193,0.772,0.129,1.095-0.063
		c0.193-0.13,0-0.516,0.129-0.645c0.451-0.322,2.448-0.58,2.706-0.709c0.257-0.193,0.322-0.644,0.58-0.837
		c0.193-0.065,0.193,0.386,0.386,0.322c0.065,0,0.709-1.934,0.838-1.868c0.323,0,0.451,0.709,0.773,0.58
		c1.289-0.387,0.258-1.16,0.322-1.74c0.193-1.225,1.61-3.028,2.255-3.995c1.289-1.74,5.09,0.452,5.541,0.129
		c0.387-0.321,0.516-0.838,0.902-1.095c0.126-0.126,1.846,0.24,1.074-1.074c0.062-0.074,0.082-0.134,0.021-0.215
		c-0.129-0.257-0.515,0.387-0.837,0.323c-0.193-0.065,0.193-0.323,0.387-0.451c0.258-0.258,0.708-0.387,0.966-0.645
		c0.386-0.517,0.257-1.567,0.902-1.696c0.902-0.258,1.804,1.116,1.997,1.825c0.065,0.321-2.126,3.221-1.933,3.673
		c0.387,0.838,1.933,1.029,2.062,2.061c0.064,0.194-0.064,0.451-0.258,0.708c-0.258,0.324-0.515,0.709-0.515,0.967
		c0,0.322,0.515,0.451,0.58,0.774c0.064,0.451-0.129,0.836-0.194,1.288c-0.064,0.838,0.924,0.451,1.44,1.095
		c1.16,1.354,0.385,2.642,0.966,4.123c0.193,0.646,0.966,1.226,1.353,1.87c0.193,0.322,0.322,0.579,0.322,0.901
		c0.065,1.354-0.515,0.321-1.031,1.03c-0.064,0.065,0.258,0.194,0.258,0.322c-0.129,0.387-0.322,0.708-0.451,1.031
		c-0.065,0.193-0.387,0.258-0.323,0.451c0,0.321,0.709,1.997,0.581,2.318c-0.258,0.451-0.774,0.709-0.903,1.226
		c-0.064,0.258,0.322,0.451,0.387,0.709c0.064,1.224-0.515,2.254-0.644,3.349c-0.065,0.709,0.129,1.417,0.064,2.063
		c0,0.387-0.386,0.708-0.386,1.158c0,0.709,0.515,1.354,0.386,2.063c-0.194,0.709-0.644,0.063-0.708,0.58
		c-0.129,1.095,0.258,2.255,0.322,3.415c0.129,1.869,0.129,3.95-0.258,5.884v0.065l-0.451,0.257h86.314v-89.621
		c-0.387,0-0.773,0-1.16-0.065c-1.419-0.063-1.997-1.223-3.092-1.546c-0.58-0.129-1.095,0.258-1.612,0.388
		c-0.129,0-0.128-0.259-0.257-0.193c-0.194,0.129-0.193,0.451-0.451,0.58c-1.548,1.095-4.209,1.933-5.949,0.708
		c-0.644-0.517-2.19-2.77-3.479-4.769c-0.838-1.353-1.546-2.64-1.74-3.093c-0.129-0.322,0.258-0.643,0.258-0.965
		c-0.064-0.646-1.031-1.224-0.709-1.804c0.258-0.323,0.967-0.388,1.289-0.129c0.387,0.321-0.064,0.9,0.065,1.287
		c0.129,0.322,0.579-0.45,0.579-0.838c0-0.129-0.194-0.257-0.258-0.449c-0.065-0.193,0.001-0.451-0.065-0.646
		c-0.193-0.515-0.708-0.966-0.579-1.48c0.129-0.323,0.708,0.128,0.966-0.065c0.129-0.129-0.321-0.258-0.451-0.387
		c-0.322-0.322-0.772-0.516-1.03-0.837c-0.838-1.161,0.064-0.837-0.065-1.998c-0.129-1.16-1.869-3.156-1.224-4.38
		c0.838-1.549,0.193,1.096,1.095-1.354c0.065-0.259-0.129-0.645,0-0.838c0.129-0.194,0.387-0.194,0.516-0.194
		c0.129,0,0.193,0.259,0.258,0.194c0.258-0.194,0.321-0.516,0.579-0.709c0.258-0.193,0.58-0.387,0.902-0.387
		c0.387,0,1.096,0.645,1.096,0.258c0.129-1.032-2.577-1.675-3.222-1.417c-1.611,0.58-1.288,1.482-3.221,1.159
		c-0.323-0.064-0.452-0.837-0.452-0.837c-0.128-0.193-0.965-0.064-1.03-0.064l-1.676-0.774c-1.095-0.644-1.351-1.61-2.577-2.255
		c-1.095-0.58-3.092-1.739-4.252-1.933h-0.064h-0.065h-0.064c-0.387,0-0.709,0.322-1.096,0.193c-0.451-0.193-0.708-0.708-1.094-1.03
		c-0.645-0.451-3.672-2.191-4.318-2.384c-0.258-0.065-0.515,0.258-0.837,0.193c-0.451-0.129-0.967-1.546-1.095-1.675
		c-0.129-0.129-0.387,0.064-0.581,0c-0.386-0.064-0.901-0.064-1.223-0.387c-1.16-1.16-0.387-1.373-0.516-2.534
		c0-0.193-0.322,0.451-0.515,0.322c-0.645-0.451-0.58-1.546-0.773-2.19c-0.129-0.387-0.258-0.837-0.322-1.289
		c-0.065-0.516,0.322-1.095,0-1.482c-0.194-0.257-0.709,0.193-0.838,0.516c-0.065,0.064,0.773,2.448,0.902,3.092
		c0,0.065-0.129,0.129-0.193,0.065c-1.161-0.903-0.387-0.773-0.516-2.32c0-0.193-1.803-1.288-2.384-1.739
		c-0.322-0.194-0.386-0.709-0.773-0.838c-0.258-0.064-0.516,0.387-0.773,0.258c-0.387-0.065-0.515-0.709-0.966-0.709
		c-1.741-0.193-1.052,1.354-2.341-0.129c-1.097-1.289,0.515-1.739,0.515-2.77c0-0.451-0.773-0.967-0.451-1.224
		c0.323-0.258,0.58,0.709,0.966,0.773c0.516,0-0.58-0.837-0.58-1.353c0-0.193,0.451-0.193,0.451-0.387
		c0.129-0.644-0.451-1.352-0.322-1.997c0-0.129,0.451-0.064,0.387-0.193c0-0.387-0.387-0.58-0.451-0.902
		c-0.065-0.323,0.257-0.645,0.129-0.902c-0.129-0.129-0.58,0.193-0.58,0c-0.064-0.194,0.452-0.065,0.515-0.258v-0.065v-0.064v-0.065
		v-0.128v-0.065c0-0.129,0-0.257-0.064-0.386v-0.129v-0.064v-0.065c0,0,0.001-0.064-0.065-0.129v-0.064
		c0-0.065,0.001-0.129-0.063-0.129c-0.013-0.05-0.027-0.084-0.043-0.129h0.043h0.385c0.194,0,0.001-0.386-0.193-0.58
		c-0.258-0.322-0.772-0.451-1.095-0.838c-0.064-0.064,0.258-0.064,0.452-0.064c0.451,0.129,1.051,0.967,1.631,0.516
		c0.258-0.194-0.408-0.58-0.343-0.967c0.064-0.774,0.15,0.323,0.859-0.322c0.387-0.387,0.708-0.773,0.967-1.224
		c0.063-0.193-0.194-0.451-0.129-0.645c0.129-0.193,0.515-0.128,0.579-0.321c0.258-0.517-0.193-1.289,0.322-1.547
		c0.258-0.129,2.063,1.354,1.547,0.194c-0.387-0.71-1.869-0.773-1.676-1.611c0.129-0.709,0.258,0.515,0.967,0.064
		c0.258-0.129-0.58-0.322-0.515-0.515c0.386-0.967,1.352,2.192,1.61-1.16c0,0,0.001,0,0-0.065c0,0,0.001,0,0-0.064
		c0,0,0.001-0.064,0-0.064h-0.064c-0.001-0.065,0-0.065,0-0.065c-0.001,0,0,0,0-0.064c-0.001-0.065,0-0.065,0-0.065
		c-0.065,0-0.064-0.064-0.064-0.064c-0.001-0.065,0-0.129-0.065-0.193v-0.129c-0.064-0.129-0.128-0.258-0.193-0.451l-0.193-0.58
		c-0.258-0.903-0.58-1.804-0.387-1.997c0.387-0.323,0.966,0.257,1.353,0c0.193-0.129-0.708-0.387-0.515-0.516
		c0.258-0.193,0.579,0.193,0.901,0.258c0.129,0,1.483-1.997,1.612-2.384c0.193-0.516-0.066-1.869,0.385-1.611
		c0.129,0.065,0.194,0.451,0.258,0.322c0.194-0.644-0.064-1.417,0.129-1.933c0.065-0.193,0.322,0.065,0.451,0
		c0.194-0.064,0.194-0.45,0.323-0.386c0.193,0.065,0,0.451,0.193,0.58c0.004,0.008,0.017-0.004,0.021,0
		c0.028-0.061,0.043-0.29,0.043-0.387c-0.064-0.322-0.193-1.632,0.258-1.052c0.065,0.064,0.322,0.258,0.322,0.129
		c0.129-1.418-0.258-1.804,1.095-2.964c1.225-1.096,2.448,0.194,3.609-0.258c0.257-0.064,0.257-0.451,0.451-0.58
		c0.129-0.129,0,0.387,0.129,0.387c0.644,0.065,1.352-0.258,1.932-0.451c0.387-0.193,0.194-2.19,0.258-2.319
		c0.193-0.258,0.708,0,1.03-0.194c0.58-0.258,1.546-1.546,2.256-1.031c0.322,0.194,0.515,0.645,0.902,0.902
		c0.257,0.129,1.031-0.064,1.031,0.516c0,0.064-0.258,0.064-0.258,0.129c-0.064,0.515-0.064,1.095,0,1.61
		c0,0.194,0-0.386,0.129-0.45c0.193-0.129,0.322,0.257,0.516,0.386c1.031,0.838,1.674,1.932,1.932,3.286
		c0.322,1.418-0.129,3.113,0.387,4.467c0.258,0.58,3.608-0.515,3.479-0.193c-0.581,1.418-2.706,1.61-3.995,2.126
		c-0.838,0.387-1.352,1.417-2.255,1.74c-0.451,0.193-1.03,0.128-1.482,0.386c-0.451,0.194,0.001,1.031-0.321,1.417
		c-0.195,0.323-0.774,0.387-0.903,0.774c-0.902,2.385,0.451,0.772,0.838,2.512c0.065,0.194-0.451-0.193-0.644-0.064
		c-0.387,0.322-0.709,0.837-0.774,1.353c-0.258,1.547,1.739,0.902,2.32,1.031c0.386,0.129,0.58,0.644,0.966,0.709
		c0.258,0,0.323-0.451,0.516-0.516c0.387-0.064,0.901-0.064,1.224,0.258c0.129,0.129-0.515,0.129-0.708,0.258
		c-0.195,0.129-0.774,0.193-0.903,0.386c-0.064,0.258,0.065,0.644-0.064,0.773c-0.967,0.967-1.417-0.129-2.32,0
		c-1.418,0.258,0.194,0.193-0.451,0.644c-0.902,0.581-2.835,0.322-2.512,1.998c0.644,3.481,3.414-0.065,4.638,0.708
		c0.387,0.258,0.451,0.838,0.774,1.16c0.967,0.774,4.316,2.126,4.896,2.771c0.58,0.58,0.193,2.126,1.031,2.383
		c0.452,0.065,1.546-1.352,2.126-1.481c0.129,0,0,0.386,0.129,0.451c0.516,0.322,1.095,0.451,1.675,0.773
		c0.193,0.129,0.451,0.257,0.774,0.322c0.515,0.129,0.579-0.902,1.03-1.224c0.451-0.322,0.644,0.451,1.031,0.322
		c0.387-0.129,1.159-2.062,2.577-1.095c0.323,0.193,0.838,0.837,0.516,1.031c-0.387,0.257-0.902-0.451-1.354-0.451
		c-0.322,0,0.58,0.257,0.838,0.451c0.709,0.451,0.903,1.417,1.031,2.254c0.065,0.517,0.129,1.031,0.065,1.482
		c-0.065,0.129-0.258,0.065-0.258,0.194c-0.129,1.031,1.095,1.095,1.546,1.868c0.258,0.387,0,0.967,0.322,1.289
		c0.258,0.322,0.902-0.001,1.225,0.258c0.258,0.193,0.128,0.773,0.386,1.095c0.129,0.129,0.386-0.129,0.58-0.064
		c0.386,0.064,0.859,0.192,1.246,0.386c0.515,0.258,1.352,1.868,1.932,1.868c0.774,0-1.674-2.061-1.803-2.19
		c-0.323-0.645-0.086-1.933-0.731-2.448c-0.773-0.645-0.709,1.353-0.902,0.129c-0.193-1.225-0.322-2.448,0-3.673
		c0.258-0.967,0.129-0.064,1.031-0.709c0.58-0.386,1.052-1.675,1.632-1.997c0.451-0.258,1.031-0.451,1.547-0.258
		c0.451,0.194,0.386,0.902,0.708,1.225c0.258,0.193,0.644,0.257,0.902,0.386c0.774,0.58,0.902,1.611,1.868,1.997
		c0.129,0.065,0.323,0.129,0.452,0.194v-7.088V-70.207H388.04c-0.129,0.258-0.258,0.515-0.194,0.773
		c0.258,1.031,3.222,1.546,1.933,3.221c-0.516,0.645-1.739,0.451-2.319,1.096c-0.774,0.773-0.837,1.932-1.546,2.641
		c-0.195,0.194-2.857,1.675-3.115,1.804c-1.612,0.709-0.965-0.193-2.383-0.708c-0.129-0.065-0.516,0.387-0.516,0.193
		c0.258-3.095,0.45-0.322,1.353-1.546c0.064-0.129-0.452-0.065-0.387-0.258c0-0.193,0.387-0.258,0.516-0.387
		c0.064-0.193-0.323-0.579-0.129-0.579c0.193,0,0.386,0.128,0.58,0.128c0.515-0.064,1.094,0.065,1.482-0.257
		c0.322-0.387-0.194-1.418,0.257-1.482c0.516-0.065,0.129,0.966,0.129,1.417c0,0.065-0.194,0.129-0.194,0.194
		c0,0.129,0.129,0.257,0.194,0.321c0.515,0.258,1.375-0.063,1.633-0.579c0.257-0.387,0-0.966-0.065-1.418
		c0-0.064-0.258-0.128-0.193-0.193c0.064-0.193,0.387-0.193,0.451-0.387c0-0.129-0.387,0.129-0.387,0
		c0-0.129,0.258-0.257,0.323-0.45c0-0.065-0.323-0.258-0.194-0.258s0.258,0.387,0.322,0.258c0.194-0.065,0.387-0.387,0.258-0.58
		c-0.194-0.387-1.761-1.031-2.148-1.031c-0.129-0.065-0.515,0.322-0.45,0.129c0.129-0.451,0.514-0.838,0.772-1.353
		c0.129-0.258-0.193-0.451-0.516-0.709H367.401z M127.917-52.875c-0.048-0.003-0.092,0.009-0.129,0.022
		c-0.039,0.014-0.083,0.05-0.108,0.086c-0.386,0.515-0.772,1.159-0.837,1.804c-0.128,1.676,1.16,2.318,1.482,3.801
		c0.129,0.516-0.065,1.289,0.322,1.482c0.193,0.129,0.516-0.193,0.516,0c0.064,0.193-0.451,0.193-0.387,0.386
		c0,0.129,0.709,0.645,0.902,0.516c1.612-1.031,0.129-1.224-0.193-2.384c0-0.193-0.064-0.58,0.129-0.58
		c3.287-0.258-0.259,0.709,1.546,1.353c1.16,0.387-0.903-3.285-0.645-4.574C130.515-50.964,128.636-52.823,127.917-52.875
		 M321.463-36.382c-2.864,0.098-2.769,2.243-1.44,4.661c0.839,1.289,1.353,2.126,2.835,1.546c0.129-0.065,0.258,0.193,0.387,0.129
		c0.644-0.58,1.353-1.868,1.353-2.706c0-0.065-0.515-2.706-0.064-2.771c0.387,0,0.45,1.547,0.644,1.16
		c0.065-0.322-0.193-1.16-0.386-1.353c-0.904-0.774-1.546-0.58-2.706-0.644C321.868-36.372,321.653-36.388,321.463-36.382
		 M253.49-21.842c-0.733,0.007-1.193,0.626,0.258,2.04c0.322-0.387,1.223-0.515,1.159-1.031
		C254.795-21.481,254.059-21.847,253.49-21.842 M248.142-17.482c-0.58,0.387-1.224,0.58-1.611,1.096
		c-0.129,0.129,0.322,0.386,0.193,0.515c-0.58,0.838-0.321-0.773-0.901-0.515c-0.129,0.064,0.129,0.386,0,0.451
		c-0.193,0-0.193-0.323-0.322-0.387c-0.258-0.064,0.451,0.645,0.193,0.838c-0.193,0.129-0.322-0.322-0.58-0.322
		c-0.065,0,0.064,0.129,0.064,0.193c0.129,0,0.258,0.064,0.323,0.129c0.193,0.387-0.258,0.837-0.387,1.224
		c1.547-0.258,2.255-0.837,2.77-2.255c0.129-0.258,0.387-0.516,0.452-0.773c0-0.061-0.208-0.076-0.237-0.129
		C248.115-17.426,248.142-17.45,248.142-17.482 M253.576-15.764c-0.064,0.009-0.328,0.086-0.473,0.086c0.129,0,0.322,0,0.451-0.065
		C253.587-15.759,253.596-15.767,253.576-15.764 M244.383-15.571c0.008,0.004,0.002,0.012,0.022,0.021h0.258
		C244.534-15.55,244.432-15.563,244.383-15.571 M241.699-11.512c-0.026,0.002-0.048,0.005-0.065,0.022
		c-0.322,0.128-0.708,0.257-0.837,0.515c-0.129,0.322,0.193,0.644,0.257,0.966c0.258-0.451,0.58-0.901,0.774-1.417
		C241.877-11.474,241.778-11.518,241.699-11.512 M239.573-9.365c-0.065,0.129,0,0.194,0,0.323c0-0.065,0.064-0.129,0.064-0.194
		L239.573-9.365z M237.941-8.999c-0.748,0.042-1.59,0.327-1.783,0.859c-0.129,0.451,0.902,0.579,1.224,0.966
		C240.163-8.463,239.186-9.07,237.941-8.999 M102.339-3.824c-0.199,0.025-0.272,0.266,0.043,0.967c0.109,0.241,0.214,0.42,0.3,0.58
		c-0.158-0.024-0.317,0-0.429,0.129c-0.258,0.387,0.451,1.61,0.644,1.804c0.129,0.193,0.516,0.064,0.516,0.322
		c-0.065,1.289-1.16,1.804-1.16,1.74c-1.741-1.097,0.001-3.802-1.546-4.382c-1.87-0.644-0.387,1.418-0.645,1.611
		c-0.451,0.193-0.773-1.031-1.224-0.837c-0.967,0.451,1.224,2.19,1.031,2.383c-0.58,0.645-2.062-3.545-1.804-1.353
		c0.065,0.516,0.192,1.096,0.579,1.482c0.258,0.258,1.289-0.065,1.096,0.258c-0.322,0.322-1.031-0.387-1.289-0.065
		c-0.258,0.194,0.709,0.516,0.515,0.774c-1.482,1.998-0.708-2.384-1.868-2.32c-1.289,0,0.645,1.868-0.257,2.449
		c-0.323,0.193-1.869-3.674-2.384-0.967c-0.129,0.645,1.417,0.579,1.61,1.16c0,0.193-0.322,0.193-0.322,0.386
		c0.064,1.032,1.869,0.129,2.256,0.258c0.193,0.064,0.128,0.516,0.321,0.516c0.323-0.065,0.322-0.516,0.58-0.709
		c0.064,0-0.064,0.257,0,0.322c0.322,0.838,0.322,0.064,0.773-0.065c0.129,0-0.128,0.323,0,0.323c0.129,0,0.323-0.387,0.387-0.258
		c0.129,0.322-0.193,0.73-0.129,1.117c0.129,0.58,2.32-2.772,2.384,0.257c0,0.516-1.225,0.322-1.289,0.773
		c-0.064,0.71,2.385,0.516,1.869,0.967c-0.258,0.322-0.709-0.064-1.096-0.064c-0.773,0.064-1.932,0.192-2.641,0.708l-0.129,0.129
		c0.258,0.516,0.709,1.224,1.289,1.289c0.516,0.064,1.159-0.645,1.417-0.258c1.676,2.772-3.156-0.387-3.48,0
		c0.078-0.264,0.17-0.539,0-0.709c-0.386-0.258-0.901,0-1.352,0.129c-0.258,0.065-0.387,0.645-0.58,0.451
		c-0.258-0.193,0.451-0.709,0.193-0.838c-0.58-0.322-1.481,0.516-2.061,0.194c-0.645-0.387-0.515-1.031-1.225-0.709
		c-2.578,1.225,0.967,1.224,1.225,1.353c0.193,0.064,0.129,0.387,0.258,0.451c0.064,0.065,0.193-0.129,0.322-0.129
		s0.322-0.064,0.387,0.065c0.451,0.322,0.965,1.159,1.481,1.417c0.129,0.065-0.129-0.258-0.064-0.322
		c0.193-0.129,0.128,0.387,0.322,0.451c0.258,0.129,0.838-0.258,0.902,0c0.129,0.451-0.58,0.708-0.644,1.095
		c-0.065,0.387,0.193,0.709,0.193,1.095c-0.065,0.323-0.58,0.967-0.258,1.032c0.451,0.064,2.062-0.709,2.255-0.645
		c0.193,0.129,0.902,0.065,0.709,0.193c-0.387,0.323-0.902,0.387-1.354,0.452c-0.257,0-0.579-0.194-0.772-0.129
		c-0.323,0.129-0.58,0.386-0.58,0.708c-0.065,0.194,0.58,0.258,0.451,0.387c-0.258,0.258-1.353-0.064-1.031,0.129
		c0.451,0.193,0.966,0.064,1.481,0.129h0.387c0.258,0.129,1.096,0.644,0.773,0.579c-0.451-0.064-0.901-0.386-1.353-0.386
		c-2.449,0.194,0.194,0.902-1.804,1.675c-0.451,0.193-1.03-0.258-1.546-0.193c-0.193,0-0.257,0.451-0.322,0.322
		c-0.322-0.387,0-1.224-0.516-1.418c-0.322-0.129-0.128,0.838-0.322,1.16c-0.193,0.258-1.031,0.516-0.773,0.709
		c0.193,0.129,4.317,2.126,4.51,2.19c0.258,0.065,0.515,0,0.708,0.194c0.388,0.322,0.387,0.966,0.838,1.224
		c0.258,0.129,0.58-0.709,0.709-0.451c0.258,0.451-0.58,1.159-0.193,1.482c0.034,0.027,0.073,0.024,0.107,0.021
		c-0.008,0.018-0.038,0.025-0.043,0.043c-0.128,0.322,0.194,0.709,0.258,1.031c0.451,1.289,1.159,0.708,1.997,1.417
		c0.58,0.581,1.546,2.449,2.706,2.835c0.452,0.194,2.835,0.452,3.287,0.065c0.193-0.194,0.278-1.74,1.18-1.482
		c0.516,0.193,1.933,0.258,2.32,0.387c0.258,0.128,0.322,0.644,0.58,0.58c0.322-0.13,0.193-0.967,0.58-1.032
		c0.451,0,0.58,0.709,1.031,0.903c0.129,0.064,0.386,0,0.515-0.065c0.838-0.322,1.545-1.03,2.384-1.159
		c1.612-0.259,0.643,0.193,2.384-0.129c0.065,0-0.258-0.194-0.193-0.194c0.129-0.129,0.387,0.129,0.451,0
		c0.193-0.322-0.194-1.095,0.129-1.03c1.031,0.257,0.837,1.932,1.223,1.868c0.452-0.065,0.709-0.644,1.161-0.773
		c0.193-0.065,0.579,0.322,0.708,0.129c0.322-0.387,0.064-1.16,0.451-1.482c0.322-0.322,1.16,0.129,1.353-0.258
		c0.053-0.094,0.055-0.199,0.022-0.301c0.029,0.066,0.066,0.132,0.107,0.172c0.451,0.451,1.224-0.257,1.804-0.516
		c0.129-0.064,0.451-0.192,0.387-0.321c-0.129-0.323-1.032-0.516-0.774-0.709c0.323-0.258,0.838,0.644,1.16,0.386
		c0.162-0.145,0.117-0.356-0.021-0.58c0.259,0.115,0.499,0.158,0.665,0.065c2.643-1.547-0.708-1.933-0.901-2.126
		c-0.129-0.129,0.128-0.387,0.322-0.516c0.387-0.193,0.966-0.128,1.352-0.386c0.774-0.516,0.452-1.611,0.129-2.191
		c-0.064-0.193-0.322,0.065-0.451-0.064c-0.258-0.194-0.45-0.451-0.644-0.709c-0.452-0.387-0.966,1.031-1.546,0.838
		c-0.451-0.194,0.773-0.709,1.224-0.967c0.065-0.064,0.322,0.129,0.322,0c0-0.193-0.322-0.387-0.322-0.58
		c0-0.129,0.386-0.128,0.322-0.193c-0.387-0.323-0.837-0.644-1.288-0.773c-0.097-0.032-0.24,0.016-0.344,0.064
		c0.356-0.256,2.41-1.526,1.439-2.255c-0.323-0.257-0.902-0.064-1.224-0.322c-0.129-0.064,0.257-0.193,0.193-0.387
		c0-0.129-0.387-0.128-0.322-0.257c0.644-1.032,1.03-0.902,1.997-1.096c0.322-0.064,1.031,0.065,0.902-0.193
		c-0.838-1.547-1.481-0.129-2.061,0.064c-0.258,0.129-0.58-0.128-0.838,0c-0.258,0.129-0.258,0.838-0.516,0.709
		c-2.127-1.16,0.258-1.417-0.193-2.383c-0.129-0.194-0.515-0.129-0.644-0.387c-0.129-0.451,0.838-0.966-0.322-1.224
		c-0.451-0.129-0.902-0.451-1.354-0.258c-0.322,0.193-0.257,0.708-0.322,1.095c0,0.322,0.129,0.645,0,0.903
		c-0.064,0.257-0.386,0.386-0.515,0.579c-0.048,0.097,0.015,0.373,0.022,0.451c-0.003-0.004-0.018,0.008-0.022,0
		c0,0-0.257-1.031-0.644-0.58c-0.193,0.194,0.193,0.903-0.065,0.774c-0.58-0.387-0.515-1.869-1.224-1.805
		c-0.709,0.065-1.353,0.967-1.675,1.482c-0.064,0.065-0.064,0.258-0.129,0.258c0,0-0.084-0.204-0.15-0.365
		c-0.06,0.006-0.193-0.001-0.237,0.043c-0.128,0.064-0.128,0.516-0.128,0.387c-0.129-0.645,0.258-3.415-1.417-2.964
		c-0.645,0.129-0.645,6.508-1.161,4.317c-0.322-1.225,0.129-0.515,0.323-1.869c0.451-2.385-0.323,0.388-0.645-1.224
		c-0.064-0.322,0.322-0.515,0.322-0.837c-0.064-0.517,0-1.225-0.451-1.418c-0.451-0.258-0.966,0.451-1.481,0.58
		c-0.065,0-0.065-0.193-0.129-0.258c-0.258-0.129-0.516-0.257-0.709-0.193c-0.322,0.129-0.58,0.515-0.645,0.837
		c-0.257,0.516,0.324,2.578-0.708,1.676c-1.29-1.032,0.71-3.888-1.547-4.081c-0.644-0.064-0.342,2.534-1.18,3.436
		c-0.129,0.129-2.063,1.87-1.676,0.258c0.064-0.258,1.87-2.063-0.772-0.644c-0.839,0.451-0.773,3.157-2.127,2.899
		c-0.387-0.065,0.064-0.838,0.193-1.224c0.129-0.323,0.644-0.644,0.516-0.967c-0.065-0.322-0.967-0.129-0.838-0.451
		c0.064-0.515,1.031-0.515,1.16-1.031c0.129-0.451-0.451-0.901-0.58-1.417c-0.129-0.193-0.387-0.666-0.194-0.601
		c0.516,0.193,0.709,1.117,1.225,0.988c2.965-0.451,0-1.761,0.064-2.084c0.129-0.322,1.095,0.323,1.095-0.064
		c0.065-0.387-0.901-0.129-1.03-0.515c-0.129-0.452,0.516-0.902,0.451-1.353c0-0.323-0.516-0.258-0.644-0.516
		c-0.065-0.129,0.257-0.064,0.386-0.129c0.129-0.258-0.322-0.644-0.386-0.708c-0.129-0.065-0.452,0.064-0.452-0.065
		c-0.064-0.322,0.903-0.966,0.258-1.482c-0.258-0.193-0.709-0.129-0.967-0.322c-0.257-0.193-0.256-0.58-0.45-0.773
		c-0.13-0.065,0,0.193,0,0.322c0,0.065-0.064,0.129-0.064,0.129C103.372-3.308,102.669-3.865,102.339-3.824 M143.96,49.867
		c-0.565,0.038-0.158,0.31,0.257,0.645c0.003-0.055,0.065-0.22,0.258-0.623C144.242,49.874,144.086,49.859,143.96,49.867
		 M144.733,50.19c-0.194,0.043-0.423,0.312-0.494,0.343c0.224,0.182,0.435,0.385,0.494,0.58c0.065,0.387-0.387,1.16,0,1.224
		c0.451,0,0.58-0.772,0.58-1.224c0-0.258-0.515-0.193-0.644-0.451c0-0.064,0.322-0.064,0.322-0.193
		C144.942,50.228,144.851,50.164,144.733,50.19 M143.508,56.203c-0.064,0.58-0.193,1.611-0.193,1.611S143.444,56.783,143.508,56.203
		 M198.188,57.256c0.04,0.217,0.097,0.429,0.258,0.558c0.451,0.322,1.352-0.129,1.481,0.386c0.129,0.452-0.965-0.257-1.353,0.065
		c-0.129,0.064-0.192,0.258-0.257,0.387C198.317,58.154,198.305,57.638,198.188,57.256 M164.191,69.562
		c-0.089,0.148-0.137,0.33-0.193,0.494c0.039,0.007,0.09,0.019,0.129,0.022c-0.069,0.039-0.128,0.072-0.172,0.086
		c0.013-0.035,0.03-0.071,0.043-0.108c-0.078-0.013-0.129-0.036-0.129,0.022c-0.017,0.08,0.008,0.1,0.064,0.086
		c-0.06,0.149-0.118,0.281-0.258,0.365c-0.451,0.193-0.966,0.064-1.482,0.129c-0.129,0.064-0.387-0.001-0.322,0.128
		c0.129,0.452,0.322,1.031,0.773,1.225c0.322,0.193,0.58-0.773,0.838-0.516c0.322,0.194-0.387,1.632-0.773,2.47
		c-0.065,0.129-0.194,0.516-0.129,0.387c1.289-1.354,1.224-2.019,1.16-2.857c-0.065-0.193,0.772,0.001,0.708-0.58
		c0-0.193-0.386-0.193-0.386-0.322c0.065-0.193,0.321,0.129,0.45,0.065c0.065-0.065-0.193-0.258-0.193-0.322
		c0.129-0.194,0.645-0.451,0.451-0.516c-0.138-0.028-0.339,0.092-0.515,0.193C164.319,69.884,164.19,69.755,164.191,69.562
		 M153.603,79.054c-2.708,0.193,1.159,3.802,1.997,2.126c-0.258-0.129-0.515-0.193-0.709-0.322c-0.386-0.129-0.967-0.129-1.16-0.516
		c-0.129-0.193,0.709-0.128,0.645-0.386C154.376,79.505,153.99,79.054,153.603,79.054 M265.237,80.407
		c-0.072,0-0.15,0.021-0.215,0.021c-0.129,0.387-0.257,1.16-0.837,1.289c-0.451,0.129-1.869,0.322-1.418,0.387
		c0.451,0.129,1.611-0.13,1.868,0.579c0,0,0.065,1.569,0.774,1.182c2.386-1.354,0.58-0.343,1.096-1.568
		c0.128-0.258,2.191,0.001,0-1.288c-0.258-0.129-0.709,0.193-0.967,0.064c-0.194-0.129,0-0.451-0.129-0.58
		C265.377,80.428,265.309,80.407,265.237,80.407 M139.128,81.567c-0.382,0.06-0.714,0.491-1.053,0.773
		c-0.065,0-0.065,0.193-0.129,0.258c-0.645,0.064-0.644-0.645-1.288,0.128c-0.193,0.194,0.193,1.16,0.129,1.16
		c-0.903-0.193-1.353-1.676-1.933-0.064c-0.064,0.258,0,0.515,0.129,0.773c0,0.064,0.258,0,0.192,0.064
		c-0.128,0.129-0.643,0.065-0.579,0.258c0.194,0.516,1.288,0.58,1.095,1.031c-0.193,0.516-1.288-0.258-1.546,0.193
		c-0.258,0.387,0.129,1.16,0.58,1.16c0.516,0,0.579-0.902,1.031-1.16c0.128-0.129,0.386,0.194,0.58,0.129
		c0.386-0.193-0.129-1.546,0.257-1.288c0.323,0.322,0.258,1.03,0.709,1.095c0.838,0,0.902-1.804,0.966-2.126
		c0.129-0.387,1.031-0.902,1.354-1.418c-0.129-0.322,0-0.902-0.323-0.966C139.243,81.56,139.182,81.559,139.128,81.567
		 M145.098,81.76c-0.258,0.387-0.644,0.708-0.709,1.16c-0.064,0.193,0.387,0.322,0.387,0.515c0,0.129-0.322-0.193-0.451-0.129
		c-0.065,0.129,0.258,0.387,0.129,0.516c0,0-0.967-0.838-0.645,0.257c0.129,0.388,1.031,0.709,0.774,1.032
		c-0.323,0.386-1.031,0-1.483-0.194c-0.193,0-0.385-0.515-0.385-0.322c-0.065,0.451,0.515,1.031,0.257,1.482
		c-0.129,0.322-0.966-0.387-0.966-0.065c-0.065,0.838,0.644,0.902,0.451,1.74c0,0.065-0.065,0-1.096-0.58
		c-0.773-0.451-0.064,1.031-0.58,1.16c-0.387,0.129-0.45-1.095-0.773-0.902c-1.418,0.58,0.708,2.835,0.773,2.964
		c0.129,0.258,0.064,0.644,0.064,1.031c0,0.515,0.258,1.159-0.064,1.546c-0.322,0.387-1.288-0.194-1.482,0.257
		c0,0,2.192,1.16,0.452,1.547c-0.516,0.129-0.967-0.452-1.418-0.322c-1.805,0.451,0.064,1.223-0.194,1.868
		c-0.129,0.258-1.803-0.451-2.19,0.064c-0.516,0.774,3.544,0.709,2.964,0.967c-0.451,0.193-0.966-0.065-1.482-0.065
		c-0.193,0-0.709-0.193-0.644,0c0.064,0.323,0.193,0.58,0.322,0.774c-0.129-0.065-0.258-0.194-0.322-0.322
		c-0.194-0.194-0.322-0.451-0.451-0.709c-2.901,0.967,1.16,0.257,0.129,1.61c-1.612,2.192-0.58,0.258-1.869,0.774
		c-0.064,0-0.129,0.193-0.064,0.258c0.451,0.902,3.027,0,3.543-0.452c0.129-0.128-0.129-0.257-0.193-0.386
		c-0.129-0.065-0.193-0.129-0.257-0.258c0.58,0.258,1.352-0.451,2.19-0.902c0.387-0.193,0.321-0.322,0.773-0.644
		c0.193-0.193,0.709-0.516,0.58-0.322c-0.129,0.386-0.773,0.45-0.773,0.837c0,0.258,1.03,0,0.837,0.194
		c-0.064,0-2.706,0.45-2.255,1.353c0.194,0.322,0.903,0.321,0.903,0.644c0,0.322-0.581-0.387-0.903-0.322
		c-0.322,0.064-0.644,0.45-0.773,0.837c-0.194,0.451,0,1.611-0.258,2.127c-0.258,0.451-1.546,0.708-1.159,1.03
		c0.322,0.194,0.514-0.773,0.902-0.644c0.902,0.193-0.967,1.288-0.838,1.546c0.129,0.323,0.837-0.129,0.966,0.194l-1.352,1.159
		c-0.387,0.516-0.258,1.353-0.645,1.869c-0.193,0.322-1.096,0.837-0.708,1.031c0.516,0.193,1.095,0,1.546-0.258
		c0.129-0.065-0.258-0.322-0.193-0.451c0.193-0.516,0.708-0.773,0.902-1.289c0.064-0.129-0.065-0.386,0-0.579
		c0-0.194,0.129-0.387,0.258-0.516c0.193-0.193,0.708-0.128,0.772-0.387c0.129-0.709-0.709-1.61-0.193-2.061
		c0.193-0.194,2.77-1.611,2.835-1.547c0.193,0.258-0.065,0.644-0.065,0.967c-0.064,0.516-0.258,1.03-0.064,1.417
		c0,0.129,3.222,1.676,1.096,0.709c-0.581-0.258-1.096-0.902-1.354-0.322c-0.193,0.515,0.001,1.095-0.193,1.546
		c-0.065,0.194-0.451,0.194-0.386,0.387c0.064,0.129,1.288,1.61,1.03,2.061c-0.129,0.258-0.579,0.258-0.772,0.452
		c-2.064,1.869,0.773-0.324-0.903,1.546c-0.902,1.031-1.16,0.922-1.095,2.405c0.064,0.323-1.482,0-0.837,0.387
		c2.513,1.482,0.578-1.354,2.448,1.095c0.258,0.387,0.579,1.418,0.837,1.031c0.644-1.032-0.58-2.126,0.322-1.933
		c0.709,0.129,0.709,1.095,1.031,1.482c0.065,0.064,0.194,0.129,0.258,0.064c0.129-0.129,0.065-0.579,0.258-0.515
		c0.193,0.065-0.193,0.386-0.129,0.58c0.065,0.129,0.258,0.129,0.387,0.064c0.322-0.129,0.515-0.451,0.901-0.58
		c0.323-0.064,0.709,0.193,1.031,0.065c0.387-0.129,0.58-0.644,0.966-0.773c0.324-0.065,0.58,0.257,0.838,0.322
		c0.387,0.064,1.289-0.322,1.16,0.064c-0.129,0.451-0.838,0.58-1.353,0.709c-0.129,0.064-0.065-0.258-0.194-0.322
		c-0.064,0-2.19,2.126-2.125,2.899l0.773,1.611c0.064,0.387-0.58,1.095,0.193,1.353c0.258,0.064,0.579-0.709,0.708-0.451
		c0.195,0.516-0.58,1.739,0,1.739c0.904-0.064,1.676-1.804,1.741-0.837c0.064,0.516-0.516,0.837-0.709,1.289
		c-0.194,0.451,0.322,0.257,0.257,0.644c0,0.773-2.191-0.645-1.353,1.159c0.13,0.387,0.902,0.387,1.031,0.774
		c0.065,0.322-0.58,0.128-0.838,0.322c-0.322,0.258-0.708,0.58-0.772,0.966c-0.066,0.361-0.04,0.784,0.086,1.16
		c-0.358-0.096-0.637-0.055-0.666,0.064c-0.129,0.452,0.58,1.16,0.193,1.418c-0.387,0.258-0.516-0.709-0.838-1.031
		c-0.644-0.58-0.837,0.258-1.739,0.129c-0.451-0.065-0.709-0.58-1.16-0.644c-0.194-0.065,0.193,0.386,0.064,0.451
		c-0.902,0.322-1.288,0.128-1.739,0.128c-0.193,0.065-0.451,0.129-0.709,0.322c-0.129,0.065-0.258,0.194-0.387,0.387
		s-0.064,0.515-0.193,0.709c-0.838,0.645-2.191,0.708-2.062,1.16c0.452,2.642,1.675-1.225,2.771,0.128
		c0.387,0.387,0.644,2.9,0.515,3.157c-0.386,0.903-2.448,2.642-3.479,2.9c-0.322,0.064-0.644-0.258-0.966-0.258
		c-0.451,0.064-0.515,0.708-0.902,0.837c-0.322,0.129-2.706-0.58-2.706,0.709c0,0.452-0.129,0.967,0.129,1.353
		c0.181,0.242,1.764,0.326,1.803,0.387c-0.386,0.065-1.03-0.194-1.223,0.193c-0.129,0.258,0.45,0.645,0.772,0.709
		c0.387,0.129,0.903,0,1.289-0.129c0.129-0.129,0.065-0.516,0.258-0.516c1.547-0.451,0.966,0.065,1.417,0
		c0.129,0,0.193-0.386,0.258-0.257c0.064,0.193,0.193,2.191,0.322,2.255c0.645,0.322,1.611-0.838,2.126-0.322
		c0.58,0.58,0.451,1.675,1.096,2.126c1.933,1.096,1.545-0.258,3.157-0.709c0.193-0.064,0.321,0.258,0.515,0.258
		c0.323-0.064,0.644-0.129,0.967-0.258c0.257-0.193,2.319-1.546,1.932-1.095c-0.387,0.451-3.478,2.384-3.543,2.513
		c-0.193,0.386-0.064,1.03-0.451,1.353c-1.225,0.967-2.834-0.709-4.06-0.902c-3.609-0.645-1.867,1.611-3.349,1.481
		c-0.258-0.064-0.516-0.579-0.774-0.515c-0.451,0.194-0.386,1.675-0.58,1.933c-0.322,0.387-0.901,0.451-1.223,0.773
		c-0.194,0.129-0.129,0.386-0.323,0.515c-0.193,0.065-0.515-0.193-0.709-0.064c-0.451,0.323-0.579,0.902-1.031,1.288
		c-0.129,0.065-0.386-0.128-0.514,0c-0.129,0.065,0,0.323-0.129,0.387c-0.194,0.258-0.452,0.516-0.709,0.58
		c-0.065,0-2.642,0.129-2.642,0.58c0,0.709,1.546,0.279,1.933,0.924c0.257,0.322,0.064,1.159,0.451,1.224
		c0.258,0,1.031-1.611,1.16-1.933c0.064-0.065,0.129-0.086,0.193-0.086c0.451,0,0.902,0.279,1.288,0.15
		c0.258-0.129,0.129-0.73,0.452-0.794c0.902-0.258,1.997,0.536,2.77,0.73c0.628,0.145,0.247-0.275,0.15-0.408
		c0.011,0.004,0.019-0.015,0.043,0c0.516,0.258,1.869,2.147,2.642,1.825c0.967-0.451,1.675-3.565,1.675-3.565
		c1.16-0.451,3.607,0.129,4.445,0.967c0.323,0.257,0.645,0.515,0.774,0.837c0.064,0.193-0.194,0.73-0.065,0.602
		c0.194-0.323,0.065-0.924,0.451-1.117c0.323-0.129,1.353,0.579,1.675,0.773h0.065c0.193,0,0.515,0.279,0.58,0.086
		c0.064-0.387-0.58-0.859-0.387-1.181c0.129-0.194,3.737,0.644,3.802,0c0.064-0.387-0.709-0.838-0.451-1.16
		c0.193-0.193,0.772,1.095,1.545,1.353c0.194,0.064-0.064-1.096,0.387-0.516c0.129,0.194-0.258,0.516-0.064,0.58
		c0.193,0.065,0.386-0.386,0.58-0.322c0.193,0-0.258,0.193-0.258,0.387c0.064,0.129,0.258,0.258,0.387,0.193
		c0.129-0.193-0.065-0.773,0.322-0.515c0.644,0.451-0.258,0.193-0.193,0.515c0.193,0.709,3.414-0.064,3.866,0
		c0.58,0.129,1.61,1.246,2.19,1.246c0.387,0,0.515-0.537,0.837-0.666c0.709-0.322,1.374-0.129,2.084-0.58
		c0.709-0.451,0.128,0.58,1.288,0.194c0.322-0.13,0.129-0.774,0.387-0.967c0.644-0.323,1.804-0.064,1.997-0.709
		c0.064-0.515,0-0.966,0.129-1.417c0.064-0.258,0.709-0.58,0.451-0.645c-0.967-0.386-1.933,0.323-2.835,0.194
		c-1.869-0.323,0.902,0.129,0-0.709c-0.258-0.193-0.516-0.451-0.773-0.387c-0.024,0-0.045,0.011-0.065,0.022
		c0.002-0.005-0.001-0.016,0-0.022c0.065-0.837-1.803-0.257-1.868-0.257c-0.193-0.129,0.322-0.322,0.516-0.387
		c0.709-0.258,2.126,0.323,2.641-0.515c0.194-0.323,0.387-0.838,0.129-1.095c-0.258-0.323-1.418,0.193-1.224-0.194
		c0.257-0.387,1.031-0.258,1.546-0.322c0.065,0,0.065,0.129,0.129,0.129c0.194,0.129,0.322,0.258,0.515,0.193
		c2.579-0.709-0.451-0.386,0.129-1.546c0.129-0.322,0.709,0.451,1.096,0.322c1.095-0.387,2.577-3.285,2.77-4.381
		c0.645-3.481-2.898-4.446-5.09-4.768c-1.353-0.193-0.385,0.644-1.675,1.482c-0.194,0.129-0.515-0.193-0.709-0.387
		c-0.193-0.193-0.257-0.579-0.515-0.773c-0.129-0.129-0.645,0.129-0.58-0.064c0.644-1.612,1.03-0.58,1.932-1.482
		c1.162-1.225-1.159-3.672-1.159-3.995c0.065-1.354,1.096,0.452,0.774-0.773c-0.258-0.902-1.483-2.383-1.225-3.415
		c0.065-0.386,0.773-0.386,0.773-0.773c0-1.289-0.902-0.644-1.224-1.353c-0.322-0.516-0.15-1.288-0.537-1.739
		c-1.096-1.354-0.837-0.773-1.997-1.482c-0.258-0.129-0.45-0.451-0.709-0.516c-0.129-0.064-0.257,0.322-0.322,0.193
		c-0.386-0.966-0.837-5.754-0.902-6.915c0-0.387,0-0.837,0.065-1.224c0-0.194,0.128-0.322,0.064-0.451
		c-0.193-0.258-0.58-0.322-0.838-0.58c-0.451-0.516-0.643-2.126-1.095-2.448c-0.257-0.258-2.706-2.062-2.835-1.998
		c-0.257,0.13-0.322,0.58-0.58,0.774c-0.58,0.386-1.674-0.451-2.19-0.58c-0.451-0.065,0.064,0.322-0.387,0.129
		c-0.064,0-0.064-0.709,0-0.709c4.191,0.322,0.579-0.515,2.577-0.838c0.387-0.064,1.74,1.161,1.933-0.257
		c0.064-0.452-0.773-0.58-0.966-1.031c-0.057-0.169,0.491-0.669,0.022-0.752c0.171,0.01,0.349,0,0.557-0.021
		c0.838-0.065,3.544-3.866,4.06-4.832c0.064-0.065-0.065-0.194,0-0.323c0.064-0.322,0.193-0.644,0.387-0.902l1.481-1.481
		c0.129-0.387,0.064-0.773-0.064-1.16c-0.581-1.29-4.188-1.224-5.284-1.547c-0.451-0.128-0.837-0.58-1.288-0.644
		c-0.903-0.193-1.803,0.58-2.512,0.644c-0.388,0-0.709-0.257-1.032-0.193c-0.129,0,0.194,0.193,0.129,0.322
		c-0.322,0.387-1.547,0.001-1.031-1.159c0.193-0.452,0.258,0.064,0.709-0.129c0.193-0.065,0.387-0.322,0.644-0.258
		c0.129,0,0,0.451,0.129,0.451c0.323-0.065,0.451-0.387,0.644-0.58c0.259-0.193,0.838-0.451,0.58-0.58
		c-0.645-0.516-2.514,0.581-1.224-0.386c0.774-0.581,4.832-3.029,4.961-3.351c0.129-0.387,0-0.773,0.129-1.16
		c0.194-0.386,1.031-0.708,0.709-1.03c-0.323-0.387-1.031,0.064-1.547,0c-1.289-0.194-3.478-0.58-4.445-0.323
		c-0.258,0.065-0.387,0.774-0.645,0.581c-0.322-0.258,0.129-1.096-0.257-1.225c-0.148-0.021-0.245,0.061-0.344,0.172
		C146.221,82.289,145.462,81.942,145.098,81.76 M138.655,127.848l-0.193-2.319c-1.612,0.516-0.645,0.772-0.258,2.126
		c0.064,0.129-0.322,0.387-0.129,0.387S138.462,127.978,138.655,127.848 M138.462,125.529l0.902,1.997
		C140.589,126.946,139.622,126.431,138.462,125.529 M267.341,84.188c-1.482,0.451-2.447-0.13-2.898,1.417
		c-0.064,0.194-0.322-0.45-0.516-0.386c-0.386,0.193,0.194,1.224-0.193,1.224c-0.451,0-1.096-0.902-1.225-0.516
		c-0.193,0.452,0.838,0.645,0.903,1.16c0.064,0.258-0.645,0.387-0.516,0.645c0.194,0.451,0.774,0.579,1.16,0.773
		c0.194,0.129,0.515-0.129,0.579,0.064c0.129,0.709-1.352,1.224-0.579,2.126c0.258,0.258,0.708-0.386,0.837-0.708
		c0.129-0.516,0-1.096,0-1.611c0-0.065,0-0.129,0.065-0.129c0.322-0.194,0.773-0.322,1.16-0.515
		c0.129-0.065,0.129-0.323,0.258-0.323c0.193-0.064,0.837,0.194,1.159-0.644c0.064-0.258-0.129-0.58,0-0.709
		c0.064-0.129,0.344,0.258,0.408,0.194c0.322-0.323-0.064-1.16,0.322-1.224c0.182-0.026,0.301,0.078,0.387,0.193
		c-0.064-0.129-0.05-0.174,0.021-0.172C268.146,84.784,268.007,84.647,267.341,84.188 M268.673,85.046
		c0.137,0.068,0.282,0.143,0.495,0.236C269.044,85.201,268.8,85.05,268.673,85.046 M250.032,92.478
		c-0.119,0.01-0.231,0.037-0.344,0.086c-0.258,0.129-2.19,3.221-2.126,3.543c0,0.451,0.387,0.773,0.451,1.224
		c0,0.387-0.257,0.773-0.257,1.16c0,0.129,0.644,0.902,0.709,1.353c0,0.064,0,0.258,0.064,0.258c0.032,0,0.075-0.024,0.107-0.065
		c-0.222,0.417-0.604,0.876-0.429,1.224c0.193,0.388,0.643-0.579,0.837-0.901c0.515-0.967-0.065-0.966,0.902-1.676
		c1.225-0.966-0.258-0.257,0.58-1.224c0.258-0.258,0.773-0.322,0.773-0.708c0-0.58-1.611-0.064-0.902-1.74
		c0.58-1.354,1.418-1.481,0.709-2.513C250.767,92.547,250.388,92.447,250.032,92.478 M212.813,96.88
		c-0.515,0-0.772,0.901-1.223,1.159c-0.453,0.194-1.032,0.065-1.483,0.258c-1.353,0.774-1.03,2.835-2.513,3.544
		c-1.095,0.451-2.64-0.13-3.737,0.386c-1.225,0.451-2.385,4.446-0.257,4.51c0.065,0-0.322-1.804-0.258-1.868
		c1.29-3.417,0.322-0.838,1.224-1.224c0.193-0.129,0.065-0.516,0.258-0.58c0.451-0.129,0.966-0.194,1.482-0.129
		c0.194,0,0.322,0.322,0.515,0.322c0.451-0.064,0.838-0.451,1.289-0.58c0.516-0.129,2.448-0.58,1.997,0
		c0.451,0,0.774,0.451,1.16,0.58c1.16,0.387,1.804-3.092,1.611-3.801c-0.323-0.968-0.774-0.386-0.645-1.289
		C212.298,97.588,213.78,96.88,212.813,96.88 M243.181,98.297c-0.193,0.065-0.451,0-0.58,0.193c-0.58,0.774-0.129,1.095-0.258,1.869
		c-0.322,1.74-1.61,2.705-1.739,4.574c0,0.194,0.257,3.093,0.386,3.093c0.839-0.065,0.58-2.577,0.644-2.9
		C241.828,103.773,242.729,99.328,243.181,98.297 M122.956,107.253c-3.352,1.547-0.58,0.45-0.838,1.546
		c-0.065,0.193-0.644,0.193-0.515,0.322c0.194,0.193,0.774,0.644,0.45,0.709c-0.322,0.064-0.837-0.451-1.094-0.194
		c-0.194,0.258,0.838,0.645,0.58,0.773c-0.387,0.129-1.675-1.289-2.063,0c-0.193,0.774,1.096,0.838,1.354,0.967
		c0.064,0,0.064,0.129,0.064,0.194c0,0.128-0.193,0.386-0.064,0.386c0.838,0.064,0.644-0.903,0.838-0.194
		c0,0.129,1.159-0.128,0.965,0.129c-0.322,0.387-0.708,0.774-1.223,0.967c0,0.065-1.933-0.129-2.063,0.451
		c-0.064,0.515,0.58,1.031,0.387,1.482c-0.064,0.193-1.739-1.74-2.319-1.418c-0.323,0.194-0.322,0.967-0.709,0.838
		c-0.387-0.129-0.128-0.837-0.322-1.288c-0.322-0.839-3.35-0.838-3.93-0.709s0.064,0.064,0,0.322
		c-0.129,0.322-0.774,0.644-0.516,0.902c0.258,0.258,0.774-0.709,1.031-0.451c0.323,0.387-0.257,0.966-0.257,1.417
		c0.064,0.064,0.709,0.709,0.322,0.967c-0.193,0.193-0.902-0.065-0.774,0.193c0.129,0.58,2.514-0.903,1.676,1.095
		c-0.064,0.129-0.193,0.194-0.322,0.194c-0.516,0-1.031-0.387-1.482-0.258c-0.516,0.129-0.837,0.58-1.095,1.031
		c-0.065,0.064,0.258,0.258,0.193,0.322c-0.064,0-2.707-0.774-1.289,1.095c0.516,0.774,2.319-0.065,2.642,1.095
		c0.322,1.096-1.032,1.031,0.322,1.418c1.418,0.387,2.706,0.129,2.77,0.322c0,0.194-0.451,0.129-0.451,0.322
		c0,0.169,0.479,0.108,0.516,0.129c-0.451,0.258-0.966,0.472-1.483,0.472c-0.193,0.065-0.256-0.472-0.45-0.408
		c-0.322,0.065-0.451,0.408-0.644,0.666c-0.258,0.258-1.096,0.515-0.838,0.838c0.129,0.193,0.709,0.064,0.58,0.193
		c-0.193,0.065-0.387,0.129-0.516,0.258c-0.322,0.322-0.386,0.773-0.644,1.031c-0.129,0.064-0.386-0.129-0.515,0
		c-0.516,0.193-0.902,0.515-1.418,0.773c-0.193,0.065-0.838,0-0.644,0.064c0.257,0.129,2.127-0.257,2.127-0.257
		c0.064,0.064-0.065,0.193,0,0.322c0.129,0.193,0.321,0.386,0.579,0.451c0.258,0.064,0.515-0.194,0.709-0.129
		c0.136,0.045-0.077,0.201-0.172,0.322c-0.847-0.122-1.735-0.258-1.761-0.258c-0.258,0.065-0.258,0.451-0.516,0.58
		c-0.451,0.194-1.245-0.129-1.438,0.322c-0.259,0.516,1.245,1.804,0.729,1.546c-0.515-0.257-0.922-0.837-1.502-0.837
		c-0.452-0.064-1.353-0.129-1.676,0.065c-0.129,0.064,0.001,0.322-0.128,0.386c-0.13,0.065-0.58-0.258-0.516-0.129
		c0.387,0.516,2.32,1.482,2.384,1.418c0.129-0.065-0.064-0.322,0-0.451c0.322-0.194,1.245,0.257,0.987,0.451
		c-0.322,0.322-0.923,0.257-1.374,0.322c-0.58,0.128-1.61-0.194-1.739,0.386c0,0.194,0.322,0.387,0.129,0.516
		c-0.193,0.129-0.709-0.387-0.774-0.129c-0.064,0.645,0.386,1.546,0.966,1.675c0.13,0,3.05-0.386,3.115-0.322
		c0.257,0.322-0.859,0.386-1.246,0.515c-0.773,0.322-0.515-0.515-1.095,0c-0.065,0.065,0.129,0.129,0.129,0.194
		c0,0.128-0.129,0.193-0.258,0.193c-0.838,0.258-1.03,0.322-0.966,0.322c1.869,0.194,3.371-0.129,3.436,0
		c0.128,0.065,0.386,0.065,0.322,0.194c-0.258,0.58-2.728,1.288-2.47,1.674c0.129,0.129,1.696-0.064,2.083-0.193
		c1.032-0.193-0.967,0.838,0.322,0.709c0.452-0.064,0.838-0.451,1.354-0.516c0.387-0.064,1.546,0.709,2.19,0.645
		c0.194-0.065,0.065-0.451,0.194-0.58c0.193-0.065,0.386,0.064,0.515,0.193c0.129,0.065,0.064,0.322,0.194,0.322
		c0.257-0.064,0.385-0.451,0.579-0.58c0.322-0.193,0.773-0.193,0.967-0.45c0.128-0.323-0.581-1.031-0.194-1.096
		c1.225-0.193,0.386,1.74,1.739,1.031c0.387-0.258,0.581-0.837,1.032-1.031c0.258-0.064,0.386,0.322,0.644,0.322
		c0,0,1.159-0.257,1.224-0.579c0-0.194-0.387-0.452-0.258-0.516c0.903-0.322,1.224,0.193,2.191,0.258
		c0.194,0.064,0.708-0.322,0.644-0.129c0,0.129-0.322,0.129-0.258,0.258c0.322,0.386,1.739-0.323,2.192-0.194
		c0.257,0.065,0.256,0.452,0.514,0.516c0.645,0.258,0.774-0.323,0.967,0.129c0,0.064,0.129,0.064,0.129,0
		c0.064-0.452-0.129-0.966,0-1.418c0.064-0.257,0.387-0.322,0.516-0.451c0.386-0.322,2.641-3.608,2.835-4.188
		c0.257-0.838,0-1.352-0.065-2.19c0-0.129,0.129-0.258,0.129-0.387c-0.064-0.322-0.58-0.579-0.387-0.837
		c0.129-0.258,0.644,0.322,0.837,0.064c0.129-0.258-0.386-0.515-0.321-0.773c0-0.387,0.515-0.58,0.515-0.902
		c0-0.451-0.451-0.73-0.515-1.181c0-0.516,0.321-1.031,0.257-1.547c0-0.257-0.708-0.386-0.515-0.58
		c0.387-0.258,1.159,0.065,1.417-0.322c0.151-0.151,0.016-0.311-0.15-0.494c0.104,0.089,0.215,0.151,0.343,0.151
		c1.161,0.193,0.837-0.838,1.289-1.289c0.516-0.58,2.32,0.775,2.578-1.74c0.193-2.578-0.966-1.739-2.063-1.482
		c0,0,1.676-0.965,1.418-1.674c-0.773-2.45-0.451,1.161-1.16-1.611c0-0.129,0.258-0.194,0.258-0.387
		c0.129-0.516,0-1.159-0.258-1.611c-0.064-0.193-0.45,0.065-0.644-0.064c-0.387-0.129-0.644-0.58-1.095-0.644
		c-0.387-0.065-0.709,0.258-1.16,0.258c-0.387,0.064-0.837-0.516-1.224-0.258c-0.387,0.193-0.516,0.837-0.902,1.031
		c-0.224,0.111-0.442-0.032-0.623-0.237c0.037-0.002,0.067,0,0.107,0c-0.258-0.322-0.515-0.837-0.901-0.966
		c-0.258,0,0.064,0.579-0.065,0.708c-0.322,0.323-0.838,0.774-1.096,0.516c-0.257-0.451,1.354-1.095,0.903-1.417
		c-0.774-0.516-1.804-0.451-2.706-0.709C123.407,107.382,123.149,107.188,122.956,107.253 M201.559,111.676
		c-0.023,0.019-0.005,0.312,0.043,0.602v-0.516C201.587,111.698,201.568,111.671,201.559,111.676 M140.459,117.411
		c-0.323,0.064-0.644,0.064-0.966,0.258c-0.258,0.193-0.322,0.644-0.58,0.902C136.141,121.085,140.589,120.441,140.459,117.411
		 M219.579,120.01c-0.195,0.064-0.58-0.065-0.709,0.193c-0.309,0.494,0.432,0.936,0.837,1.482c-0.774-0.645-2.125-0.838-3.028-1.611
		c-0.644,1.161-1.353,1.289-1.16,1.417c0.258,0.258,0.709,0.387,1.031,0.581c0.452,0.257,0.708,0.923,1.224,0.987
		c0.387,0.065,0.645-0.537,0.967-0.601c0.193-0.065,0.643,0,1.03,0v0.064l0.065-0.064c0.064-0.194,0.193-0.322,0.386-0.451
		c0.065-0.129,0.323-0.322,0.516-0.451c0-0.065,0.064-0.129,0-0.258v-0.129h0.322c0.065-0.064,0.065-0.129,0.065-0.193
		C220.996,120.396,220.03,120.461,219.579,120.01 M227.138,122.307c-0.012-0.007-0.053-0.002-0.086,0.022
		c-0.257,0.323-0.837,0.665-0.773,1.053c0.016,0.035,0.041,0.057,0.064,0.086c-0.249-0.102-0.46-0.125-0.515,0.042
		c-0.194,0.581,0.064,1.289-0.065,1.869c0.452,0.322,0.903,1.095,1.418,0.902c1.032-0.258,1.89-0.902,1.761-1.031
		c-0.193-0.451-0.795-0.515-0.924-0.967c-0.129-0.386,0.795-0.901,0.408-1.03c-0.386-0.129-0.858,0.064-1.309-0.065
		C226.891,123.132,227.228,122.364,227.138,122.307 M103.048,154.716v0.043V154.716z M473.644,156.799
		c-0.195,0.098,0.218,1.366-0.064,1.761c-0.194,0.258-0.579-0.516-0.902-0.451c-0.193,0.064,0.129,0.515,0,0.773
		c-0.257,0.322-0.644,0.58-1.095,0.709c-0.065,0-0.708-1.096-2.126-1.096c-0.194,0,0.322,0.258,0.386,0.452
		c0.258,0.644,0.644,1.674,0.837,2.19c0.517,1.289-0.193,2.405,0.838,3.758c0.194,0.258,0.645,0.129,0.838,0.387
		c0.258,0.258,0.065,0.773,0.322,1.031c0.323,0.193,0.837,0,1.16,0.129c-0.129-0.129-0.194-0.258-0.129-0.516
		c0-0.064,0.064-0.129,0.129-0.129v-8.912c0,0,0-0.065-0.065-0.065C473.716,156.78,473.673,156.784,473.644,156.799
		 M206.821,221.551c-0.258,0.516-0.773,0.901-0.837,1.418c-0.064,0.45,0.387,1.287,0,1.287c-0.451,0-0.773-0.516-1.16-0.45
		c-0.322,0.063-0.322,0.644-0.645,0.838c-0.257,0.129-0.579-0.129-0.837,0c-0.322,0.129-0.386,0.58-0.709,0.644
		c-0.128,0.065-0.257-0.386-0.322-0.257c-0.258,0.386-0.193,0.965-0.322,1.417c-0.129,0.193-0.838,0-0.774,0.257
		c0.195,0.388,0.838,0.58,0.838,0.967c0,0.451-0.838,0.579-0.773,0.967c0.129,0.517,1.096,0.387,1.16,0.901
		c0.065,0.451-0.967,0.709-0.838,1.226c0.065,0.387,1.096-0.388,1.16,0c0.065,0.516-0.966,0.771-0.966,1.288
		c-0.065,0.45,0.709,0.58,0.902,0.967c0.129,0.258-0.258,0.514-0.129,0.709c0.58,0.967,1.095,0.321,1.933,0.579
		c0.193,0.063-0.065,1.933,0.838,1.03c0.322-0.387,0.128-0.966,0.322-1.418c0.129-0.192,0.515-0.191,0.515-0.449
		c0.065-0.129-0.386,0.063-0.386-0.129c0-0.13,0.322,0,0.386-0.13c0.709-0.772,0.386-1.932,0.386-2.77
		c0-0.774,1.096-1.611,1.031-2.706c0-0.387,0-1.804-0.193-2.255c-0.027-0.07-0.07-0.129-0.107-0.194
		c-0.12-0.032-0.473-0.439-0.473-0.644C206.821,223.935,207.724,222.195,206.821,221.551 M207.294,225.287
		c0.031,0.008,0.056-0.01,0.043-0.063c-0.129-0.259-0.516-0.968-0.452-0.709C206.987,224.819,207.158,225.048,207.294,225.287
		 M205.533,236.306c-0.58,0.709-4.123,3.606-5.09,3.543c-1.16,0-1.611-1.354-1.676-1.288c-0.193,0.192,0.129,0.45,0.065,0.708
		c-0.065,0.451-0.515,0.772-0.579,1.224c-0.194,3.225,0.321,0.451,1.094,1.288c0.387,0.453,1.354,3.63,1.096,4.275
		c-0.129,0.258-0.516,0.192-0.645,0.387c-0.064,0.063,0.194,0.063,0.194,0.129c0,0.322-0.322,0.708-0.129,1.03
		c0.193,0.258,0.902-0.065,0.967,0.258c0.129,0.386-0.58,0.58-0.645,0.966c-0.129,0.452,0.129,0.837,0.065,1.289
		c-0.194,1.095-1.096,1.932-0.709,3.221c0.129,0.388,0.773,0.646,0.773,1.097c0.066-0.019,0.128-0.026,0.193,0
		c1.225,0.516-0.324,2.898,2.191,1.738c1.805-0.838,0.772-0.578,1.159-2.061c0.065-0.323,0.387-0.452,0.451-0.709
		c0-0.194-0.644-0.452-0.451-0.452c1.097-0.063,2.255,0.838,3.157,1.161c0.129,0.063,0.065,0.386,0.129,0.257
		c1.225-2.127,0.967-5.088,1.16-7.216c0-0.323,0.323-0.579,0.323-0.837c-0.129-0.709-1.096-0.709-0.774-1.547
		c0.322-0.902,1.482-1.288,1.417-2.384c-0.129-3.095-0.708,0.173-1.095-2.147c0-0.194,0.452-0.258,0.323-0.387
		c-0.323-0.388-1.225-0.257-1.354-0.709c-0.129-0.451,0.967-0.321,1.031-0.708C208.561,237.013,205.726,237.207,205.533,236.306
		 M200.314,254.432c-0.173,0.045-0.347,0.197-0.537,0.301C200.01,254.676,200.282,254.593,200.314,254.432 M170.483,244.595
		c-0.954,0.009-1.415,0.453-0.408,1.139c0.387,0.257,0.902-0.065,1.289,0.063c0.902,0.451,2.643,3.03,1.159-0.709
		C171.798,244.75,171.055,244.589,170.483,244.595 M165.758,245.024c-1.998,0.386-3.994,1.223-5.605,2.448
		c-0.064,0.063-0.193,0.192-0.129,0.258c0.387,0.258,0.838,0.451,1.289,0.515c0.258,0.065,0.644-0.386,0.902-0.129
		c0.322,0.258,0.193,0.837,0.322,1.29c0.322,0.966,0.386-0.066,1.353,0.514c0.323,0.194,0.257,0.902,0.579,0.902
		c0.258,0.065,3.544-3.092,3.093-3.479C165.951,245.989,164.792,248.313,165.758,245.024 M298.612,246.636
		c-0.387,0.192-0.773,0.386-1.16,0.644c-0.258,0.193-0.451,0.386-0.709,0.644c-0.194,0.193-0.45,0.388-0.644,0.58
		c-0.129,0.13-0.451,0.58-0.258,0.517c0.323-0.194,0.515-0.646,0.902-0.774c0.709-0.129,4.51,1.675,4.704,2.255
		c0.129,0.194-0.258,0.451-0.129,0.646c0.838,0.838,1.932-0.387,2.706-0.129c0.516,0.192,0.966,0.515,1.288,0.901
		c0.129,0.129-0.258,0.321-0.258,0.45c0.065,0.129,0.194-0.191,0.323-0.191c0.193,0.129,0.386,0.32,0.451,0.515
		c0,0.129-0.258,0.193-0.194,0.322c0.387,0.45,2.148,1.739,2.792,0.709c1.161-1.935-1.76-0.967-2.469-1.483
		c-0.645-0.451-0.516-2.383-0.516-2.448c-0.193-0.321-0.644-0.32-0.902-0.579c-0.065-0.063,0-0.192,0.064-0.258
		c0-0.063,0.129-0.129,0.065-0.192c-0.258-0.451-1.611,0.321-1.997,0.258c-0.387-0.129-0.645-0.517-0.967-0.646
		c-0.129-0.063-0.193,0.194-0.322,0.194c-0.258,0-0.515-0.065-0.708-0.194C299.578,247.794,299.708,247.215,298.612,246.636
		 M160.089,249.148l-0.581,0.063c-0.515,0.13-0.643,0.45-0.901,0.773c-0.258,0.386-0.644,0.966-0.064,1.224
		c0.322,0.194,1.288-0.321,1.481-0.709c0.129-0.321,0.516-0.386,0.516-0.772C160.54,249.405,160.089,249.148,160.089,249.148
		 M161.055,251.146c-0.386,0.386-0.966,0.386-0.966,0.709c0,0.258,0.257,0.771,0.58,0.771c0.386,0,0.643,0.001,1.159-0.449
		c0.515-0.452,0-0.773,0-0.773l-0.708-0.258H161.055z M281.28,254.303c-1.418,1.868-1.096,3.736-0.58,2.642
		c0.129-0.258-0.193-0.709,0-0.838c0.387-0.323,0.193,1.16,0.644,1.289c0.258,0.063,0.516-0.258,0.774-0.258
		c0.451,0.063,0.643,0.709,1.159,0.258c0.71-0.581-0.966-1.289-1.288-1.354c-0.322-0.063-0.129-0.644-0.129-0.709
		C281.731,254.946,281.473,254.624,281.28,254.303 M199.734,254.732c-0.211,0.047-0.378,0.086-0.258,0.086
		C199.567,254.818,199.649,254.773,199.734,254.732 M378.675,254.946c-0.064-0.065-5.024,4.446-5.927,4.961
		c-0.709,0.387-2.576,0.837-3.35,0.902c-0.387,0.063-0.902-0.322-1.096-0.065c-0.257,0.323,0.323,0.709,0.387,1.032
		c0.065,0.386,0.129,0.901-0.193,1.094c-0.451,0.389-1.675-0.129-2.126,0.58c-0.129,0.194-0.258,1.354-0.838,1.418
		c-0.322,0-0.515-0.322-0.837-0.322c-0.581-0.063,1.095,1.739,1.288,1.934c0.129,0.192,0.065,0.515,0.258,0.644
		c0.064,0,2.19,0.323,2.384,0.258c0.386-0.129,0.644-0.644,1.031-0.644c0.193,0,0.708,1.482,1.224,0.515
		c0.193-0.321-0.194-0.772,0-1.03c0.515-0.709,2.77-1.354,3.286-2.255c0.193-0.322-0.129-0.901,0.129-1.225
		c0.322-0.323,0.965-0.063,1.352-0.322c0.258-0.129,0.322-0.515,0.58-0.58c0.258-0.129,0.774,0.193,0.967-0.063
		c0.322-0.516-2.578-0.708-1.997-2.126C375.713,258.489,378.611,255.979,378.675,254.946 M247.455,259.864
		c-0.49,0.003-0.698,0.623-1.568,0.816c-0.193,0.063-0.193-0.709-0.386-0.516c-0.516,0.517,0.386,1.225-0.903,1.288
		c-1.869,0.129-0.45-1.289-2.383-0.257c-0.838,0.451-0.773,1.095-2.384,1.418c-0.774,0.193-1.868-0.194-2.384-0.129
		c0,0-1.611,0.772-1.676,0.772c-0.451,0-0.966-0.258-1.352-0.516c-0.129-0.063-0.065-0.451-0.258-0.515
		c-0.322-0.129-0.709,0.192-0.967,0.063c-0.387-0.192,0.194-0.967-0.515-0.967c-2.772-0.128-0.579,1.482-2.77,1.804
		c-0.581,0.065-0.774-1.223-0.902-1.417c-0.258-0.321-0.581,0.646-0.903,0.903c-0.258,0.257-0.665,0.321-0.858,0.579
		c-0.194,0.257-0.323,0.58-0.323,0.838c0,0.193,0.258,0.257,0.194,0.451c0,0.193-0.387,0.322-0.387,0.58
		c-0.064,0.258,1.503,1.932,1.632,1.997c0.58,0.258,1.224-0.322,1.805-0.194c1.418,0.387,2.318,1.611,3.607,2.063
		c0.322,0.129,0.645-0.064,0.902,0.129c0.323,0.129,0.451,0.58,0.709,0.772c0.194,0.129,0.386,0.194,0.58,0.258
		c1.29,0.517,2.319-0.193,3.479,0.517c0.838,0.515,1.03,1.932,1.804,2.255c1.805,0.838,1.223,0.386,2.577,0.257
		c1.225-0.063,1.418,0.773,1.547,0.58c0.193-0.323-0.065-0.837,0-1.224c0.064-0.322,0.129-0.709,0.322-1.031
		c0.193-0.323,0.515-0.515,0.837-0.709c0.129-0.129,0.387-0.064,0.322-0.193c-0.193-0.516-0.966-0.772-1.095-1.289
		c-0.064-0.192,0.065-0.321,0.065-0.451c0.193-0.45,0.128,0.193,0.45,0c0.387-0.258-0.901-0.257-1.159-0.644
		c-0.645-0.903,2.126-7.602,2.964-8.053C247.82,259.923,247.618,259.864,247.455,259.864 M323.889,273.566
		c-1.482,2.128-1.288,0.386-2.641,1.74c-0.258,0.192-0.258,0.9-0.58,0.771c-1.547-0.451-0.452-1.803-0.58-1.868
		c-0.581-0.129-1.74,0.838-2.062,0.903c-0.193,0.064-0.322-0.323-0.516-0.323c-0.773-0.063-1.481,0.517-2.19,0.323
		c-0.387-0.065,0.001-0.581-0.451-0.452c-0.709,0.129-1.417,0.065-2.126,0.258c-0.58,0.194-1.03,0.709-1.675,0.903
		c-0.322,0.128-0.58,0.385-0.902,0.321c-0.387-0.065-0.644-0.516-0.966-0.645c-0.323-0.129-0.902,0.065-0.967-0.258
		c-0.129-0.322,0.967-0.58,0.645-0.837c-0.258-0.258-0.452,0.58-0.774,0.709c-0.451,0.193-0.987,0.451-1.438,0.257
		c-0.452-0.192-1.096-2.449-1.805-0.451c-0.193,0.451,0,1.03,0,1.546c-0.064,0.388-0.451,1.611,0.516,1.611
		c0.451-0.063,0.965-0.322,1.417-0.451c0.452-0.063,0.923-0.129,1.31-0.063c0.194,0,0.258,0.322,0.451,0.322
		c3.159,0-1.484-0.902,2.255-0.451c3.675,0.451,1.611,0.32,2.771,1.609c0.386,0.322,3.994-1.352,4.381-1.48
		c0.515-0.194,5.734-1.354,5.798-1.547C324.469,274.017,323.18,274.92,323.889,273.566 M239.38,279.107c0,0,0.644,0.063,0.966,0.129
		C240.024,279.171,239.38,279.107,239.38,279.107 M240.861,280.719c0,0,0.58,0.258,0.838,0.387
		C241.441,280.977,240.861,280.719,240.861,280.719" />
		</g>
		<g>
			<defs>
				<rect id="SVGID_3_" width="1193.375" height="346.582" />
			</defs>
			<clipPath id="SVGID_4_">
				<use xlink:href="#SVGID_3_" overflow="visible" />
			</clipPath>
			<path clip-path="url(#SVGID_4_)" fill="#A0CF69" d="M75.101,302.433c8.604,1.605,19.7,2.556,33.857,2.021
		c-44.48-25.756-74.469-73.874-74.469-128.866c0-82.048,66.75-148.798,148.798-148.798c82.047,0,148.797,66.75,148.797,148.798
		c0,25.24-6.325,49.028-17.461,69.875c10.213-4.535,18.69-8.495,24.898-11.467c6.822-18.187,10.563-37.868,10.563-58.408
		c0-91.973-74.825-166.798-166.797-166.798c-91.973,0-166.798,74.825-166.798,166.798C16.488,226.313,39.254,271.815,75.101,302.433
		" />
			<path clip-path="url(#SVGID_4_)" fill="#A0CF69" d="M367.13,257.442c2.859,2.212,4.851,4.547,6.096,6.352
		c1.244,1.807,1.731,3.084,1.731,3.084c-1.878,0.999-3.696,1.938-5.771,3c-2.048,1.031-4.255,2.144-6.613,3.33
		c-4.713,2.342-10.03,4.923-15.891,7.682c-11.723,5.516-25.621,11.743-41.169,18.145c-31.097,12.757-68.782,26.416-108.922,35.327
		c-20.065,4.418-40.757,7.633-61.465,8.376c-10.349,0.363-20.701,0.092-30.902-1.054c-10.196-1.147-20.253-3.183-29.834-6.349
		c-9.573-3.151-18.658-7.478-26.658-12.855c-8.012-5.356-14.865-11.745-20.308-18.366c-0.679-0.83-1.413-1.61-2.019-2.466
		c-0.617-0.849-1.228-1.689-1.834-2.521c-1.206-1.668-2.412-3.293-3.39-4.986c-1.021-1.674-2.037-3.307-2.976-4.924
		c-0.847-1.65-1.675-3.266-2.481-4.84c-0.391-0.791-0.817-1.562-1.159-2.343c-0.334-0.784-0.663-1.557-0.987-2.32
		c-0.642-1.527-1.266-3.014-1.871-4.458c-1.058-2.909-1.962-5.662-2.832-8.222c0,0,1.086,1.558,3.065,4.396
		c0.49,0.696,1.034,1.474,1.63,2.322c0.611,0.844,1.355,1.738,2.106,2.698c1.534,1.915,3.177,4.092,5.317,6.334
		c1.039,1.13,2.101,2.31,3.236,3.505c1.205,1.143,2.459,2.331,3.76,3.565c1.246,1.237,2.7,2.396,4.164,3.581
		c1.465,1.181,2.908,2.412,4.574,3.5c6.362,4.586,13.875,8.59,21.99,11.34c8.115,2.781,16.768,4.354,25.493,4.969
		c8.733,0.623,17.558,0.325,26.259-0.445c17.409-1.552,34.338-4.933,49.558-6.94c19.134-2.582,41.732-7.479,66.906-15.105
		c25.179-7.583,52.954-17.811,82.498-30.66c14.426-6.287,25.65-8.955,34.57-9.322c-3.37,1.631-6.752,3.243-10.148,4.83l7.111,15.188
		C349.091,266.512,358.139,262.109,367.13,257.442 M221.641,298.247c-2.619,0.758-5.236,1.516-7.865,2.218
		c-2.623,0.729-5.251,1.432-7.885,2.096l4.175,16.24c2.725-0.686,5.438-1.41,8.143-2.162c2.713-0.727,5.408-1.505,8.103-2.285
		L221.641,298.247z M243.052,291.602c-2.595,0.857-5.192,1.708-7.799,2.518c-2.601,0.834-5.209,1.635-7.822,2.415l4.838,16.057
		c2.687-0.803,5.369-1.626,8.038-2.48c2.676-0.833,5.34-1.704,8.001-2.584L243.052,291.602z M255.39,287.399
		c-2.192,0.783-4.395,1.529-6.596,2.279l8.352,24.569c2.286-0.776,4.571-1.552,6.841-2.363L255.39,287.399z M264.278,284.214
		c-2.189,0.792-4.373,1.605-6.571,2.368l8.68,24.457c2.278-0.792,4.536-1.631,6.801-2.451L264.278,284.214z M285.31,276.186
		c-5.1,2.017-10.208,4.013-15.344,5.923l5.883,15.705c5.246-1.953,10.459-3.988,15.662-6.047L285.31,276.186z M306.137,267.572
		c-5.044,2.168-10.111,4.278-15.193,6.344l6.314,15.536c5.182-2.106,10.346-4.258,15.485-6.466L306.137,267.572z M326.748,258.43
		c-4.991,2.284-9.998,4.539-15.032,6.729l6.711,15.369c5.129-2.232,10.229-4.528,15.314-6.856L326.748,258.43z" />
		</g>
		<path display="none" d="M448.662,88.121h-32.249V79.28h74.695v8.841h-32.344v88.889h-10.102V88.121z M519.976,145.241
	l-11.562,31.769h-10.035l35.396-97.729h12.103l36.914,97.729H572.04l-11.864-31.769H519.976z M557.296,136.4
	c-10.179-27.526-15.554-41.346-17.582-48.308h-0.14c-2.38,7.802-8.411,24.758-16.625,48.308H557.296z M525.618,60.666
	c0-7.297,5.337-13.62,14.091-13.62c8.731,0,14.164,6.02,14.164,13.362c0,7.6-5.757,13.502-14.046,13.502
	C531.118,73.91,525.618,67.985,525.618,60.666z M547.324,60.548c0-3.785-2.43-7.901-7.52-7.901c-5.113,0-7.498,3.88-7.498,7.644
	c0,3.975,2.268,7.996,7.476,7.996C544.754,68.286,547.324,64.451,547.324,60.548z M680.804,177.01
	c-0.604-1.456-0.93-6.182-1.092-14.557c-3.837,6.81-11.852,15.767-31.012,15.767c-28.117,0-42.762-21.375-42.762-49.167
	c0-29.215,15.67-51.173,44.684-51.173c21.813,0,35.926,11.599,38.871,29.367h-9.984c-3.796-12.513-12.626-20.661-29.167-20.661
	c-23.646,0-33.836,18.591-33.836,41.956c0,21.345,9.994,40.926,33.059,40.926c23.287,0,29.496-15.309,29.496-34.629v-1.075h-29.731
	v-8.841h39.414v52.086H680.804z M727.396,79.28h9.984v88.889h51.81l-1.579,8.84h-60.215V79.28z M824.422,79.28v59.023
	c0,24.67,13.757,31.166,26.988,31.166c15.572,0,26.898-6.792,26.898-31.166V79.28h10.103v58.491
	c0,31.086-16.801,40.404-37.28,40.404c-19.77,0-36.929-10.186-36.929-39.301V79.28H824.422z M928.844,79.28h60.819v8.841h-50.924
	v34.535h47.749v8.841h-47.749v45.513h-9.896V79.28z M1020.683,79.28h60.819v8.841h-50.924v34.535h47.749v8.841h-47.749v45.513
	h-9.896V79.28z M1116.501,145.241l-11.562,31.769h-10.035l35.396-97.729h12.103l36.914,97.729h-10.752l-11.864-31.769H1116.501z
	 M1153.822,136.4c-10.18-27.526-15.555-41.346-17.582-48.308h-0.141c-2.38,7.802-8.41,24.758-16.624,48.308H1153.822z" />
		<g display="none">
			<defs>
				<rect id="SVGID_5_" width="1193.375" height="346.582" />
			</defs>
			<clipPath id="SVGID_6_" display="inline">
				<use xlink:href="#SVGID_5_" overflow="visible" />
			</clipPath>
			<path display="inline" clip-path="url(#SVGID_6_)" d="M450.665,221.213v50.266h-5.136v-50.266H450.665z M635.003,247.32h-25.348
		v19.611h27.796l-0.703,4.547h-32.171v-50.266h31.676v4.547h-26.598v17.014h25.348V247.32z M712.509,221.213v30.357
		c0,12.688,7.075,16.029,13.881,16.029c8.009,0,13.835-3.493,13.835-16.029v-30.357h5.195v30.084
		c0,15.988-8.641,20.781-19.174,20.781c-10.168,0-18.994-5.239-18.994-20.215v-30.65H712.509z M825.008,248.603v22.876h-5.149
		v-50.266h18.755c10.098,0,15.459,5.443,15.459,13.318c0,6.455-3.782,10.527-9.029,11.798c4.688,1.166,8.19,4.4,8.19,13.026v2.037
		c0,3.493-0.23,8.072,0.66,10.086h-5.093c-0.924-2.16-0.777-6.135-0.777-10.628v-1.192c0-7.762-2.252-11.056-11.601-11.056H825.008z
		 M836.6,244.102c8.313,0,12.083-3.116,12.083-9.291c0-5.8-3.778-9.097-11.153-9.097h-12.521v18.388H836.6z M946.188,272.198
		c-14.439,0-22.697-11.426-22.697-25.853c0-14.292,8.178-25.854,23.236-25.854c13.952,0,22.636,10.746,22.636,25.528
		C969.362,259.785,961.845,272.198,946.188,272.198z M928.926,246.13c0,11.246,6.016,21.533,17.513,21.533
		c12.397,0,17.489-10.172,17.489-21.56c0-11.31-5.766-21.075-17.403-21.075C934.291,225.028,928.926,235.064,928.926,246.13z
		 M1040.963,221.213h18.464c9.459,0,15.946,5.263,15.946,14.283c0,9.385-6.611,14.645-16.404,14.645h-12.81v21.338h-5.196V221.213z
		 M1046.159,245.64h12.858c7.559,0,10.967-3.882,10.967-9.987c0-6.469-4.326-9.938-10.69-9.938h-13.135V245.64z M1147.05,255.139
		l-5.946,16.34h-5.161l18.205-50.266h6.225l18.986,50.266h-5.53l-6.103-16.34H1147.05z M1166.245,250.591
		c-5.235-14.157-8-21.266-9.043-24.846h-0.072c-1.224,4.013-4.325,12.733-8.55,24.846H1166.245z" />
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
			<path d="M452.953,84.56h-35.98v-4.34h76.58v4.34h-35.98v93.66h-4.62V84.56z" />
			<path d="M567.194,149.52h-58.66l-13.16,28.7h-5.18l45.36-98h4.62l45.36,98h-5.18L567.194,149.52z M565.374,145.46l-27.44-59.78
		l-27.58,59.78H565.374z M528.274,65.52c-2.52-2.52-3.78-5.6-3.78-9.24s1.26-6.766,3.78-9.38c2.52-2.612,5.692-3.92,9.52-3.92
		c3.826,0,7.022,1.309,9.59,3.92c2.566,2.614,3.85,5.74,3.85,9.38s-1.284,6.72-3.85,9.24c-2.568,2.52-5.764,3.78-9.59,3.78
		C533.966,69.3,530.794,68.04,528.274,65.52z M545.004,63.35c1.912-1.912,2.87-4.27,2.87-7.07c0-2.8-0.98-5.18-2.94-7.14
		c-1.96-1.96-4.34-2.94-7.14-2.94c-2.8,0-5.158,0.98-7.07,2.94c-1.914,1.96-2.87,4.34-2.87,7.14c0,2.8,0.932,5.158,2.8,7.07
		c1.866,1.914,4.246,2.87,7.14,2.87C540.686,66.22,543.09,65.264,545.004,63.35z" />
			<path d="M674.015,129.92h4.479v36.26c-4.294,4.108-9.403,7.234-15.33,9.38c-5.928,2.148-12.25,3.22-18.97,3.22
		c-9.614,0-18.294-2.146-26.04-6.439c-7.748-4.292-13.838-10.22-18.27-17.78c-4.435-7.56-6.65-16.006-6.65-25.34
		c0-9.332,2.216-17.78,6.65-25.34c4.432-7.56,10.521-13.486,18.27-17.78c7.746-4.292,16.426-6.44,26.04-6.44
		c7,0,13.44,1.074,19.32,3.22c5.88,2.148,10.92,5.368,15.12,9.66l-2.801,3.22c-8.119-7.84-18.619-11.76-31.5-11.76
		c-8.773,0-16.684,1.984-23.729,5.95c-7.048,3.968-12.578,9.404-16.591,16.31c-4.014,6.908-6.02,14.56-6.02,22.96
		s2.006,16.054,6.02,22.96c4.013,6.908,9.543,12.344,16.591,16.31c7.046,3.968,14.956,5.95,23.729,5.95
		c12.227,0,22.12-3.406,29.681-10.22V129.92z" />
			<path d="M711.532,80.22h4.76v93.66h57.4v4.34h-62.16V80.22z" />
			<path
				d="M800.362,168.14c-6.769-7.092-10.15-17.5-10.15-31.22v-56.7h4.76v56.56c0,12.508,2.892,21.91,8.68,28.21
		c5.786,6.3,14.141,9.45,25.061,9.45c10.826,0,19.156-3.15,24.99-9.45c5.832-6.3,8.75-15.702,8.75-28.21V80.22h4.62v56.7
		c0,13.72-3.36,24.128-10.08,31.22c-6.721,7.095-16.148,10.64-28.28,10.64C816.578,178.779,807.128,175.234,800.362,168.14z" />
			<path d="M906.831,84.56v44.8h53.2v4.2h-53.2v44.66h-4.76v-98h64.26v4.34H906.831z" />
			<path d="M995.029,84.56v44.8h53.2v4.2h-53.2v44.66h-4.76v-98h64.26v4.34H995.029z" />
			<path d="M1133.77,149.52h-58.66l-13.16,28.7h-5.18l45.359-98h4.62l45.36,98h-5.181L1133.77,149.52z M1131.949,145.46l-27.44-59.78
		l-27.58,59.78H1131.949z" />
		</g>
		<g>
			<path d="M452.653,216.133h2.72v56h-2.72V216.133z" />
			<path d="M621.932,269.652v2.48h-37.84v-56h36.72v2.48h-34v23.84h30.399v2.479h-30.399v24.72H621.932z" />
			<path d="M686.852,266.373c-3.867-4.053-5.8-10-5.8-17.84v-32.4h2.721v32.32c0,7.146,1.652,12.52,4.96,16.119
		c3.306,3.601,8.079,5.4,14.319,5.4c6.187,0,10.946-1.8,14.28-5.4c3.332-3.6,5-8.973,5-16.119v-32.32h2.64v32.4
		c0,7.84-1.92,13.787-5.76,17.84c-3.84,4.054-9.228,6.08-16.16,6.08C696.118,272.453,690.718,270.427,686.852,266.373z" />
			<path
				d="M828.331,272.133l-13.84-19.279c-1.813,0.319-3.84,0.479-6.08,0.479h-16.72v18.8h-2.72v-56h19.439
		c6.986,0,12.44,1.641,16.36,4.92c3.92,3.28,5.88,7.828,5.88,13.641c0,4.479-1.16,8.227-3.479,11.239
		c-2.32,3.015-5.641,5.108-9.96,6.28l14.319,19.92H828.331z M808.571,250.854c6.24,0,11.04-1.4,14.4-4.201
		c3.359-2.799,5.04-6.785,5.04-11.959c0-5.121-1.681-9.08-5.04-11.881c-3.36-2.8-8.16-4.199-14.4-4.199h-16.88v32.24H808.571z" />
			<path d="M898.851,268.773c-4.399-2.453-7.854-5.841-10.359-10.16c-2.508-4.32-3.761-9.146-3.761-14.48
		c0-5.332,1.253-10.16,3.761-14.48c2.506-4.319,5.96-7.705,10.359-10.16c4.4-2.451,9.32-3.68,14.761-3.68
		c5.439,0,10.372,1.229,14.8,3.68c4.426,2.455,7.893,5.841,10.399,10.16c2.507,4.32,3.761,9.148,3.761,14.48
		c0,5.334-1.254,10.16-3.761,14.48c-2.507,4.319-5.974,7.707-10.399,10.16c-4.428,2.453-9.36,3.68-14.8,3.68
		C908.171,272.453,903.251,271.227,898.851,268.773z M926.971,266.613c4-2.24,7.146-5.333,9.44-9.28
		c2.292-3.946,3.439-8.347,3.439-13.2c0-4.853-1.147-9.252-3.439-13.2c-2.294-3.946-5.44-7.04-9.44-9.28s-8.453-3.359-13.359-3.359
		c-4.908,0-9.348,1.119-13.32,3.359c-3.974,2.24-7.107,5.334-9.4,9.28c-2.294,3.948-3.439,8.348-3.439,13.2
		c0,4.854,1.146,9.254,3.439,13.2c2.293,3.947,5.427,7.04,9.4,9.28c3.973,2.24,8.412,3.359,13.32,3.359
		C918.518,269.973,922.971,268.854,926.971,266.613z" />
			<path d="M1037.17,221.053c3.92,3.28,5.88,7.828,5.88,13.641c0,5.867-1.96,10.439-5.88,13.719c-3.92,3.281-9.374,4.921-16.36,4.921
		h-16.72v18.8h-2.72v-56h19.439C1027.796,216.133,1033.25,217.773,1037.17,221.053z M1035.37,246.652
		c3.359-2.799,5.04-6.785,5.04-11.959c0-5.121-1.681-9.08-5.04-11.881c-3.36-2.8-8.16-4.199-14.4-4.199h-16.88v32.24h16.88
		C1027.21,250.854,1032.01,249.453,1035.37,246.652z" />
			<path d="M1133.049,255.732h-33.52l-7.521,16.4h-2.96l25.921-56h2.64l25.92,56h-2.96L1133.049,255.732z M1132.009,253.412
		l-15.68-34.159l-15.76,34.159H1132.009z" />
		</g>
	</svg>
</template>
