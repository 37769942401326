<template>
  <div class="summary">
    <div class="ticket-name">
      {{ title }}
    </div>
    <div class="ticket-details">{{ ageGroup }}, {{ comfort }}</div>
    <div class="ticket-price">{{ price }} kr</div>
  </div>
</template>

<script lang="ts" setup>
import { useBookingStore } from "@/store/bookingStore";
import { defineProps } from "vue";

const bookingStore = useBookingStore();

defineProps({
  title: {
    type: String,
    default: "",
  },
  ticketId: {
    type: String,
    default: "",
  },
  ageGroup: {
    type: String,
    default: "",
  },
  comfort: {
    type: String,
    default: "",
  },
  price: {
    type: String,
    default: "",
  },
});
</script>

<style scoped lang="scss">
.summary {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  border-bottom: 1px solid lightgray;
  margin-bottom: 1rem;
  margin-top: 1rem;

  .ticket-name {
    font-size: 1rem;
  }
  .ticket-details {
    font-size: 1rem;
    color: gray;
  }

  .ticket-price {
    align-self: flex-end;
    margin-bottom: 1rem;
  }
}
</style>
