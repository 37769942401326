<template>
  <label class="switch">
    <input type="checkbox" v-model="state.checked" @click="handle_click" />
    <span class="slider round"></span>
  </label>
</template>

<script lang="ts" setup>
import { defineProps, reactive, defineEmits } from "vue";

const emits = defineEmits(["switched"]);

const state = reactive({
  checked: false,
});

function handle_click() {
  emits("switched", state.checked);
}
</script>

<style lang="scss">
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ff8a65;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ff8a65;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
