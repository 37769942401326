<template>
  <img
    :class="['image', fit ? 'cssFit' : '', auto ? 'cssAuto' : '']"
    :style="`height: ${height};`"
    :src="getImgUrl(picturePath)"
  />
</template>

<script lang="ts" setup>
import { defineProps } from "vue";

defineProps({
  picturePath: {
    type: String,
    default: () => "",
  },
  height: {
    type: String,
    default: () => "100%",
  },
  fit: {
    type: Boolean,
    default: true,
  },
  auto: {
    type: Boolean,
    default: false,
  },
});

function getImgUrl(pic: string) {
  return require("@/assets/" + pic);
}
</script>

<style scoped>
.image {
  width: 100%;
}

.cssAuto {
  width: auto;
  height: auto;
}

.cssFit {
  object-fit: cover;
}
</style>
