<template>
  <div class="title">
    <div><slot></slot></div>
    <div class="divider"></div>
  </div>
</template>

<script lang="ts" setup></script>

<style scoped>
.title {
  width: 100%;
}
.divider {
  margin-top: 0.5rem;
  border-bottom: 2px solid grey;
}
</style>
