<template>
	<teleport to="head">
		<component :is="'script'" type="application/ld+json">
			{{ meta.interrailCards }}
		</component>
	</teleport>
	<PageTitle
		text="Interrailkort"
		backgroundImageClass="background-platform"
	></PageTitle>
	<ContentWrapper>
		<TextBlock
			header-tag="h2"
			header="Om du har bestämt dig för att du vill ta dig fram i Europa med hjälp av tåg är det bästa alternativet att inhandla interrailkort. Interrail erbjuder många varianter, men i korta drag innebär det att du köper ett kort som är laddat med ett visst antal resdagar. Under dessa resedagar kan du hoppa på vilket tåg som helst, men om du vill vara säker på att få sitt-, eller sovplats måste du boka detta i förväg. Göra en, så kallad, platsreservation. Då tillkommer en liten extra avgift, men denna är liten i förhållande till tågresans fulla pris."
		>
		</TextBlock>

		<TextBlock>
			<h2>När behöver jag boka plats?</h2>
			Det finns vissa tåg som ofta är fullbokade, har få platser avsatta för
			interrail, eller rent av kräver platsbokning. Det gäller ofta för
			snabbtåg, nattåg eller tåg som färdas mellan flera länder. Det är därför
			viktigt att planera sin rutt innan man ger sig av och ge sig möjlighet att
			upptäcka biljetter som behöver bokas på förhand. Det bör också nämnas att
			privatägda tågbolag, som till exempel Thalys och Thello, inte är en del av
			interrail-samarbetet. Man kan därmed inte åka på dessa tåg med endast ett
			interrailkort, men det kan ge rabatt på biljettpriset däremot. De tåg som
			man å andra sidan bara kan hoppa på är just de statligt ägda tågbolagen
			och regionaltrafiken i varje land. Det vill säga SJ:s motsvarigheter i
			Europa.
			<br />
			<br />
			För de som vill åka tåg inom endast ett land finns specialanpassade
			interrailkort för detta. De kallas Interrail One Country Pass.
		</TextBlock>
		<CustomPic
			:picturePath="'pictures/boarding.jpg'"
			height="32rem"
		></CustomPic>
		<TextBlock>
			<h2>Hur genomför jag en platsreservation?</h2>
			Platsbokning på ett tåg under din resa erhåller du enklast och billigast
			genom railplanner-appen. När du planerar in resan i appen så dyker där upp
			information om att platsreservation kan behövas. Därtill erhålls länkar
			till rekommenderade sajter för platsbokning givet den planerade resan.
			Genomför din platsbokning där.
			<br />
			<br />I vissa fall går platsreservation inte att genomföra genom de sajter
			railplanner-appen rekommenderar. Vi vill göra dig varse om att det
			fortfarande kan finnas möjlighet att boka plats på tågoperatörens hemsida
			eller på tågstationen. Platsreservation genom tågoperatören kommer att
			resultera i den lägsta möjliga priset.
		</TextBlock>
		<TextBlock>
			För vissa tåg räcker inte att endast genomföra en platsreservation, utan
			krävs också ett ytterligare tillägg, "additional supplements". Det är en
			tilläggsavgift som du måste betala för att åka med det specifika tåget. I
			Railplanner-appen ser du om något av dina planerade tåg omfattas av en
			tilläggsavgift. Om så är fallet, kolla på
			<a
				href="https://www.eurail.com/en/plan-your-trip/about-reservations/trains-with-additional-supplements"
			>
				Eurails hemsida
			</a>
			hur du bäst betalar avgiften samt hur stor den är.
		</TextBlock>
		<TextBlock
			header="Prislista för Global Pass"
			content="Priset för global passes beror på typ av interrailkort, passangerarens ålder samt vald komfort. Nedan visas vår prislista för Global Pass:"
		>
		</TextBlock>
		<CustomTable :table-items="ticket_ref_object"></CustomTable>
		<TextBlock
			>Priser inom parentes är priser för en 1:a klass biljett.</TextBlock
		>
		<TextBlock>
			<h2>Interrailkort för barn</h2>
			Barn under 12 år åker gratis om de åker tillsammans med föräldrar. Varje
			vuxen har lov att färdas med två barn gratis. Skulle ett barn resa utan
			föräldrar, eller en förälders kvot om två barn redan fyllts så kommer
			barnet bli tvungen att skaffa ett ungdomskort.
			<br />
			<br />
			Tillhör du antingen åldersgruppen senior eller ungdom och planerar att
			resa med barn, så behöver du uppgradera din biljett till vuxen. Det är
			betydligt mer prisvärt än att uppgradera barnets biljett till ungdom.
			<br />
			<br />
			Barn upp till 4 års ålder behöver inte ha ett personligt interrailkort.
		</TextBlock>
		<TextBlock header="Ditt interrailkort gäller i 33 Europeiska länder:">
			<div class="country-list">
				<div
					v-for="(country, index) of countrylist"
					:key="`${country}-${index}-country`"
				>
					{{ country }}
				</div>
			</div>
		</TextBlock>
		<CustomPic
			:picturePath="'pictures/interrail-countries-tagluffaieuropa.png'"
		></CustomPic>
		<TextBlock
			><h2>Interrailkort gäller inte i ditt hemland</h2>
			Interrail Global Pass ger dig generellt 2 resor i ditt hemland. Sverige är
			ett långt land och därför ges svenska tågresenärer möjlighet till 4 resor
			i sitt hemland: 2 för att åka iväg och 2 för att återvända, med flera
			tågbyten inkluderade. Ditt hemland är det land där du är bosatt och
			exempelvis har permanent boende, arbetar, studerar och betalar skatt. Du
			kan resa under en resdag för att lämna ditt hemland (utresan) och en
			resdag för att återvända (inresan). Du kan använda dessa resor i ditt eget
			land när som helst under din Pass giltighetstid.
			<br />
			<br />
			Värt att observera är att utresa och inresa inte räknas som extra
			resdagar, men att de måste genomföras under ditt pass giltighetstid. Har
			du ett pass med <i>10 resdagar inom två månader</i>, så behöver in- och
			utresa ske inom de två månaderna. Har du, å andra sidan, ett pass med
			<i>15 resdagar i följd</i>, så behöver in- och utresa ske inom de 15
			dagarna.
			<br />
			<br />
			När du reser med ett mobilpass känner appen automatiskt igen en utgående
			eller ingående resa, baserat på ditt hemland. Om du lägger till en
			utgående eller ingående resa till ditt pass, kommer ett popup-fönster att
			meddela dig att du kommer att använda en av de två tillgängliga
			ut/inresor. Du måste godkänna detta innan du kan lägga till din resa i
			ditt pass.
			<br />
			<br />
			Tänk på att när du vill resa från ett land till ett annat och måste resa
			genom ditt hemland, kommer appen att förbruka både den in- och utgående
			resan på denna resa.</TextBlock
		>
	</ContentWrapper>
</template>

<script lang="ts" setup>
import TextBlock from "../blocks/TextBlock.vue";
import ContentWrapper from "../generics/ContentWrapper.vue";
import PageTitle from "../blocks/PageTitle.vue";
import CustomPic from "../generics/CustomPic.vue";
import CustomTable from "../generics/CustomTable.vue";
import meta from "@/data/meta.json";

var ticket_ref_object = [
	{
		Interailkort: "4 dagar på 1 månad",
		Ungdom: "2349:- (2949:-)",
		Vuxen: "3099:- (3949:-)",
		Senior: "2799:- (3549:-)",
	},
	{
		Interailkort: "4 dagar på 1 månad",
		Ungdom: "2349:- (2949:-)",
		Vuxen: "3099:- (3949:-)",
		Senior: "2799:- (3549:-)",
	},
	{
		Interailkort: "4 dagar på 1 månad",
		Ungdom: "2349:- (2949:-)",
		Vuxen: "3099:- (3949:-)",
		Senior: "2799:- (3549:-)",
	},
	{
		Interailkort: "4 dagar på 1 månad",
		Ungdom: "2349:- (2949:-)",
		Vuxen: "3099:- (3949:-)",
		Senior: "2799:- (3549:-)",
	},
];

var countrylist = [
	"Belgien",
	"Bosnien-Hercegovina",
	"Bulgarien",
	"Danmark",
	"Estland",
	"Finland",
	"Frankrike",
	"Grekland",
	"Irland",
	"Italien",
	"Kroatien",
	"Lettland",
	"Litauen",
	"Luxemburg",
	"Makedonien",
	"Montenegro",
	"Nederländerna",
	"Norge",
	"Polen",
	"Portugal",
	"Rumänien",
	"Schweiz",
	"Serbien",
	"Slovakien",
	"Slovenien",
	"Spanien",
	"Storbritannien",
	"Tjeckien",
	"Turkiet",
	"Tyskland",
	"Ungern",
	"Österrike",
	"Italien",
	"Grekland",
];
</script>

<style scoped>
.country-list {
	display: grid;
	grid-template-columns: repeat(auto-fill, 10rem);
	row-gap: 0.5rem;
}
</style>
