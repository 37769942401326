// adminStore.ts

import { defineStore } from "pinia";
import { router } from "@/router/router";

const backend_adress = process.env.VUE_APP_BACKEND_ADRESS;
const admin_key = process.env.VUE_APP_ADMIN_KEY;

interface AdminState {
  adminKey: string;
}

export const useAdminStore = defineStore({
  id: "adminStore",
  state: (): AdminState => ({
    adminKey: admin_key ? String(admin_key) : "",
  }),

  actions: {
    login(username: string, password: string) {
      const baseURL = backend_adress + "/";
      const url = baseURL + "login";
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: username,
          password: password,
        }),
      })
        .then((resp) => resp.json())
        .then((response) => {
          if (response.ans === "No data") {
            console.log("Invalid login!");
          } else {
            this.$state.adminKey = response.ans as string;
            router.push("/");
          }
        });
    },
  },
});
