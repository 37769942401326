<template>
  <Teleport to="body">
    <Transition :name="props.enterFrom">
      <div
        :class="['modal-underlay', props.enterFrom]"
        v-if="props.show"
      >
        <div :class="['modal', fullHeight ? 'full-height' : '']">
          <CustomCard class="card">
            <div class="modal-content">
              <div class="modal-header">
                <div class="title"><slot name="header"></slot></div>
                <CustomIconButton
                  v-if="!unRemoveable"
                  @click="$emit('onClose')"
                ></CustomIconButton>
              </div>
              <div class="modal-body">
                <slot name="body"></slot>
              </div>
            </div>
          </CustomCard>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script lang="ts" setup>
import { defineProps, onUnmounted, watch } from "vue";
import CustomCard from "./CustomCard.vue";
import CustomIconButton from "./CustomIconButton.vue";

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
  enterFrom: {
    type: String,
    default: "bottom",
  },
  fullHeight: {
    type: Boolean,
    default: false,
  },
  header: {
    type: String,
    default: "",
  },
  unRemoveable: {
    type: Boolean,
    default: false,
  },
});

watch(
  () => props.show,
  () => {
    if (props.show) {
      window.scrollTo(0, 0);
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }
);

onUnmounted(() => {
  document.body.classList.remove("modal-open");
});
</script>

<style scoped lang="scss">
.modal-underlay {
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  display: flex;
  z-index: 10000;

  &.center {
    justify-content: center;
    align-items: center;
  }
  &.right {
    justify-content: flex-end;
    .modal {
      max-width: 24rem;
      width: 100%;
    }
  }
}

.full-height {
  height: 100%;
  width: 100%;

  .card {
    min-height: 100%;
    .modal-content {
      max-height: 90vh !important;
    }
  }
}

.modal {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .card {
    width: 100%;
    max-width: 480px;
    .modal-content {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      width: 100%;
      height: 100%;
      max-height: 80vh;

      .modal-header {
        padding-left: 2rem;
        padding-top: 2rem;
        padding-right: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .title {
          font-size: 1.2rem;
          font-weight: bold;
        }
      }
      .modal-body {
        overflow-y: auto;
        min-height: 100%;
        max-height: calc(100vh - 5rem);
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 2rem;
      }
    }
  }
}

.bottom-enter-active,
.bottom-leave-active {
  transition: opacity 0.5s ease;
  .modal {
    transition: transform 0.5s ease;
    transform: translate(0, 0);
  }
}

.bottom-enter-from,
.bottom-leave-to {
  opacity: 0;
  .modal {
    transition: transform 0.5s ease;
    transform: translate(0, 100%);
  }
}

.right-enter-active,
.right-leave-active {
  transition: opacity 0.5s ease;
  .modal {
    transition: transform 0.5s ease;
    transform: translate(0, 0);
  }
}

.right-enter-from,
.right-leave-to {
  opacity: 0;
  .modal {
    transition: transform 0.5s ease;
    transform: translate(100%, 0);
  }
}
</style>
