<template>
  <div class="navigation-fotter">
    <div class="button-wrapper">
      <div>
        <CustomButton
          @click="handle_back_click()"
          v-if="
            bookingStore.ticketBuyPaths.indexOf(bookingStore.currentBuyPath) > 0
          "
        >
          Tillbaka
        </CustomButton>
      </div>
      <div>
        <CustomButton
          @click="get_next_step()"
          :disabled="!bookingStore.currentBuyPath?.valid"
          v-if="bookingStore.currentBuyPath?.nextPath != ''"
        >
          Nästa
        </CustomButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, onMounted, reactive } from "vue";
import CustomButton from "../generics/CustomButton.vue";
import { useBookingStore } from "@/store/bookingStore";
import { router } from "@/router/router";

defineProps({});

const bookingStore = useBookingStore();

function get_next_step() {
  if (bookingStore.currentBuyPath != undefined) {
    router.push(bookingStore.currentBuyPath.nextPath);
  }
}

function handle_back_click() {
  router.go(-1);
}

onMounted(() => {
  if (bookingStore.currentBuyPath?.path) {
    bookingStore.validate_step(bookingStore.currentBuyPath?.path);
  }
});
</script>

<style scoped lang="scss">
.navigation-fotter {
  position: sticky;
  bottom: 0;
  height: 30px;
  width: calc(100vw - 2rem);
  background-color: white;
  padding: 1rem;
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 1px -1px rgba(0, 0, 0, 0.2),
    0px 0px 1px 0px rgba(0, 0, 0, 0.14), 0px 0px 3px 0px rgba(0, 0, 0, 0.12);
  z-index: 1000;
}

.button-wrapper {
  width: 100%;
  max-width: 768px;
  display: flex;
  justify-content: space-between;
}
</style>
