<template>
  <div class="customer_input">
    <div class="input-fields">
      <div class="input-fields-wrapper">
        <CustomInputField
          id="name"
          class="input-field"
          label="Förnamn"
          :initValue="bookingStore.order.firstname"
          @onInput="handle_paye_input($event, 'firstname')"
        ></CustomInputField>
        <CustomInputField
          id="surname"
          class="input-field"
          label="Efternamn"
          :initValue="bookingStore.order.surname"
          @onInput="handle_paye_input($event, 'surname')"
        ></CustomInputField>
      </div>
      <div class="input-fields-wrapper">
        <CustomInputField
          id="adress"
          class="input-field"
          label="Adress"
          :initValue="bookingStore.order.adress"
          @onInput="handle_paye_input($event, 'adress')"
        ></CustomInputField>
        <CustomInputField
          id="city"
          class="input-field"
          label="Ort"
          :initValue="bookingStore.order.city"
          @onInput="handle_paye_input($event, 'city')"
        ></CustomInputField>
        <CustomInputField
          id="postalnr"
          class="input-field"
          label="Postnummer"
          :initValue="bookingStore.order.postalNumber"
          @onInput="handle_paye_input($event, 'postalNumber')"
        ></CustomInputField>
      </div>
      <div class="input-fields-wrapper">
        <CustomInputField
          id="phonenr"
          class="input-field"
          label="Telefonnummer"
          :initValue="bookingStore.order.phoneNumber"
          @onInput="handle_paye_input($event, 'phoneNumber')"
        ></CustomInputField>
        <CustomInputField
          id="email"
          class="input-field"
          label="Email"
          :initValue="bookingStore.order.email"
          @onInput="handle_paye_input($event, 'email')"
        ></CustomInputField>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import CustomInputField from "../generics/CustomInputField.vue";
import { useBookingStore, order } from "@/store/bookingStore";

const bookingStore = useBookingStore();

function handle_paye_input(event: any, key_name: keyof order) {
  bookingStore.order[key_name as keyof order] = event;
}
</script>

<style scoped lang="scss">
@import "../../styles/variables.scss";
.customer_input {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.input-fields {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.input-fields-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 1rem;
}

@media (max-width: $screen-size-mobile) {
  .input-fields-wrapper {
    flex-direction: column;
    row-gap: 1rem;
  }
}

.input-field {
  width: 100%;
}

.divider {
  border-bottom: 2px solid grey;
}
</style>
