<template>
  <div class="customer_input">
    <div class="input-fields">
      <div class="input-fields-wrapper">
        <CustomInputField
          id="nameSwish"
          class="input-field"
          label="Förnamn"
          :initValue="bookingStore.order.swishFirstname"
          @onInput="handle_paye_input($event, 'swishFirstname')"
        ></CustomInputField>
        <CustomInputField
          id="surnameSwish"
          class="input-field"
          label="Efternamn"
          :initValue="bookingStore.order.swishSurname"
          @onInput="handle_paye_input($event, 'swishSurname')"
        ></CustomInputField>
      </div>
      <div class="input-fields-wrapper">
        <CustomInputField
          id="phonenumberSwish"
          class="input-field"
          label="Telefonnummer"
          :initValue="bookingStore.order.swishPhoneNumber"
          @onInput="handle_paye_input($event, 'swishPhoneNumber')"
        ></CustomInputField>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive } from "vue";
import CustomInputField from "../generics/CustomInputField.vue";
import { useBookingStore, order } from "@/store/bookingStore";

const state = reactive({
  firstName: "",
  lastName: "",
  country: "",
  birthday: "",
});

console.log(state);

const bookingStore = useBookingStore();

function handle_paye_input(event: any, key_name: keyof order) {
  bookingStore.order[key_name as keyof order] = event;
}
</script>

<style scoped lang="scss">
@import "../../styles/variables.scss";
.customer_input {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.input-fields {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.input-fields-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 1rem;
}

@media (max-width: $screen-size-mobile) {
  .input-fields-wrapper {
    flex-direction: column;
    row-gap: 1rem;
  }
}

.input-field {
  width: 100%;
}

.divider {
  border-bottom: 2px solid grey;
}
</style>
