<template>
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 617.31 176.61"
    width="100%"
    height="100%"
  >
    <path
      class="cls-1"
      d="M482.85,430.76c0,.5,0,1,0,1.48a98.39,98.39,0,0,1-1.95,18.14,95.9,95.9,0,0,1-2.37,9.3A97.88,97.88,0,0,1,474.69,470a96,96,0,0,1-5.33,10.46q-2.08,3.53-4.44,6.86l-1.56,2.14-.12.17q-2.88,3.84-6.12,7.37l-5.35-7.37-6.39-8.79-.09-.13L437.51,470l-4.26-5.86h0a58.85,58.85,0,0,0,7-13.75,59.22,59.22,0,0,0,2-32.07,58.54,58.54,0,0,0-9.05-20.92v0l-.51-.69-.56-.77a58.92,58.92,0,0,0-30.69-21.6,59.33,59.33,0,0,0-10.8-2.13q-2.94-.29-5.95-.29t-6,.29a59.33,59.33,0,0,0-10.8,2.13,58.92,58.92,0,0,0-30.69,21.6l-.56.77-.51.69,0,0a58.56,58.56,0,0,0-9.06,20.92,58.67,58.67,0,0,0,9.06,45.82h0L332,470l-7.78,10.71-.09.13-6.39,8.79L312.35,497q-3.24-3.54-6.12-7.37l-.12-.17-1.56-2.14c-1.56-2.22-3.05-4.51-4.43-6.86A97.57,97.57,0,0,1,291,459.68a95.9,95.9,0,0,1-2.37-9.3,98.39,98.39,0,0,1-1.95-18.14c0-.49,0-1,0-1.48s0-1,0-1.49a97.51,97.51,0,0,1,13.49-48.21c1.38-2.36,2.87-4.65,4.43-6.87l1.55-2.12v0q2.94-3.93,6.24-7.53a98.77,98.77,0,0,1,14.71-13.15A97.81,97.81,0,0,1,355.86,337c2.55-.78,5.13-1.47,7.76-2a98.21,98.21,0,0,1,19.63-2.27h3a98.38,98.38,0,0,1,19.63,2.27c2.62.57,5.21,1.26,7.75,2a98.13,98.13,0,0,1,43.51,27.56,95.83,95.83,0,0,1,6.24,7.53l0,0,1.54,2.12q2.35,3.33,4.44,6.87a97.65,97.65,0,0,1,13.48,48.21C482.85,429.76,482.85,430.26,482.85,430.76Z"
      transform="translate(-286.62 -332.64)"
    />
    <polygon
      class="cls-2"
      points="126.63 137.37 69.61 137.37 73.86 131.5 75.05 129.87 83.86 117.74 112.38 117.74 121.19 129.87 122.37 131.5 126.63 137.37"
    />
    <polygon
      class="cls-2"
      points="155.14 176.61 41.09 176.61 49.61 164.89 51.98 161.63 55.35 156.99 140.89 156.99 144.26 161.63 146.63 164.89 155.14 176.61"
    />
    <path
      class="cls-2"
      d="M713.9,394H671.43c1.64,7.18,6.87,12.82,17,12.82a34.42,34.42,0,0,0,18.36-5.74l5.34,9.54a41.9,41.9,0,0,1-24.22,7.69c-20.92,0-30.16-14.36-30.16-29.13,0-16.62,11.6-29.14,28.93-29.14,15.7,0,27.6,10.36,27.6,28A52.15,52.15,0,0,1,713.9,394Zm-42.47-10.16h29.75c-1.13-7.8-6.87-12.62-14.47-12.62C678.92,371.26,673.17,376.19,671.43,383.88Z"
      transform="translate(-286.62 -332.64)"
    />
    <path
      class="cls-2"
      d="M597.6,384.87v32.41H584.16V384.87c0-7.39-4.31-12.42-11.69-12.42s-11.8,5-11.8,12.42v32.41H547.23V384.87c0-15.6,9.64-24.62,25.24-24.62C587.86,360.25,597.6,369.38,597.6,384.87Z"
      transform="translate(-286.62 -332.64)"
    />
    <path
      class="cls-2"
      d="M789.37,372.92a14,14,0,0,0-3.64-.46c-7.49,0-11.8,5-11.8,12.41v32.42H760.49V384.87c0-15.59,9.64-24.62,25.24-24.62a32.7,32.7,0,0,1,7.94.92Z"
      transform="translate(-286.62 -332.64)"
    />
    <path
      class="cls-2"
      d="M752.82,372.92a14.08,14.08,0,0,0-3.65-.46c-7.49,0-11.8,5-11.8,12.41v32.42H723.93V384.87c0-15.59,9.65-24.62,25.24-24.62a32.64,32.64,0,0,1,7.94.92Z"
      transform="translate(-286.62 -332.64)"
    />
    <path
      class="cls-2"
      d="M855,361.41v55.71H842.24v-6.87a23.61,23.61,0,0,1-18.16,8.2c-16.11,0-27.6-12.92-27.6-29.44,0-16.72,11.7-28.93,27.6-28.93a23.6,23.6,0,0,1,18.16,8.41v-7.08Zm-12.41,27.81c0-9.24-6.67-16.93-16.42-16.93s-16.31,7.69-16.31,16.93,6.57,17,16.31,17S842.55,398.55,842.55,389.22Z"
      transform="translate(-286.62 -332.64)"
    />
    <path
      class="cls-2"
      d="M866.08,417.12V361.41h13.44v55.71Z"
      transform="translate(-286.62 -332.64)"
    />
    <path
      class="cls-2"
      d="M890.59,417.12v-78h13.34v78Z"
      transform="translate(-286.62 -332.64)"
    />
    <ellipse class="cls-1" cx="586.24" cy="13.7" rx="8.51" ry="8.41" />
    <path
      class="cls-2"
      d="M522.63,417.12V361.41h13.44v55.71Z"
      transform="translate(-286.62 -332.64)"
    />
    <ellipse class="cls-2" cx="242.79" cy="13.7" rx="8.51" ry="8.41" />
    <path
      class="cls-2"
      d="M651.33,414a29.32,29.32,0,0,1-16,4.58c-12.51,0-18.91-7.53-18.91-21.56v-23.8h-9.16V362.05h9.36V342.52h13.12v19.53h18.51v11.19H629.77v23.29c0,6.51,2.34,10,8,10a18.1,18.1,0,0,0,9.36-2.85Z"
      transform="translate(-286.62 -332.64)"
    />
    <path
      class="cls-2"
      d="M574.84,484.21H532.17c1.65,7.22,6.91,12.88,17.11,12.88a34.58,34.58,0,0,0,18.45-5.77l5.36,9.59a42.12,42.12,0,0,1-24.32,7.72c-21,0-30.3-14.42-30.3-29.26,0-16.7,11.64-29.27,29.06-29.27,15.77,0,27.72,10.41,27.72,28.13A52.38,52.38,0,0,1,574.84,484.21ZM532.17,474h29.89c-1.13-7.83-6.91-12.68-14.53-12.68C539.7,461.33,533.93,466.28,532.17,474Z"
      transform="translate(-286.62 -332.64)"
    />
    <path
      class="cls-2"
      d="M584.94,484V451.44h13.5V484c0,7.42,4.33,12.46,11.75,12.46S622,491.43,622,484V451.44h13.5V484c0,15.66-9.69,24.73-25.35,24.73C594.73,508.74,584.94,499.57,584.94,484Z"
      transform="translate(-286.62 -332.64)"
    />
    <path
      class="cls-2"
      d="M674.47,463a13.7,13.7,0,0,0-3.67-.47c-7.52,0-11.85,5.05-11.85,12.47v32.57h-13.5V475c0-15.66,9.69-24.73,25.35-24.73a32.55,32.55,0,0,1,8,.93Z"
      transform="translate(-286.62 -332.64)"
    />
    <path
      class="cls-2"
      d="M739.84,451.44v56H727.06v-6.91a23.72,23.72,0,0,1-18.24,8.25c-16.18,0-27.72-13-27.72-29.58,0-16.8,11.75-29.06,27.72-29.06,8,0,13.91,3.61,18.24,8.45v-7.11Zm-12.47,27.93c0-9.28-6.7-17-16.49-17s-16.38,7.73-16.38,17,6.59,17.1,16.38,17.1S727.37,488.75,727.37,479.37Z"
      transform="translate(-286.62 -332.64)"
    />
    <path
      class="cls-2"
      d="M751.07,507.4v-56h13.5v56Z"
      transform="translate(-286.62 -332.64)"
    />
    <path
      class="cls-2"
      d="M775.72,507.4V429.08h13.4V507.4Z"
      transform="translate(-286.62 -332.64)"
    />
    <ellipse class="cls-1" cx="471.25" cy="103.65" rx="8.55" ry="8.45" />
  </svg>
</template>

<style>
.cls-1 {
  fill: #febc22;
}
.cls-2 {
  fill: #140a33;
}
</style>
