<template>
  <div>
    <ContentWrapper>
      <SectionHeader>Login</SectionHeader>
      <CustomInputField
        id="login-username"
        class="input-field"
        label="Användarnamn"
        @onInput="handleUsernameInput"
      ></CustomInputField>
      <!--       <SectionHeader>Lösenord</SectionHeader> -->
      <CustomInputField
        id="login-password"
        class="input-field"
        label="Lösenord"
        @onInput="handlePasswordInput"
      ></CustomInputField>
      <CustomButton @onClick="handleLogin">Logga in</CustomButton>
    </ContentWrapper>
  </div>
</template>

<script lang="ts" setup>
import ContentWrapper from "../generics/ContentWrapper.vue";
import CustomInputField from "../generics/CustomInputField.vue";
import SectionHeader from "../generics/SectionHeader.vue";
import CustomButton from "../generics/CustomButton.vue";
import { useAdminStore } from "@/store/adminStore";
import { reactive } from "vue";

const state = reactive({
  userName: "",
  password: "",
});

const adminStore = useAdminStore();

function handleUsernameInput(input: string) {
  state.userName = input;
}

function handlePasswordInput(input: string) {
  state.password = input;
}

function handleLogin() {
  adminStore.login(state.userName, state.password);
}
</script>

<style scoped></style>
