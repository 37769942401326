<template>
  <ContentWrapper>
    <template v-if="!state.ticketsReturned">
      <SectionHeader
        ><h1 class="header">Returnering av order</h1></SectionHeader
      >
      <div v-if="orderFound()">
        <p>
          Vill du returnera en eller flera av dina biljetter? Ange ditt
          bokningsnummer nedan så hämtar vi din bokning.
          <br />
          <br />
          Det är möjligt att returnera delar av en bokning förutsatt att den
          kvarvarande bokningen uppfyller kraven på en godkänd bokning.
          <br />
          <br />
          Det går tyvärr inte att returnera en bokning som redan aktiverat.
        </p>

        <div class="input-wrapper">
          <CustomInputField
            id="return"
            label="Ordernummer"
            @onInput="handleOrderNumber($event)"
          ></CustomInputField>

          <CustomButton
            class="button"
            :disabled="!state.orderNumber"
            @onClick="handleGetOrderClick"
          >
            Hämta min bokning
          </CustomButton>
        </div>
      </div>
      <div class="ticket-wrapper" v-if="!orderFound()">
        <template
          v-for="(returnCandidateTicket, index) of state.shoppingCart"
          :key="`ticket-${index}`"
        >
          <h2 class="title">Biljett {{ index + 1 }}</h2>
          <div class="divider"></div>
          <CustomTable
            :class="state.ticketIdsToReturn.includes(returnCandidateTicket.eurailTicketId as string) ? 'highlight-table': ''"
            :tableItems="[
              {
                Pass: returnCandidateTicket.title,
                Komfort: `${returnCandidateTicket.comfort}, ${returnCandidateTicket.age}`,
                Resenär: `${returnCandidateTicket.firstName} ${returnCandidateTicket.lastName}`,
                Aktiveringskod: returnCandidateTicket.mobileActivationCode,
                Pris: returnCandidateTicket.price,
              },
            ]"
          ></CustomTable>
          <CustomButton
            class="select-button"
            variant="outlined"
            
            @onClick="
              handleAddRemoveTicketFromReturnList(
                returnCandidateTicket.eurailTicketId as string
              )
            "
          >
            <div
              v-if="
                state.ticketIdsToReturn.includes(
                  String(returnCandidateTicket.eurailTicketId)
                )
              "
            >
              Exkludera biljett från retur
            </div>
            <div
              v-if="
                !state.ticketIdsToReturn.includes(
                  String(returnCandidateTicket.eurailTicketId)
                )
              "
            >
              Välj biljett för retur
            </div>
          </CustomButton>
        </template>
        <CustomButton
          class="select-button"
          @onClick="handleReturnTickets()"
          :disabled="state.ticketIdsToReturn.length <= 0"
        >
          Returnera {{ state.ticketIdsToReturn.length }} biljetter
        </CustomButton>
<!--       <div>{{ state.error }}</div>
        <div>{{ state.refundError }}</div>
        <div>{{ state.ticketIdsToReturn }}</div> -->
        <!--       <div>{{ state.order }}</div>
      <div>{{ state.shoppingCart }}</div> -->
      </div>
    </template>
    <template v-if="state.ticketsReturned">
      <div>
        <h1 class="header">Biljetter returnerade</h1>
      </div>
    </template>
  </ContentWrapper>
  <CustomModal
    :show="state.fetchingOrder"
    :full-height="true"
    :un-removeable="true"
  >
    <template v-slot:body>
      <div class="modal-content">
        <CustomLoader />
        <h2>Hämtar bokning</h2>
      </div>
    </template>
  </CustomModal>
  <CustomModal
    :show="state.returningTicket"
    :full-height="true"
    :un-removeable="true"
  >
    <template v-slot:body>
      <div class="modal-content">
        <CustomLoader />
        <h2>Returnerar biljetter</h2>
      </div>
    </template>
  </CustomModal>
</template>

<script lang="ts" setup>
import ContentWrapper from "../generics/ContentWrapper.vue";
import CustomInputField from "../generics/CustomInputField.vue";
import SectionHeader from "../generics/SectionHeader.vue";
import CustomButton from "../generics/CustomButton.vue";

import postEurailAPI from "@/functions/postEurailAPI";
import { reactive } from "vue";
import { order, useBookingStore } from "@/store/bookingStore";
import ticket from "@/types/ticket";
import CustomTable from "../generics/CustomTable.vue";
import sendReturnMail from "@/functions/sendReturnMail";
import CustomModal from "../generics/CustomModal.vue";
import CustomLoader from "@/components/generics/CustomLoader.vue";

const state = reactive({
  orderNumber: "",
  fetchingOrder: false,
  returningTicket: false,
  ticketsReturned: false,
  order: {} as order,
  shoppingCart: [] as ticket[],
  eurailBooking: {} as any,
  error: "",
  refundError: "",
  ticketIdsToReturn: [] as string[],
});

const bookingStore = useBookingStore();

const backend_adress = process.env.VUE_APP_BACKEND_ADRESS;

function handleOrderNumber(event: any) {
  state.orderNumber = event;
}

function handleGetOrderClick() {
  console.log("Ping!")
  state.fetchingOrder = true;
  state.order = {} as order;
  state.eurailBooking = {} as any;
  state.shoppingCart = [] as ticket[];
  state.error = "";
  state.refundError = "";
  state.ticketIdsToReturn = [] as string[];
  getRefundableTickets(state.orderNumber);
}

function handleAddRemoveTicketFromReturnList(ticketId: string) {
  if (state.ticketIdsToReturn.includes(ticketId)) {
    const index_of = state.ticketIdsToReturn.indexOf(ticketId);
    if (index_of > -1) {
      state.ticketIdsToReturn.splice(index_of, 1);
    }
  } else {
    state.ticketIdsToReturn.push(ticketId);
  }
}

function handleReturnTickets() {
  console.log("Returning tickets...");
  returnTickets(state.ticketIdsToReturn);
}

function generate_table_view() {
  if (!state.shoppingCart) {
    return [];
  }
  return [
    ...state.shoppingCart.map((ticket: ticket) => {
      return [
        {
          Pass: ticket.title,
          Komfort: `${ticket.comfort}, ${ticket.age}`,
          Resenär: `${ticket.firstName} ${ticket.lastName}`,
          Aktiveringskod: ticket.mobileActivationCode,
          Pris: ticket.price,
        },
      ];
    }),
  ];
}

function orderFound() {
  return Object.keys(state.order).length === 0;
}

function getTicketsFromEurailID(ids: string[]) {
  let ticket_list = [] as ticket[];
  ids.forEach((uniqueID: string) => {
    state.shoppingCart.forEach((ticket) => {
      if (ticket.eurailTicketId == uniqueID) {
        ticket_list.push(ticket);
      }
    });
  });
  return ticket_list;
}

async function getRefundableTickets(id: string) {
  var message = {
    status: 400,
    statusMessage: "Unspecified error",
    body: "",
    concatenatedError: [],
    error: "",
  };

  // Start by calling our dB and receive the order connected to the entered id
  // - Create an instance of that order
  /*     var testOrder = new order_class();
    	console.log("Setting testOrder");
    	testOrder.fromJSON(JSON.parse(JSON.stringify(new_order)));
   		console.log("Set testOrder to:", testOrder); */
  let tempOrder = await getOrder(id);
  console.log(tempOrder)
  if (!tempOrder) {
    state.error =
      "Ordern gick inte att hitta. Säkerställ att ditt ordernummer är korrekt.";

    state.fetchingOrder = false;
    return;
  }
  tempOrder = await updateOrderVersion(tempOrder, message);
  // - Achieve its eurail order id and pass it into the getTicketStatus(id) function.

  await getTicketStatus(tempOrder.eurailOrderId, message).then(
    (result: any) => {
      console.log("result:", result);
      if (result.error) {
        var errorMessage;
        switch (result.concatenatedError[result.concatenatedError.length - 1]) {
          case "Not Found":
            errorMessage =
              "Ordern gick inte att hitta. Säkerställ att ditt ordernummer är korrekt.";
            break;
        }
        state.error = String(errorMessage);
        return;
      }
      if ("body" in result) {
        var index = 0;
        tempOrder.order.map((ticket: ticket) => {
          if (ticket.type === "ticket") {
            for (let i = 0; i < result.body.lineItems.length; i++) {
              console.log(result.body.lineItems);
              if (result.body.lineItems[i].id == ticket.eurailTicketId) {
                /* ticket.set_refundable(true); */
                ticket.refundable = true;
              }
            }

            index += 1;
          }
        });

        console.log("TempOrder");
        console.log(tempOrder);

        state.order = tempOrder;

        state.order = {
          firstname: tempOrder.firstname,
          surname: tempOrder.surname,
          adress: tempOrder.adress,
          city: tempOrder.city,
          postalNumber: tempOrder.postalNumber,
          phoneNumber: tempOrder.phoneNumber,
          swishPhoneNumber: tempOrder.swishPhoneNumber,
          email: tempOrder.email,
          swishFirstname: tempOrder.swishFirstname,
          swishSurname: tempOrder.swishSurname,
          acceptTermsAndConditions: tempOrder.acceptTermsAndConditions,
          originalPaymentReference: tempOrder.originalPaymentReference,
          CurrentSwishIdentifier: tempOrder.CurrentSwishIdentifier,
          bookStatus: tempOrder.bookStatus,
          shoppingCartId: tempOrder.shoppingCartId,
          eurailOrderNumber: tempOrder.eurailOrderNumber,
          eurailOrderId: tempOrder.eurailOrderId,
          orderStatus: tempOrder.orderStatus,
          tagluffaOrderNumber: tempOrder.tagluffaOrderNumber,
          eurailBooking: tempOrder.eurailBooking,
        };

        state.shoppingCart = tempOrder.order;
      }
    }
  );
  console.log("setting order to:", tempOrder);
  state.fetchingOrder = false;
}

async function getOrder(id: string) {

  const url = backend_adress + "/getOrderFromDB";
  let res = fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id: id,
    }),
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (1 > data.ans.length) {
        return;
      }
      var order = JSON.parse(data.ans[0].order);
      return order;
    });
  return res;
}

async function updateOrderVersion(tempOrder: any, message: any) {
  // Get order from Eurail API
  await getEurailOrder(tempOrder.eurailOrderId, message).then((result: any) => {
    if (result.error) {
      var errorMessage;
      switch (result.concatenatedError[result.concatenatedError.length - 1]) {
        case "Not Found":
          errorMessage =
            "Ordern gick inte att hitta. Säkerställ att ditt ordernummer är korrekt.";
          break;
      }
      state.error = String(errorMessage);
      return;
    }
    if ("body" in result) {
      // Version is refetched from eurail in order for version to match when making a return
      tempOrder.eurailBooking = result.body;
    }
  });
  // Update database with Eurail order info
  return tempOrder;
}

async function getTicketStatus(id: any, message: any) {
  message.body = id;
  var result = await postEurailAPI(message, "refundable", backend_adress);

  return result;
}

async function getEurailOrder(id: any, message: any) {
  message.body = id;
  var result = await postEurailAPI(message, "get_order", backend_adress);

  return result;
}

async function returnTickets(tickets: string[]) {
  // Return the tickets chosen by the customer to be returned. NOT all of the returnable.
  // Firstly, check whether the new order would be valid.
  if (bookingStore.is_order_invalid(state.order, state.shoppingCart)) {
    state.refundError =
      bookingStore.is_order_invalid(state.order, state.shoppingCart) +
      ". Åtgärda det och fortsätt sedan till returnering.";
    return;
  }

  // Proceed with refund.
  state.returningTicket = true;
  var message = {
    status: 400,
    statusMessage: "Unspecified error",
    body: "",
    concatenatedError: [],
    error: "",
  };

  //await tickets.map((ticket) => {
  await returnATicket(state.order.eurailOrderId, tickets, message).then(
    //ticket.get_eurail_ticket_id, message).then(
    (result: any) => {
      if (result.error) {
        var errorMessage;
        switch (result.concatenatedError[result.concatenatedError.length - 1]) {
          case "Not Found":
            errorMessage =
              "Ordern gick inte att hitta. Säkerställ att ditt ordernummer är korrekt.";
            break;
          case "Preparing swish payment did not succeed":
            if (result.concatenatedError[0] == "Payer not Enrolled") {
              errorMessage = "Felaktigt swishnummer.";
            }
            break;
          case "Swish payment failed.":
            errorMessage =
              "Swishbetalning genomfördes inte. Var god försök igen. Om det inte fungerar då, var vänlig kontakta kundservice.";
            break;
          case "Create order did not execute properly":
            //history.push("/order-error");
            break;
          default:
            console.log("The error:", result.error);
            errorMessage = result.error.error;
            break;
        }
        state.refundError = errorMessage;
        console.log(errorMessage);
        state.returningTicket = false;
      } else {
        sendReturnMail(
          state.order,
          getTicketsFromEurailID(tickets),
          getPriceOfTickets(tickets) - getFee(tickets),
          backend_adress
        ).then((response) => {
          if (response == "success") {
            console.log("Confirmation mail successfully sent.");
          } else {
            console.log("Failed to send confirmation mail.");
          }
        });
        state.returningTicket = false;
        state.ticketsReturned = true;
        // Send to confirmation page
      }
    }
  );
  return;
}

function getPriceOfTickets(tickets: string[]) {
  var total = 0;
  var ticket_list = getTicketsFromEurailID(tickets);
  ticket_list.forEach((element) => {
    total += Number(element.price);
  });
  return total;
}

function getFee(tickets: string[]) {
  var price = getPriceOfTickets(tickets);
  var free_return = freeReturn();
  var fee = free_return ? 0 : Math.ceil(price * 0.05);
  return fee;
}

function freeReturn() {
  //Placeholder to determine if an order is being returned within 14 days and thus is covered by ångerrätten.
  //Then return True
  var order_time = new Date(state.order.eurailBooking.createdAt);
  var time_now = new Date();

  // To calculate the time difference of two dates
  var Difference_In_Time = time_now.getTime() - order_time.getTime();

  // To calculate the no. of days between two dates
  var Difference_In_Days = Math.floor(Difference_In_Time / (1000 * 3600 * 24));

  // You have the right to return if it has not been more than 14 days since the order was created
  var right_to_return = Difference_In_Days <= 14 ? true : false;

  console.log("The right to return is: ", right_to_return);

  return right_to_return;
}

async function returnATicket(
  orderId: string,
  ticketIds: string[],
  message: any
) {
  // This is returning an entire order at this stage

  let lineItems = [] as any[];

  ticketIds.forEach((element) => {
    lineItems.push({ id: element, quantity: 1 });
  });

  console.log("version" + state.order.eurailBooking.version);

  message.body = {
    message: {
      refundPaymentType: "auto",
      lineItems: lineItems,
      refundReason: {
        reason: "unknown",
        replaceOption: false,
        explanation: "Unknown",
      },
      refundState: "line-item-mobile-refund-requested",
    },
    order: orderId,
    version: state.order.eurailBooking.version,
  };
  var result = await postEurailAPI(message, "refund", backend_adress)
    .then((result) => {
      console.log(result);
      /*if ("status" in result && "body" in result) {
					if (result.status < 400) {
						order.set_eurail_booking(result.body);
						const url = backend_adress + "/update_order";
						fetch(url, {
							method: "POST",
							headers: {
								"Content-Type": "application/json",
							},
							body: JSON.stringify({
								order: order,
							}),
						});
					}
				}*/
      return result as any;
    })
    .catch(function (error) {
      console.log("Request failure: ", error);
    });
  console.log(result);

  // Update order in dB with new Eurail order dump existing in result variable
  if ("status" in result && "body" in result) {
    if (result.status < 400) {
      console.log("now");
      state.order.eurailBooking = result.body;
      const url = backend_adress + "/update_order";
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          order: state.order,
          id: state.order.eurailOrderId,
        }),
      }).catch(function (error) {
        console.log("Request failure: ", error);
      });
    }
  }

  return result;
}
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  display: flex;
  justify-content: center;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 1rem;
  .input-field {
    width: 100%;
  }
}

.ticket-wrapper {
  display: flex;
  flex-direction: column;
  column-gap: 1rem;
  row-gap: 1rem;
  .select-button {
    width: 100%;
  }
}

.divider {
  border-bottom: 2px solid grey;
}

.highlight-table {
  outline: 2px solid #ff8a65;
}

.modal-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
}
</style>
