<template>
  <ContentWrapper>
    <div class="customer-info-wrapper">
      <CustomerInputBlock
        v-for="(value, index) in bookingStore.shoppingCart"
        :key="`${index}-customerinfo-${value.id}`"
        :index_in_shoppingcart="index"
        :shopping_cart_ticket="value"
      >
      </CustomerInputBlock>
    </div>
  </ContentWrapper>
</template>

<script lang="ts" setup>
import CustomerInputBlock from "@/components/blocks/CustomerInputBlock.vue";
import ContentWrapper from "@/components/generics/ContentWrapper.vue";
import { useBookingStore } from "@/store/bookingStore";
import { onMounted } from "vue";

const bookingStore = useBookingStore();

onMounted(() => {
  bookingStore.validateTicketsInCart(bookingStore.shoppingCart);
  bookingStore.validate_step("resenarer")
});
</script>

<style scoped>
.customer-info-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
</style>
