<template>
  <div class="headerWrapper" id="top">
    <div class="header">
      <div v-if="state.screenWidth < 480" class="homepage-logo-mobile">
        <router-link to="/" class="icon-link">
          <slot name="logo-mobile"></slot>
        </router-link>
        <ShoppingButton></ShoppingButton>
      </div>
      <div class="box logo-wrapper">
        <div class="homepage-logo">
          <router-link to="/">
            <slot name="left"></slot>
          </router-link>
        </div>
      </div>
      <div class="box linksWrapper">
        <HeaderTabs :tabs="links"></HeaderTabs>
      </div>
      <div class="box shopping-button-wrapper">
        <slot name="right">
          <ShoppingButton></ShoppingButton>
        </slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import HeaderTabs from "./HeaderTabs.vue";
import ShoppingButton from "./ShoppingButton.vue";

import { defineProps, PropType, onMounted, onUnmounted, reactive } from "vue";

defineProps({
  links: {
    type: Array as PropType<{ linkText: string; linkTo: string }[]>,
    required: false,
    default: () => [],
  },
});

const state = reactive({
  screenWidth: 0,
});

onMounted(() => {
  window.addEventListener("resize", handleWindowSizeChange);
  handleWindowSizeChange();
});
onUnmounted(() => {
  window.removeEventListener("resize", handleWindowSizeChange);
});
const handleWindowSizeChange = () => {
  state.screenWidth = window.innerWidth;
};
</script>

<style scoped lang="scss">
@import "../../styles/variables.scss";

.headerWrapper {
  width: 100%;
  position: fixed;
  z-index: 10000;
}

.header {
  min-height: 4rem;
  display: flex;
  flex-direction: row;
  padding: 0.5rem 1rem;
  background-color: white;
}

.homepage-logo {
  height: 4rem;
}

.box {
  flex: 1;
  display: flex;
  justify-content: center;
}

.box:first-child > div {
  padding-left: 1rem;
  margin-right: auto;
}

.box:last-child > div {
  padding-right: 1rem;
  margin-left: auto;
}

.linksWrapper {
  display: flex;
  flex-direction: row;
  
}

.navButton {
  color: #ff8a65;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}

.shopping-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.homepage-logo-mobile {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  height: 3rem;
  padding-left: 1rem;
}

.icon-link {
  height: 100%;
}

@media (max-width: $screen-size-mobile) {
  .headerWrapper {
    font-size: small;
  }

  .header {
    min-height: 2rem;
    flex-direction: column;
    row-gap: 1rem;
  }

  .logo-wrapper {
    display: none;
  }

  .shopping-button-wrapper {
    display: none;
  }
}
</style>
