<script lang="ts" setup>
import { ref, watch } from "vue";
import {
	LMap,
	LTileLayer,
	LMarker,
	LPopup,
	LPolyline,
} from "@vue-leaflet/vue-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { defineProps } from "vue";

const props = defineProps({
	locations: Array,
	color: { type: String, default: "blue" },
});

const paddingValue = 20;

const mapRef = ref(null);

const onMapReady = (mapInstance) => {
	if (mapInstance && props.locations.length) {
		const bounds = L.latLngBounds(
			props.locations.map((loc) => [loc.lat, loc.lon])
		);
		mapInstance.fitBounds(bounds, {
			padding: [paddingValue, paddingValue],
		});
	}
};

watch(
	() => props.locations,
	() => {
		if (mapRef.value && props.locations.length) {
			const bounds = L.latLngBounds(
				props.locations.map((loc) => [loc.lat, loc.lon])
			);
			mapRef.value.leafletObject.fitBounds(bounds, {
				padding: [paddingValue, paddingValue],
			});
		}
	}
);

const customIcon = new L.Icon({
	iconUrl:
		"https://chart.googleapis.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ff8a65",
	iconSize: [30, 42],
	iconAnchor: [15, 42],
	popupAnchor: [0, -42],
});

const scrollToSection = (id: string) => {
	const section = document.querySelector(id);
	if (section) {
		section.scrollIntoView({ behavior: "smooth" });
	}
};

const handleLinkClick = (link: string) => {
	if (link.startsWith("#")) {
		scrollToSection(link);
	} else {
		// Ensure `window` is available before calling `open`
		if (typeof window !== "undefined") {
			window.open(link, "_blank", "noopener,noreferrer");
		} else {
			console.error("Window object is not available");
		}
	}
};
</script>

<template>
	<l-map
		ref="mapRef"
		@ready="onMapReady"
		style="height: 700px; width: 100%; border-radius: 12px"
	>
		<l-tile-layer
			url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
			attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OSM</a> &copy; <a href='https://carto.com/'>CARTO</a>"
		/>

		<!-- Markörer -->
		<l-marker
			v-for="location in locations"
			:key="location.id"
			:lat-lng="[location.lat, location.lon]"
		>
			<l-popup>
				<strong>{{ location.id }}. {{ location.name }}</strong
				><br />
				<a
					v-if="location.link"
					:href="location.link"
					@click.prevent="handleLinkClick(location.link)"
					:target="location.link.startsWith('#') ? '_self' : '_blank'"
					:rel="location.link.startsWith('#') ? '' : 'nofollow'"
					>Mer info</a
				>
			</l-popup>
		</l-marker>

		<!-- Linjer mellan punkter -->
		<l-polyline
			v-for="(location, index) in locations.slice(0, locations.length - 1)"
			:key="'line-' + index"
			:lat-lngs="[
				[location.lat, location.lon],
				[locations[index + 1].lat, locations[index + 1].lon],
			]"
			:color="color"
			weight="2"
		/>

		<!-- Extra linje från sista till första punkten -->
		<l-polyline
			v-if="locations.length > 1"
			:lat-lngs="[
				[
					locations[locations.length - 1].lat,
					locations[locations.length - 1].lon,
				],
				[locations[0].lat, locations[0].lon],
			]"
			:color="color"
			weight="2"
		/>
	</l-map>
</template>
