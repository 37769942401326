//////////////////////////////////////////////////////////////////////////
//
// Function loop through shopping cart in search of tickets, categorizing
// passengers and creating Request message based on it.
//
//////////////////////////////////////////////////////////////////////////

export default function generateinterrailCart(shopping_cart, payer) {
  var interrailTicketTemplate = {
    custom: {
      type: {
        key: "line-item-pass-custom-fields",
      },
      fields: {
        fulfilmentMethod: "mobile",
        passCategory: "regular", //[regluar, ...]
        travellerFirstName: "",
        travellerLastName: "",
        dateOfBirth: "", // YYYY-MM-DD
        countryOfResidence: "", // Country code
        gender: "u", // [m,f,u]
      },
    },
    quantity: 1,
    sku: "",
  };

  var interrailCart = {
    locale: "en-GB", // Language tag
    currency: "EUR",
    customerEmail: payer.email,
    custom: {
      type: {
        key: "cart-custom-fields",
      },
      fields: {
        contactPhone: payer.phoneNumber,
        fulfilmentMethod: "mobile",
        businessChannel: "IN", // [EU, IN] - Probably Eurail & Interrail
      },
    },
    lineItems: [],
    shippingMethod: {
      key: "no-shipping",
      typeId: "shipping-method",
    },
    shippingAddress: {
      // TODO: Check if the below variable may be set to SE for all orders
      country: "NL", // Country code, e.g. "NL"
    },
  };

  var protectionplan = false,
    customer;
  // Loop through shopping cart to search for tickets
  shopping_cart.map((ticket) => {
    // Check if avbeställningsskydd
    if (ticket.type == "addition") {
      protectionplan = true;
    } else if (ticket.type == "ticket") {
      // Otherwise ticket and then add info to cart structure
      customer = JSON.parse(JSON.stringify(interrailTicketTemplate)); // Create a deep copy of the template
      /* customer.custom.fields.passCategory = ticket.get_comfort(); */
      customer.custom.fields.travellerFirstName = ticket.firstName;
      customer.custom.fields.travellerLastName = ticket.lastName;
      customer.custom.fields.dateOfBirth = ticket.dateOfBirth;
      customer.custom.fields.countryOfResidence = ticket.country;
      //customer.custom.fields.gender = ticket.get_gender();  // Uncomment if gender is included in ticket.
      customer.sku = ticket.sku;

      //Add customer information for every ticket
      interrailCart.lineItems.push(customer);
    }
  });

  console.log(interrailCart);
  return interrailCart;
}
