<template>
  <details :open="state.open" @toggle="handleToggle" class="detail">
    <summary>
      <slot name="header"></slot>
      <CustomPic
        class="disclosure-icon"
        picture-path="SVG/arrow.svg"
        :auto="true"
        height="2rem"
      />
    </summary>
    <p><slot name="body"></slot></p>
  </details>
</template>

<script lang="ts" setup>
import { defineProps, reactive } from "vue";
import CustomPic from "./CustomPic.vue";

defineProps({
  open: {
    type: Boolean,
    default: () => false,
  },
});

var state = reactive({
  open: false,
});

function handleToggle(event: Event) {
  const target = event.target as HTMLDetailsElement;
  state.open = target.open;
}
</script>

<style scoped>
.detail {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  border-bottom: 1px solid rgb(128, 128, 128);
  cursor: pointer;
}

.detail[open] {
  .disclosure-icon {
    transform: rotate(180deg);
  }
}

summary {
  list-style-type: none;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

summary::webkit-details-marker {
  display: none;
}

details summary::-webkit-details-marker {
  display: none;
}
</style>
