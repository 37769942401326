// trackingStore.ts

import { defineStore } from "pinia";
import { useGtm } from "@gtm-support/vue-gtm";
import { useBookingStore } from "./bookingStore";

interface TrackingState {
  userId: string;
}

export const useTrackingStore = defineStore({
  id: "trackingStore", // Specify a unique identifier for the store
  state: (): TrackingState => ({
    userId: "",
  }),

  actions: {
    setCookiesAcceptedCookie(
      ad_storage_accept: boolean,
      analytics_storage_accept: boolean
    ) {
      const user_cookie = this.getCookie("userId");
      console.log(user_cookie);
      if (!user_cookie) {
        const id = "id" + Math.random().toString(16).slice(2);

        document.cookie = `userId=${id}; SameSite=None; Secure`;

        document.cookie = `ad_storage_accept=${ad_storage_accept}; SameSite=None; Secure`;
        document.cookie = `analytics_storage_accept=${analytics_storage_accept}; SameSite=None; Secure`;
      }
    },

    getCookiesAccept() {
      const user_cookie = this.getCookie("userId");
      const ad_storage_accept = this.getCookie("ad_storage_accept");
      const analytics_storage = this.getCookie("analytics_storage_accept");

      if (user_cookie) {
        return {
          userId: user_cookie,
          adAcceptCookie: ad_storage_accept,
          analyticsCookie: analytics_storage,
        };
      } else {
        return {
          userId: "",
          adAcceptCookie: "",
          analyticsCookie: "",
        };
      }
    },

    getCookie(name: string) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop()?.split(";").shift();
    },

    initializeTracking(): void {
      const gtm = useGtm();
      if (gtm) {
        gtm.enable(true);

        window.dataLayer = window.dataLayer || [];

        // eslint-disable-next-line prefer-rest-params
        window.dataLayer.push(arguments);
      }
    },

    trackEvent(eventName: string, eventData?: Record<string, any>): void {
      // Track custom events using Google Tag Manager or other tools
      const gtm = useGtm();
      if (gtm) {
        gtm.trackEvent({
          event: eventName,
          // Include any additional data relevant to the event
          ...(eventData || {}),
        });
      }
    },

    generateGA4EventParametersItem(item: any): Record<string, any> {
      return {
        currency: "SEK",
        price: item.price,
        item_name: item.type,
        item_id: item.sku,
        item_category: item.age,
        item_category2: item.comfort,
      };
    },

    generateGA4EventParametersPurchase(order: any): Record<string, any> {
      const bookingStore = useBookingStore();
      return {
        currency: "SEK",
        value: Math.round((bookingStore.totalVAT(order.order) * 1.06) / 0.06),
        tax: bookingStore.totalVAT(order.order),
        items: order.order.map((item: any) =>
          this.generateGA4EventParametersItem(item)
        ),
        price: bookingStore.totalPrice(order.order),
      };
    },

    // Add other tracking-related actions
  },
});
