<template>
	<teleport to="head">
		<component :is="'script'" type="application/ld+json">
			{{ meta.wineFranceGuide }}
		</component>
	</teleport>
	<PageTitle
		text="Vinresa i Frankrike med Tåg"
		backgroundImageClass="background-bike"
	></PageTitle>
	<ContentWrapper>
		<TextBlock
			header-tag="h2"
			header="
            Att upptäcka Frankrikes fantastiska vindistrikt med tåg är en dröm för vinälskare och resenärer som vill kombinera kultur, gastronomi och natursköna upplevelser. Med ett välutvecklat järnvägsnät och smidiga förbindelser mellan landets mest kända vinregioner, är detta en resa som både är bekväm och inspirerande."
		>
		</TextBlock>
		<div class="card_text_and_image_wrapper">
			<div>
				<MapComponent :locations="locations" color="blue" />
			</div>
			<TextBlock header="Planering och praktisk information">
				<ul class="custom-list">
					<li>
						<component :is="DateRangeIcon"></component><b> Reslängd:</b> 2-3
						veckor rekommenderas för att hinna uppleva flera av de större
						vinregionerna.
					</li>
					<li>
						<component :is="TrainTicketIcon"></component><b> Tågpass:</b> För
						denna resan rekommenderar vi interrailkort på 15 dagar på 2 månader.
					</li>
					<li>
						<component :is="BookingIcon"></component><b> Bokningar:</b> Vissa
						tåg, särskilt snabbtågen (TGV), kräver platsreservation.
					</li>
					<li>
						<component :is="trainIcon"></component><b> Logistik:</b> In- och
						utresa till och från Frankrike görs med fördel genom Strasbourg.
					</li>
					<li>
						<component :is="WineIcon"></component><b> Vinprovningar:</b> De
						flesta vingårdar kräver förbokning av besök och provningar.
					</li>
				</ul>
				<p></p>
			</TextBlock>

			<TextBlock header="Reseupplägg och rekommenderad rutt">
				<ol>
					<li>Alsace (Strasbourg & Colmar)</li>
					<li>Bourgogne (Dijon & Beaune)</li>
					<li>Rhône (Châteauneuf-du-Pape och Lyon)</li>
					<li>Provence (Toulon & Vidauban)</li>
					<li>Bordeaux (Staden Bordeaux, Medoc och Saint-Émilion)</li>
					<li>Loire (Tours och Chinon)</li>
					<li>Paris</li>
					<li>Champagne (Reims & Épernay)</li>
				</ol>
			</TextBlock>

			<TextBlock
				header-tag="h2"
				header="Vinregioner och detaljerade reseplaner"
			>
				I denna sektion går vi igenom de olika regionerna och ger förslag på
				resmål i regionen. Vi förklarar vad man bör se, göra, äta och dricka i
				regionerna. Vi ger också förslag på hur man tar sig mellan vingårdarna.
				Att köra bil mellan vingårdarna leder till att alla inte kan dricka vin.
				Därför förespråkar vi att cykla mellan vingårdarna och med elcyklar
				behöver det inte vara betyngande. Det finns ofta ställen att hyra cyklar
				lokal, men att ta med din egen cykel på resan är också görbart. För mer
				information, läs vår guide om att
				<a href="/artiklar/cykla-i-europa">ta med cykel i Europa</a>.
			</TextBlock>

			<CustomPic
				picturePath="pictures/alsace-frankrike.jpg"
				id="alsace"
			></CustomPic>
			<TextBlock header="Alsace - Blommiga viner och sagolika byar">
				<ul class="custom-list">
					<li>
						<component :is="QuestionMarkIcon"></component
						><b>Varför besöka?</b> Känt för torra vita viner och pittoreska
						byar.
					</li>
					<li>
						<component :is="trainIcon"></component
						><b> Rekommenderad bas:</b> Strasbourg eller Colmar.
					</li>
					<li>
						<component :is="WineIcon"></component
						><b> Vingårdar att besöka:</b> Domaine Weinbach, Trimbach, Hugel &
						Fils.
					</li>
					<li>
						<component :is="ToDoIcon"></component><b> Att göra:</b> Utforska
						vinrutten Route des Vins d'Alsace.
					</li>
					<li>
						<component :is="restaurantIcon"></component
						><b> Mat & dryck:</b> Flammkuchen, choucroute och lokala
						Riesling-viner.
					</li>
				</ul>
				Vi inleder vår resa i Alsace, en vinregion där fransk och tysk kultur
				möts. Här dominerar vita druvor som Riesling, Gewurztraminer och Pinot
				Gris, och vinvägen Route des Vins de Alsace slingrar sig genom charmiga
				småstäder och vingårdar. Oavsett hur du tar dig runt i området, bjuder
				regionen på fantastiska vyer och spännande smaker.
				<br /><br />
				Strasbourg och Colmar är de bästa utgångspunkterna för din resa.
				Strasbourg är en livlig stad med imponerande arkitektur, medan Colmar
				bjuder på sagolika gränder och en intim atmosfär. Colmar är känd för
				sina korsvirkeshus, dess kanalområde kallat "Lilla Venedig" och känns
				som taget ur den lilla staden i "Skönheten och odjuret".

				<h4>Ta sig runt</h4>
				Både bil och cykel är möjliga färdmedel för att färdas mellan vingårdar
				och städer på landsbygden, men Alsace är en vinregion med mer kuperad
				terräng än andra. Om du väljer att cykla, se till att boka en elcykel
				för att enkelt ta dig mellan vingårdarna.
				<br /><br />Hos
				<a href="http://www.velodocteurs.com/">Colmar Cycling - Vélodocteurs</a
				>, beläget vid stadens tågstation går det att hyra cykel till ett mycket
				överkomligt pris. Enda nackdelen är att deras utbud vad gäller elcykel
				är bristande. Hos
				<a href="https://www.levelolibre.fr/">The Free Bike</a> är priserna
				dessvärre inte så gratis, men där finns å andra sidan gott om elcyklar.

				<h4>Vingårdar i Alsace</h4>
				Vi har satt ihop en resplan för en dagsutflykt genom Alsaces
				vinlandskap. Om du vill ta det lugnare kan rutten delas upp över två
				dagar.

				<MapComponent :locations="alsaceVineyards" color="blue" />
				<ol>
					<li v-for="vineyard in alsaceVineyards" :key="vineyard.id">
						<strong>{{ vineyard.name }}</strong> <br />
						{{ vineyard.text }}
					</li>
				</ol>
			</TextBlock>
			<CustomPic
				picturePath="pictures/Domaines-Famille-Picard.jpg"
				id="bourgogne"
			></CustomPic>
			<TextBlock header="Bourgogne - Pinot Noir och Chardonnay i världsklass">
				<ul class="custom-list">
					<li>
						<component :is="QuestionMarkIcon"></component
						><b>Varför besöka?</b> En av de mest ansedda vinregionerna i
						världen.
					</li>
					<li>
						<component :is="locationIcon"></component
						><b> Rekommenderad bas:</b> Dijon eller Beaune.
					</li>
					<li>
						<component :is="WineIcon"></component
						><b> Vingårdar att besöka:</b> Domaine de la Romanée-Conti, Louis
						Jadot.
					</li>
					<li>
						<component :is="ToDoIcon"></component><b> Att göra:</b> Vinprovning
						i Hospices de Beaune, cykla genom vinfälten.
					</li>
					<li>
						<component :is="restaurantIcon"></component
						><b> Mat & dryck:</b> Boeuf Bourguignon och ost som Epoisses.
					</li>
				</ul>
				Vi fortsätter resan till Bourgogne – en av världens mest prestigefyllda
				vinregioner. Här, i hjärtat av Frankrike, produceras några av de mest
				eftertraktade vinerna, där särskilt Pinot Noir och Chardonnay når sin
				fulla potential. Bourgognes vintradition sträcker sig över tusen år, och
				de små familjeägda vingårdarna i regionen arbetar ofta enligt
				hantverksmässiga metoder som förts vidare genom generationer. Landskapet
				är en mosaik av böljande vinfält, historiska kloster och charmiga byar
				där tiden verkar stå stilla. <br /><br />
				Städer lämpliga att utgå ifrån är antingen Dijon eller Beaune. Dijon,
				Bourgognes huvudstad, bjuder på en elegant blandning av historisk
				arkitektur, kulinariska upplevelser och en livlig marknad där du kan
				prova lokala delikatesser. Beaune, å andra sidan, är en mer intim stad
				känd för sitt vinhus Hôtel-Dieu och sina vinkällare, där du kan utforska
				århundraden av vinproduktion under jord. Båda städerna har goda
				förbindelser med tåg och bil, vilket gör dem till perfekta
				utgångspunkter för en vinresa genom Bourgogne.
				<h4>Ta sig runt</h4>
				Både bil och cykel är möjliga färdmedel för att färdas mellan vingårdar
				på landsbygden. Beaune och Dijon ligger längsmed floden Saône som
				slingrar sig genom landskapet. Med det sagt är landskapet rätt platt i
				förhållande till andra vindistrikt. Därtill finns det goda cykelvägar
				och du klarar dig bra på en vanlig cykel.
				<br /><br />Hos
				<a href="https://location-velo-beaune.notresphere.com/"
					>Bourgogne Randonnées location vélo électrique Beaune</a
				>, beläget mellan Beaunes stadskärna och stadens tågstation går det att
				hyra cykel till ett överkomligt pris.
				<h4>Vingårdar i Bourgogne</h4>
				Vi har satt ihop en resplan för en dagsutflykt genom Bourgognes
				vinlandskap. Om du vill ta det lugnare kan rutten delas upp över två
				dagar. Turen tar dig genom Côte d’Or, Bourgognes främsta vinregion, och
				kombinerar ikoniska vingårdar, historiska platser och utmärkta
				matupplevelser. Turen kan göras med bil eller cykel för den som vill
				njuta av de natursköna omgivningarna.
				<MapComponent :locations="bourgogneVineyards" color="blue" />
				<ol>
					<li v-for="vineyard in bourgogneVineyards" :key="vineyard.id">
						<strong>{{ vineyard.name }}</strong> <br />
						{{ vineyard.text }}
					</li>
				</ol></TextBlock
			>
			<CustomPic
				picturePath="pictures/Château-des-Fines-Roches-Chateau-neuf-de-Pape.jpg"
				id="rhone"
			></CustomPic>
			<TextBlock header="Rhône - Mustiga viner och gastronomi">
				<ul class="custom-list">
					<li>
						<component :is="QuestionMarkIcon"></component
						><b>Varför besöka?</b> Kraftfulla röda viner och fantastisk mat.
					</li>
					<li>
						<component :is="locationIcon"></component
						><b> Rekommenderad bas:</b> Châteauneuf-du-Pape.
					</li>
					<li>
						<component :is="WineIcon"></component
						><b> Vingårdar att besöka:</b> Chapoutier, Guigal.
					</li>
					<li>
						<component :is="ToDoIcon"></component><b> Att göra:</b> Vinprovning
						i området, gastronomiska upplevelser i Lyon. Besök Palais des Papes
						i Avignon.
					</li>
					<li>
						<component :is="restaurantIcon"></component><b> Mat & dryck:</b> Coq
						au Vin, Lyonnais-korvar Andouillette.
					</li>
				</ul>
				Vi fortsätter vår vinresa till Rhône, där vi rekommenderar ett besök i
				Châteauneuf-du-Pape, en av regionens mest ikoniska vinbyar. Här, bland
				solvarma vingårdar och historiska slottsruiner, produceras några av
				världens mest prestigefyllda viner.
				<h4>Ta sig runt</h4>
				Det böljande landskapet i Châteauneuf-du-Pape lämpar sig utmärkt för att
				utforskas med elcykel. Med välanlagda småvägar mellan vingårdarna kan du
				smidigt ta dig runt och njuta av de natursköna omgivningarna.
				<br /><br />
				Vi rekommenderar att bo på Domaine de Cristia, där du kan låna elcyklar
				för att enkelt ta dig mellan vingårdarna. Alternativt kan du hyra cykel
				här: <a href="https://orangebike.fr/">Orange bike</a>.
				<h4>Vingårdar i Châteauneuf-du-Pape</h4>
				Vi har satt ihop en resplan för en dagsutflykt genom
				Châteauneuf-du-Papes vinlandskap, där du får möjlighet att besöka några
				av de mest framstående vingårdarna, njuta av lokala delikatesser och
				fördjupa dig i regionens rika vintradition.
			</TextBlock>
			<MapComponent :locations="rhoneVineyards" color="blue" />
			<ol>
				<li v-for="vineyard in rhoneVineyards" :key="vineyard.id">
					<strong>{{ vineyard.name }}</strong> <br />
					{{ vineyard.text }}
				</li>
			</ol>
			<CustomPic
				picturePath="pictures/provence-lavendar-fields.jpeg"
				id="provence"
			></CustomPic>
			<TextBlock header="Provence - Roséviner och medelhavsatmosfär">
				<ul class="custom-list">
					<li>
						<component :is="QuestionMarkIcon"></component
						><b>Varför besöka?</b> Roséviner och soliga landskap.
					</li>
					<li>
						<component :is="locationIcon"></component
						><b> Rekommenderad bas:</b> Avignon eller Châteauneuf-du-Pape.
					</li>
					<li>
						<component :is="WineIcon"></component
						><b> Vingårdar att besöka:</b> Domaine Tempier, Château de
						Beaucastel.
					</li>
					<li>
						<component :is="ToDoIcon"></component><b> Att göra:</b> Besök de
						enastående lavendelfälten i Valensole.
					</li>
					<li>
						<component :is="restaurantIcon"></component
						><b> Mat & dryck:</b> Bouillabaisse, Ratatouille.
					</li>
				</ul>
				Vi fortsätter vår vinresa till Provence, en region känd för sina
				soldränkta landskap, charmiga byar och utsökta roséviner. Här möts du av
				lavendelfält, olivlundar och vingårdar som sträcker sig mot horisonten.
				<h4>Ta sig runt</h4>
				Det böljande provensalska landskapet upplevs bäst på två hjul. Med
				elcykel kan du smidigt ta dig mellan vingårdarna och njuta av den
				pittoreska naturen i lugn takt. Det finns flera cykeluthyrare i området
				där du kan hyra en passande cykel för din vistelse.
				<br /><br />
				Vi rekommenderar att bo på [Lägg till hotell eller vingård med boende],
				där du kan koppla av i en vacker miljö och få en genuin provensalsk
				upplevelse.
				<h4>Vingårdar i Provence</h4>
				Vi har satt ihop en resplan för en dagsutflykt genom Provence
				vinlandskap, där du får möjlighet att besöka några av regionens mest
				framstående vingårdar och smaka på dess eleganta viner.
				<br /><br />
				Denna rutt tar dig genom några av de mest ikoniska delarna av Provence –
				från soliga vinfält till dramatiska havsklippor, med stopp vid vingårdar
				där du kan prova de bästa lokala vinerna och njuta av regionens
				gastronomi.
				<br /><br />
				För att uppleva det bästa av Provence och dess roséviner på cykel,
				föreslår vi en rutt i området kring Lorgues och Le Thoronet. Denna
				region är känd för sina pittoreska landskap och framstående vingårdar
				inom Côtes de Provence.
			</TextBlock>
			<MapComponent :locations="provenceVineyards" color="blue" />
			<ol>
				<li v-for="vineyard in provenceVineyards" :key="vineyard.id">
					<strong>{{ vineyard.name }}</strong> <br />
					{{ vineyard.text }}
				</li>
			</ol>
			<CustomPic
				picturePath="pictures/wine-tasting-bordeaux.jpg"
				id="bordeaux"
			></CustomPic>
			<TextBlock header="Bordeaux - Röda viner i världsklass">
				<ul class="custom-list">
					<li>
						<component :is="QuestionMarkIcon"></component
						><b>Varför besöka?</b> Ikoniska viner och vacker arkitektur.
					</li>
					<li>
						<component :is="locationIcon"></component
						><b> Rekommenderad bas:</b> Bordeaux stad.
					</li>
					<li>
						<component :is="WineIcon"></component
						><b> Vingårdar att besöka:</b> Château Margaux, Château Mouton
						Rothschild.
					</li>
					<li>
						<component :is="ToDoIcon"></component><b> Att göra:</b> Vinmuseet
						Cité du Vin, utflykt till Saint-Émilion.
					</li>
					<li>
						<component :is="restaurantIcon"></component
						><b> Mat & dryck:</b> Entrecôte à la Bordelaise och canelés.
					</li>
				</ul></TextBlock
			>
			Denna rutt tar dig genom Médocs prestigefyllda vinlandskap och avslutas i
			Bordeaux stad.
			<MapComponent :locations="bordeauxVineyards" color="blue" />
			<ol>
				<li v-for="vineyard in bordeauxVineyards" :key="vineyard.id">
					<strong>{{ vineyard.name }}</strong> <br />
					{{ vineyard.text }}
				</li>
			</ol>
			<CustomPic picturePath="pictures/Saumur-Loire.jpg" id="loire"></CustomPic>
			<TextBlock header="Loire - Slottsvyer och eleganta viner">
				<ul class="custom-list">
					<li>
						<component :is="QuestionMarkIcon"></component
						><b>Varför besöka?</b> Känt för sina eleganta vita och mousserande
						viner.
					</li>
					<li>
						<component :is="locationIcon"></component
						><b> Rekommenderad bas:</b> Tours eller Chinon.
					</li>
					<li>
						<component :is="WineIcon"></component
						><b> Vingårdar att besöka:</b> Domaine Huet, Château de Chambord.
					</li>
					<li>
						<component :is="ToDoIcon"></component><b> Att göra:</b> Besök
						Loire-slotten, vinprovning i Saumur.
					</li>
					<li>
						<component :is="restaurantIcon"></component
						><b> Mat & dryck:</b> Får- och getostar, Tarte Tatin.
					</li>
				</ul>
				Denna rutt tar dig genom Loiredalens mest kända vinområden och avslutas
				med en spektakulär slottsupplevelse.
				<MapComponent :locations="loireVineyards" color="blue" />
				<ol>
					<li v-for="vineyard in loireVineyards" :key="vineyard.id">
						<strong>{{ vineyard.name }}</strong> <br />
						{{ vineyard.text }}
					</li>
				</ol></TextBlock
			>
			<CustomPic
				picturePath="pictures/champs_elysees.jpg"
				id="paris"
			></CustomPic>
			<TextBlock
				header="Paris - Kultur, gastronomi och världskända sevärdheter"
			>
				<ul class="custom-list">
					<li>
						<component :is="QuestionMarkIcon"></component>
						<b>Varför besöka?</b> Världens mest romantiska stad, fylld med
						historia, konst och gastronomi.
					</li>
					<li>
						<component :is="locationIcon"></component>
						<b> Rekommenderad bas:</b> Centrala Paris – Marais, Saint-Germain
						eller Montmartre.
					</li>
					<li>
						<component :is="WineIcon"></component>
						<b> Vingårdar att besöka:</b> Clos Montmartre (Paris), dagsutflykter
						till Champagne.
					</li>
					<li>
						<component :is="ToDoIcon"></component>
						<b> Att göra:</b> Besök Louvren och Eiffeltornet, promenera längs
						Seine, njut av en klassisk caféupplevelse.
					</li>
					<li>
						<component :is="restaurantIcon"></component>
						<b> Mat & dryck:</b> Croissant och café au lait till frukost, boeuf
						bourguignon till middag, och självklart vin.
					</li>
				</ul>
				Upptäck Paris genom dess ikoniska sevärdheter, kulinariska pärlor och
				dolda charmiga kvarter. Mer information finns att finna i vår
				<a href="/guider/paris-frankrike">Guide till Paris</a>.
				<MapComponent :locations="parisHighlights" color="blue" />
				<ol>
					<li v-for="highlight in parisHighlights" :key="highlight.id">
						<strong>{{ highlight.name }}</strong> <br />
						{{ highlight.text }}
					</li>
				</ol>
			</TextBlock>

			<CustomPic
				picturePath="pictures/champagne.jpg"
				id="champagne"
			></CustomPic>
			<TextBlock header="Champagne - Bubblande avslut på resan">
				<ul class="custom-list">
					<li>
						<component :is="QuestionMarkIcon"></component
						><b>Varför besöka?</b> Hem till världens mest berömda mousserande
						vin.
					</li>
					<li>
						<component :is="locationIcon"></component
						><b> Rekommenderad bas:</b> Reims eller Épernay.
					</li>
					<li>
						<component :is="WineIcon"></component
						><b> Vingårdar att besöka:</b> Moët & Chandon, Veuve Clicquot,
						Taittinger.
					</li>
					<li>
						<component :is="ToDoIcon"></component><b> Att göra:</b> Besök
						katedralen i Reims, promenera Avenue de Champagne.
					</li>
					<li>
						<component :is="restaurantIcon"></component
						><b> Mat & dryck:</b> Prova lokala specialiteter som Choucroute de
						Brienne & Cacasse à Cul Nu.
					</li>
				</ul>
				<p>
					Vi har nu kommit till Le Grand Finale längs denna vinresa genom
					Frankrike, nämligen Champagne. Känt för sin exklusiva dryck med toner
					av bröd, är Champagne den vinregion som längst producerat mousserande
					vin och använder sig av "Champagne-metoden" i sin tillverkning. Vi kan
					inte annat än rekommendera er att ta tid att lära känna historien,
					processen och vinet när ni väl är på besök.
				</p>
				<p>
					Champagne-distriktet är orienterat runt orterna Reims och Épernay,
					vilka båda lämpar sig att övernatta i. Vi kommer att ge ett förslag på
					visit som utgår från Épernay.
				</p>
				<p>
					Ta sig runt. Man kan antingen köra bil eller cykla mellan gårdarna.
					Det må vara vanligare att åka bil, men då kommer dessvärre inte alla
					kunna smaka på vinet vid varje vingård. Vi skulle därför rekommendera
					er att cykla. Champagne har ett böljande landskap, men vägarna går
					oftast genom dalarna och man kan därför klara sig utan elcykel. Cykel
					både med och utan el-stöd finns att hyra vid Épernay's turistbyrå. Hör
					av er på förhand och boka cyklar, så de inte riskerar att vara
					uppbokade.
				</p>
				<p>
					Vingårdarna i Champage är mycket gästvänliga. De större husen
					arrangerar strukturerade turer och provningar, men oavsett storlek är
					du välkommen in att prova vinet och prata med personalen. Ju mindre
					vingård, desto mer personligt. Vi rekommenderar att besöka något av de
					stora husen för en organsierad rundtur, men att i övrigt besöka de
					mindre gårdarna. De mindre gårdarna låter dig komma nära produktionen
					och ofta prova viner gratis. Det finns naturligtvis en förhoppning om
					att du som besökare ska köpa med dig vin, om det smakar. Av den
					anledningen är det bra att ha bil, men vi rekommenderar att spara lite
					plats i väskorna inför detta besök och säkerställa att cyklarna har
					cykelväska.
				</p>
				<h4>Vingårdar i Champagne:</h4>
				<p>
					Vi har satt samman en tur som går att genomföra under en dag, både med
					cykel och bil. Om man önskar hålla ett lugnare tempo går rutten att
					dela upp i två dagar. Vi har satt samman en resplan som tar dig genom
					hjärtat av Champagne, där du kan utforska familjeägda vingårdar, lära
					dig om champagneproduktion och njuta av lokala delikatesser. Du kan
					smidigt ta dig mellan stoppen med cykel eller bil och uppleva
					regionens charm i din egen takt. Turen går att genomföra under en dag,
					men för den som önskar hålla ett lugnare tempo går rutten naturligtvis
					att dela upp över flera dagar.
				</p>
				<MapComponent :locations="champagneVineyards" color="blue" />
				<ol>
					<li v-for="vineyard in champagneVineyards" :key="vineyard.id">
						<strong>{{ vineyard.name }}</strong> <br />
						{{ vineyard.text }}
					</li>
				</ol>
			</TextBlock>

			<TextBlock header-tag="h2" header="Sammanfattning">
				Att tågluffa genom Frankrikes vinregioner är en oförglömlig upplevelse
				som kombinerar gastronomi, kultur och natursköna miljöer. Genom att
				planera resan väl, boka provningar i förväg och njuta av varje regions
				unika atmosfär, kan du skapa en minnesvärd vinresa. Skål och trevlig
				resa!
			</TextBlock>
		</div>
	</ContentWrapper>
</template>

<script lang="ts" setup>
import { ref } from "vue"; // Ensure this import is present
import TextBlock from "../../blocks/TextBlock.vue";
import ContentWrapper from "../../generics/ContentWrapper.vue";
import PageTitle from "../../blocks/PageTitle.vue";
import CustomPic from "../../generics/CustomPic.vue";
import trainIcon from "../../../assets/SVG/trainIcon.vue";
import restaurantIcon from "../../../assets/SVG/restaurantIcon.vue";
import meta from "@/data/meta.json";
import "leaflet/dist/leaflet.css";
import DateRangeIcon from "@/assets/SVG/DateRangeIcon.vue";
import TrainTicketIcon from "@/assets/SVG/trainTicketIcon.vue";
import locationIcon from "@/assets/SVG/locationIcon.vue";
import BookingIcon from "@/assets/SVG/bookingIcon.vue";
import WineIcon from "@/assets/SVG/wineIcon.vue";
import QuestionMark from "@/assets/SVG/questionMark.vue";
import ToDoIcon from "@/assets/SVG/toDoIcon.vue";
import QuestionMarkIcon from "@/assets/SVG/questionMarkIcon.vue";
import MapComponent from "../../generics/MapComponent.vue";

const locations = ref([
	{
		id: 1,
		name: "Alsace (Strasbourg & Colmar)",
		country: "Frankrike",
		lat: 48.07582833549854,
		lon: 7.357322440626196,
		link: "#alsace",
	},
	{
		id: 2,
		name: "Bourgogne (Dijon & Beaune)",
		country: "Frankrike",
		lat: 47.322,
		lon: 5.0415,
		link: "#bourgogne",
	},
	{
		id: 3,
		name: "Rhône (Châteauneuf-du-Pape & Lyon)",
		country: "Frankrike",
		lat: 44.05600944547393,
		lon: 4.833848992296051,
		link: "#rhone",
	},
	{
		id: 4,
		name: "Provence (Toulon & Vidauban)",
		country: "Frankrike",
		lat: 43.42627607857844,
		lon: 6.430021145169462,
		link: "#provence",
	},
	{
		id: 5,
		name: "Bordeaux (Staden och Medoc, Saint-Émilion)",
		country: "Frankrike",
		lat: 44.8378,
		lon: -0.5792,
		link: "#bordeaux",
	},
	{
		id: 6,
		name: "Loire (Tours och Chinon)",
		country: "Frankrike",
		lat: 47.3941,
		lon: 0.6848,
		link: "#loire",
	},
	{
		id: 7,
		name: "Paris",
		country: "Frankrike",
		lat: 48.8566,
		lon: 2.3522,
		link: "#paris",
	},
	{
		id: 8,
		name: "Champagne (Reims & Épernay)",
		country: "Frankrike",
		lat: 49.2583,
		lon: 4.0317,
		link: "#champagne",
	},
]);

const champagneVineyards = ref([
	{
		id: 1,
		name: "Gaston Chiquet",
		town: "Dizy",
		lat: 49.06846519073113,
		lon: 3.965252758240512,
		link: "http://www.gastonchiquet.com/",
		text: "Dagen inleds med ett besök hos Gaston Chiquet, en familjeägd vingård med över åtta generationers historia. Här får du en inblick i hur de skapar sina eleganta champagner från egna odlingar, med en särskild betoning på Pinot Meunier. En provning i deras rustika källare låter dig uppleva husets karaktäristiska stil.",
	},
	{
		id: 2,
		name: "Pressoria",
		town: "Ay",
		lat: 49.05696027416394,
		lon: 3.998989500979022,
		link: "http://pressoria.com/",
		text: "Nästa stopp är Pressoria, ett interaktivt museum beläget i ett gammalt presseri i Aÿ. Här får du en sensorisk upplevelse där du kan se, lukta och smaka på champagne på ett helt nytt sätt. Utställningarna tar dig genom områdets unika terroir, druvornas resa och den fascinerande processen bakom varje bubblande flaska. Perfekt för dig som grotta ner dig i vad Champagne är.",
	},
	{
		id: 3,
		name: "Champion Roland",
		town: "Chouilly",
		lat: 49.02542991768207,
		lon: 4.013943303670642,
		link: "https://champagne-roland-champion.com/",
		text: "Därefter besöker vi Champion Roland, en charmig liten vingård där passionen för champagne genomsyrar varje steg i tillverkningen. Roland Champion och hans familj producerar utsökta Grand Cru-champagner i Chouilly, med en särskild förkärlek för Chardonnay. Här kan du prova deras mest kända cuvéer i familjens vardagsrum och få en personlig rundvisning i källaren.",
	},
	{
		id: 4,
		name: "Brasserie de Saint Martin (Lunch)",
		town: "Chouilly",
		lat: 49.025188907837155,
		lon: 4.012947929453434,
		link: null,
		text: "Dags för en välförtjänt lunch på Brasserie de Saint Martin, en mysig restaurang där du kan njuta av franska klassiker med en modern touch. Här rekommenderas särskilt deras rätter med lokala råvaror, såsom en krämig osttallrik från regionen eller en saftig entrecôte med hemlagad bearnaisesås - självklart med ett glas champagne till.",
	},
	{
		id: 5,
		name: "Champagne Pierre Gimonnet et Fils",
		town: "Cuis",
		lat: 48.99878220926288,
		lon: 3.9663940709823646,
		link: "http://www.champagne-gimonnet.com/",
		text: "Efter lunchen väntar ett besök hos Pierre Gimonnet et Fils, en ikonisk producent med ett arv av elegant, mineraldriven Blanc de Blancs. Deras vingårdar i Côte des Blancs är kända för sina kalkrika jordar som ger champagnerna en unik friskhet. Här får du en exklusiv provning där du kan jämföra olika årgångar och upptäcka vad som gör deras cuvéer så speciella.",
	},
	{
		id: 6,
		name: "Avenue de Champagne",
		town: "Épernay",
		lat: 49.042962420407015,
		lon: 3.9608095834098096,
		link: null,
		text: "Dagen avslutas längs den världsberömda Avenue de Champagne i Épernay, där några av de mest prestigefyllda champagnehusen har sina huvudkontor. Promenera eller cykla längs den historiska gatan, njut av arkitekturen och kanske avsluta med ett glas på en av de öppna terrasserna. Besök Moët & Chandon, Perrier-Jouët eller Pol Roger för en sista smak av Champagne-regionens stolthet.",
	},
]);

const alsaceVineyards = ref([
	{
		id: 1,
		name: "Domaine Weinbach",
		town: "Kaysersberg",
		lat: 48.141778,
		lon: 7.260406,
		link: "https://www.domaineweinbach.com/",
		text: "Dagen inleds med ett besök hos Domaine Weinbach, en av Alsaces mest prestigefyllda producenter. Denna biodynamiska vingård i Kaysersberg har anor från 1600-talet och producerar eleganta viner med djup och komplexitet. Här kan du prova deras hyllade Riesling och Gewurztraminer, medan du njuter av utsikten över sluttningarna.",
	},
	{
		id: 2,
		name: "Hugel & Fils",
		town: "Riquewihr",
		lat: 48.167011,
		lon: 7.296939,
		link: "https://m.hugel.com/",
		text: "Nästa stopp är den sagolika byn Riquewihr, en av de mest välbevarade längs vinvägen. Här kan du promenera genom smala gränder, besöka lokala butiker och självklart prova vin. Vi rekommenderar ett besök hos Hugel & Fils, en anrik producent känd för sina torra Rieslingviner med lång lagringspotential.",
	},
	{
		id: 3,
		name: "Ribeauvillé (Lunch)",
		town: "Ribeauvillé",
		lat: 48.195158,
		lon: 7.31745,
		link: null,
		text: "Lunch intas i den charmiga byn Ribeauvillé, där du kan njuta av klassiska Alsace-rätter. Prova en mustig choucroute garnie (surkål med korv och fläsk) eller en tarte flambée, Alsaces svar på pizza.",
	},
	{
		id: 4,
		name: "Domaine Trimbach",
		town: "Ribeauvillé",
		lat: 48.196659,
		lon: 7.314097,
		link: "https://www.trimbach.fr/",
		text: "Efter lunch besöker vi Domaine Trimbach i Ribeauvillé, en av Alsaces mest respekterade vinproducenter. Trimbach har skapat förstklassiga viner i nästan 400 år och är särskilt kända för sin ikoniska Riesling Cuvée Frédéric Emile. En provning här är ett måste för Riesling-älskaren.",
	},
	{
		id: 5,
		name: "Haut-Koenigsbourg",
		town: "Orschwiller",
		lat: 48.249286,
		lon: 7.344833,
		link: "https://www.haut-koenigsbourg.fr/",
		text: "Avsluta dagen med ett besök på det imponerande slottet Haut-Koenigsbourg, som tronar högt över vinfälten. Slottet erbjuder en fascinerande inblick i Alsaces historia och bjuder på en spektakulär utsikt över regionen.",
	},
]);
const bourgogneVineyards = ref([
	{
		id: 1,
		name: "Domaine Yves Boyer-Martenot",
		town: "Meursault",
		lat: 46.9789,
		lon: 4.7651,
		link: "http://www.boyer-martenot.com/",
		text: "En familjeägd vingård i hjärtat av Meursault, känd för sina eleganta och lagringsdugliga vita viner. Här får du en personlig vinprovning i en autentisk miljö.",
	},
	{
		id: 2,
		name: "Domaines Famille Picard",
		town: "Pommard",
		lat: 46.937023768805496,
		lon: 4.735679725255919,
		link: "http://www.famillepicard.fr/",
		text: "Domaines Famille Picard äger flera egendomar i Bourgogne och producerar både röda och vita viner av hög kvalitet. Här erbjuds guidade provningar i deras historiska källare.",
	},
	{
		id: 3,
		name: "Château de Pommard",
		town: "Pommard",
		lat: 47.0033,
		lon: 4.7925,
		link: "https://www.chateaudepommard.com/",
		text: "Dags för lunch på det berömda Château de Pommard, där du kan avnjuta lokala specialiteter i en vacker slottsmiljö. Här får du även möjlighet att prova deras biodynamiska viner och lära dig om terroiren i Côte de Beaune.",
	},
	{
		id: 4,
		name: "Domaine Bouchard Père & Fils",
		town: "Beaune",
		lat: 47.0246,
		lon: 4.8375,
		link: "https://www.bouchard-pereetfils.com/",
		text: "Efter lunch beger vi oss till Beaune och besöker en av regionens äldsta vinproducenter. Här erbjuds en exklusiv provning i deras historiska källare, där du kan smaka på både unga och lagrade Bourgogne-viner.",
	},
	{
		id: 5,
		name: "Clos de Vougeot",
		town: "Vougeot",
		lat: 47.1734,
		lon: 4.9501,
		link: "https://www.closdevougeot.fr/",
		text: "Ett historiskt stopp på din resa! Clos de Vougeot är ett imponerande kloster och vingård från medeltiden, grundat av cisterciensermunkarna. Här får du en fascinerande inblick i Bourgognes vinproduktion genom tiderna. Vingården ligger en bit bort, men åker du med ett obegränsat interrailkort rekommenderar vi att ta cykeln på tåget till och från Vougeot. Guidade turer på engelska sker 11:30 och 15:30.",
	},
	{
		id: 6,
		name: "Hôtel-Dieu (Hospices de Beaune)",
		town: "Beaune",
		lat: 47.0239,
		lon: 4.837,
		link: "https://hospices-de-beaune.com/",
		text: "Innan dagen avrundas tar vi en avstickare till Beaunes ikoniska sjukhus och museum, Hôtel-Dieu. Byggnaden är känd för sin färgglada takarkitektur och sin rika vinauktionshistoria.",
	},
	{
		id: 7,
		name: "Rue de Lorraine, Beaune",
		town: "Beaune",
		lat: 47.0222,
		lon: 4.8389,
		link: null,
		text: "Avsluta dagen med en promenad genom Beaunes charmiga gamla stad. Slå dig ner på en lokal bistro och njut av en klassisk Boeuf Bourguignon eller en osttallrik med Epoisses och Comté, ackompanjerat av ett glas elegant Pinot Noir.",
	},
]);
//chateauneufVineyards
const rhoneVineyards = ref([
	{
		id: 1,
		name: "Domaine de Cristia",
		town: "Châteauneuf-du-Pape",
		lat: 44.0809,
		lon: 4.8583,
		link: "https://www.cristia.com/",
		text: "Dagen börjar med ett besök på Domaine de Cristia, en familjeägd vingård som producerar både traditionella och moderna Châteauneuf-du-Pape-viner. Här får du prova eleganta Grenache-dominerade viner med en tydlig terroirprägel.",
	},
	{
		id: 2,
		name: "Château de Beaucastel",
		town: "Châteauneuf-du-Pape",
		lat: 44.0725,
		lon: 4.8314,
		link: "https://www.beaucastel.com/",
		text: "Ett av de mest berömda vinhusen i regionen, Château de Beaucastel, är känd för sina biodynamiskt odlade viner och sin imponerande historia. Här får du en guidad tur genom vingårdarna och en djupgående vinprovning.",
	},
	{
		id: 3,
		name: "Le Verger des Papes (Lunch)",
		town: "Châteauneuf-du-Pape",
		lat: 44.0575,
		lon: 4.831,
		link: "https://vergerdespapes.com/",
		text: "För lunch rekommenderar vi Le Verger des Papes, en restaurang med fantastisk utsikt över vingårdarna. Här kan du njuta av lokala specialiteter som lamm med örter, ratatouille och en ostbricka ackompanjerad av ett glas Châteauneuf-du-Pape.",
	},
	{
		id: 4,
		name: "Domaine de Beaurenard - Paul Coulon & Fils",
		town: "Châteauneuf-du-Pape",
		lat: 44.05359133841021,
		lon: 4.834505690722797,
		link: "http://www.beaurenard.fr/",
		text: "Efter lunch fortsätter vi till Domaine de Beaurenard, en historisk producent med rötter från 1600-talet. Här får du prova ett brett utbud av viner, inklusive deras prestigefyllda cuvéer.",
	},
	{
		id: 5,
		name: "Château Des Fines Roches",
		town: "Châteauneuf-du-Pape",
		lat: 44.050264040245146,
		lon: 4.849061295646685,
		link: "https://www.domainesmousset.com/fr/147-domaine-cave-chateauneuf-du-pape",
		text: "Vårt nästa stopp är Château Des Fines Roches, ett charmigt vinslott som erbjuder provningar i en spektakulär miljö. Deras eleganta och välbalanserade viner speglar den klassiska Châteauneuf-du-Pape-stilen.",
	},
	{
		id: 6,
		name: "Clos du Calvaire",
		town: "Châteauneuf-du-Pape",
		lat: 44.0326,
		lon: 4.8587,
		link: "https://www.clos-du-calvaire.fr/",
		text: "Dagen avslutas med en avslappnad provning hos Clos du Calvaire, en mindre familjedriven vingård där du får ett varmt välkomnande och en chans att prova autentiska och välgjorda viner.",
	},
]);
const provenceVineyards = ref([
	{
		id: 1,
		name: "Château de Saint Martin",
		town: "Taradeau",
		lat: 43.444253688416225,
		lon: 6.4330554904000685,
		link: "https://www.chateaudesaintmartin.com/",
		text: "Resan startar på Château de Saint Martin, en av de äldsta stora vingårdarna i Provence. Här kan du bo i deras charmiga gästhus och hyra cyklar för att utforska regionens vingårdar.",
	},
	{
		id: 2,
		name: "Château les Crostes",
		town: "Lorgues",
		lat: 43.44567365013211,
		lon: 6.379377030837671,
		link: "https://chateau-les-crostes.com/",
		text: "Första stoppet är Château les Crostes, en vacker vingård där du kan njuta av en guidad provning av deras eleganta roséviner.",
	},
	{
		id: 3,
		name: "Lunch på Restaurant Le Vallon",
		town: "Le Thoronet",
		lat: 43.452868993377834,
		lon: 6.300042890831295,
		link: "https://www.restaurant-levallon.com/",
		text: "Dags för en smakfull lunch på Restaurant Le Vallon, där du kan avnjuta lokala provensalska rätter i en avslappnad miljö.",
	},
	{
		id: 4,
		name: "Abbaye du Thoronet",
		town: "Le Thoronet",
		lat: 43.46044199576398,
		lon: 6.263831197856193,
		link: "https://www.lethoronet.fr/",
		text: "Innan vi återgår till vingårdarna besöker det historiska cistercienserklostret Abbaye du Thoronet, känt för sin arkitektur och akustik.",
	},
	{
		id: 5,
		name: "Château Sainte Croix",
		town: "Carcès",
		lat: 43.477354844129835,
		lon: 6.224895733818457,
		link: "https://www.chateau-sainte-croix.com/",
		text: "Dagens sista vingårdsbesök är på Château Sainte Croix, en familjeägd vingård som producerar klassiska Provence-roséviner. Efter en dag full av vinprovningar och natursköna vyer återvänder vi till Château de Saint Martin för en avkopplande kväll.",
	},
]);

/*
const provenceVineyards = ref([
{
		id: 1,
		name: "Chateau de Saint Martin",
		town: "Taradeau",
		lat: 43.4943,
		lon: 6.3534,
		link: "https://www.chateauberne.com/",
		text: "Resan startar på Château de Berne, en vingård och hotell där du kan bo bekvämt och hyra cyklar för att utforska regionens vingårdar.",
	},{
		id: 1,
		name: "Château de Berne",
		town: "Lorgues",
		lat: 43.4943,
		lon: 6.3534,
		link: "https://www.chateauberne.com/",
		text: "Resan startar på Château de Berne, en vingård och hotell där du kan bo bekvämt och hyra cyklar för att utforska regionens vingårdar.",
	},
	{
		id: 2,
		name: "Château les Crostes",
		town: "Vidauban",
		lat: 43.4255,
		lon: 6.4337,
		link: "https://www.lescrostes.com/",
		text: "Första stoppet är Château les Crostes, en vacker vingård där du kan njuta av en guidad provning av deras eleganta roséviner.",
	},
	{
		id: 3,
		name: "Abbaye du Thoronet",
		town: "Le Thoronet",
		lat: 43.4704,
		lon: 6.2291,
		link: "https://www.lethoronet.fr/",
		text: "Efter vinprovningen tar vi en paus från vingårdarna och besöker det historiska cistercienserklostret Abbaye du Thoronet, känt för sin arkitektur och akustik.",
	},
	{
		id: 4,
		name: "Lunch på Restaurant Le Vallon",
		town: "Le Thoronet",
		lat: 43.4709,
		lon: 6.2325,
		link: "https://www.restaurant-levallon.com/",
		text: "Dags för en smakfull lunch på Restaurant Le Vallon, där du kan avnjuta lokala provensalska rätter i en avslappnad miljö.",
	},
	{
		id: 5,
		name: "Château Sainte Croix",
		town: "Carcès",
		lat: 43.4742,
		lon: 6.1768,
		link: "https://www.chateau-sainte-croix.com/",
		text: "Dagens sista vingårdsbesök är på Château Sainte Croix, en familjeägd vingård som producerar klassiska Provence-roséviner. Efter en dag full av vinprovningar och natursköna vyer återvänder vi till Château de Berne för en avkopplande kväll.",
	},
]);*/
/*
const provenceVineyards = ref([
	{
		id: 1,
		name: "Domaine Tempier",
		town: "Le Castellet (Bandol)",
		lat: 43.1906,
		lon: 5.7928,
		link: "https://www.domainetempier.com/",
		text: "Nästa stopp är Domaine Tempier i Bandol, en av de mest respekterade producenterna i regionen. Här får du prova kraftfulla, lagringsdugliga Mourvèdre-viner och lära dig om den unika terroiren i Bandol.",
	},
	{
		id: 2,
		name: "Lunch i Le Castellet",
		town: "Le Castellet",
		lat: 43.1961,
		lon: 5.7808,
		link: null,
		text: "Lunch på en charmig bistro i den medeltida byn Le Castellet. Prova en klassisk Ratatouille eller en grillad lammkotlett med lokala örter.",
	},
	{
		id: 3,
		name: "Clos Sainte Magdeleine",
		town: "Cassis",
		lat: 43.2121,
		lon: 5.5316,
		link: "https://www.clossaintemagdeleine.fr/",
		text: "På eftermiddagen beger vi oss till den pittoreska kuststaden Cassis och besöker Clos Sainte Magdeleine. Denna vingård ligger spektakulärt vid havet och producerar några av Frankrikes bästa vita viner.",
	},
	{
		id: 4,
		name: "Calanques de Cassis",
		town: "Cassis",
		lat: 43.2148,
		lon: 5.502,
		link: "https://www.calanques-parcnational.fr/",
		text: "Dagen avslutas med en båttur eller vandring i de dramatiska Calanques-klipporna vid Cassis. Avnjut en aperitif vid vattnet innan du återvänder till din utgångspunkt.",
	},
]);*/
const bordeauxVineyards = ref([
	{
		id: 1,
		name: "Château Margaux",
		town: "Margaux",
		lat: 45.0413,
		lon: -0.6769,
		link: "https://www.chateau-margaux.com/",
		text: "Dagen inleds med ett besök på det legendariska Château Margaux, en av de mest prestigefyllda producenterna i Bordeaux. Här får du en guidad tur genom de historiska vinkällarna och en exklusiv provning av deras utsökta viner.",
	},
	{
		id: 2,
		name: "Château Pichon Baron",
		town: "Pauillac",
		lat: 45.1866,
		lon: -0.7462,
		link: "https://www.pichonbaron.com/",
		text: "Nästa stopp är Château Pichon Baron, ett sagolikt slott i Pauillac. Vinerna här är djupa och strukturerade, med klassisk Bordeaux-karaktär. En provning här ger en inblick i regionens terroir och vinmakningsfilosofi.",
	},
	{
		id: 3,
		name: "Lunch i Pauillac",
		town: "Pauillac",
		lat: 45.192,
		lon: -0.7511,
		link: null,
		text: "Lunch på en charmig restaurang vid Gironde-floden. Här rekommenderas en klassisk Entrecôte à la Bordelaise, serverad med en kraftig röd Bordeaux.",
	},
	{
		id: 4,
		name: "Château Smith Haut Lafitte",
		town: "Pessac-Léognan",
		lat: 44.7276,
		lon: -0.5959,
		link: "https://www.smith-haut-lafitte.com/",
		text: "Efter lunch beger vi oss söderut till Château Smith Haut Lafitte, en ikonisk producent känd för sina eleganta röda och vita viner. Gården erbjuder även en konstupplevelse med sina skulpturer utspridda i vingårdarna.",
	},
	{
		id: 5,
		name: "Cité du Vin",
		town: "Bordeaux",
		lat: 44.8571,
		lon: -0.5505,
		link: "https://www.laciteduvin.com/",
		text: "Dagen avslutas i Bordeaux stad med ett besök på Cité du Vin, ett interaktivt vinmuseum där du kan fördjupa dig i vinets värld. Avsluta kvällen med ett glas Bordeaux vid floden.",
	},
]);
const loireVineyards = ref([
	{
		id: 1,
		name: "Domaine Huet",
		town: "Vouvray",
		lat: 47.4133,
		lon: 0.7861,
		link: "https://www.domainehuet.com/",
		text: "Dagen inleds i Vouvray med ett besök hos Domaine Huet, en ikonisk producent känd för sina biodynamiska Chenin Blanc-viner. Här får du en djupgående provning av allt från torra till söta och mousserande viner.",
	},
	{
		id: 2,
		name: "Château de Minière",
		town: "Bourgueil",
		lat: 47.2776,
		lon: 0.1782,
		link: "https://www.chateaudeminiere.com/",
		text: "Nästa stopp är Château de Minière i Bourgueil, där Cabernet Franc är kungen. Slottet kombinerar tradition med hållbar vinproduktion och erbjuder en provning i en autentisk slottsmiljö.",
	},
	{
		id: 3,
		name: "Lunch i Saumur",
		town: "Saumur",
		lat: 47.2597,
		lon: -0.0781,
		link: null,
		text: "Lunch i Saumur vid Loirefloden. Prova en klassisk Rillettes de Tours, en lokal paté som passar perfekt med en lätt och fruktig Saumur-Champigny.",
	},
	{
		id: 4,
		name: "Bouvet-Ladubay",
		town: "Saumur",
		lat: 47.2651,
		lon: -0.0806,
		link: "https://www.bouvet-ladubay.fr/",
		text: "Efter lunch beger vi oss till Bouvet-Ladubay, en av Loiredalens mest kända mousserande vinproducenter. Deras källare sträcker sig över flera kilometer och erbjuder en imponerande guidad tur med provning av deras Crémant de Loire.",
	},
	{
		id: 5,
		name: "Château de Chenonceau",
		town: "Chenonceaux",
		lat: 47.3241,
		lon: 1.0703,
		link: "https://www.chenonceau.com/",
		text: "Dagen avslutas med ett besök på Château de Chenonceau, ett av Loiredalens mest ikoniska slott. Här kan du promenera genom dess eleganta trädgårdar och njuta av en sista smak av Loiredalens magi.",
	},
]);
const parisHighlights = ref([
	{
		id: 1,
		name: "Eiffeltornet",
		lat: 48.8588443,
		lon: 2.2943506,
		link: "https://www.toureiffel.paris/en",
		text: "Inget besök i Paris är komplett utan ett stopp vid Eiffeltornet. Detta ikoniska monument erbjuder en fantastisk utsikt över staden och är en symbol för Paris.",
	},
	{
		id: 2,
		name: "Musée d'Orsay",
		lat: 48.86,
		lon: 2.325,
		link: "https://www.musee-orsay.fr/en",
		text: "Musée d'Orsay är hem till en av världens bästa samlingar av impressionistisk och post-impressionistisk konst, med verk av Monet, Van Gogh och Degas.",
	},
	{
		id: 3,
		name: "Louvre",
		lat: 48.8606111,
		lon: 2.337644,
		link: "https://www.louvre.fr/en",
		text: "Världens största och mest besökta museum. Hem till oersättliga konstverk som Mona Lisa och Venus de Milo.",
	},
	{
		id: 4,
		name: "Notre-Dame de Paris",
		lat: 48.8529682,
		lon: 2.3499021,
		link: "https://www.notredamedeparis.fr/en/",
		text: "Den gotiska katedralen är ett måste för alla som vill uppleva både religiös historia och fantastisk arkitektur.",
	},
	{
		id: 5,
		name: "Montmartre & Sacré-Cœur",
		lat: 48.8867,
		lon: 2.3431,
		link: "https://www.sacre-coeur-montmartre.com/en/",
		text: "Montmartre är en historisk och konstnärlig stadsdel med små gator och charmiga caféer. Den berömda Basilikan Sacré-Cœur ger en fantastisk vy över Paris.",
	},
	{
		id: 6,
		name: "Champs-Élysées och Triumfbågen",
		lat: 48.8738,
		lon: 2.295,
		link: "https://www.parisinfo.com/museum-monument/71444/Arc-de-Triomphe",
		text: "Promenera längs den berömda avenyn Champs-Élysées och avsluta vid den majestätiska Triumfbågen, ett symboliskt monument för Frankrike.",
	},
]);
</script>

<style scoped>
.card_text_and_image_wrapper {
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
}

.email {
	border: 1px solid lightgrey;
	padding: 2rem 1rem;
}
</style>
