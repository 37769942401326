<template>
  <CustomModal :show="show" :un-removeable="true" enter-from="center">
    <template v-slot:header> Cookies </template>
    <template v-slot:body>
      <div class="modal-body" v-if="state.cookieInfoShow">
        <div>
          För att tagluffaieuriopa.se ska fungera behövs nödvändiga cookies. Vi
          använder också andra cookies för att anpassa och förbättra din
          upplevelse.
        </div>
        <div class="button-wrapper">
          <CustomButton :grow="true" variant="outlined" @onClick="handleSettingsClick()">
            Inställningar
          </CustomButton>
          <CustomButton :grow="true" @onClick="handleAcceptCookies()">
            Godkänn alla cookies
          </CustomButton>
        </div>
      </div>
      <div class="modal-body" v-if="state.inCookieSettingsView">
        Cookies är små filer med information som lagras i webbläsaren för att
        förbättra webbplatsen och din upplevelse.
        <div class="read-more-link-button" @click="handleReadMore()">
          Läs mer om cookies
        </div>
        <div class="cookie-category">
          <div class="cookie-category-header-wrapper">
            <h2>Nödvändiga cookies</h2>
            <div class="cookie-switch-text">Alltid aktiv</div>
          </div>
          <div>
            Behövs för att du ska kunna surfa på tagluffaieuropa.se ger oss
            anonym statistik så att vi kan förbättra webbplatsen.
          </div>
        </div>

        <div class="cookie-category">
          <div class="cookie-category-header-wrapper">
            <h2>Personalisering</h2>
            <SwitchCheckbox
              @switched="state.personalAccept = !state.personalAccept"
            ></SwitchCheckbox>
          </div>
          <div>
            Sparar dina inställningar och preferenser för att underlätta
            kommande besök.
          </div>
        </div>
        <div class="cookie-category">
          <div class="cookie-category-header-wrapper">
            <h2>Marknadsföring</h2>
            <SwitchCheckbox
              @switched="state.marketingAccept = !state.marketingAccept"
            ></SwitchCheckbox>
          </div>
          <div>
            Ger dig personliga erbjudanden på tagluffaieuropa.se och hos våra
            sammarbetspartner.
          </div>
        </div>
        <CustomButton @onClick="handleAcceptCookies">
          Spara mina val
        </CustomButton>
      </div>
      <div class="modal-body" v-if="state.detailInfoShow">
        <div>
          Enligt lag om elektronisk information ska besökare på en webbplats i
          integritetssyfte få information om att cookies används. Informationen
          i cookien är möjlig att använda för att följa en användares surfande.
          Cookie är en liten textfil som webbplatsen du besöker begär att få
          spara på din dator för att ge tillgång till olika funktioner. Det går
          att ställa in sin webbläsare så att den automatiskt nekar cookies. Mer
          information kan man hitta på Post och telestyrelsens hemsida.
          <a
            href="https://www.pts.se/sv/privat/internet/integritet/kakor-cookies/"
            target="_blank"
            >www.pts.se/sv/privat/internet/integritet/kakor-cookies/</a
          >
          <br />
          <br />
          Cookies kan antingen vara förstapartscookies eller tredjepartscookies.
          Förstapartscookies lagras av webbplatsen som du besöker medan
          tredjepartscookies lagras av annan part/webbplats än den som du
          besöker. Tredjepartscookies används exempelvis ofta av annonsnätverk
          för att identifiera en viss användare och använda information om hur
          användaren konsumerat innehåll på de olika webbplatserna samt visa
          (anpassade) marknadsföringsbudskap på de webbplatser som är med i
          nätverket.
          <br />
          <br />
          <br />
          <br />
          <b>Hur Tågluffa i Europa använder cookies</b>
          <br />
          <br />
          Tågluffa i Europa använder kakor för att du ska kunna använda Tågluffa
          i Europas webbplats på det sätt vi avsett, och för att kunna ge dig
          som besökare en bättre användarupplevelse. Vissa kakor krävs för att
          Tågluffa i Europas webbplats och våra tjänster ska fungera. Vi
          använder även marknadsförings- och annonskakor för att kunna leverera
          anpassat innehåll och funktioner baserat på användarval, samt för att
          kunna rikta anpassad marknadsföring till dig på andra webbsidor och
          sociala medier.
          <br />
          <br />
          <br />
          <br />
          <b>Tredjepartscookies</b>
          <br />
          <br />
          Tredjepartscookies används för att få statistik om antalet användare
          och detaljer om deras geografi och intressen för att vi ska kunna
          anpassa innehåll och annonsering därefter.
          <br />
          <br />
          <br />
          <br />
          <b>Google Analytics (trafikmätning)</b>
          <br />
          <br />
          Vi använder Google Analytics-cookies för att mäta din användning av
          webbplatsen. Dessa mätningar används för att generera statistik om
          användningen av webbplatsen och för att identifiera problem med
          webbplatsen så att vi kan förbättra din användarupplevelse. Varje
          cookie innehåller ett slumpmässigt genererat ID som används för att
          känna igen din webbläsare när du får tillgång till en webbplats som
          använder Google Analytics. Cookien innehåller ingen personlig
          information och används enbart för webbanalys.
          <br />
          <br />
          Läs mer här:
          <br />
          <a href="https://policies.google.com/privacy?hl=sv/" target="_blank"
            >https://policies.google.com/privacy?hl=sv</a
          >
          <br />
          <br />
          Du kan säga nej till Google Analytics-cookies här:
          <br />
          <a href="http://tools.google.com/dlpage/gaoptout" target="_blank"
            >http://tools.google.com/dlpage/gaoptout</a
          >
        </div>
        <CustomButton variant="outlined" @onClick="handleReturnToConfig">
          Tillbaka
        </CustomButton>
      </div>
    </template>
  </CustomModal>
</template>

<script lang="ts" setup>
import { defineProps, reactive, defineEmits } from "vue";
import CustomModal from "@/components/generics/CustomModal.vue";
import CustomButton from "@/components/generics/CustomButton.vue";
import SwitchCheckbox from "@/components/generics/SwitchCheckbox.vue";
import { useTrackingStore } from "@/store/trackingStore";

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["close"]);

const state = reactive({
  cookieInfoShow: true,
  inCookieSettingsView: false,
  detailInfoShow: false,
  personalAccept: true,
  marketingAccept: true,
});

const trackingStore = useTrackingStore();

function handleAcceptCookies() {
  trackingStore.setCookiesAcceptedCookie(
    state.marketingAccept,
    state.personalAccept
  );
  trackingStore.initializeTracking("consent", "default", {
    ad_storage: state.marketingAccept ? "granted" : "denied",
    analytics_storage: state.personalAccept ? "granted" : "denied",
  });
  emit("close");
}

function handleReadMore() {
  state.cookieInfoShow = false;
  state.detailInfoShow = true;
  state.inCookieSettingsView = false;
}

function handleSettingsClick() {
  state.cookieInfoShow = false;
  state.inCookieSettingsView = true;
  state.detailInfoShow = false;
  state.personalAccept = false;
  state.marketingAccept = false;
}

function handleReturnToConfig() {
  state.cookieInfoShow = false;
  state.inCookieSettingsView = true;
  state.detailInfoShow = false;
}
</script>

<style lang="scss">
/* @import "../../styles/globals.scss"; */

.modal-body {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  .read-more-link-button {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
    flex-wrap: wrap;
    row-gap: 1rem;
  }
  .cookie-category {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    .cookie-switch-text {
      color: #ff8a65;
      font-weight: bold;
    }
    .cookie-category-header-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>
