<template>
  <ContentWrapper>
    <div class="summary-section">
      <SectionHeader><h2>Dina produkter</h2></SectionHeader>
      <CustomTable :table-items="ticket_obj"></CustomTable>
    </div>
    <div class="summary-section">
      <SectionHeader><h2>Dina uppgifter</h2></SectionHeader>
      <CustomerInputSummaryBlock></CustomerInputSummaryBlock>
    </div>
    <div class="summary-section">
      <SectionHeader><h2>Betalning</h2></SectionHeader>
      <CustomerInputSwishBlock></CustomerInputSwishBlock>
      <TermsAndConditionsCheckbox></TermsAndConditionsCheckbox>
    </div>
    <div class="summary-section">
      <div class="button-and-price-wrapper">
        <div>Totalbelopp inkl. angiven moms</div>
        <div class="price">{{ bookingStore.summarize_price() }} kr</div>
        <div v-if="state.error != ''" class="error-text">{{ state.error }}</div>
        <CustomButton
          class="button"
          @click="handleBuyButtonClick"
          :disabled="!customer_info_valid()"
        >
          Köp biljetter
        </CustomButton>
        <SwishLogo class="swishlogo"></SwishLogo>
      </div>
    </div>
  </ContentWrapper>
  <CustomModal
    :show="state.showModal"
    :full-height="false"
    :un-removeable="true"
  >
    <template v-slot:header> Bokning utförs </template>
    <template v-slot:body>
      <div class="booking-modal-content">
        <div class="icons">
          <SwishLogo />
        </div>
        <div v-if="bookingStore.order.bookStatus == ''">
          Validerar din bokning.
        </div>
        <div v-if="bookingStore.order.bookStatus == 'swish_pending'">
          Inväntar din swishbetalning.
        </div>
        <div v-if="bookingStore.order.bookStatus == 'swish_paid'">
          Swish betald. Utför bokning. Var god stäng inte detta fönster.
        </div>
        <CustomLoader />
      </div>
    </template>
  </CustomModal>
</template>

<script lang="ts" setup>
import CustomTable from "@/components/generics/CustomTable.vue";
import SectionHeader from "@/components/generics/SectionHeader.vue";
import CustomerInputSummaryBlock from "@/components/blocks/CustomerInputSummaryBlock.vue";
import CustomerInputSwishBlock from "@/components/blocks/CustomerInputSwishBlock.vue";
import TermsAndConditionsCheckbox from "@/components/blocks/TermsAndConditionsCheckbox.vue";
import CustomButton from "@/components/generics/CustomButton.vue";
import SwishLogo from "@/assets/SVG/swishLogo.vue";
import ContentWrapper from "@/components/generics/ContentWrapper.vue";
import { order, useBookingStore } from "@/store/bookingStore";
import { useTrackingStore } from "@/store/trackingStore";
import CustomModal from "@/components/generics/CustomModal.vue";
import CustomLoader from "@/components/generics/CustomLoader.vue";
import tagluffaieuropa_logo from "@/assets/SVG/tagluffaieuropa_logo.vue";
import { onMounted, reactive } from "vue";
import { router } from "@/router/router";
import ticket from "@/types/ticket";

const bookingStore = useBookingStore();
const trackingStore = useTrackingStore();

const state = reactive({
  showModal: false,
  error: "",
});

onMounted(async () => {
  let urlParams = new URLSearchParams(window.location.search);
  const bookingId = urlParams.get("bookingId");
  console.log(bookingId);
  if (bookingId) {
    state.showModal = true;
    bookingStore.shoppingCart = [] as ticket[];
    bookingStore.order = {} as order;
    bookingStore.order.shoppingCartId = bookingId;
    var result = await bookingStore.other_half_of_checkout(
      bookingStore.shoppingCart,
      bookingStore.order
    );
    console.log(result);
    if (result == true) {
      router.push("/confirmation");
    } else {
      router.push("/");
    }
  }
});

var ticket_obj = bookingStore.shoppingCart.map((ticket) => ({
  Pass: ticket.title,
  Resenär: ticket.age,
  Komfort: ticket.comfort,
  Pris: ticket.price,
}));

function customer_info_valid() {
  if (!bookingStore.order.firstname) {
    return false;
  }
  if (!bookingStore.order.surname) {
    return false;
  }
  if (!bookingStore.order.adress) {
    return false;
  }
  if (!bookingStore.order.city) {
    return false;
  }
  if (!bookingStore.order.postalNumber) {
    return false;
  }
  if (!bookingStore.order.phoneNumber) {
    return false;
  }
  if (!bookingStore.order.email) {
    return false;
  }
  if (!bookingStore.order.swishFirstname) {
    return false;
  }
  if (!bookingStore.order.swishSurname) {
    return false;
  }
  if (!bookingStore.order.swishPhoneNumber) {
    return false;
  }
  if (!bookingStore.order.acceptTermsAndConditions) {
    return false;
  }
  if (!bookingStore.validateTicketsInCart(bookingStore.shoppingCart)) {
    return false;
  }
  return true;
}

async function handleBuyButtonClick() {
  state.showModal = true;
  trackingStore.trackEvent("initate_purchase");
  const res = await bookingStore.bookEurail(
    bookingStore.shoppingCart,
    bookingStore.order
  );

  if (res == true) {
    router.push("/confirmation");
  } else {
    console.log("Error handling");
    state.showModal = false;
    bookingStore.order.bookStatus = "";
    state.error = "Något har gått fel. Var god försök igen.";
  }
}
</script>

<style scoped>
.summary-section {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.error-text {
  color: red;
}

.button-and-price-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
  .swishlogo {
    width: 8rem;
    height: 8rem;
  }

  .price {
    font-size: 1.25rem;
    font-weight: 700;
  }

  .button {
    width: 12rem;
  }
}

.booking-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 2rem;
  font-size: large;
  font-weight: bold;
  height: 100%;
  padding-bottom: 2rem;
  .icons {
    padding-top: 4rem;
    width: 12rem;
  }
}
</style>
