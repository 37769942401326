<template>
  <div class="timeline-wrapper">
    <div class="timeline">
      <template
        v-for="(path, index) in bookingStore.ticketBuyPaths"
        :key="`path-${path.path}-${index}-dot`"
      >
        <div v-if="index != 0" class="line"></div>
        <div
          v-if="!path.valid"
          :class="[
            'dot',
            path.path == bookingStore.currentBuyPath?.path
              ? 'active'
              : 'disabled',
          ]"
        ></div>
        <div
          v-if="path.valid"
          :class="[
            'checkbow-wrapper',
            path.path == bookingStore.currentBuyPath?.path
              ? 'active'
              : 'disabled',
          ]"
        >
          <checkIcon></checkIcon>
        </div>
        <!--      <div>{{ path.title }}</div> -->
      </template>
    </div>
    <div class="timeline">
      <template
        v-for="(path, index) in bookingStore.ticketBuyPaths"
        :key="`path-${path.path}-${index + 1000}-dot`"
      >
        <div class="step-name">{{ path.title }}</div>
      </template>
      <div class="mobile-step-name">
        {{ bookingStore.currentBuyPath?.title }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useBookingStore } from "@/store/bookingStore";
import checkIcon from "@/assets/SVG/checkIcon.vue";

const bookingStore = useBookingStore();
</script>

<style scoped lang="scss">
@import "./../../styles/variables.scss";

.timeline-wrapper {
  padding-top: 6rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  justify-content: center;
  align-items: center;
  width: calc(100% - 6rem);
  padding-left: 3rem;
  padding-right: 3rem;
}

.timeline {
  max-width: $screen-size-tablet;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 0.5rem;
  align-items: center;
}

.step-name {
  width: 0px;
  display: flex;
  justify-content: center;
}

.line {
  border-bottom: 1px solid $primary-color;
  height: 1px;
  width: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
}

.step {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  align-items: center;
  row-gap: 1rem;
  width: 100%;
}

.dot {
  height: 0.5rem;
  width: 0.5rem;
  min-width: 0.5rem;
  min-height: 0.5rem;
  border-radius: 50%;
}

.checkbow-wrapper {
  width: 0;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  svg {
    position: absolute;
    fill: $primary-color;
  }
}
.active {
  background-color: $primary-color;
}

.disabled {
  background-color: lightgray;
  color: lightgray;
}

.mobile-step-name {
  display: none;
  width: 100%;
  text-align: center;
}

@media (max-width: $screen-size-mobile) {
  .step-name {
    display: none;
  }

  .mobile-step-name {
    display: inline;
  }
}
</style>
