<template>
  <div class="card">
    <CustomCard>
      <div class="card-content">
        <CustomButton
          :icon-button="true"
          variant="secondary"
          class="about-button"
          @click="state.info_open = true"
        >
          <InfoIcon />
        </CustomButton>
        <div
          @click="handleClick"
          :class="[
            'card-header',
            isOpen ? 'card-header-open' : '',
            backgroundImgClass,
          ]"
        ></div>

        <Transition name="fade">
          <div v-if="isOpen" :class="['card-footer']">
            <div class="card-header-title">{{ ticket?.title }}</div>

            <div class="ticket-price">
              <div class="card-footer-pricefrom">PRIS FRÅN:</div>
              <div class="card-footer-price">
                {{ get_lowest_price() }} KR
                <div v-if="ticket.promotion" class="promo-msg">
                  <div class="promo-text">15% rabatt</div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <Transition name="fade">
          <div v-if="!isOpen" :class="['card-footer']">
            <div class="card-header-title">{{ ticket?.title }}</div>
            <div class="buy-ticket-wrapper">
              <CustomSelect
                label="Åldersgrupp"
                :initialySelected="
                  state.current_ticket_age == null
                    ? ''
                    : age_obj.find((el) => el.value == state.current_ticket_age)
                        ?.name
                "
                :options="age_obj"
                @selected="handleSelectedAge"
              ></CustomSelect>
              <CustomSelect
                label="Komfort"
                :initialySelected="
                  state.current_ticket_comfort == null
                    ? ''
                    : comfort_obj.find(
                        (el) => el.value == state.current_ticket_comfort
                      )?.name
                "
                :options="comfort_obj"
                @selected="handleSelectedComfort"
              ></CustomSelect>
              <div class="price-and-button-wrapper">
                <div>{{ state.current_exact_price }} kr</div>
                <CustomButton
                  @onClick="addTicketToCart"
                  :disabled="
                    state.current_ticket_age == null ||
                    state.current_ticket_comfort == null
                  "
                >
                  Lägg i korg
                </CustomButton>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </CustomCard>
  </div>
  <CustomModal :show="state.info_open" @onClose="state.info_open = false">
    <template v-slot:header>
      {{ ticket.title }}
    </template>
    <template v-slot:body>
      {{ descriptions[ticket?.title as String] }}
    </template>
  </CustomModal>
</template>

<script lang="ts" setup>
import { reactive, defineProps, PropType, defineEmits } from "vue";
import CustomCard from "../generics/CustomCard.vue";
import CustomSelect from "../generics/CustomSelect.vue";
import CustomButton from "../generics/CustomButton.vue";
import { useBookingStore } from "@/store/bookingStore";
import QuestionMark from "@/assets/SVG/questionMark.vue";
import CustomModal from "../generics/CustomModal.vue";
import InfoIcon from "@/assets/SVG/InfoIcon.vue";

const bookingStore = useBookingStore();

const props = defineProps({
  ticket: {
    type: Object as PropType<{
      id: string;
      title: string;
      price_object: { age: string; comfort: string; price: 0; sku: string }[];
      promotion: boolean;
    }>,
    required: true,
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
  backgroundImgClass: {
    type: String,
    default: "background-backpack",
  },
});

const emits = defineEmits(["clicked"]);

const state = reactive({
  current_exact_price: 0,
  current_ticket_age: null,
  current_ticket_comfort: null,
  info_open: false,
});

const age_obj = [
  { value: "CHD", name: "Barn 0-11 år" },
  { value: "YTH", name: "Ungdom 12-27 år" },
  { value: "ADT", name: "Vuxen 28-59 år" },
  { value: "SEN", name: "Senior 65+ år" },
];

const comfort_obj = [
  { value: "first", name: "Första klass" },
  { value: "second", name: "Andra klass" },
];

const daysInMonthText = (travelDays: number, traveltime: string) =>
  `Denna biljett ger dig tillgång till ${travelDays} resdagar att använda
      under ${traveltime}s tid. Under en resdag kan du åka med hur många tåg du vill
      och resedagarna går att aktivera när du vill under ${traveltime} från ditt
      första resedatum. \n Perfekt när du vill besöka ${
        travelDays - 2
      } - ${travelDays} destinationer.`;

const daysConsecutiveText = (travelDays: string) =>
  `Denna biljett ger dig tillgång till ${travelDays} att använda i följd från första resedatum. Du kan åka med hur många tåg du vill under dessa dagar. \n Perfekt när du vill resa mycket under en kortare tid eller bara ha full frihet i ditt resande.`;

const activationText = () =>
  "Passet aktiveras i RailPlanner appen. Kunddetaljer anges i nästa steg. Ha en trevlig resa!";

const descriptions = {
  "4 dagar på 1 månad": daysInMonthText(4, "1 månad") + activationText(),
  "5 dagar på 1 månad": daysInMonthText(5, "1 månad") + activationText(),
  "7 dagar på 1 månad": daysInMonthText(7, "1 månad") + activationText(),
  "10 dagar på 2 månader": daysInMonthText(10, "2 månader") + activationText(),
  "15 dagar på 2 månader": daysInMonthText(15, "2 månader") + activationText(),
  "15 dagar i följd": daysConsecutiveText("15 resdagar") + activationText(),
  "22 dagar i följd": daysConsecutiveText("22 resdagar") + activationText(),
  "1 månad i följd":
    daysConsecutiveText("1 månads resdagar") + activationText(),
  "2 månad i följd":
    daysConsecutiveText("2 månader resdagar") + activationText(),
  "3 månad i följd":
    daysConsecutiveText("3 månader resdagar") + activationText(),
};

function handleClick() {
  emits("clicked", props.ticket.id);
}

function get_lowest_price() {
  const with_no_zeros = props.ticket.price_object.filter(
    (obj) => obj.price != 0
  );
  const lowest = with_no_zeros.reduce(function (prev, curr) {
    return prev.price < curr.price ? prev : curr;
  });
  return lowest.price;
}

function handleSelectedAge(age: any) {
  state.current_ticket_age = age.value;
  recalculate_price();
}

function handleSelectedComfort(comfort: any) {
  state.current_ticket_comfort = comfort.value;
  recalculate_price();
}

function recalculate_price() {
  if (!state.current_ticket_age && !state.current_ticket_comfort) {
    return;
  } else {
    const current_price_obj = props.ticket.price_object.find(
      (el) =>
        el.age == state.current_ticket_age &&
        el.comfort == state.current_ticket_comfort
    );

    if (current_price_obj) {
      state.current_exact_price = current_price_obj.price;
    }
  }
}

function addTicketToCart() {
  if (
    state.current_ticket_age == null &&
    state.current_ticket_comfort == null
  ) {
    return;
  }
  emits("clicked", "");

  let price_obj_for_ticket = props.ticket.price_object.find(
    (el) =>
      el.age == state.current_ticket_age &&
      el.comfort == state.current_ticket_comfort
  );

  bookingStore.add_to_shopping_cart({
    id: props.ticket.id,
    title: props.ticket.title,
    type: "ticket",
    sku: String(price_obj_for_ticket?.sku),
    VAT: price_obj_for_ticket?.vat,
    firstName: "",
    lastName: "",
    age: state.current_ticket_age ? state.current_ticket_age : "",
    comfort: state.current_ticket_comfort ? state.current_ticket_comfort : "",
    country: "",
    dateOfBirth: "",
    price: String(state.current_exact_price),
    refundable: true,
  });
}
</script>

<style scoped lang="scss">
.card-content {
  display: flex;
  position: relative;
  width: 100%;
  height: 20rem;
  cursor: pointer;
}

.about-button {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}

.title {
  position: absolute;
}

.card-header {
  width: 100%;
  height: 100%;
  background-position: bottom;

  background-size: auto 400px;
}

.background-florence {
  background-image: url("../../assets/pictures/florence.jpg");
}

.background-london {
  background-image: url("../../assets/pictures/london.jpeg");
}

.background-prague {
  background-image: url("../../assets/pictures/prague.jpeg");
}

.background-barcelona {
  background-image: url("../../assets/pictures/barcelona.jpeg");
}

.background-louvre {
  background-image: url("../../assets/pictures/le-louvre.jpeg");
}

.background-eifel {
  background-image: url("../../assets/pictures/eiffeltornet-paris-frankrike.jpeg");
}

.background-railway-italy {
  background-image: url("../../assets/pictures/railway-italy.jpeg");
}

.background-champs {
  background-image: url("../../assets/pictures/champs_elysees.jpg");
}

.background-vernazza {
  background-image: url("../../assets/pictures/vernazza-italien.jpg");
}

.background-aqueduct {
  background-image: url("../../assets/pictures/aqueduct.jpeg");
}

.card-footer {
  transition: max-height 0.2s;
  position: absolute;
  bottom: 0;
  background-color: white;
  width: 100%;
}

.card-header-title {
  margin: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
}

.buy-ticket-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding: 1rem;
}

.ticket-price {
  padding: 1rem;
  .card-footer-pricefrom {
    font-size: 0.75rem;
  }
  .card-footer-price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.5rem;
  }
}

.promo-msg {
  display: flex;
  font-size: 1rem;
  .promo-text {
    padding: 0.5rem;
    border-radius: 4px;
    font-weight: bold;
    background-color: #ff8a65;
    color: white;
  }
}

.card-footer-1 {
  max-height: 15rem;
}

.card-footer-1-open {
  max-height: 0rem;
}

.card-footer-2 {
  max-height: 0rem;
}

.card-footer-2-open {
  max-height: 15rem;
}

.price-and-button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease, max-height 0.3s ease;
  overflow: hidden;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  max-height: 0;
}
</style>
