<template>
  <div
    :class="[
      'input-frame',
      state.input != '' || inputType == 'date' ? 'input-present' : '',
    ]"
    v-on:click.stop="forwardClick"
  >
    <label class="label" :for="label">{{ label }}</label>
    <input
      ref="input_field"
      :name="label"
      :id="label"
      class="input"
      v-model="state.input"
      @input="$emit('onInput', state.input)"
      :type="inputType"
    />
  </div>
</template>

<script lang="ts" setup>
import { reactive, ref, defineProps } from "vue";

const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    required: true,
  },
  initValue: {
    type: String,
    default: "",
  },
  inputType: {
    type: String,
    default: "text",
  },
});

const state = reactive({
  input: props.initValue,
});

const input_field = ref<HTMLInputElement | null>();

const forwardClick = () => {
  if (input_field.value) {
    input_field.value.focus(); // Focus the input element when parent div is clicked
  }
};

</script>

<style scoped lang="scss">
@import "./../../styles/variables.scss";
.input-frame {
  position: relative;
  background-color: whitesmoke;
  border: none;
  padding: 0.5rem;
  padding-top: 1.5rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 2px solid lightgray;
  cursor: text;
  .input {
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 1rem;
    padding-left: 0.25rem;
    margin-right: 1rem;
    color: black;
    width: calc(100% - 0.25rem);
  }
  .label {
    position: absolute;
    font-size: 1rem;
    top: 1.2rem;
    left: 1rem;
    color: grey;
    transition: all 0.1s ease-in-out;
  }
}

.input-frame:hover {
  background-color: whitesmoke;
}

.input-frame:focus-within {
  border-bottom: 2px solid $primary-color;
  .label {
    position: absolute;
    font-size: 0.75rem;
    top: 0.5rem;
    left: 0.75rem;
  }
}

.input-present {
  .label {
    position: absolute;
    font-size: 0.75rem;
    top: 0.5rem;
    left: 0.75rem;
  }
}
</style>
