<template>
	<div class="checkbox">
		<input
			type="checkbox"
			id="termsAndCond"
			name="termsAndCond"
			v-model="bookingStore.order.acceptTermsAndConditions"
		/>
		<label>
			Jag har läst och godkänner webbplatsens
			<a @click="handleLinkClick" class="termslink">villkor</a>
		</label>
	</div>
	<CustomModal
		:show="state.showModal"
		:fullHeight="false"
		@onClose="state.showModal = false"
		><template v-slot:header> Allmänna villkor </template>
		<template v-slot:body>
			<TermsAndConditions></TermsAndConditions>
		</template>
	</CustomModal>
</template>

<script lang="ts" setup>
import { reactive } from "vue";
import CustomModal from "../generics/CustomModal.vue";
import TermsAndConditions from "@/components/blocks/TermsAndConditions.vue";
import { useBookingStore } from "@/store/bookingStore";

const bookingStore = useBookingStore();

const state = reactive({
	showModal: false,
});

function handleLinkClick() {
	state.showModal = true;
}
</script>

<style scoped>
.checkbox {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 0.5rem;
}

.termslink {
	cursor: pointer;
}

.termslink:hover {
	text-decoration: underline;
}

input[type="checkbox"] {
	/* Add if not using autoprefixer */
	-webkit-appearance: none;
	/* Remove most all native input styles */
	appearance: none;
	/* For iOS < 15 */
	background-color: white;
	/* Not removed via appearance */
	margin: 0;

	font: inherit;
	color: currentColor;
	width: 1.15em;
	height: 1.15em;
	border: 0.15em solid currentColor;
	border-radius: 0.15em;
	transform: translateY(-0.075em);

	display: grid;
	place-content: center;
}

input[type="checkbox"]::before {
	content: "";
	width: 0.65em;
	height: 0.65em;
	clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
	transform: scale(0);
	transform-origin: bottom left;
	box-shadow: inset 1em 1em black;
	/* Windows High Contrast Mode */
	background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
	transform: scale(1);
}

input[type="checkbox"]:focus {
	/*   outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em); */
}

input[type="checkbox"]:disabled {
	--form-control-color: var(--form-control-disabled);

	color: var(--form-control-disabled);
	cursor: not-allowed;
}
</style>
