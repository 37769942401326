<template>
	<div class="contentWrapper">
		<div
			:class="[`background-image`, backgroundImageClass]"
			:style="`filter: opacity(${state.backgroundFadePercentage});`"
		></div>
		<div class="content" ref="mainContent">
			<slot></slot>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, reactive, defineProps } from "vue";

defineProps({
	backgroundImageClass: {
		type: String,
		default: () => "",
	},
});

const state = reactive({
	backgroundFadePercentage: 1,
});

function fadeBackgroundBasedOnScroll() {
	let fadeAmount = 1.0 - window.scrollY / (window.innerHeight * 0.5);

	if (fadeAmount <= 0.5) {
		fadeAmount = 0.5;
	}
	state.backgroundFadePercentage = fadeAmount;
}

onMounted(() => {
	window.addEventListener("scroll", () => fadeBackgroundBasedOnScroll());
});

onUnmounted(() => {
	window.removeEventListener("scroll", () => fadeBackgroundBasedOnScroll());
});
</script>

<style scoped lang="scss">
@import "./../../styles/variables.scss";

.contentWrapper {
	padding-top: 5rem;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	min-height: calc(100vh - 5rem);
}

.background-image {
	top: 5rem;
	position: fixed;
	min-height: 100%;
	width: 100vw;
	z-index: -1;
	background-position: center;
}

.background-trainbridge {
	background-image: url("../../assets/pictures/trainbridge.jpeg");
}

.background-platform {
	background-image: url("../../assets/pictures/interrail_card_platform.jpeg");
}

.background-railway-italy {
	background-image: url("../../assets/pictures/railway-italy.jpeg");
}

.background-map {
	background-image: url("../../assets/pictures/map_detail_view.jpg");
}

.background-louvre {
	background-image: url("../../assets/pictures/le-louvre.jpeg");
}

.background-paris {
	background-image: url("../../assets/pictures/paris-eiffeltower.jpg");
}

.background-lavender {
	background-image: url("../../assets/pictures/provence-lavendar-fields.jpeg");
}

.background-image:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.content {
	max-width: $screen-size-tablet;
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
	padding-left: 1rem;
	padding-right: 1rem;
	margin-bottom: 10rem;
	width: calc(100% - 2rem);
	z-index: 10;
}

@media (max-width: $screen-size-tablet) {
	.content {
		row-gap: 2rem;
	}
}
</style>
