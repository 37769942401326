<template>
  <SiteHeader
    :links="[
      { linkText: 'Interrailkort', linkTo: '/interrailkort' },
      { linkText: 'Guider', linkTo: '/guider' },
      { linkText: 'Support', linkTo: '/support' },
    ]"
  >
    <template v-slot:left>
      <!-- <CustomPic :picturePath="'SVG/logo.svg'" height="4rem" /> -->
      <tagluffaieuropa_logo />
    </template>
    <template v-slot:logo-mobile>
      <!-- <CustomPic :picturePath="'SVG/logo.svg'" height="4rem" /> -->
      <tagluffaieuropa_logo />
    </template>
  </SiteHeader>
  <router-view></router-view>
  <SiteFooter v-if="state.showFooter" />
  <!--   <CustomModal
    :show="state.showCookieModal"
    :un-removeable="true"
    enter-from="center"
  >
    <template v-slot:header> Vi använder cookies. </template>
    <template v-slot:body>
      <div>
        >För att tagluffaieuriopa.se ska fungera behövs nödvändiga cookies. Vi
        använder också andra cookies för att anpassa och förbättra din
        upplevelse.
        <CustomButton>Inställningar</CustomButton>
        <CustomButton>Godkänn alla cookies</CustomButton>
      </div>
    </template>
  </CustomModal> -->
  <CookieModal
    :show="state.showCookieModal"
    @close="state.showCookieModal = false"
  ></CookieModal>
</template>

<script lang="ts" setup>
import SiteHeader from "@/components/blocks/SiteHeader.vue";
import tagluffaieuropa_logo from "./assets/SVG/tagluffaieuropa_logo.vue";
import { useTrackingStore } from "./store/trackingStore";
import SiteFooter from "@/components/blocks/SiteFooter.vue";
import CustomModal from "@/components/generics/CustomModal.vue";
import { onMounted, reactive, watch } from "vue";
import { useRoute } from "vue-router";
import CustomButton from "./components/generics/CustomButton.vue";
import CookieModal from "./components/blocks/CookieModal.vue";

const trackingStore = useTrackingStore();
const route = useRoute();

const hide_footer_on = [
  "/biljetter/biljettval",
  "/biljetter/resenarer",
  "/biljetter/summary",
];

const state = reactive({
  showFooter: true,
  showCookieModal: false,
});
trackingStore.userId = trackingStore.getCookiesAccept().userId;

onMounted(() => {
  if (trackingStore.userId != "") {
    var cookies = trackingStore.getCookiesAccept();
    trackingStore.initializeTracking("consent", "default", {
      ad_storage: cookies.adAcceptCookie ? "granted" : "denied",
      analytics_storage: cookies.analyticsCookie ? "granted" : "denied",
    });
  } else {
    state.showCookieModal = true;
  }

  console.log(process.env);
});

watch(
  () => route.params,
  () => {
    if (hide_footer_on.includes(route.path)) {
      state.showFooter = false;
    } else {
      state.showFooter = true;
    }
  }
);
</script>

<style lang="scss">
@import "./styles/globals.scss";
</style>
