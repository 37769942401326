<template>
	<teleport to="head">
		<component :is="'script'" type="application/ld+json">
			{{ meta.beachGuide }}
		</component>
	</teleport>
	<PageTitle
		text="De bästa stränderna i Europa som du kan nå med tåg"
		backgroundImageClass="background-sveti-stefan"
	></PageTitle>
	<ContentWrapper>
		<TextBlock
			header-tag="h2"
			header="
            Till alla er som inte vet något bättre än en heldag på stranden. Om det så innebär att bada, hoppa från klippor, surfa, sola, sippa på en drink eller ligga under parasollet med en bok i handen. Här är vår top 10 guide!"
		>
		</TextBlock>
		<div>
			<l-map
				:zoom="3.5"
				:center="[56.0, 10.0]"
				style="height: 700px; width: 100%; border-radius: 12px"
			>
				<!-- Replace the tile layer with Carto's light-themed tiles -->
				<l-tile-layer
					url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
					attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OSM</a> &copy; <a href='https://carto.com/'>CARTO</a>"
				/>
				<!--<l-tile-layer
						url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
						attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
					/>
				<l-marker
					v-for="beach in beaches"
					:key="beach.id"
					:lat-lng="[beach.lat, beach.lon]"
					:icon="createNumberedIcon(beach.id)"
				>
				-->
				<l-marker
					v-for="beach in beaches"
					:key="beach.id"
					:lat-lng="[beach.lat, beach.lon]"
				>
					<l-popup>
						<strong>{{ beach.id }}. {{ beach.name }}</strong
						><br />
						{{ beach.country }}<br />
						<a :href="beach.link" @click.prevent="scrollToSection(beach.link)"
							>Mer info</a
						>
					</l-popup>
				</l-marker>
			</l-map>
		</div>
		<div class="card_text_and_image_wrapper">
			<TextBlock header="Ta tåget till Europas bästa stränder">
				Gå igenom vår lista av de allra bästa stränderna i Europa som du
				dessutom kan nå enkelt med tåget. Oavsett om det är klippor, sand, kallt
				eller varmt; dessa får du inte missa!
			</TextBlock>
			<CustomPic
				picturePath="pictures/tropea-italy.jpg"
				id="tropea"
			></CustomPic>
			<TextBlock header="1. Tropea Beach, Italien">
				<ul class="custom-list">
					<li><component :is="flagIcon"></component><b>Land:</b> Italien</li>
					<li>
						<component :is="trainIcon"></component
						><b>Närmaste tågstation:</b> Tropea
					</li>
					<li>
						<component :is="waterIcon"></component
						><b>Vattenkvalitet:</b> Kristallklart
					</li>
					<li>
						<component :is="landscapeIcon"></component
						><b>Strandtyp:</b> Sandstrand med dramatiska klippformationer
					</li>
					<li>
						<component :is="restaurantIcon"></component
						><b>Mat och dryck:</b> Restauranger och kaféer i närheten
					</li>
					<li>
						<component :is="starIcon"></component><b>Känd för:</b> Sin
						fantastiska utsikt och charmiga kuststad som ger en kombinationen av
						historiska byggnader och enastående naturlig skönhet.
					</li>
				</ul>
			</TextBlock>

			<CustomPic
				picturePath="pictures/praia-da-falesia-portugal.jpg"
				id="falesia"
			></CustomPic>
			<TextBlock header="2. Praia da Falésia, Portugal">
				<ul class="custom-list">
					<li><component :is="flagIcon"></component><b> Land: </b>Portugal</li>
					<li>
						<component :is="trainIcon"></component>
						<b>Närmaste tågstation:</b> Loule
					</li>
					<li>
						<component :is="waterIcon"></component> <b>Vattenkvalitet:</b>
						Kristallklart
					</li>
					<li>
						<component :is="landscapeIcon"></component> <b>Strandtyp:</b> Lång
						sandstrand omgiven av röda klippor
					</li>
					<li>
						<component :is="restaurantIcon"></component> <b>Mat och dryck:</b>
						Restauranger, caféer och barer i närheten
					</li>
					<li>
						<component :is="starIcon"></component> <b>Känd för:</b> Sin
						spektakulära natur och fantastiska solnedgångar. Den dramatiska
						kontrasten mellan de röda klipporna och det turkosa vattnet.
					</li>
				</ul>
			</TextBlock>

			<CustomPic
				picturePath="pictures/sveti-stefan.jpg"
				id="sveti-stefan"
			></CustomPic>
			<TextBlock header="3. Sveti Stefan, Montenegro">
				<ul class="custom-list">
					<li>
						<component :is="flagIcon"></component><b> Land: </b>Montenegro
					</li>
					<li>
						<component :is="trainIcon"></component>
						<b>Närmaste tågstation:</b> Bar (därefter 30 min buss eller taxi)
					</li>
					<li>
						<component :is="waterIcon"></component> <b>Vattenkvalitet:</b>
						Kristallklart
					</li>
					<li>
						<component :is="landscapeIcon"></component>
						<b>Strandtyp:</b> Småsten och sand
					</li>
					<li>
						<component :is="restaurantIcon"></component>
						<b>Mat och dryck:</b> Lyxiga restauranger och kaféer
					</li>
					<li>
						<component :is="starIcon"></component> <b>Känd för:</b> Lyxig resort
						och otrolig skönhet. En av de mest exklusiva stranddestinationerna i
						Europa.
					</li>
				</ul>
			</TextBlock>
			<CustomPic
				picturePath="pictures/porquerolles.jpg"
				id="porquerolles"
			></CustomPic>
			<TextBlock header="4. Île de Porquerolles, Frankrike ">
				<ul class="custom-list">
					<li>
						<component :is="flagIcon"></component><b> Land: </b> Frankrike
					</li>
					<li>
						<component :is="trainIcon"></component
						><b>Närmaste tågstation:</b> Toulon (därefter färja)
					</li>
					<li>
						<component :is="waterIcon"></component
						><b>Vattenkvalitet:</b> Kristallklart
					</li>
					<li>
						<component :is="landscapeIcon"></component
						><b>Strandtyp:</b> Sandstränder med medelhavskänsla
					</li>
					<li>
						<component :is="restaurantIcon"></component
						><b>Mat och dryck:</b> Restauranger i byn
					</li>
					<li>
						<component :is="starIcon"></component><b>Känd för:</b> Sin orörda
						natur och bilfria miljö. En perfekt tillflyktsort med några av
						Frankrikes vackraste stränder.
					</li>
				</ul>
			</TextBlock>
			<CustomPic
				picturePath="pictures/nazare-portugal.jpg"
				id="nazare"
			></CustomPic>
			<TextBlock header="5. Praia de Nazare, Portugal">
				<ul class="custom-list">
					<li><component :is="flagIcon"></component><b> Land: </b> Portugal</li>
					<li>
						<component :is="trainIcon"></component
						><b>Närmaste tågstation:</b> Valado - Nazare - Alcobaca
					</li>
					<li>
						<component :is="waterIcon"></component><b>Vattenkvalitet:</b> Klart
						men med kraftiga vågor
					</li>
					<li>
						<component :is="landscapeIcon"></component><b>Strandtyp:</b> Lång
						sandstrand
					</li>
					<li>
						<component :is="restaurantIcon"></component
						><b>Mat och dryck:</b> Flera restauranger och fiskbarer
					</li>
					<li>
						<component :is="starIcon"></component><b>Känd för:</b> Jättevågor
						och surfing. Hem till några av världens största surfvågor.
					</li>
				</ul>
			</TextBlock>
			<CustomPic
				picturePath="pictures/barafundleBay.jpg"
				id="barafundle"
			></CustomPic>
			<TextBlock header="6. Barafundle Bay, Storbritannien">
				<ul class="custom-list">
					<li>
						<component :is="flagIcon"></component><b> Land: </b> Storbritannien
					</li>
					<li>
						<component :is="trainIcon"></component
						><b>Närmaste tågstation:</b> Lamphey
					</li>
					<li>
						<component :is="waterIcon"></component><b>Vattenkvalitet:</b> Mycket
						klart
					</li>
					<li>
						<component :is="landscapeIcon"></component
						><b>Strandtyp:</b> Sandstrand omgiven av klippor
					</li>
					<li>
						<component :is="restaurantIcon"></component
						><b>Mat och dryck:</b> Inga faciliteter, ta med egen matsäck
					</li>
					<li>
						<component :is="starIcon"></component><b>Känd för:</b> Att vara en
						av Storbritanniens vackraste stränder. Avskild och naturskön med
						gyllene sand och klart vatten.
					</li>
				</ul>
			</TextBlock>
			<CustomPic
				picturePath="pictures/hauklandstranda.jpg"
				id="hauklandstranda"
			></CustomPic>
			<TextBlock header="7. Hauklandstranda, Norge">
				<ul class="custom-list">
					<li><component :is="flagIcon"></component><b> Land: </b> Norge</li>
					<li>
						Närmaste tågstation: Bodø (därefter färja och buss till Lofoten)
					</li>
					<li>
						<component :is="waterIcon"></component
						><b>Vattenkvalitet:</b> Kristallklart och kallt
					</li>
					<li>
						<component :is="landscapeIcon"></component><b>Strandtyp:</b> Vit
						sandstrand med berg i bakgrunden
					</li>
					<li>
						<component :is="restaurantIcon"></component
						><b>Mat och dryck:</b> Begränsat utbud, bäst att ta med egen matsäck
					</li>
					<li>
						<component :is="starIcon"></component><b>Känd för:</b> Sin
						dramatiska skönhet och vildmarkskänsla. En av Nordens vackraste
						stränder, perfekt för naturälskare.
					</li>
				</ul>
			</TextBlock>
			<CustomPic
				picturePath="pictures/praia-da-arrifana.jpg"
				id="arrifana-clerigo"
			></CustomPic>
			<TextBlock
				header="8. Praia da Arrifana & Praia de Monte Clérigo, Portugal"
			>
				<ul class="custom-list">
					<li><component :is="flagIcon"></component><b> Land: </b> Portugal</li>
					<li>
						<component :is="trainIcon"></component
						><b>Närmaste tågstation:</b> Lagos (därefter buss i 1 timme)
					</li>
					<li>
						<component :is="waterIcon"></component><b>Vattenkvalitet:</b> Mycket
						klart, rent och friskt
					</li>
					<li>
						<component :is="landscapeIcon"></component
						><b>Strandtyp:</b> Sandstrand med höga klippor
					</li>
					<li>
						<component :is="restaurantIcon"></component
						><b>Mat och dryck:</b> Restauranger i närheten
					</li>
					<li>
						<component :is="starIcon"></component><b>Känd för:</b> Fantastisk
						surf och naturskönhet. En av de vackraste surfstränderna i Portugal
						med en perfekt blandning av avkoppling och äventyr.
					</li>
				</ul>
			</TextBlock>
			<CustomPic
				picturePath="pictures/zlatni-rat.jpg"
				id="zlatni-rat"
			></CustomPic>
			<TextBlock header="9. Zlatni Rat, Kroatien">
				<ul class="custom-list">
					<li><component :is="flagIcon"></component><b> Land: </b> Kroatien</li>
					<li>
						Närmaste tågstation: Split (därefter färja till Brač och buss till
						Bol)
					</li>
					<li>
						<component :is="waterIcon"></component
						><b>Vattenkvalitet:</b> Kristallklart
					</li>
					<li>
						<component :is="landscapeIcon"></component><b>Strandtyp:</b> Små
						vita stenar
					</li>
					<li>
						<component :is="restaurantIcon"></component
						><b>Mat och dryck:</b> Flera strandbarer och restauranger
					</li>
					<li>
						Känd för: Sin unika spetsform som förändras beroende på strömmarna.
						En av Europas mest ikoniska stränder med otroligt vackert vatten och
						en perfekt plats för vindsurfing.
					</li>
				</ul>
			</TextBlock>
			<CustomPic picturePath="pictures/biarritz.jpg" id="biarritz"></CustomPic>
			<TextBlock header="10. Biarritz Grande Plage, Frankrike">
				<ul class="custom-list">
					<li>
						<component :is="flagIcon"></component><b> Land: </b> Frankrike
					</li>
					<li>
						<component :is="trainIcon"></component
						><b>Närmaste tågstation:</b> Biarritz
					</li>
					<li>
						<component :is="waterIcon"></component><b>Vattenkvalitet:</b> Rent
						men ofta med starka vågor
					</li>
					<li>
						<component :is="landscapeIcon"></component><b>Strandtyp:</b> Gyllene
						sand
					</li>
					<li>
						Mat och dryck: Gott om restauranger och kaféer längs
						strandpromenaden
					</li>
					<li>
						<component :is="starIcon"></component><b>Känd för:</b> Surfing och
						en elegant atmosfär. Kombinationen av surfkultur och fransk lyx gör
						denna strand unik.
					</li>
				</ul>
			</TextBlock>
			<CustomPic
				picturePath="pictures/galebove-stijene-croatia.jpg"
				id="kamenjak"
			></CustomPic>
			<TextBlock header="11. Galebove stijene / Rt Kamenjak, Kroatien">
				<ul class="custom-list">
					<li><component :is="flagIcon"></component><b> Land: </b> Kroatien</li>
					<li>
						<component :is="trainIcon"></component
						><b>Närmaste tågstation:</b> Pula (därefter buss eller cykel)
					</li>
					<li>
						<component :is="waterIcon"></component
						><b>Vattenkvalitet:</b> Kristallklart och turkost
					</li>
					<li>
						<component :is="landscapeIcon"></component><b>Strandtyp:</b> Klippor
						och små vikar
					</li>
					<li>
						<component :is="restaurantIcon"></component
						><b>Mat och dryck:</b> Strandbarer och enklare restauranger
					</li>
					<li>
						<component :is="starIcon"></component><b>Känd för:</b> Snorkling,
						klippdykning och vild natur. Oslagbart klart vatten och avskildhet i
						en vacker nationalpark.
					</li>
				</ul>
			</TextBlock>
			<TextBlock header="Ta tåget till Europas stränder">
				Med Interrail kan du enkelt nå dessa stränder och uppleva några av
				Europas vackraste kustlinjer utan att behöva flyga. Oavsett om du söker
				avkoppling, surfvågor eller unika naturupplevelser, finns det en strand
				för dig på denna lista!
			</TextBlock>
		</div>
	</ContentWrapper>
</template>

<script lang="ts" setup>
import { ref, defineComponent } from "vue"; // Ensure this import is present
import TextBlock from "../../blocks/TextBlock.vue";
import ContentWrapper from "../../generics/ContentWrapper.vue";
import PageTitle from "../../blocks/PageTitle.vue";
import CustomPic from "../../generics/CustomPic.vue";
import flagIcon from "../../../assets/SVG/flagIcon.vue";
import trainIcon from "../../../assets/SVG/trainIcon.vue";
import waterIcon from "../../../assets/SVG/waterIcon.vue";
import landscapeIcon from "../../../assets/SVG/landscapeIcon.vue";
import starIcon from "../../../assets/SVG/starIcon.vue";
import restaurantIcon from "../../../assets/SVG/restaurantIcon.vue";
import meta from "@/data/meta.json";
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker, LPopup } from "@vue-leaflet/vue-leaflet";
import L from "leaflet"; // Import Leaflet for custom icons

defineComponent({
	components: {
		LMap,
		LTileLayer,
		LMarker,
		LPopup,
	},
});

const beaches = ref([
	{
		id: 1,
		name: "Tropea Beach",
		country: "Italien",
		lat: 38.6762,
		lon: 15.8984,
		link: "#tropea",
	},
	{
		id: 2,
		name: "Praia da Falésia",
		country: "Portugal",
		lat: 37.0891,
		lon: -8.1698,
		link: "#falesia",
	},
	{
		id: 3,
		name: "Sveti Stefan",
		country: "Montenegro",
		lat: 42.2569,
		lon: 18.896,
		link: "#sveti-stefan",
	},
	{
		id: 4,
		name: "Île de Porquerolles",
		country: "Frankrike",
		lat: 43.0036,
		lon: 6.2148,
		link: "#porquerolles",
	},
	{
		id: 5,
		name: "Praia da Nazaré",
		country: "Portugal",
		lat: 39.6022,
		lon: -9.0703,
		link: "#nazare",
	},
	{
		id: 6,
		name: "Barafundle Bay",
		country: "Storbritannien",
		lat: 51.6271,
		lon: -4.9123,
		link: "#barafundle",
	},
	{
		id: 7,
		name: "Hauklandstranda",
		country: "Norge",
		lat: 68.1532,
		lon: 13.6017,
		link: "#hauklandstranda",
	},
	{
		id: 8,
		name: "Praia da Arrifana & Praia de Monte Clérigo",
		country: "Portugal",
		lat: 37.292089,
		lon: -8.864528,
		link: "#arrifana-clerigo",
	},
	{
		id: 9,
		name: "Zlatni Rat",
		country: "Kroatien",
		lat: 43.256508,
		lon: 16.633956,
		link: "#zlatni-rat",
	},
	{
		id: 10,
		name: "Biarritz",
		country: "Frankrike",
		lat: 43.487559,
		lon: -1.556521,
		link: "#biarrritz",
	},
	{
		id: 11,
		name: "Galebove stijene & Rt Kamenjak",
		country: "Kroatien",
		lat: 44.861023,
		lon: 13.805323,
		link: "#kamenjak",
	},
]);

// Function to create a custom numbered marker
const createNumberedIcon = (number: number) => {
	return L.divIcon({
		className: "custom-icon",
		html: `<div class="marker-icon">${number}</div>`,
		iconSize: [30, 30], // Adjust size as needed
		iconAnchor: [15, 30], // Center the icon
	});
};

const scrollToSection = (id: string) => {
	const section = document.querySelector(id);
	if (section) {
		section.scrollIntoView({ behavior: "smooth" });
	}
};
</script>

<style scoped>
.card_text_and_image_wrapper {
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
}

.email {
	border: 1px solid lightgrey;
	padding: 2rem 1rem;
}
</style>
