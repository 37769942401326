<template>
  <teleport to="head">
    <component :is="'script'" type="application/ld+json">
      {{ meta.homepage }}
    </component>
  </teleport>
  <ContentWrapper backgroundImageClass="background-lavender">
    <PageHeader text="Ditt äventyr som tågluffare börjar här!"
      ><template v-slot:main-action>
        <div class="promo-text">15% rabatt på alla biljetter</div>
        <CustomButton :grow="true"
          ><div class="main-book-button-content" @click="handleBookClick">
            Boka biljett
          </div></CustomButton
        >
      </template>
    </PageHeader>
    <CustomCard>
      <TextBlock
        class="usp-card"
        leadingIcon="SVG/explore.svg"
        header="UPPTÄCK"
        content="Ge dig iväg på ett äventyr du sent lär glömma. Med ett interrailkort kan du nå 31 länder i Europa och resa obegränsat från 5 dagar upp till en hel månad. Som hjälp har du också alla våra guider som kommer se till att äventyret går problem- och bekymmersfritt."
        :fit="true"
      >
      </TextBlock>
    </CustomCard>
    <CustomCard>
      <TextBlock
        class="usp-card"
        leadingIcon="SVG/lock.svg"
        header="SÄKERT"
        content="Vi är Sveriges äldsta tågluffarplattform och har sedan 2009 arbetat för att förbättra resenärers tågluffarupplevelser. Vi sätter resenärernas intressen först, förbereder för att minimera risken för oväntade problem och är dessutom SSL-certifierade för att garantera säker handel i vår webshop. Med oss kan du känna dig trygga."
        :fit="true"
      >
      </TextBlock>
    </CustomCard>
    <CustomCard>
      <TextBlock
        class="usp-card"
        leadingIcon="SVG/swishLogo.svg"
        header="ENKELT"
        content="Det är inte bara resan som ska vara enkel, vägen dit också. Därför har vi försökt göra det så enkelt som möjligt för dig att köpa dina tågluffarkort, exempelvis genom att inte göra betalningen besvärligare än att swisha."
        :fit="true"
      >
      </TextBlock>
    </CustomCard>
  </ContentWrapper>
</template>

<script lang="ts" setup>
import TextBlock from "@/components/blocks/TextBlock.vue";
import ContentWrapper from "@/components/generics/ContentWrapper.vue";
import CustomCard from "../generics/CustomCard.vue";
import PageHeader from "../blocks/PageTitle.vue";
import meta from "@/data/meta.json";
import CustomButton from "../generics/CustomButton.vue";
import { router } from "@/router/router";

function handleBookClick() {
  router.push({ name: "cardbuy" });
}
</script>

<style scoped>
.usp-card {
  margin: 2rem 2rem;
}

.promo-text {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  background-color: #006064;
  border-radius: 8px;
  margin: 1rem;
  width: 100%;
  max-width: 768px;
  text-align: center;
}

/* .main-book-button-content {
  font-size: 1.5rem;
  font-weight: bold;
} */
</style>
