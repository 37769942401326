<template>
  <ContentWrapper>
    <div class="summary">
      <div class="summary-text-wrapper">
        <div><b>Din order med ordernummer:</b></div>
        <div class="code">{{ bookingStore.order.eurailOrderNumber }}</div>
        <div><b>har skapats.</b></div>
      </div>
      <CustomTable :table-items="orderinfo"></CustomTable>
      <CustomTable :table-items="ticketinfo"></CustomTable>
    </div>
  </ContentWrapper>
</template>

<script lang="ts" setup>
import ContentWrapper from "@/components/generics/ContentWrapper.vue";
import { useBookingStore } from "@/store/bookingStore";
import CustomTable from "@/components/generics/CustomTable.vue";
import ticket from "@/types/ticket";

const bookingStore = useBookingStore();

var orderinfo = [
  {
    Namn: bookingStore.order.firstname + " " + bookingStore.order.surname,
    Email: bookingStore.order.email,
    Telefon: bookingStore.order.phoneNumber,
    Adress: `${bookingStore.order.adress},${bookingStore.order.postalNumber}, ${bookingStore.order.city}`,
  },
];

var ticketinfo = [
  ...bookingStore.shoppingCart.map((ticket: ticket) => {
    return {
      Pass: ticket.type,
      Komfort: `${ticket.comfort}, ${ticket.age}`,
      Resenär: `${ticket.firstName} ${ticket.lastName}`,
      Aktiveringskod: ticket.mobileActivationCode,
    };
  }),
];
</script>

<style scoped lang="scss">
.summary {
  margin-top: 1rem;
  margin-bottom: 1rem;
  .summary-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5rem;
    .code {
      font-weight: bold;
      font-size: 2rem;
    }
  }
}
</style>
