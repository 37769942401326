<template>
	<teleport to="head">
		<component :is="'script'" type="application/ld+json">
			{{ meta.bringOnTrip }}
		</component>
	</teleport>
	<PageTitle
		text="Packning"
		backgroundImageClass="background-backpack"
	></PageTitle>
	<ContentWrapper>
		<TextBlock
			header-tag="h2"
			header="
            Något utav de besvärligare momenteten inför en resa är packningen där man behöver bestämma sig för vad som ska med och vad som skall lämnas hemma. Man vill å ena sidan inte packa för mycket, men å andra sidan inte heller skära ner på nödvändigheter. I denna guide hjälper vi dig med din packning."
		>
		</TextBlock>
		<TextBlock
			header="Vad ska man packa i?"
			content="Något som är väldigt svårt att veta innan man genomfört en resa är vad man ska packa i. Vad det står mellan är väl resväska, eller ryggsäck. Sedan kommer man dessutom till frågeställningen om hur stor denna skall vara. Att svara på dessa frågor är inte enkelt och svaret varierar naturligtvis beroende på resa, men även mellan olika personer. För att göra det enkelt skiljer vi olika resor åt beroende på terräng, resemängd och reselängd."
		>
		</TextBlock>
		<TextBlock header="Vad ska man ta med?">
			Packningen beror förstås mycket på hur länge du planerar vara borta. Det
			är en stor en stor skillnad på en vecka och en månad, men vi anser inte
			att du bör packa mera kläder för det. Kläder väger mycket och tar dessutom
			stor plats. Istället bör du packa kläder för en vecka och ta med
			tvättmedel för en månad. En mycket bra lösning.
			<br />
			<br />
			Plastpåsar får du inte glömma. De är jättelätta, små och kan vara hur
			nödvändiga som helst. Planerar du att utforska naturen med din packning,
			eller om regnet öser ner är det riktigt bra att ha plockat ner all
			packning i plastpåsar inuti väskan för att minimera risken att packningen
			blir blöt. Dessutom håller packningen sig flytande om den skulle tappas i
			vatten och kan det vara praktiskt att förvara smutstvätt i en plastpåse.
		</TextBlock>
		<div class="packingwiz-wrapper">
			<div class="input-rows">
				<h2>Bagageguide</h2>
				<div class="category-radiobuttons">
					<div class="title">Terräng - {{ state.terrain }}</div>
					<div class="radiobuttons_3">
						<div class="radiobutton">
							<input
								type="radio"
								id="city"
								v-model="state.terrain"
								value="storstad"
							/>
							<label for="city">storstad</label>
						</div>
						<div class="radiobutton">
							<input
								type="radio"
								id="mixed"
								v-model="state.terrain"
								value="mix"
							/>
							<label for="mixed">Mixat</label>
						</div>
						<div class="radiobutton">
							<input
								type="radio"
								id="countryside"
								v-model="state.terrain"
								value="landsbygd"
							/>
							<label for="countryside">Landsbygd</label>
						</div>
					</div>
				</div>

				<div class="category-radiobuttons">
					<div class="title">Reslängd - {{ state.travelLength }}</div>
					<div class="radiobuttons_3">
						<div class="radiobutton">
							<input
								type="radio"
								id="city"
								v-model="state.travelLength"
								value="1"
							/>
							<label for="city">Mindre än 1 vecka</label>
						</div>
						<div class="radiobutton">
							<input
								type="radio"
								id="mixed"
								v-model="state.travelLength"
								value="2"
							/>
							<label for="mixed">1 - 3 veckor</label>
						</div>
						<div class="radiobutton">
							<input
								type="radio"
								id="countryside"
								v-model="state.travelLength"
								value="3"
							/>
							<label for="countryside">Mer än 3 veckor</label>
						</div>
					</div>
				</div>

				<div class="category-radiobuttons">
					<div class="title">Resmängd - {{ state.stopDuration }}</div>
					<div class="radiobuttons_2">
						<div class="radiobutton">
							<input
								type="radio"
								id="city"
								v-model="state.stopDuration"
								value="short"
							/>
							<label for="city">Mycket resande, korta stopp</label>
						</div>
						<div class="radiobutton">
							<input
								type="radio"
								id="mixed"
								v-model="state.stopDuration"
								value="long"
							/>
							<label for="mixed">Lite resande, långa stopp</label>
						</div>
					</div>
				</div>
			</div>

			<div class="wizard-list-grid">
				<div
					v-for="(category, index) in state.PackingObject"
					:key="`${index}-${category.title}`"
				>
					<div class="category-title">
						<component :is="packingBase[index].icon"></component
						><b>{{ category.title }}</b>
					</div>
					<ul class="itemlist">
						<li
							v-for="(item, index2) in category.items"
							:key="`${index}-${index2}-${item.item}`"
						>
							{{ item.item }}
						</li>
					</ul>
				</div>
			</div>
		</div>
	</ContentWrapper>
</template>

<script lang="ts" setup>
import TextBlock from "../../blocks/TextBlock.vue";
import ContentWrapper from "../../generics/ContentWrapper.vue";
import PageTitle from "../../blocks/PageTitle.vue";
import DocumentIcon from "../../../assets/SVG/documentIcon.vue";
import SoapIcon from "../../../assets/SVG/soapIcon.vue";
import CardsIcon from "../../../assets/SVG/cardsIcon.vue";
import TechIcon from "../../../assets/SVG/TechIcon.vue";
import ClothesIcon from "../../../assets/SVG/ClothesIcon.vue";
import EtcIcon from "../../../assets/SVG/EtcIcon.vue";
import meta from "@/data/meta.json";
import { reactive, watch } from "vue";

const packingBase = [
	{
		title: "Resehandlingar",
		icon: DocumentIcon,
		items: [
			{
				item: "Bankkort",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "EU-kort från Försäkringskassan",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Reseförsäkringshandlingar",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Hotellbokningar",
				tags: {
					enviornment: ["mix", "landsbygd", "storstad", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Hyrbilsbokningar",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Offlinekarta",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Pass",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Utländsk valuta",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
		],
	},
	{
		title: "Hygienartiklar",
		icon: SoapIcon,
		items: [
			{
				item: "Deoderant",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Parfym",
				tags: {
					enviornment: ["mix", "storstad", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Handduk",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Hårstyling",
				tags: {
					enviornment: ["mix", "storstad", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Kam/Borste",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Lypsyl",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Hudkräm",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Nagelsax",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Pincett",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Rakningstillbehör",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Schampo & Tvål",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Smink",
				tags: {
					enviornment: ["mix", "storstad", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Solskydd",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Tandborste",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Tandkräm",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Tandtråd",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Toalettpapper",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Våtservetter",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Handsprit",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
		],
	},
	{
		title: "Underhållning",
		icon: CardsIcon,
		items: [
			{
				item: "Böcker",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Kortlek",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Korsordstidning",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Hörlurar (gärna med brusreducering)",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Simglasögon / Snorkel",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
		],
	},
	{
		title: "Elektronik",
		icon: TechIcon,
		items: [
			{
				item: "Reseadapter",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Tejp, förslagsvis silvertejp",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Mobiltelefon",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Mobilladdare",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Powerbank",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["2", "3", null],
					stopDuration: ["short", null],
				},
			},
		],
	},
	{
		title: "Kläder",
		icon: ClothesIcon,
		items: [
			{
				item: "Underkläder",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "T-shirts",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Shorts",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Badkläder",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Tjocktröja",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "En findress",
				tags: {
					enviornment: ["mix", "storstad", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Långbyxor",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Lämpliga skor",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Vandringskängor",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Sandaler",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Regnkläder",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
		],
	},
	{
		title: "Övrigt",
		icon: EtcIcon,
		items: [
			{
				item: "Adresslista",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Glasögon",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Keps",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Klocka",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Kniv",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["3", null],
					stopDuration: ["long", null],
				},
			},
			{
				item: "Korkskruv & Kapsylöppnare",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Liten ryggsäck",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Värktabletter",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Vätskeersättning",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Första hjälpen kit",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Allergimedicin",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Multiverktyg",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["2", "3", null],
					stopDuration: ["long", null],
				},
			},
			{
				item: "Myggmedel",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Nål & tråd",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Papper & penna",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Påsar i olika storlekar",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Reseguider",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Skavsårsplåster",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Tofs",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Tändare/tändstickor",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Vattenflaska",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Vattentät säck/påse",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Hänglås",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Tvättlina",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Tvättmedel",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
		],
	},
];

const state = reactive({
	PackingObject: [...packingBase],
	terrain: "",
	travelLength: "",
	stopDuration: "",
});

function handleSelection() {
	var output = JSON.parse(JSON.stringify(packingBase)) as typeof packingBase;
	output.forEach((category) => {
		if (state.terrain) {
			category.items = category.items.filter((item) => {
				return item.tags.enviornment.includes(state.terrain);
			});
		}

		if (state.travelLength) {
			category.items = category.items.filter((item) => {
				return item.tags.duration.includes(state.travelLength);
			});
		}

		if (state.stopDuration) {
			category.items = category.items.filter((item) => {
				return item.tags.stopDuration.includes(state.stopDuration);
			});
		}
	});

	state.PackingObject = [...output];
}

watch(
	() => [state.terrain, state.travelLength, state.stopDuration],
	() => {
		handleSelection();
	}
);
</script>

<style scoped lang="scss">
.packingwiz-wrapper {
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
	border: 1px solid lightgrey;
	padding: 2rem;
	border-radius: 8px;
	.input-rows {
		display: flex;
		flex-direction: column;
		row-gap: 1rem;
		padding-bottom: 1rem;
		border-bottom: 1px solid lightgrey;

		.category-radiobuttons {
			display: grid;
			grid-template-rows: 1fr 1fr;
			row-gap: 0.5rem;
			padding-bottom: 1rem;

			.title {
				font-weight: bold;
			}

			.radiobutton {
				display: flex;
				flex-direction: row;
				column-gap: 1rem;
			}

			.radiobuttons_3 {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				column-gap: 1rem;
			}
			.radiobuttons_2 {
				display: grid;
				grid-template-columns: 1fr 1fr;
				column-gap: 1rem;
			}
		}
	}
}
.card_text_and_image_wrapper {
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
}

.wizard-list-grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, 16rem);
	justify-items: center;
	justify-content: center;
	row-gap: 2rem;

	.category-title {
		border-bottom: 1px solid lightgrey;
		padding-bottom: 1rem;
		display: flex;
		flex-direction: row;
		align-items: center;
		column-gap: 0.5rem;
	}

	.itemlist {
		display: flex;
		flex-direction: column;
		row-gap: 0.5rem;
		padding-left: 0;
	}
}
</style>
