<template>
  <ContentWrapper>
    <div class="card-grid">
      <TicketPresentationCard
        v-for="(ticket, index) in bookingStore.availableTickets"
        :key="`${ticket.id}-${index}-ticket`"
        :ticket="ticket"
        :is-open="ticket.id != state.currentActiveTicket"
        @clicked="handleClick"
        :background-img-class="[...ticket_images, ...ticket_images][index]"
      >
      </TicketPresentationCard>
    </div>
  </ContentWrapper>
</template>

<script lang="ts" setup>
import TicketPresentationCard from "@/components/blocks/TicketPresentationCard.vue";
import ContentWrapper from "@/components/generics/ContentWrapper.vue";
import { useBookingStore } from "@/store/bookingStore";
import { reactive } from "vue";

const bookingStore = useBookingStore();

const state = reactive({
  currentActiveTicket: "",
});

function handleClick(id: string) {
  state.currentActiveTicket = id;
}

const ticket_images = [
  "background-florence",
  "background-london",
  "background-prague",
  "background-aqueduct",
  "background-barcelona",
  "background-louvre",
  "background-eifel",
  "background-vernazza",
  "background-railway-italy",
  "background-champs",
];
</script>

<style scoped lang="scss">
@import "../../../styles/variables.scss";

.card-grid {
  padding-top: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
}

@media (max-width: $screen-size-tablet) {
  .card-grid {
    padding-top: 4rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
  }
}

@media (max-width: $screen-size-mobile) {
  .card-grid {
    padding-top: 4rem;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
  }
}
</style>
