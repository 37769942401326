<template>
  <div class="tabs">
    <CustomTab
      v-for="(tab, index) in tabs"
      :key="`tab-${index}`"
      :selected="state.current_selected == tab.linkText"
      :text="tab.linkText"
      :fade="
        state.current_hovered != '' && state.current_hovered != tab.linkText
      "
      :link-to="tab.linkTo"
      @hover="handle_hover"
    >
    </CustomTab>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, PropType, reactive, watch } from "vue";
import CustomTab from "../generics/CustomTab.vue";
import { useRoute } from "vue-router";

const props = defineProps({
  tabs: {
    type: Array as PropType<{ linkText: string; linkTo: string }[]>,
    default: () => [
      { linkText: "Interrailkort", linkTo: "interrailkort" },
      { linkText: "Guider", linkTo: "guider" },
      { linkText: "Support", linkTo: "support" },
    ],
  },
});

const state = reactive({
  current_selected: "",
  current_hovered: "",
});

const route = useRoute();

function handle_hover(tab_name: string | null) {
  if (tab_name == null) {
    state.current_hovered = "";
    return;
  }
  state.current_hovered = tab_name;
}

watch(
  () => route.params,
  () => {
    var current_link_active_obj = props.tabs.find((tab_obj) => {
      return tab_obj.linkTo == route.path;
    });

    state.current_selected = current_link_active_obj
      ? current_link_active_obj?.linkText
      : "";
  }
);
</script>

<style scoped lang="scss">
.tabs {
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  justify-content: space-between;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}
</style>
